import React, {useState} from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {isEmpty} from "../../../_helpers/commonFunctions";

const HourPicker = (props) => {
    const [changingRaw, setChangingRaw] = useState(false);

    const handleOnChange = (e) => {
        if (!changingRaw && e) {
            if (props.onChange && typeof props.onChange === "function") {
                if (!moment(e).isValid()) {
                    props.onChange("00:00");
                } else {
                    props.onChange(moment(e).format("HH:mm"));
                }
            }
        }
    }

    const handleOnChangeRaw = (e) => {
        setChangingRaw(true);
        const val = e.target.value;
        const regex = /^([01]\d|2[0-3]):[0-5]\d$/;
        if (regex.test(val)) props.onChange(val);
    }

    const handleOnBlur = () => {
        if (changingRaw) setChangingRaw(false);
    }

    return (
        <div className={`input-container ${props.classes ? props.classes : ""}`}>
            {props.label && (
                <label htmlFor={props.label} className="input-label mb-2">
                    {props.label}
                    {props.required && <span className="text-danger">*</span>}
                </label>
            )}
            <div className="input-wrapper">
                <DatePicker
                    selected={props.selected}
                    onChange={(e) => handleOnChange(e)}
                    onChangeRaw={(e) => handleOnChangeRaw(e)}
                    onBlur={() => handleOnBlur()}
                    showTimeSelect={true}
                    showTimeSelectOnly={true}
                    timeIntervals={isEmpty(props.timeIntervals) ? 10 : props.timeIntervals}
                    timeCaption={"Time"}
                    timeFormat={"HH:mm"}
                    dateFormat={"HH:mm"}
                    disabled={props.disabled === true}
                    className={"form-control " + props.classes}
                    wrapperClassName={"datepicker-full-width"}
                />
            </div>
        </div>
    )
}

export default HourPicker;
