import {createSlice} from "@reduxjs/toolkit";

const initialShift = {
    name: "",
    hourFrom: "00:00",
    hourTo: "00:00",
    splitHourFrom: "00:00",
    splitHourTo: "00:00",
}

const initialWorkMovement = {
    date: null,
    time: "00:00",
    employeeName: "", // firstName|lastName|vatNumber
    employeeId: "",
    employeeProgramData: {},
    installationMasterId: "",
    movementType: "arrive", // arrive, leave
    delayReason: "", // 001, 002, 003
    notes: "",
}

const initialPermit = {
    employeeId: "",
    employeeIdentifier: "",
    permitType: "",
    dateFrom: "",
    dateTo: "",
}

const initialWorkPermit = {
    description: "",
    installationMasterId: "0",
    employees: [],
    notes: "",
}

const initialState = {
    companyHasErgani: false,
    payrollSettings: {
        autoSendEmployeeCard: false,
        autoSendOutOfTimeDays: false,
        shifts: [],
        companyNonWorkingDays: [],
        publicHolidays: [],
        multipliers: [],
        erganiCardNotificationOnHit: false,
        erganiCardNotificationOnHitEmails: [],
        erganiCardNotificationOnDelay: false,
        erganiCardNotificationOnDelayEmails: [],
        erganiCardNotificationOnDelayAfter: 5,
    },
    tempNewShift: initialShift,
    workingProgram: null,
    workingProgramOnEmployee: null,
    workingProgramNeedsSave: false,
    workingProgramExtraData: {
        workingDaysPerWeek: {},
        erganiCards: {},
        employeesInstallation: {},
    },
    legacyWorkingProgram: null,
    workingProgramOnlyView: false,

    newWorkMovement: initialWorkMovement,
    editWorkMovement: initialWorkMovement,

    newWorkPermit: initialWorkPermit,
    tempNewWorkPermit: initialPermit,
    editWorkPermit: initialWorkPermit,
    tempNewEditWorkPermit: initialPermit,

    searchWorkMovements: [],
    totalItemsWM: 0,
    perPageWM: 0,

    searchWorkPermits: [],
    totalItemsWP: 0,
    perPageWP: 0,

    qrScannerRequestData: {
        installationMasterId: "0",
    },
    qrScannerResponseData: {
        data: [],
    },

    // Ergani Submissions Report
    erganiSubmissionsReportRequestData: {
        page: 1,
        date_from: "",
        date_to: "",
        installationMasterId: "",
        submissionType: "",
    },
    erganiSubmissionsReportResponseData: {
        totalPages: 0,
        data: [],
    },

    // Payroll Hour Report
    hourReportResponseData: {
        data: [],
    },
    hourReportDisplayData: {
        page: 1,
        month: "",
        date_from: "",
        date_to: "",
        totalPages: 0,
        dayAnalysis: false,
        data: [],
    },

    // Upgrade Employee Limit Modal Data
    upgradeEmployeeLimitModalData: {
        show: false,
        text: "",
    },
}

export const PayrollSlice = createSlice({
    name: "payroll",
    initialState,
    reducers: {
        setCompanyHasErgani: (state, {payload}) => {
            state.companyHasErgani = payload;
        },
        setPayrollSettings: (state, {payload}) => {
            state.payrollSettings = payload;
        },
        setTempNewShift: (state, {payload}) => {
            state.tempNewShift = payload;
        },
        resetTempNewShift: (state) => {
            state.tempNewShift = initialShift;
        },
        setWorkingProgram: (state, {payload}) => {
            state.workingProgram = payload;
        },
        setWorkingProgramOnEmployee: (state, {payload}) => {
            state.workingProgramOnEmployee = payload;
        },
        setWorkingProgramNeedsSave: (state, {payload}) => {
            state.workingProgramNeedsSave = payload;
        },
        setWorkingProgramExtraData: (state, {payload}) => {
            state.workingProgramExtraData = payload;
        },
        setLegacyWorkingProgram: (state, {payload}) => {
            state.legacyWorkingProgram = payload;
        },
        setWorkingProgramOnlyView: (state, {payload}) => {
            state.workingProgramOnlyView = payload;
        },

        // Work Movements
        setNewWorkMovement: (state, {payload}) => {
            state.newWorkMovement = payload;
        },
        setEditWorkMovement: (state, {payload}) => {
            state.editWorkMovement = payload;
        },
        resetNewWorkMovement: (state) => {
            state.newWorkMovement = initialWorkMovement;
        },
        setSearchWorkMovements: (state, {payload}) => {
            state.searchWorkMovements = payload;
        },
        setSearchWorkMovementsTotalItems: (state, {payload}) => {
            state.totalItemsWM = payload;
        },
        setSearchWorkMovementsPerPage: (state, {payload}) => {
            state.perPageWM = payload;
        },

        // Work Permits
        setNewWorkPermit: (state, {payload}) => {
            state.newWorkPermit = payload;
        },
        resetNewWorkPermit: (state) => {
            state.newWorkPermit = initialWorkPermit;
        },
        setTempNewWorkPermit: (state, {payload}) => {
            state.tempNewWorkPermit = payload;
        },
        resetTempNewWorkPermit: (state) => {
            state.tempNewWorkPermit = initialPermit;
        },
        setEditWorkPermit: (state, {payload}) => {
            state.editWorkPermit = payload;
        },
        setTempNewEditWorkPermit: (state, {payload}) => {
            state.tempNewEditWorkPermit = payload;
        },
        resetTempNewEditWorkPermit: (state) => {
            state.tempNewEditWorkPermit = initialPermit;
        },
        setSearchWorkPermits: (state, {payload}) => {
            state.searchWorkPermits = payload;
        },
        setSearchWorkPermitsTotalItems: (state, {payload}) => {
            state.totalItemsWP = payload;
        },
        setSearchWorkPermitsPerPage: (state, {payload}) => {
            state.perPageWP = payload;
        },

        setQRScannerRequestData: (state, {payload}) => {
            state.qrScannerRequestData = payload;
        },
        setQRScannerResponseData: (state, {payload}) => {
            state.qrScannerResponseData = payload;
        },

        // Ergani Submissions Report
        setErganiSubmissionsReportRequestData: (state, {payload}) => {
            state.erganiSubmissionsReportRequestData = payload;
        },
        setErganiSubmissionsReportResponseData: (state, {payload}) => {
            state.erganiSubmissionsReportResponseData = payload;
        },

        // Payroll Hour Report
        setPayrollHourReportResponseData: (state, {payload}) => {
            state.hourReportResponseData = payload;
        },
        setPayrollHourReportDisplayData: (state, {payload}) => {
            state.hourReportDisplayData = payload;
        },

        // Upgrade Employee Limit Modal Data
        setUpgradeEmployeeLimitModalData: (state, {payload}) => {
            state.upgradeEmployeeLimitModalData = payload;
        }
    }
})

export const {
    setCompanyHasErgani,
    setPayrollSettings,
    setTempNewShift,
    resetTempNewShift,
    setWorkingProgram,
    setWorkingProgramOnEmployee,
    setWorkingProgramNeedsSave,
    setWorkingProgramExtraData,
    setLegacyWorkingProgram,
    setWorkingProgramOnlyView,

    // Work Movements
    setNewWorkMovement,
    setEditWorkMovement,
    resetNewWorkMovement,
    setSearchWorkMovements,
    setSearchWorkMovementsTotalItems,
    setSearchWorkMovementsPerPage,

    // Work Permits
    setNewWorkPermit,
    resetNewWorkPermit,
    setTempNewWorkPermit,
    resetTempNewWorkPermit,
    setEditWorkPermit,
    setTempNewEditWorkPermit,
    resetTempNewEditWorkPermit,
    setSearchWorkPermits,
    setSearchWorkPermitsTotalItems,
    setSearchWorkPermitsPerPage,

    setQRScannerRequestData,
    setQRScannerResponseData,

    // Ergani Submissions Report
    setErganiSubmissionsReportRequestData,
    setErganiSubmissionsReportResponseData,

    // Payroll Hour Report
    setPayrollHourReportResponseData,
    setPayrollHourReportDisplayData,

    // Upgrade Employee Limit Modal Data
    setUpgradeEmployeeLimitModalData
} = PayrollSlice.actions;
