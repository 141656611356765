import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    currentPath: "/",
    currentPathLocked: false,
    dirData: [],
    previewFile: {
        name: "",
        path: "",
        data: null,
        size: 0, // In MB
    },
    extraData: {
        spaceUsed: 0,
        cloudSpace: 0,
        cloudMaximumFileSize: 0,
        specialCode: "0",
    },
    copyFilePath: "",
    copyFileCut: false,

    // Emron functionalities
    disableCloudPage: false,
    disableEmronRegisterPage: false,
}

export const CloudSlice = createSlice({
    name: "cloud-slice",
    initialState,
    reducers: {
        setCurrentPath: (state, {payload}) => {
            state.currentPath = payload;
        },
        setCurrentPathLocked: (state, {payload}) => {
            state.currentPathLocked = payload;
        },
        setDirData: (state, {payload}) => {
            state.dirData = payload;
        },
        setPreviewFile: (state, {payload}) => {
            if (state.previewFile.data) URL.revokeObjectURL(state.previewFile.data); // Clear last blob preview from browser memory
            state.previewFile = payload;
        },
        resetPreviewFile: (state) => {
            if (state.previewFile.data) URL.revokeObjectURL(state.previewFile.data); // Clear last blob preview from browser memory
            state.previewFile = {
                name: "",
                path: "",
                data: null,
                size: 0,
            }
        },
        setExtraData: (state, {payload}) => {
            state.extraData = payload;
        },
        setCopyFilePath: (state, {payload}) => {
            state.copyFilePath = payload;
        },
        setCopyFileCut: (state, {payload}) => {
            state.copyFileCut = payload;
        },

        // Emron functionalities
        setDisableCloudPage: (state, {payload}) => {
            state.disableCloudPage = payload;
        },
        setDisableEmronRegisterPage: (state, {payload}) => {
            state.disableEmronRegisterPage = payload;
        }
    }
})

export const {
    setCurrentPath,
    setCurrentPathLocked,
    setDirData,
    setPreviewFile,
    resetPreviewFile,
    setExtraData,
    setCopyFilePath,
    setCopyFileCut,

    // Emron functionalities
    setDisableCloudPage,
    setDisableEmronRegisterPage
} = CloudSlice.actions;
