import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import {
  checkPermission,
  getCountryOptions,
  getCurrentUser,
  getDefaultOptionByValue,
  getDOYOptions,
  getGeneratedMaskCodeCLient,
  getMainInstallation,
  getOptionsByData,
  getVatCategoryAbroadOptions,
  getVatCategoryOptions,
  isEmpty,
  isValidEmail,
} from "../../../../_helpers/commonFunctions";
import {useDispatch, useSelector} from "react-redux";
import {setSaveNewClient} from "../../../../_reducers/activitySlice";
import {
  setNewClient,
  setNewClientStatus,
  setNewContactPoint,
} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import getMyData from "../../../../_helpers/sharedmydata";
import TextArea from "../../../common/TextArea";
import EditableTable from "../../../primer/Editable-Table";
import {
  findObjectFromArrayOfObjects,
  getLabelByValue,
  getObjectFromArrayOfObjects,
} from "../../../../_helpers/helperFunctions";
import {toast} from "react-toastify";
import Checkbox from "../../../common/CheckBox";
import {fetchClientsSettings,} from "../../../../_apis/api";
import axios from "axios";
import {useTranslation} from 'react-i18next';
import i18n from "i18next";
import AnathetousesModal from "../../../primer/POPUP-COMPONENTS/AnathetousesModal";

const NewClientGeneral = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
  const ACTIVITY = useSelector((state) => state.ACTIVITY);
  const company = useSelector((state) => state.COMPANY_DATA.company);
  const zeroVatReasonOptions = getMyData({type: 'zerovatreason'});
  const permissionsData = JSON.parse(localStorage.getItem("permissions"));

  const [incrKey, setIncrKey] = useState(0);
  useEffect(() => {
    setIncrKey(incrKey + 1);
  }, [CLIENTS_DATA.newClient.contactPoints])

  const isValidCustomVat = (value) => {
    let error;
    if (+value > 100) {
      error = 'Can not be greater than 100.';
    }
    return error;
  }
  const clientCategories = getObjectFromArrayOfObjects(CLIENTS_DATA.clientsSettings.categories, "active", "isActive");
  const clientCategoriesOption = getOptionsByData(clientCategories, "_id", "category");
  let vatCategoryList = (CLIENTS_DATA.newClient.categoryName === "Client within E.U." || CLIENTS_DATA.newClient.categoryName === "Client outside E.U." || (CLIENTS_DATA.newClient.categoryName === "Retail" && CLIENTS_DATA.newClient.country !== "GR")) ? getVatCategoryAbroadOptions() : getVatCategoryOptions();
  const clientContactPointsColumns = [
    {
      name: "No",
      field: "no",
      editable: false,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('ClientsNewGeneral.contactPoints.contactName'),
      field: "name",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('ClientsNewGeneral.contactPoints.mobilePhone'),
      field: "mobile",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('ClientsNewGeneral.contactPoints.landLine'),
      field: "landline",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('ClientsNewGeneral.contactPoints.otherPhone'),
      field: "otherPhone",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('ClientsNewGeneral.contactPoints.email'),
      field: "email",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('ClientsNewGeneral.contactPoints.contactNotes'),
      field: "contactNotes",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('ClientsNewGeneral.contactPoints.acceptNotifications'),
      field: "acceptsNotifications",
      editable: true,
      inputType: {
        type: "checkbox",
      },
    },
  ];
  useEffect(() => {
    dispatch(
      fetchClientsSettings({
        company: company.id,
        year: company.year.toString(),
        isActive: "active"
      })
    );
  }, [dispatch]);
  const currentUser = getCurrentUser();
  useEffect( () => {
    console.log(currentUser.lang)
    setTableColumns(clientContactPointsColumns.slice());
  },[i18n.language])
  const [tableColumns, setTableColumns] = useState(clientContactPointsColumns.slice());
  const [inActiveClientWarning, showInActiveClientWarnning] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.: Select Greece, Accept only Greece, show only Greece
  let countryData = getCountryOptions("greece"); // Only Greece Country Data
  let [countriesList, setCountriesList] = useState(countryData);
  let disabledFields = false;
  if (
    CLIENTS_DATA.newClient.category === undefined ||
    CLIENTS_DATA.newClient.category === ""
  ) {
    disabledFields = true;
  }
  const apiURL2 = process.env.REACT_APP_API_URL2;

  const fetchViesDetails = async (vat, country) => {
    let jsonData = {
      vatNumber: vat,
      country: country
    }
    if (vat === "" || vat === undefined) {
      toast.error("Please input a vat Number to check.");
    } else if (country === "" || country === undefined) {
      toast.error("Please select a country for VIES check.");
    } else {
      console.log(jsonData);
      const abort = axios.CancelToken.source();
      const timer = setTimeout(() => abort.cancel(`timeout`), 10000);
      await axios.post(apiURL2 + `/client/fetch-vies-details/`, JSON.stringify(jsonData), {headers: {'Content-Type': 'application/json'}})
          .then((res) => {
            if (res.data && res.data.status === "200") {
              if (!ACTIVITY.saveNewClient) dispatch(setSaveNewClient(true));

              clearTimeout(timer)
              let companyDetails = res.data.data;
              if (companyDetails.valid === true) {
                let newClient = Object.assign({}, CLIENTS_DATA.newClient);
                newClient.name = companyDetails?.name !== undefined ? companyDetails?.name : "";
                let address = companyDetails?.address.split(",");
                newClient.postalCode = address[2] !== undefined ? address[2].split(" ")[1] : "";
                newClient.address = address[1] !== undefined ? address[1] : "";
                newClient.city = address[2] !== undefined ? address[2].split(" ")[2] : "";
                newClient.vatCategory = 'Zero VAT';
                newClient.vatReason = "14";
                let updatedContactPoints = CLIENTS_DATA.newClient.contactPoints.slice();
                let newContacts = {
                  name: newClient.name,
                  email: newClient.email,
                  mobile: newClient.phone,
                  acceptsNotifications: "active"
                };
                updatedContactPoints["0"] = {
                  ...updatedContactPoints["0"],
                  ...newContacts,
                };
                let defaultInstallation = [
                  {
                    id: 0,
                    no: 0,
                    type: getMainInstallation()?.value,
                    address: newClient.address,
                    DOY: newClient.DOY,
                    city: newClient.city,
                    county: newClient.county,
                    vatCategory: newClient.vatCategory,
                    vatReason: newClient.vatReason,
                    customVat: newClient.customVat,
                    postalCode: newClient.postalCode
                  },
                ];
                dispatch(setNewClient({
                  ...newClient,
                  contactPoints: updatedContactPoints,
                  installations: defaultInstallation
                }));
                toast.success(res.data.message);
              } else {
                toast.error("VAT Number with this country was not found on VIES");
              }
            } else {
              console.log(res)
              toast.error(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response?.status === 400) {
              alert({
                message: error.response.data?.Error ? error.response.data?.Error : error?.message === "timeout" ? "Timeout. Please try again later!" : error.code,
                type: 'warning',
              })
            }
          });
    }
  };
  const fetchTaxisNetDetails = async (data) => {
    let jsonData = {
      vatNumber: data,
      onlyAADE: "true"
    }
    const abort = axios.CancelToken.source();
    const timer = setTimeout(
        () => abort.cancel(`timeout`),
        10000
    )
    await axios.post(apiURL2 + `/client/fetch-vat-details/`, JSON.stringify(jsonData), { headers: { 'Content-Type': 'application/json'}})
        .then((res) => {
          if (res.data && res.data.status === "200") {
            if (!ACTIVITY.saveNewClient) dispatch(setSaveNewClient(true));

            clearTimeout(timer)
            let companyDetails = res.data.data;
            let newClient = Object.assign({}, CLIENTS_DATA.newClient);
            newClient.name = companyDetails?.name !== undefined ? companyDetails?.name : "";
            newClient.smallName = companyDetails?.smallName !== undefined ? companyDetails?.smallName : "";
            newClient.activity = companyDetails?.activity !== undefined ? companyDetails?.activity : "";
            newClient.postalCode = companyDetails?.tk !== undefined ? companyDetails?.tk : "";
            newClient.address =  companyDetails?.address !== undefined ? companyDetails?.address : "";
            newClient.DOY = getDefaultOptionByValue(getDOYOptions(), Number(companyDetails?.doyCode), "value", "label", "value").value;
            newClient.city = companyDetails?.city !== undefined ? companyDetails?.city : "";
            if(companyDetails?.vatCategory === 'Y'){
              newClient.vatCategory = 'Normal VAT';
            }
            let updatedContactPoints = CLIENTS_DATA.newClient.contactPoints.slice();
            let newContacts = {
              name: newClient.name,
              email: newClient.email,
              mobile: newClient.phone,
              acceptsNotifications: "active"
            };
            updatedContactPoints["0"] = {
              ...updatedContactPoints["0"],
              ...newContacts,
            };
            let defaultInstallation = [
              {
                id: 0,
                no: 0,
                type: getMainInstallation()?.value,
                address: newClient.address,
                DOY: newClient.DOY,
                city: newClient.city,
                county: newClient.county,
                vatCategory: newClient.vatCategory,
                vatReason: newClient.vatReason,
                customVat: newClient.customVat,
                postalCode: newClient.postalCode
              },
            ];
            dispatch(setNewClient({...newClient, contactPoints: updatedContactPoints, installations: defaultInstallation}));
            if (!ACTIVITY.saveNewClient) {
              dispatch(setSaveNewClient(true));
            }
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response?.status === 400){
            alert({
              message: error.response.data?.Error ? error.response.data?.Error : error?.message === "timeout" ? "Timeout. Please try again later!" : error.code,
              type: 'warning',
            })
          }
        });
  }
  const handleOnChange = async (e, type = "default", ddname = "") => {
    setShowErrors(true);
    // Set if editing the form
    if (!ACTIVITY.saveNewClient) {
      dispatch(setSaveNewClient(true));
    }
    let name;
    let value;
    if (type === "dd") {
      name = ddname;
      value = e.value;
    } else if (type === "ac") {
      name = ddname;
      value = e.row.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    if(name === 'customVat'){
      let error;
      if (+value > 100) {
        error = 'Can not be greater than 100.';
        toast.error(error);
        value = "";
      }
      value = +String(value).slice(0, 3);
      if (value === 0) {
        value = "";
      }
    }
    // Set settings data
    let newClient = Object.assign({}, CLIENTS_DATA.newClient);
    let newClientData = {};
    if (name === "category") {
      newClientData[name] = value;
      newClientData.categoryName = getLabelByValue(clientCategoriesOption, value, "value");
      const selectedCategory = await findObjectFromArrayOfObjects(clientCategories, value, "_id");
      if (selectedCategory) {
        newClient.categoryMaskCodePrefix = selectedCategory.maskCodePrefix;
        newClient.systemCode = getGeneratedMaskCodeCLient(selectedCategory._id, clientCategories, selectedCategory.maskCodePrefix); // New With Updated Prefix
        newClient.accounting = selectedCategory.accounting ? selectedCategory.accounting : '';
        newClient.bypassAccounting = selectedCategory.bypassAccounting ? selectedCategory.bypassAccounting : '';
      }
      // If Category: Retail: Add 000000000, do not validate VAT number. Field not mandatory
      if (newClientData.categoryName === "Retail" || newClientData.categoryName === "Patient") {
        newClientData.vatNumber = "000000000";
      } else {
        newClientData.vatNumber = "";
      }
      if (newClientData.categoryName === "Client within E.U.") {
        // Update country list for inside EU.
        // Client inside E.U., show only countries within E.U. except Greece
        let EuCountryData = getCountryOptions("insideEU");
        setCountriesList(EuCountryData);
      } else if (newClientData.categoryName === "Client outside E.U.") {
        // Update country list for outside EU
        // outside E.U., show only countries outside E.U.
        let NonEuCountryData = getCountryOptions("outsideEU");
        setCountriesList(NonEuCountryData);
      }else if(newClientData.categoryName === "Retail" || newClientData.categoryName === "Patient"){
        setCountriesList(getCountryOptions('all'));
        newClientData.country = "GR";
      } else {
        // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:
        // Select Greece, Accept only Greece, show only Greece
        let onlyGreeceCountryData = getCountryOptions("greece");
        setCountriesList(onlyGreeceCountryData);
        if (onlyGreeceCountryData?.length > 0) {
          newClientData.country = onlyGreeceCountryData["0"].value;
        }
      }
    } else {
      if (name === "isActive" && value === "inactive") {
        showInActiveClientWarnning(true);
      } else {
        newClientData[name] = value;
      }
    }
    vatCategoryList = (newClientData.categoryName === "Client within E.U." || newClientData.categoryName === "Client outside E.U." || (newClientData.categoryName === "Retail" && newClientData.country !== "GR")) ? getVatCategoryAbroadOptions() : getVatCategoryOptions();
    let updatedContactPoints = CLIENTS_DATA.newClient.contactPoints.slice();
    let newContacts = {
      name: newClient.name,
      email: newClient.email,
      mobile: newClient.phone,
      acceptsNotifications: "active"
    }

    if (e) {
      if (name === "name") {
        newContacts["name"] = value;
      } else if (name === "email") {
        newContacts["email"] = value;
      } else if (name === "phone") {
        newContacts["mobile"] = value;
      } else if (name === "category") {
        newContacts["mobile"] = "";
      }
    }

    updatedContactPoints["0"] = {
      ...updatedContactPoints["0"],
      ...newContacts,
    };
    newClient = { ...newClient, ...newClientData };

    // Default Installation
    let defaultInstallation = [
      {
        id: 0,
        no: 0,
        type: getMainInstallation()?.value,
        address: newClient.address,
        DOY: newClient.DOY,
        city: newClient.city,
        county: newClient.county,
        vatCategory: newClient.vatCategory,
        vatReason: newClient.vatReason,
        customVat: newClient.customVat,
        postalCode: newClient.postalCode
      },
    ];
    dispatch(
      setNewClient({
        ...newClient,
        contactPoints: updatedContactPoints,
        installations: defaultInstallation,
      })
    );
  };

  // It will hide or show the table columns for searchtable.
  const handleColumnShowHide = (field) => {
    let tempTc = tableColumns.slice();
    let object = getObjectFromArrayOfObjects(tempTc, field, "field");
    const index = tempTc.findIndex((item) => field === item.field);
    if (object.length > 0) {
      tempTc[index].hiddenColumn = !object["0"].hiddenColumn;
      setTableColumns(tempTc);
    }
  };

  const parentMydataActions = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }

      let tempContactPoint = Object.assign({}, CLIENTS_DATA.newContactPoint);
      if (e.key !== "Enter") {
        tempContactPoint[data.field] = data.value;
        dispatch(setNewContactPoint(tempContactPoint)); // Update redux
        if (data.value !== "") {
          let updatedContactPoint = Object.assign({}, tempContactPoint);
          let oldContactPoints = CLIENTS_DATA.newClient.contactPoints;
          updatedContactPoint.no = oldContactPoints.length + 1;
          updatedContactPoint.id = oldContactPoints.length + 1;
          dispatch(setNewClient({...CLIENTS_DATA.newClient, contactPoints: [...oldContactPoints, updatedContactPoint],}));
          dispatch(setNewContactPoint(CLIENTS_DATA.newContactPointInitialValue)); // Reset new category mydata
        }
      }
      if (e.key === "Enter" && data.value === "add") {
        let updatedContactPoint = Object.assign({}, tempContactPoint);
        let oldContactPoints = CLIENTS_DATA.newClient.contactPoints;
        updatedContactPoint.no = oldContactPoints.length + 1;
        updatedContactPoint.id = oldContactPoints.length + 1;
        dispatch(setNewClient({...CLIENTS_DATA.newClient, contactPoints: [...oldContactPoints, updatedContactPoint],}));
        dispatch(setNewContactPoint(CLIENTS_DATA.newContactPointInitialValue)); // Reset new category mydata
      }
    },
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      if (data.name && data.name.length === 0) {
        toast.error("Contact Name is mandatory field.");
      } else {
        setSaveNewClient(true);
        let contactPoints = CLIENTS_DATA.newClient.contactPoints.slice();
        if (contactPoints.find((item) => item.id === id) !== undefined) {
          contactPoints[id - 1] = {...contactPoints[id - 1], ...data};
          dispatch(setNewClient({...CLIENTS_DATA.newClient, contactPoints: contactPoints,})); // update data
        }
      }
    },
    selected: () => {
      console.log("selected mydata row clicked");
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveNewClient) {
        dispatch(setSaveNewClient(true));
      }
      let contactPoints = CLIENTS_DATA.newClient.contactPoints.slice();
      if (id > 1 && contactPoints.length >= 2) {
        contactPoints.splice(id - 1, 1);
        dispatch(setNewClient({...CLIENTS_DATA.newClient, contactPoints: contactPoints,}));
      } else {
        toast.error("First row can not be deleted.");
      }
    },
  };
  const handleConfirmCategory = () => {
    // Set Status Inactive on Yes
    dispatch(setNewClientStatus("inactive"));
    showInActiveClientWarnning(false);
  };

  const [loadingPeppolDetails, setLoadingPeppolDetails] = useState(false);
  const handleGetCompanyInfoPeppol = () => {
    if (!isEmpty(CLIENTS_DATA.newClient.vatNumber) && String(CLIENTS_DATA.newClient.vatNumber).length === 9) {
      setLoadingPeppolDetails(true);
      axios.post(process.env.REACT_APP_API_URL2 + "/client/get-company-info-peppol", {vatNumber: CLIENTS_DATA.newClient.vatNumber}, {
        headers: { 'Content-Type': 'application/json'}
      }).then((res) => {
        setLoadingPeppolDetails(false);
        if (res.data.status === "200") {
          if (res.data.data && res.data.data?.length > 0) {
            if (!ACTIVITY.saveNewClient) dispatch(setSaveNewClient(true));
            dispatch(setNewClient({...CLIENTS_DATA.newClient, peppolPartyIdentification: res.data.data}));
            toast.success("Οι κωδικοί αναθετουσών αρχών συμπληρώθηκαν αυτόματα επιτυχώς.");
          } else {
            toast.info("Δεν βρέθηκαν αναθέτουσες αρχές για αυτό το Α.Φ.Μ.");
          }
        } else {
          toast.error(res.data.message);
        }
      }).catch((error) => {
        setLoadingPeppolDetails(false);
        console.log(error);
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      })
    } else {
      toast.error("Παρακαλώ συμπληρώστε Α.Φ.Μ. πρώτα.");
    }
  }

  return (
    <div className="new-client-general product-form position-relative">
      {disabledFields && (
        <div className="overlay_w">{t('ClientsNewGeneral.message')}</div>
      )}
        <Row className="position-relative border-bottom mb-3">
          <Col
            xs="12"
            md="12"
            className="d-flex align-items-center justify-content-end "
          >
            <div className="inline-field d-flex align-items-center acremd  mb-3">
              <label htmlFor="client-active" className="mb-0 mr-2 ">
                Ενεργός Πελάτης:
              </label>
              <Checkbox
                className="mb-2"
                name="isActive"
                key={Math.random()}
                id="client-active"
                defaultValue={CLIENTS_DATA.newClient.isActive}
                value={CLIENTS_DATA.newClient.isActive === "active" ? "inactive" : "active"}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </Col>
        </Row>
        {/* First Row */}
        <Row>
          <Col sm="12" md="3" className="showWithOverlay">
            <Dropdown
                label={t('ClientsNewGeneral.clientCategory')}
              required={true}
              options={clientCategoriesOption}
              classes="w-100 mb-3"
              name="category"
              isCLientCategory={true}
              key={Math.random()}
              defaultValue={getDefaultOptionByValue(clientCategories, CLIENTS_DATA.newClient.category, "_id", "category", "_id")}
              onChange={(e) => handleOnChange(e, "dd", "category")}
            />
          </Col>
          <Col sm="12" md="3">
            <Input
              label={t('ClientsNewGeneral.systemCode')}
              className="mb-3 w-100"
              disabled={true}
              name="systemCode"
              value={CLIENTS_DATA.newClient.systemCode}
            />
          </Col>
          <Col sm="12" md="3">
            <Input
              label={t('ClientsNewGeneral.manualCode.code')}
              className="mb-3 w-100"
              name="manualCode"
              type="text"
              value={CLIENTS_DATA.newClient.manualCode}
              placeholder={t('ClientsNewGeneral.manualCode.eg')}
              onChange={(e) => handleOnChange(e)}
            />
          </Col>
          <Col sm="12" md="3" className="d-flex align-items-center justify-content-end">
            <Button disabled className="disabled" type="button">
              {t("ClientsNewGeneral.files")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="4">
            <div className="mb-3">
              <Input
                label={t('ClientsNewGeneral.name.name')}
                required={true}
                className="w-100"
                name="name"
                type="text"
                value={CLIENTS_DATA.newClient.name}
                placeholder={t('ClientsNewGeneral.name.placeholder')}
                onChange={(e) => handleOnChange(e)}
              />
              {showErrors && CLIENTS_DATA.newClient.name === "" && (
                <div className="text-danger">{t('ClientsNewGeneral.name.required')}</div>
              )}
            </div>
          </Col>
          {CLIENTS_DATA.newClient.categoryName !== "" &&
              CLIENTS_DATA.newClient.categoryName !== "Retail" &&
              CLIENTS_DATA.newClient.categoryName !== "Patient" &&
          <Col sm="12" md="4">
            <Input
              label={t('ClientsNewGeneral.smallName.smallName')}
              className="mb-3 w-100"
              name="smallName"
              type="text"
              value={CLIENTS_DATA.newClient.smallName}
              placeholder={t('ClientsNewGeneral.smallName.placeholder')}
              onChange={(e) => handleOnChange(e)}
            />
          </Col>
          }
              <Col sm="12" md="4">
                <div className="mb-3">
                  <Dropdown
                    label={t('ClientsNewGeneral.vatCategory.vatCategory')}
                    classes="w-100"
                    required={true}
                    name="vatCategory"
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(vatCategoryList, CLIENTS_DATA.newClient.vatCategory, "value", "label", "value")}
                    options={vatCategoryList}
                    onChange={(e) => handleOnChange(e, "dd", "vatCategory")}
                  />
                  {showErrors && CLIENTS_DATA.newClient.vatCategory === "" && (
                    <div className="text-danger">{t('ClientsNewGeneral.vatCategory.required')}</div>
                  )}
                </div>
              </Col>
          {(CLIENTS_DATA.newClient.vatCategory !== "" && CLIENTS_DATA.newClient.vatCategory === 'Zero VAT') && (
              <Col sm="12" md="4">
                <div className="mb-3">
                  <Dropdown
                      classes='w-100'
                      label={t('ClientsNewGeneral.0vatReason.0vatReason')}
                      key = {Math.random()}
                      name="vatReason"
                      required={true}
                      options={zeroVatReasonOptions}
                      defaultValue={CLIENTS_DATA.newClient.vatReason ? { value: CLIENTS_DATA.newClient.vatReason, label: getLabelByValue(zeroVatReasonOptions, CLIENTS_DATA.newClient.vatReason,'value'), color: "#76AEB7" } : null}
                      onChange = {(e) => handleOnChange(e, 'dd', 'vatReason')}
                  />
                  {showErrors && CLIENTS_DATA.newClient.vatCategory === 'Zero VAT' && CLIENTS_DATA.newClient.vatReason === "" && (
                      <div className="text-danger">{t('ClientsNewGeneral.0vatReason.required')}</div>
                      )}
                </div>
              </Col>
              )}
          { (CLIENTS_DATA.newClient.vatCategory !== "" && CLIENTS_DATA.newClient.vatCategory === 'Custom VAT') &&
              <Col xs="12" md="4">
                <div className="mb-3">
                  <Input
                      name="customVat"
                      type="number"
                      validate={isValidCustomVat}
                      placeholder={t('ClientsNewGeneral.customVat.placeholder')}
                      label={t('ClientsNewGeneral.customVat.customVat')}
                      charLimit={3}
                      onChange = {(e) => handleOnChange(e)}
                      value={ CLIENTS_DATA.newClient.customVat }
                  />
                  { (CLIENTS_DATA.newClient.vatCategory === 'Custom VAT' &&
                      CLIENTS_DATA.newClient.customVat === "") &&
                      <div className="text-danger">{t('ClientsNewGeneral.customVat.required')}</div>
                  }
                </div>
              </Col>
          }
        </Row>
        <Row>
          {/* Retail: Add 000000000, do not validate VAT number. Field not mandatory */}
          {(CLIENTS_DATA.newClient.categoryName === "Retail" || CLIENTS_DATA.newClient.categoryName === "Patient") && (
            <Col sm="12" md="4">
              <Input
                label={t('ClientsNewGeneral.vatNumber.vatNumber')}
                className="mb-3 w-100 bg-white"
                name="vatNumber"
                type="text"
                defaultValue={CLIENTS_DATA.newClient.vatNumber}
                placeholder={t('ClientsNewGeneral.vatNumber.placeholder')}
                onChange={(e) => handleOnChange(e)}
              />
            </Col>
          )}
          {/* Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.: Validate VAT number, use "Get Details" button to get company details. Mandatory field */}
          {(CLIENTS_DATA.newClient.categoryName === "Freelancer" ||
            CLIENTS_DATA.newClient.categoryName === "O.E." ||
            CLIENTS_DATA.newClient.categoryName === "E.E." ||
            CLIENTS_DATA.newClient.categoryName === "E.P.E." ||
            CLIENTS_DATA.newClient.categoryName === "I.K.E." ||
            CLIENTS_DATA.newClient.categoryName === "A.E." ||
            CLIENTS_DATA.newClient.categoryName === "Non-Profit Organization" ||
            CLIENTS_DATA.newClient.categoryName === 'Wholesale Others' ||
            CLIENTS_DATA.newClient.categoryName === "Client within E.U." ||
            CLIENTS_DATA.newClient.categoryName === "Client outside E.U.") && (
            <Col sm="12" md="4">
              <div className="mb-3">
                <Input
                  label={t('ClientsNewGeneral.vatNumber.vatNumber')}
                  className="w-100 bg-white"
                  name="vatNumber"
                  required={true}
                  charLimit={20}
                  type="text"
                  value={CLIENTS_DATA.newClient.vatNumber}
                  placeholder={t('ClientsNewGeneral.vatNumber.placeholder')}
                  onChange={(e) => handleOnChange(e)}
                />
                {showErrors && CLIENTS_DATA.newClient.vatNumber === "" && (
                  <div className="text-danger">{t('ClientsNewGeneral.vatNumber.required')}</div>
                )}
              </div>
            </Col>
          )}
          {/* Show only if category is NOT Retail or Patient */}
          {/* Use "Get Greek Company Details from taxisnet" algorithm */}
          {CLIENTS_DATA.newClient.categoryName !== "" &&
            CLIENTS_DATA.newClient.categoryName !== "Retail" &&
            CLIENTS_DATA.newClient.categoryName !== "Patient" &&
            CLIENTS_DATA.newClient.categoryName !== "Client within E.U." &&
            CLIENTS_DATA.newClient.categoryName !== "Client outside E.U." && (
              <Col sm="12" md="2" className="d-flex align-items-center">
                <Button
                  onClick={() => fetchTaxisNetDetails(CLIENTS_DATA.newClient.vatNumber)}
                  size="sm"
                  className="mb-3"
                  type="button"
                >
                  {t('ClientsNewGeneral.details')}
                </Button>
              </Col>
            )}
          {CLIENTS_DATA.newClient.categoryName === "Client within E.U." && (
                  <Col sm="12" md="2" className="d-flex align-items-center">
                    <Button
                        onClick={() => fetchViesDetails(CLIENTS_DATA.newClient.vatNumber, CLIENTS_DATA.newClient.country)}
                        size="sm"
                        className="mb-3"
                        type="button"
                    >
                      VIES CHECK
                    </Button>
                  </Col>
              )}
          {(CLIENTS_DATA.newClient.categoryName === "Freelancer" ||
              CLIENTS_DATA.newClient.categoryName === "O.E." ||
              CLIENTS_DATA.newClient.categoryName === "E.E." ||
              CLIENTS_DATA.newClient.categoryName === "E.P.E." ||
              CLIENTS_DATA.newClient.categoryName === "I.K.E." ||
              CLIENTS_DATA.newClient.categoryName === "A.E." ||
              CLIENTS_DATA.newClient.categoryName === "Non-Profit Organization" ||
              CLIENTS_DATA.newClient.categoryName === 'Wholesale Others') && (
          <Col sm="12" md="3">
            <Dropdown
              label={t('ClientsNewGeneral.doy.doy')}
              classes="w-100"
              required={
                CLIENTS_DATA.newClient.categoryName !== "Retail" &&
                  CLIENTS_DATA.newClient.categoryName !== "Patient" &&
                  CLIENTS_DATA.newClient.categoryName !== "Client within E.U." &&
                  CLIENTS_DATA.newClient.categoryName !== "Client outside E.U."
              }
              searchEnable={true}
              name="DOY"
              key={Math.random()}
              defaultValue={getDefaultOptionByValue(getDOYOptions(), Number(CLIENTS_DATA.newClient.DOY), "value", "label", "value")}
              options={getDOYOptions()}
              onChange={(e) => handleOnChange(e, "dd", "DOY")}
            />
            {CLIENTS_DATA.newClient.categoryName !== "Retail" &&
              CLIENTS_DATA.newClient.categoryName !== "Patient" &&
              CLIENTS_DATA.newClient.categoryName !== "Client within E.U." &&
              CLIENTS_DATA.newClient.categoryName !== "Client outside E.U." &&
              showErrors &&
              CLIENTS_DATA.newClient.DOY === "" && (
                <div className="text-danger">{t('ClientsNewGeneral.doy.required')}</div>
              )}
          </Col> )}
          {(CLIENTS_DATA.newClient.categoryName === "Freelancer" ||
              CLIENTS_DATA.newClient.categoryName === "O.E." ||
              CLIENTS_DATA.newClient.categoryName === "E.E." ||
              CLIENTS_DATA.newClient.categoryName === "E.P.E." ||
              CLIENTS_DATA.newClient.categoryName === "I.K.E." ||
              CLIENTS_DATA.newClient.categoryName === "A.E." ||
              CLIENTS_DATA.newClient.categoryName === "Non-Profit Organization" ||
              CLIENTS_DATA.newClient.categoryName === 'Wholesale Others') && (
          <Col sm="12" md="3">
            <Input
                label={t('ClientsNewGeneral.activity.activity')}
                className="w-100"
                name="activity"
                type="text"
                charLimit={1000}
                required={
                    CLIENTS_DATA.newClient.categoryName !== "Retail" &&
                    CLIENTS_DATA.newClient.categoryName !== "Patient"
                }
                value={CLIENTS_DATA.newClient.activity}
                placeholder={t('ClientsNewGeneral.activity.placeholder')}
                onChange={(e) => handleOnChange(e)}
            />
            {CLIENTS_DATA.newClient.categoryName !== "Retail" &&
                CLIENTS_DATA.newClient.categoryName !== "Patient" &&
                showErrors &&
                CLIENTS_DATA.newClient.activity === "" && (
                    <div className="text-danger">{t('ClientsNewGeneral.activity.required')}</div>
                )}
          </Col>
          )}
          {/* show only if Category is "Patient" */}
          {CLIENTS_DATA.newClient.categoryName === "Patient" && (
            <Col sm="12" md="3">
              <div className="mb-3">
                <Input
                  label={t("ClientsNewGeneral.amka.amka")}
                  className="mb-3 w-100"
                  name="amka"
                  type="text"
                  charLimit={30}
                  value={CLIENTS_DATA.newClient.amka}
                  placeholder={t("ClientsNewGeneral.amka.placeholder")}
                  onChange={(e) => handleOnChange(e)}
                />
                {showErrors &&
                    CLIENTS_DATA.newClient.categoryName === "Patient"  &&
                    CLIENTS_DATA.newClient.email === "" &&
                    CLIENTS_DATA.newClient.amka === "" &&
                    CLIENTS_DATA.newClient.phone === "" && (
                        <div className="text-danger">{t("ClientsNewGeneral.amka.required")}</div>
                    )}
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col sm="12" md="3">
            {/* Mandatory field EXCEPT if category retail & patient */}
            <Dropdown
              label={t('ClientsNewGeneral.country.country')}
              classes="w-100"
              required={CLIENTS_DATA.newClient.categoryName !== "Retail" && CLIENTS_DATA.newClient.categoryName !== "Patient"}
              name="country"
              searchEnable={true}
              key={Math.random()}
              defaultValue={getDefaultOptionByValue(countriesList, CLIENTS_DATA.newClient.country, "value", "label", "value")}
              options={countriesList}
              onChange={(e) => handleOnChange(e, "dd", "country")}
            />
            {CLIENTS_DATA.newClient.categoryName !== "Retail" &&
              CLIENTS_DATA.newClient.categoryName !== "Patient" &&
              showErrors &&
              CLIENTS_DATA.newClient.country === "" && (
                <div className="text-danger">{t('ClientsNewGeneral.country.required')}</div>
              )}
          </Col>
          <Col sm="12" md="3">
            <div className="mb-3">
              {/* Mandatory field EXCEPT if category retail & patient */}
              <Input
                label={t('ClientsNewGeneral.address.address')}
                className="w-100"
                name="address"
                type="text"
                charLimit={100}
                required={CLIENTS_DATA.newClient.categoryName !== "Retail"}
                value={CLIENTS_DATA.newClient.address}
                placeholder={t('ClientsNewGeneral.address.placeholder')}
                onChange={(e) => handleOnChange(e)}
              />
              {CLIENTS_DATA.newClient.categoryName !== "Retail" &&
                showErrors &&
                CLIENTS_DATA.newClient.address === "" && (
                  <div className="text-danger">{t('ClientsNewGeneral.address.required')}</div>
                )}
            </div>
          </Col>
          <Col sm="12" md="3">
            <div className="mb-3">
              {/* Mandatory field EXCEPT if category retail & patient */}
              <Input
                  label={t('ClientsNewGeneral.city.city')}
                  className="w-100"
                  name="city"
                  type="text"
                  charLimit={100}
                  required={
                      CLIENTS_DATA.newClient.categoryName !== "Retail" &&
                      CLIENTS_DATA.newClient.categoryName !== "Patient"
                  }
                  value={CLIENTS_DATA.newClient.city}
                  placeholder={t('ClientsNewGeneral.city.placeholder')}
                  onChange={(e) => handleOnChange(e)}
              />
              {CLIENTS_DATA.newClient.categoryName !== "Retail" &&
                  CLIENTS_DATA.newClient.categoryName !== "Patient" &&
                  showErrors &&
                  CLIENTS_DATA.newClient.city === "" && (
                      <div className="text-danger">{t('ClientsNewGeneral.city.required')}</div>
                  )}
            </div>
          </Col>
          <Col sm="12" md="3">
            {/* Mandatory field EXCEPT if category retail & patient */}
            <div className="mb-3">
              <Input
                label={t('ClientsNewGeneral.postalCode.postalCode')}
                className="w-100"
                name="postalCode"
                type="text"
                required={
                  CLIENTS_DATA.newClient.categoryName !== "Retail" &&
                    CLIENTS_DATA.newClient.categoryName !== "Patient"
                }
                value={CLIENTS_DATA.newClient.postalCode}
                placeholder={t('ClientsNewGeneral.postalCode.placeholder')}
                onChange={(e) => handleOnChange(e)}
              />
              {CLIENTS_DATA.newClient.categoryName !== "Retail" &&
                CLIENTS_DATA.newClient.categoryName !== "Patient" &&
                showErrors &&
                CLIENTS_DATA.newClient.postalCode === "" && (
                  <div className="text-danger">{t('ClientsNewGeneral.postalCode.postalCode')}</div>
                )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="3">
            <div className="mb-3">
              <Input
                label={t('ClientsNewGeneral.email.email')}
                className="w-100"
                name="email"
                type="email"
                value={CLIENTS_DATA.newClient.email}
                placeholder={t('ClientsNewGeneral.email.eg')}
                onChange={(e) => handleOnChange(e)}
                required={CLIENTS_DATA.newClient.phone === ""}
              />
              {showErrors &&
                CLIENTS_DATA.newClient.email !== "" &&
                !isValidEmail(CLIENTS_DATA.newClient.email) && (
                  <div className="text-danger">{t('ClientsNewGeneral.email.invalid')}</div>
                )}

              {showErrors &&
                CLIENTS_DATA.newClient.categoryName !== "Patient"  &&
                CLIENTS_DATA.newClient.categoryName !== "I.K.E."  &&
                CLIENTS_DATA.newClient.categoryName !== "Freelancer"  &&
                CLIENTS_DATA.newClient.categoryName !== "O.E."  &&
                CLIENTS_DATA.newClient.categoryName !== "E.E."  &&
                CLIENTS_DATA.newClient.categoryName !== "A.E."  &&
                CLIENTS_DATA.newClient.categoryName !== "Non-Profit Organization"  &&
                CLIENTS_DATA.newClient.categoryName !== "E.P.E."  &&
                CLIENTS_DATA.newClient.categoryName !== "Wholesale Others"  &&
                CLIENTS_DATA.newClient.email === "" &&
                CLIENTS_DATA.newClient.phone === "" && (
                  <div className="text-danger">{t('ClientsNewGeneral.email.required')}</div>
                )}
              {showErrors &&
                  CLIENTS_DATA.newClient.categoryName === "Patient"  &&
                  CLIENTS_DATA.newClient.email === "" &&
                  CLIENTS_DATA.newClient.amka === "" &&
                  CLIENTS_DATA.newClient.phone === "" && (
                      <div className="text-danger">{t('ClientsNewGeneral.email.required2')}</div>
                  )}
            </div>
          </Col>
          <Col sm="12" md="3">
            <Input
              label={t('ClientsNewGeneral.phone.phone')}
              className="mb-3 w-100"
              name="phone"
              type="tel"
              charLimit={20}
              value={CLIENTS_DATA.newClient.phone}
              placeholder={t('ClientsNewGeneral.phone.placeholder')}
              onChange={(e) => handleOnChange(e)}
              required={CLIENTS_DATA.newClient.email === ""}
            />
          </Col>
          <Col sm="12" md="3">
            <Input
              label={t('ClientsNewGeneral.gemh.gemh')}
              className="mb-3 w-100"
              name="gemhNo"
              type="text"
              value={CLIENTS_DATA.newClient.gemhNo}
              placeholder={t('ClientsNewGeneral.gemh.placeholder')}
              onChange={(e) => handleOnChange(e)}
            />
          </Col>
          <Col sm="12" md="3">
            <Input
              label={t('ClientsNewGeneral.webpage.webpage')}
              className="mb-3 w-100"
              name="webpage"
              type="url"
              value={CLIENTS_DATA.newClient.webpage}
              placeholder={t('ClientsNewGeneral.webpage.eg')}
              onChange={(e) => handleOnChange(e)}
            />
          </Col>
        </Row>
      {checkPermission("custom-b2g", permissionsData) && (
          <Row>
            <Col md={5}>
              <div className={"text-muted mb-1"}><i>Πληροφορίες για Δημόσιες Συμβάσεις</i></div>
              <Button size={"sm"} className={"mr-3"} onClick={() => handleGetCompanyInfoPeppol()} disabled={loadingPeppolDetails}>
                Ανάκτηση Αναθετουσών από Α.Φ.Μ.
                {loadingPeppolDetails && <Spinner className={"ml-3"} size={"sm"} animation="border" variant="dark" />}
              </Button>
              <AnathetousesModal data={CLIENTS_DATA.newClient} />
            </Col>
          </Row>
      )}
      <hr />
      <p className="text-muted">
        <i>{t('ClientsNewGeneral.contactPoints.title')}</i>
      </p>
      <EditableTable
        tableName="Client Contact Points"
        key={"Client Contact Points" + incrKey}
        allowActions={true}
        allowToggleColumns={true}
        toggleColumns={handleColumnShowHide}
        columns={tableColumns}
        data={CLIENTS_DATA.newClient.contactPoints}
        allowInsertRow={true}
        enableNewRowCheckbox={true}
        onUpdate={parentMydataActions}
      />

      <p>
        <i>{t("ClientsNewGeneral.clientNotes.title")}</i>
      </p>
      <TextArea
        className="mb-3"
        rows="4"
        placeholder={t("ClientsNewGeneral.clientNotes.note")}
        name="clientNotes"
        limit="1000"
        value={CLIENTS_DATA.newClient.clientNotes}
        onChange={(e) => handleOnChange(e)}
      />

      {/* Confirm Category Change */}
      <Modal className="themeModal" show={inActiveClientWarning}>
        <Modal.Header closeButton>
          <Modal.Title>Warning!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to make all client connections inactive (automatic sales,
          quick retail settings)?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => showInActiveClientWarnning(false)}
          >
            No
          </Button>
          <Button variant="primary" onClick={() => handleConfirmCategory()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewClientGeneral;
