export const preNumberGreek = [{label: "Κενό", value: "noprefix"}, {label: "A", value: "Α"}, {
    label: "B",
    value: "B"
}, {label: "Γ", value: "Γ"}, {label: "∆", value: "Δ"}, {label: "E", value: "Ε"}, {label: "Z", value: "Ζ"}, {
    label: "H",
    value: "Η"
}, {label: "Θ", value: "Θ"}, {label: "I", value: "Ι"}, {label: "K", value: "Κ"}, {label: "Λ", value: "Λ"}, {
    label: "M",
    value: "M"
}, {label: "N", value: "N"}, {label: "Ξ", value: "Ξ"}, {label: "O", value: "O"}, {label: "Π", value: "Π"}, {
    label: "P",
    value: "P"
}, {label: "Σ", value: "Σ"}, {label: "T", value: "T"}, {label: "Υ", value: "Υ"}, {label: "Φ", value: "Φ"}, {
    label: "X",
    value: "X"
}, {label: "Ψ", value: "Ψ"}, {label: "Ω", value: "Ω"}]

export const numberingModeOptions = [{
    value: 'automatic',
    label: 'Αυτόματη'
}, {
    value: 'manual',
    label: 'Χειροκίνητη'
}]
