import {isEmpty} from "../../../../../_helpers/commonFunctions";
import moment from "moment";
import {getFixedPublicHolidays, isOrthodoxEasterMonday} from "./fixedPublicHolidays";

export const colorMapping = {
    normal: "#b8d3ff",
    overwork: "#67b3ff",
    overtime: "#3297ff",
    permit: "#befac3",
    dayOff: "#ffe6b5",
    noWork: "#ffb0b0",
}

export const wordMapping = {
    normal: "Κανονική εργασία",
    overwork: "Υπερεργασία",
    overtime: "Υπερωρία",
    permit: "Άδεια εργασίας",
    dayOff: "Ανάπαυση/Ρεπό",
    noWork: "Μη εργασία",
}

export const workCategories = [
    { label: "Δια ζώσης", value: "company" },
    { label: "Εκ περιτροπής (Τηλεργασία)", value: "remote" },
]

export const dayTranslate = {
    "Monday": "Δευτέρα",
    "Tuesday": "Τρίτη",
    "Wednesday": "Τετάρτη",
    "Thursday": "Πέμπτη",
    "Friday": "Παρασκευή",
    "Saturday": "Σάββατο",
    "Sunday": "Κυριακή",
}

export const greekMonthOptions = [
    { label: "Ιανουάριος", value: "01" },
    { label: "Φεβρουάριος", value: "02" },
    { label: "Μάρτιος", value: "03" },
    { label: "Απρίλιος", value: "04" },
    { label: "Μάιος", value: "05" },
    { label: "Ιούνιος", value: "06" },
    { label: "Ιούλιος", value: "07" },
    { label: "Αύγουστος", value: "08" },
    { label: "Σεπτέμβριος", value: "09" },
    { label: "Οκτώβριος", value: "10" },
    { label: "Νοέμβριος", value: "11" },
    { label: "Δεκέμβριος", value: "12" },
]

export const movementTypeOptions = [
    { label: "Προσέλευση", value: "arrive" },
    { label: "Αναχώρηση", value: "leave" }
]

export const delayReasonOptions = [
    { label: "Κενό", value: "" },
    { label: "Πρόβλημα στην ηλεκτροδότηση/τηλεπικοινωνίες", value: "001" },
    { label: "Πρόβλημα στα συστήματα του εργοδότη", value: "002" },
    { label: "Πρόβλημα σύνδεσης με το ΠΣ Εργάνη", value: "003" },
]

export const submissionTypeOptions = [
    { label: "Κενό", value: "" },
    { label: "Οργάνωση Χρόνου Εργασίας - Σταθερό Εβδομαδιαίο", value: "WTOWeek" },
    { label: "Δήλωση έναρξης/λήξης εργασίας εργαζομένων", value: "WRKCardSE" },
    { label: "Οργάνωση Χρόνου Εργασίας - Άδειες", value: "WTOLeave" },
    { label: "Οργάνωση Χρόνου Εργασίας - Μεταβαλλόμενο/Τροποποιούμενο ανά Ημέρα", value: "WTODaily" },
    { label: "Οργάνωση Χρόνου Εργασίας - Μεταβαλλόμενο/Τροποποιούμενο ανά Ημέρα - Απολογιστικό", value: "WTODailyA" },
    { label: "Δήλωση Εξαίρεσης από την Υποχρέωση Προαναγγελίας", value: "ExProan" },
    { label: "Δήλωση Μεταβολής Στοιχείων Εργασιακής Σχέσης - Οργάνωση Χρόνου Εργασίας", value: "WKChgWK" }
]

export const permitTypes = [
    {
        "label": "Κανονική άδεια",
        "value": "ΑΔΚΑΝ"
    },
    {
        "label": "Αιμοδοτική άδεια",
        "value": "ΑΔΑΙΜ"
    },
    {
        "label": "Άδεια εξετάσεων",
        "value": "ΑΔΕΞ"
    },
    {
        "label": "Άδεια άνευ αποδοχών",
        "value": "ΑΔΑΑ"
    },
    {
        "label": "Άδεια μητρότητας",
        "value": "ΑΔΜΗ"
    },
    {
        "label": "Ειδική παροχή προστασίας της μητρότητας",
        "value": "ΑΔΠΠΜ"
    },
    {
        "label": "Άδεια πατρότητας",
        "value": "ΑΔΠΑ"
    },
    {
        "label": "Άδεια φροντίδας παιδιού",
        "value": "ΑΔΦΠ"
    },
    {
        "label": "Γονική άδεια",
        "value": "ΑΔΓΟΝ"
    },
    {
        "label": "Άδεια φροντιστή",
        "value": "ΑΔΦΡΟ"
    },
    {
        "label": "Απουσία από την εργασία για λόγους ανωτέρας βίας",
        "value": "ΑΔΑΠΑΒ"
    },
    {
        "label": "Άδεια για υποβολή σε μεθόδους ιατρικώς υποβοηθούμενης αναπαραγωγής",
        "value": "ΑΔΙΥΑ"
    },
    {
        "label": "Άδεια εξετάσεων προγεννητικού ελέγχου",
        "value": "ΑΔΠΕ"
    },
    {
        "label": "Άδεια γάμου",
        "value": "ΑΔΓΑΜ"
    },
    {
        "label": "Άδεια λόγω σοβαρών νοσημάτων των παιδιών",
        "value": "ΑΔΣΝΠ"
    },
    {
        "label": "Άδεια λόγω νοσηλείας των παιδιών",
        "value": "ΑΔΝΠ"
    },
    {
        "label": "Άδεια μονογονεϊκών οικογενειών",
        "value": "ΑΔΜΟ"
    },
    {
        "label": "Άδεια παρακολούθησης σχολικής επίδοσης τέκνου",
        "value": "ΑΔΠΣΕΤ"
    },
    {
        "label": "Άδεια λόγω ασθένειας παιδιού ή άλλου εξαρτώμενου μέλους",
        "value": "ΑΔΑΠΕΜ"
    },
    {
        "label": "Απουσία από την εργασία λόγω επικείμενου σοβαρού κινδύνου βίας ή παρενόχλησης",
        "value": "ΑΔΑΠΣΚ"
    },
    {
        "label": "Άδεια ασθένειας (ανυπαίτιο κώλυμα παροχής εργασίας)",
        "value": "ΑΔΑΣ"
    },
    {
        "label": "Άδεια απουσίας Α.Μ.Ε.Α.",
        "value": "ΑΔΑΜΕΑ"
    },
    {
        "label": "Άδεια λόγω θανάτου συγγενούς ",
        "value": "ΑΔΘΣΥΓ"
    },
    {
        "label": "Άδεια ανήλικων σπουδαστών",
        "value": "ΑΔΑΝΣΠ"
    },
    {
        "label": "Άδεια για μεταγγίσεις αίματος και των παραγώγων του ή αιμοκάθαρση",
        "value": "ΑΔΜΑΑ"
    },
    {
        "label": "Εκπαιδευτική άδεια για φοιτητές στο Κ.ΑΝ.Ε.Π. - Γ.Σ.Ε.Ε.",
        "value": "ΑΔΕΚΦ"
    },
    {
        "label": "Άδεια λόγω AIDS",
        "value": "ΑΔΣΕΑΑ"
    },
    {
        "label": "Ευέλικτες ρυθμίσεις εργασίας",
        "value": "ΑΔΕΡΕ"
    }
]

export const getWorkType = (dcObject) => {
    if (!isEmpty(dcObject.permitType)) {
        let findMatch = permitTypes.find((el) => el.value === dcObject?.permitType);
        if (findMatch) {
            return findMatch.label;
        } else {
            return "Άδεια εργασίας";
        }
    }
    if (dcObject?.workType === "normal") {
        if (dcObject?.workCategory === "company") {
            return "Κανονική εργασία";
        } else if (dcObject?.workCategory === "remote") {
            return "Τηλεργασία";
        }
    } else if (dcObject?.workType === "noWork") {
        return "Μη εργασία"
    } else if (dcObject?.workType === "dayOff") {
        return "Ανάπαυση/Ρεπό";
    } else if (dcObject?.workType === "overwork") {
        return "Υπερεργασία";
    } else if (dcObject?.workType === "overtime") {
        return "Υπερωρία";
    } else {
        return "";
    }
}

export const decimalToHourMinutesFormat = (hour) => {
    const onlyHour = Math.floor(hour);
    const onlyMinutes = Math.round((hour - onlyHour) * 60);

    return `${String(onlyHour).padStart(2, "0")}:${String(onlyMinutes).padStart(2, "0")}`;
}

export const hourMinutesToDecimalFormat = (hourMinutes) => {
    const split = hourMinutes.split(":");
    const hours = Number(split[0]);
    const decimal = ((Number(split[1]) * 100) / 60) / 100;

    return Number(Number(hours + decimal).toFixed(2));
}

export const getWorkTypeDDOptions = () => {
    let opt = [];
    for (let key in wordMapping) {
        if (key !== "overwork" && key !== "overtime" && key !== "permit") {
            opt.push({
                label: wordMapping[key], value: key,
            })
        }
    }
    return opt;
}

export const applyRounding = (hour, minuteStep) => {
    const minuteStepDecimal = ((minuteStep * 100) / 60) / 100;

    let bot = Math.floor(hour);
    while (bot + minuteStepDecimal <= hour) {
        bot += minuteStepDecimal;
    }
    let top = Math.ceil(hour);
    while (top - minuteStepDecimal >= hour) {
        top -= minuteStepDecimal;
    }

    return top - hour >= hour - bot ? bot : top;
}

export const applyMetaballomenaApologistika = (dateFrom, dateTo, legacyEvents, newEvents) => {
    const mdf = moment(dateFrom, "DD/MM/YYYY").startOf("day");
    if (mdf < moment()) {
        const mdt = moment(dateTo, "DD/MM/YYYY").endOf("day");
        const today = moment();

        let returnEvents = [];
        for (let ev of newEvents) {
            const findMatch = legacyEvents.find((el) => el.day === ev.day && el.hourFrom === ev.hourFrom && el.hourTo === ev.hourTo
                && el.workType === ev.workType && el.workCategory === ev.workCategory && el.installationMasterId === ev.installationMasterId)
            if (!findMatch) returnEvents.push(ev);
        }
        // returnEvents now has the changed events
        if (returnEvents.length > 0) {
            for (let ev of returnEvents) {
                ev.sentToErgani = false;
                let evDate = mdf.clone().add(ev.day, "days");
                if (evDate >= today.clone().endOf("day").subtract(1, "day") && evDate <= mdt) { // Metaballomeno
                    ev.metaballomeno = true;
                    ev.apologistiko = false;
                } else { // Apologistiko
                    ev.metaballomeno = false;
                    ev.apologistiko = true;
                }
            }
        }
        // returnEvents has now set "metaballomeno" or "apologistiko" booleans
        let cloneNewEvents = structuredClone(newEvents);
        cloneNewEvents = cloneNewEvents.filter((el) => !returnEvents.find((el2) => el.id === el2.id));
        for (let ev of cloneNewEvents) {
            const findMatchingLegacy = legacyEvents.find((el) => el.day === ev.day && el.hourFrom === ev.hourFrom && el.hourTo === ev.hourTo
                && el.workType === ev.workType && el.workCategory === ev.workCategory && el.installationMasterId === ev.installationMasterId);
            if (findMatchingLegacy) { // Remove sentToErgani, metaballomeno, apologistiko values if the event is same as the legacy event
                let copyKeys = ["sentToErgani", "metaballomeno", "apologistiko"];
                for (let key of copyKeys) {
                    if (isEmpty(findMatchingLegacy[key])) {
                        delete ev[key];
                    } else {
                        ev[key] = findMatchingLegacy[key];
                    }
                }
            }
        }
        cloneNewEvents = [...cloneNewEvents, ...returnEvents];

        cloneNewEvents = cloneNewEvents.sort((a, b) => a.hourFrom > b.hourFrom ? 1 : -1);

        // Below we apply metaballomeno & apologistiko on the days that employee has 2 or more working splits in the same day (we need to resend all working splits)
        let daysDone = [];
        for (let ev of cloneNewEvents) {
            if (!daysDone.includes(ev.day) && (ev.sentToErgani === false && (ev.metaballomeno || ev.apologistiko))) {
                daysDone.push(ev.day);
                let findSameDayEvents = cloneNewEvents.filter((el) => el.id !== ev.id && el.sentToErgani === false && ev.day === el.day);
                if (findSameDayEvents.length > 0) {
                    for (let xEv of findSameDayEvents) {
                        let copyKeys = ["sentToErgani", "metaballomeno", "apologistiko"];
                        for (let key of copyKeys) {
                            if (isEmpty(xEv[key])) {
                                delete xEv[key];
                            } else {
                                xEv[key] = ev[key];
                            }
                        }
                    }
                }
            }
        }

        return cloneNewEvents;
    }
    return newEvents;
}

export const formatEmployeeIdentifier = (employeeIdentifier) => {
    try {
        return employeeIdentifier.split("|").join(" ");
    } catch (error) {
        return employeeIdentifier;
    }
}

export const getHourIntervals = (hourFrom, hourTo) => {
    // hourFrom, hourTo in HH:mm format
    const intervals = [
        { start: 0, end: 6 },
        { start: 6, end: 22 },
        { start: 22, end: 28 }, // Night working till next day
        { start: 28, end: 46 }, // Continuing day next day
    ]
    let from = hourMinutesToDecimalFormat(hourFrom);
    if (hourTo === "23:59") hourTo = "24:00";
    let to = hourMinutesToDecimalFormat(hourTo);

    if (to <= from) to += 24;

    const hoursInIntervals = intervals.map(() => 0);

    intervals.forEach((interval, i) => {
        const intervalStart = interval.start;
        const intervalEnd = interval.end;

        // Find overlapping start and end times with current interval
        const overlapStart = Math.max(from, intervalStart);
        const overlapEnd = Math.min(to, intervalEnd);

        // If there's an overlap, calculate the hours in this interval
        if (overlapStart < overlapEnd) {
            hoursInIntervals[i] = +Number(overlapEnd - overlapStart).toFixed(2);
        }
    })
    return {
        dayHours: +Number(hoursInIntervals[1] + hoursInIntervals[3]).toFixed(2),
        nightHours: +Number(hoursInIntervals[0] + hoursInIntervals[2]).toFixed(2),
    }
}

export const isInPublicHoliday = (companyPublicHolidays, date) => {
    const year = Number(moment(date, "DD/MM/YYYY").format("YYYY"));
    return !!companyPublicHolidays?.some((el) => el.date === date)
        || getFixedPublicHolidays(year).some((el) => el.date === date || String(`${el.date}/${year}`) === date || isOrthodoxEasterMonday(date));
}

export const concatIfExistent = (arr1, arr2) => {
    let toRet = [];

    if (arr1?.length > 0 && typeof arr1 === "object") toRet = [...arr1];
    if (arr2?.length > 0 && typeof arr2 === "object") toRet = [...toRet, ...arr2];

    return toRet;
}
