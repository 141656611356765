import React from "react";
import "./styles.css";
import {formatBigName, getFileImageDisplay, popExtension} from "./constants";

const File = ({name, selected, onFileLeftClick, onContextMenu}) => {
    return (
        <div className={`fileFolderStyle ${selected ? "fileFolderStyleSelected" : ""}`}
             onClick={() => onFileLeftClick(name)} style={{userSelect: "none", position: "relative"}}
             onContextMenu={(e) => onContextMenu(e, "file", name)}
        >
            <div style={{position: "relative"}}>
                <img src={getFileImageDisplay(popExtension(name))} alt={"File"} style={{height: "48px", width: "48px"}} draggable={false} />
            </div>
            <div className={"mt-1"} style={{wordBreak: "break-all", fontSize: "12px"}}>
                {formatBigName(name)}
            </div>
        </div>
    )
}

export default File;
