import { createSlice } from "@reduxjs/toolkit";
import { deleteObjectFromArrayOfObjects } from "../../../_helpers/helperFunctions";

const newCategoryInitialValue = {
  title: '',
  description: '',
  mask: 'PCM-0001',
  maskCode: '',
  maskCodePrefix: 'PCM',
  maskCodeCounter: 0,
  mydata: '[]',
  taxes: '{}',
  accounting: '',
  extra: '',
  isActive: 'active',
  type: 'product',
  measurementUnit: "",
  defaultProperties: {}
}

const newProductsCategoryMyDataInitialValue = {
  id: 0,
  name: '',
  invoiceType: '',
  category: '',
  type: '',
}

const defaultPropertyInitialState = {
  name: "",
  values: ""
}

const initialState = {
  loading: false,
  hasErrors: false,
  categories: [],
  newProductsCategoryMyDataInitialValue: newProductsCategoryMyDataInitialValue,
  newProductsCategoryMyData: newProductsCategoryMyDataInitialValue,
  newCategoryInitialValue: newCategoryInitialValue,
  newCategory: newCategoryInitialValue,
  tempNewDefaultProperties: defaultPropertyInitialState
};

export const ProductCategorySlice = createSlice({
  name: "product_category",
  initialState,
  reducers: {
    loadingProductCategory: (state) => void (state.loading = true),

    deleteProductCategory: (state, { payload }) => {
      state.categories = state.categories.filter((item) => item['_id'] !== payload.id);
      state.loading = false;
      state.hasErrors = false;
    },

    setProductCategory: (state, { payload }) => {
      state.categories = deleteObjectFromArrayOfObjects(payload, 'active' , 'inActive');
      state.loading = false;
      state.hasErrors = false;
    },

    failedProductCategory: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },

    setNewCategory: (state, { payload }) => {
      state.newCategory = payload;
    },

    setNewProductsCategoryMyData: (state, { payload }) => {
      state.newProductsCategoryMyData = payload;
    },

    setTempNewDefaultProperties: (state, { payload }) => {
      state.tempNewDefaultProperties = payload;
    },
    resetTempNewDefaultProperties: (state, { payload }) => {
      state.tempNewDefaultProperties = {...defaultPropertyInitialState};
    },
  },
});

export const {
  loadingProductCategory,
  deleteProductCategory,
  setProductCategory,
  failedProductCategory,
  setNewCategory,
  setNewProductsCategoryMyData,
  setTempNewDefaultProperties,
  resetTempNewDefaultProperties
} = ProductCategorySlice.actions;
