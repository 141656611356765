import React, {useRef, useState} from "react";
import Folder from "./Folder";
import File from "./File";
import ContextMenu from "./ContextMenu";
import {toast} from "react-toastify";
import {convertToBase64} from "./constants";

const FileBrowser = ({dirData, hasCopiedFile, onFileLeftClick, onFolderLeftClick, onFilesDrop, onNewFolder, onFileDelete, onFolderDelete,
                         onFileRename, onFolderRename, onFileDownload, onFileCopy, onFilePaste, onFileHistory, onEmronDelete, onEmronUnlock, specialCode, canUpload}) => {
    const fileBrowserRef = useRef();
    const [isDraggingFile, setIsDraggingFile] = useState(false);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuX, setContextMenuX] = useState(0);
    const [contextMenuY, setContextMenuY] = useState(0);
    const [contextMenuType, setContextMenuType] = useState("");
    const [contextMenuDisplayedSelection, setContextMenuDisplayedSelection] = useState("");
    const [contextMenuSelection, setContextMenuSelection] = useState("");
    const [contextMenuFolderLocker, setContextMenuFolderLocked] = useState(false);

    const [someModalOpen, setSomeModalOpen] = useState(false);

    const permissionsData = JSON.parse(localStorage.getItem("user"))?.permissions;
    const canUnlock = permissionsData.some((el) => el.permissionName === "custom-logistirio");

    const fileBrowserStyle = {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        gap: "10px 10px",
        minHeight: "50vh",
        maxHeight: "60vh",

        position: "relative",
        padding: "10px",
        borderTopLeftRadius: "18px",
        borderBottomLeftRadius: "18px",
        border: (isDraggingFile && canUpload) ? "2px dashed lightgray" : "2px solid lightgray",
        overflowY: "auto",
        width: "100%",
        backgroundColor: (isDraggingFile && canUpload) ? "#ebebeb" : "",
        alignItems: "flex-start",
        alignContent: "flex-start",
    }

    const onDragOver = (e) => {
        if (!someModalOpen && canUpload) {
            e.preventDefault();
            e.stopPropagation();
            if (!isDraggingFile) setIsDraggingFile(true);
        }
    }

    const onDragEnter = (e) => {
        if (!someModalOpen && canUpload) {
            e.preventDefault();
            e.stopPropagation();
            if (!isDraggingFile) setIsDraggingFile(true);
        }
    }

    const onDragLeave = (e) => {
        if (!someModalOpen && canUpload) {
            e.preventDefault();
            e.stopPropagation();
            if (isDraggingFile) setIsDraggingFile(false);
        }
    }

    const onDrop = async (e) => {
        if (!someModalOpen && canUpload) {
            e.preventDefault();
            e.stopPropagation();
            setIsDraggingFile(false);
            const files = e.dataTransfer.files;
            if (files.length) {
                for (let file of files) {
                    if (file.size % 4096 === 0) {
                        return toast.error("Δεν είναι δυνατή η μεταφορά φακέλου.");
                    } else if (file.type === "") {
                        return toast.error("Δεν είναι δυνατή η μεταφορά άμορφο αρχείου.");
                    }
                }
                const arr = [];
                for (let file of files) {
                    const base64Encoded = await convertToBase64(file);
                    arr.push({
                        fileName: file.name,
                        base64Encoded: base64Encoded,
                    })
                }
                onFilesDrop(arr);
            }
        }
    }

    const onContextMenu = (e, type, name = "", locked = false) => {
        // type: file, folder or parent
        if (!someModalOpen) {
            e.stopPropagation();
            e.preventDefault();
            if (specialCode === "1" && type !== "file" && !locked && !canUnlock) return;

            const bounds = fileBrowserRef.current?.getBoundingClientRect();
            setShowContextMenu(true);
            let initX = e.clientX - bounds.left;
            if (initX + 120 > bounds.width) {
                initX -= 120;
            }
            setContextMenuX(initX);

            let initY = e.clientY - bounds.top;
            if (initY + 50 > bounds.height) {
                initY -= 50;
            }
            initY += fileBrowserRef.current.scrollTop;
            setContextMenuY(initY);

            setContextMenuType(type);
            setContextMenuSelection(name);
            setContextMenuDisplayedSelection(name);
            setContextMenuFolderLocked(locked);
        }
    }

    const handleCloseContext = () => {
        if (showContextMenu) {
            setShowContextMenu(false);
            setContextMenuDisplayedSelection("");
        }
    }

    const handleMouseOver = () => {
        if (isDraggingFile) setIsDraggingFile(false);
    }

    return (
        <React.Fragment>
            <div
                 onDragEnter={(e) => onDragEnter(e)}
                 onDragLeave={(e) => onDragLeave(e)}
                 onDragOver={(e) => onDragOver(e)}
                 onDrop={(e) => onDrop(e)}
                 onMouseOver={(e) => handleMouseOver(e)}

                 onClick={() => handleCloseContext()}
                 onMouseLeave={() => handleCloseContext()}
                 onContextMenu={(e) => onContextMenu(e, "parent")}
            >
                <div style={fileBrowserStyle} ref={fileBrowserRef}>
                    <ContextMenu
                        hasCopiedFile={hasCopiedFile}
                        show={showContextMenu}
                        posX={contextMenuX}
                        posY={contextMenuY}
                        type={contextMenuType}
                        selection={contextMenuSelection}
                        locked={contextMenuFolderLocker}

                        onNewFolder={onNewFolder}
                        onFileDelete={onFileDelete}
                        onFolderDelete={onFolderDelete}
                        onFileRename={onFileRename}
                        onFolderRename={onFolderRename}
                        onFileDownload={onFileDownload}
                        onFileCopy={onFileCopy}
                        onFilePaste={onFilePaste}
                        onFileHistory={onFileHistory}

                        setSomeModalOpen={setSomeModalOpen}

                        onEmronDelete={onEmronDelete}
                        onEmronUnlock={onEmronUnlock}

                        specialCode={specialCode}
                    />
                    {dirData.length > 0 && dirData.map((item, idx) => {
                        if (item.folder === true) {
                            return (
                                <Folder
                                    key={idx}
                                    name={item.name}
                                    fileCount={item.fileCount}
                                    locked={item.locked}
                                    hasSubfolders={item.hasSubfolders}
                                    onContextMenu={onContextMenu}
                                    onFolderLeftClick={onFolderLeftClick}
                                    selected={contextMenuDisplayedSelection === item.name}
                                    specialCode={specialCode}
                                />
                            )
                        } else {
                            return (
                                <File
                                    key={idx}
                                    name={item.name}
                                    onContextMenu={onContextMenu}
                                    onFileLeftClick={onFileLeftClick}
                                    selected={contextMenuDisplayedSelection === item.name}
                                />
                            )
                        }
                    })}
                </div>
            </div>
        </React.Fragment>
    )
}

export default FileBrowser;
