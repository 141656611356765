import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

let date = new Date();
const currentTime = moment().format("HH:mm");

const newSalesInitialValue = {
    documentType: "",
    documentTypeName: "",
    myDataType: "",
    date: date,
    time: currentTime,
    customTime: "false",
    installation: "",
    preNumber: "",
    numberingMode: "manual",
    number: "",
    corelatedDocument: "",
    client: "",
    clientName: "",
    clientInstallationId: 0,
    clientInstallationType: "",
    clientCode: "",
    newClient: "",
    email: "",
    phone: "",
    clientType: "Existing", // Existing or New
    vat: "",
    address: "",
    doy: "",
    city: "",
    pc: "",
    paymentType: "",
    paymentTypeName: "",
    myDataPaymentId: "",
    bankAccount: "",
    salePurpose: "",
    isDeliveryNote: false,
    products: [],
    extraData: {
        wayOfSendingGoods: "",
        wayOfSendingGoodsName: "",
        carNumber: "",
        carNumberName: "",
        goodsSendDate: "",
        otherTax: "",
        otherTaxEuro: "",
        withHoldTax: "",
        withHoldTaxEuro: "",
        fee: "",
        feeEuro: "",
        stamp: "",
        stampEuro: "",
        deductionsEuro: "0.00",
        deliveryLocation: "companyLocation",
        locationAddress: "",
        locationCity: "",
        locationCountry: "",
        locationZip: "",
        locationVatNumber: "",
        locationCompanyName: "",
        cashRegister: "",
    },
    notes: "",
    salesTaxes: [],
    totalQuantity: 0,
    totalBeforeDiscount: 0,
    totalDiscount: 0,
    totalNetValue: 0,
    totalVatValue: 0,
    totalAmount: 0,
    paymentAmount: 0,
    tipAmount: 0, // Not saved in the database
    sendEmail: "inactive",
    emailRecipients: [],
    myDataStatus: "not_sent",
    enablePeppol: "inactive",
    htmlContent: "",
    transmissionFailure: "",
    documentCategory: "Initial Document",
}

const newSalesNewProductInitialValue = {
    no: "",
    id: 0,
    code: "",
    name: "",
    quantity: 1,
    mu: "",
    unitPrice: "",
    discount: "",
    isPerDiscount: "false",
    isDiscountAmount: "true",
    fee: "",
    feeEuro: "",
    otherTax: "",
    stamp: "",
    stampEuro: "",
    taxes: "",
    vatPercentage: "",
    vatId: "",
    vatReason: "",
    vatType: "",
    withHoldTax: "",
    withHoldTaxEuro: "",
    otherTaxEuro: "",
    vat: "",
    netValue: "",
    totalValue: "",
    _id: ""
}

const initialSalesDiscountObject = {
    discountType: "percentage",
    amount: 0,
    discountAsLine: false,
    discountAsLineText: "",
}

const initialState = {
    sales: [],
    searchSales: [],
    totalItemsSS: 0,
    perPageSS: 0,
    newSalesInitialValue: newSalesInitialValue,
    newSales: newSalesInitialValue,
    newSalesNewProductInitialValue: newSalesNewProductInitialValue,
    newSalesNewProduct: newSalesNewProductInitialValue,
    editSales: newSalesInitialValue,
    editSalesNewProductInitialValue: newSalesNewProductInitialValue,
    editSalesNewProduct: newSalesNewProductInitialValue,
    salesTemplates: [],
    selectedSalesTemplate: {},
    corelatedDocumentsList: [],

    printLayout: '',

    salesDiscountObject: initialSalesDiscountObject,

    newSaleClientCustomData: {
        clientVatCategory: "Normal VAT",
        clientVatReason: "",
        clientCustomVat: 0,
    },

    // Sales Balance Sheet
    salesBalanceReportRequestData: {
        page: 1,
        date_from: "",
        date_to: "",
        clientName: "",
        invoiceType: [],
        paymentType: [],
        carNumber: [],
        providerChannel: [],
        numberFrom: "",
        numberTo: "",

        totalPages: 0,
        totalVatValue: 0,
        totalNetValue: 0,
        totalValue: 0,
    },
    salesBalanceReportData: [],

    // Sale My Data Report
    salesMyDataReportRequestData: {
        date_from: moment().startOf("month").format("DD/MM/YYYY"),
        date_to: moment().endOf("month").format("DD/MM/YYYY"),
        clientVAT: "",
        number_from: "",
        number_to: "",
        invoiceType: [],
        showFilter: "all",
        invoicesFromChannel: "all",
        reportType: "sales",
        totalSum_from: "",
        totalSum_to: "",
        page: 1,
        totalPages: 0,
        lastUpdate: "",
    },
    salesMyDataReportTotals: {
        totalNetValue: 0,
        totalVatValue: 0,
        totalValue: 0,
        totalCount: 0,
        totalNetValueMyData: 0,
        totalVatValueMyData: 0,
        totalValueMyData: 0,
        totalCountMyData: 0,
    },

    // View sale peppol quick edit
    viewSalePeppolEdit: false,
    viewSalePeppolEditOldData: {},

    // Primer Client Payments
    primerClientPaymentsRequestData: {
        extraInfo: {},
        clientPaymentsData: [],
        documentType: "",
        paymentType: "",
        paymentTypeType: "",
        bankOrPOS: "",
        preNumber: "",
        numberFrom: "",
        numberTo: "",
    },

    // Emron Client Payments
    emronClientPaymentsRequestData: {
        extraInfo: {},
        clientPaymentsData: [],
        documentType: "",
        paymentType: "",
        paymentTypeType: "",
        bankOrPOS: "",
        preNumber: "",
    },

    // Client Payments Report
    clientPaymentsReportRequestData: {
        firstLoad: true, // For date setup
        date_from: "",
        date_to: "",
        page: 1,
        clientName: "",
        invoiceType: [],
        paymentType: [],
    },
    clientPaymentsReportResponseData: {
        totalPages: 0,
        data: [],
        totalPaymentAmount: 0,
    },

    // Sales Graphic Report
    salesGraphicReportRequestData: {
        firstLoad: true, // For date setup
        date_from: "",
        date_to: "",
        clientId: "",
        clientName: "",
        paymentType: [],
    },
    salesGraphicReportResponseData: {
        data: [],
    }
}

export const SalesSlice = createSlice({
    name: "Sales",
    initialState,
    reducers: {
        setSales: (state, {payload}) => {
            state.sales = payload;
        },
        setNewSales: (state, {payload}) => {
            state.newSales = payload;
        },
        setNewSalesNewProduct: (state, {payload}) => {
            state.newSalesNewProduct = payload;
        },
        setNewSalesExtraData: (state, {payload}) => {
            state.newSales.extraData = {...state.newSales.extraData, ...payload};
        },
        resetNewSales: (state) => {
            state.newSales = state.newSalesInitialValue;
            state.salesDiscountObject = initialSalesDiscountObject;
        },
        setEditSales: (state, {payload}) => {
            state.editSales = payload;
        },
        setEditSalesNewProduct: (state, {payload}) => {
            state.editSalesNewProduct = payload;
        },
        setEditSalesExtraData: (state, {payload}) => {
            state.editSales.extraData = {...state.editSales.extraData, ...payload};
        },
        setSearchSales: (state, {payload}) => {
            state.searchSales = payload;
        },
        setTotalItemsSS: (state, {payload}) => {
            state.totalItemsSS = payload;
        },
        setPerPageSS: (state, {payload}) => {
            state.perPageSS = payload;
        },
        setSalesTemplates: (state, {payload}) => {
            state.salesTemplates = payload;
        },
        setSelectedSalesTemplate: (state, {payload}) => {
            state.selectedSalesTemplate = payload;
        },
        setCorelatedDocumentsList: (state, {payload}) => {
            state.corelatedDocumentsList = payload;
        },

        setPrintLayout: (state, {payload}) => {
            state.printLayout = payload;
        },
        setSalesDiscountObject: (state, {payload}) => {
            state.salesDiscountObject = payload;
        },
        setNewSaleClientCustomData: (state, {payload}) => {
            state.newSaleClientCustomData = payload;
        },

        // Sales Balance Sheet
        setSalesReportData: (state, {payload}) => {
            state.salesBalanceReportData = payload;
        },
        setSalesReportRequestData: (state, {payload}) => {
            state.salesBalanceReportRequestData = payload;
        },

        // Sales My Data Report
        setSalesMyDataReportRequestData: (state, {payload}) => {
            state.salesMyDataReportRequestData = payload;
        },
        setSalesMyDataReportTotals: (state, {payload}) => {
            state.salesMyDataReportTotals = payload;
        },

        // View sale peppol quick edit
        setViewSalePeppolEdit: (state, {payload}) => {
            state.viewSalePeppolEdit = payload;
        },
        setViewSalePeppolEditOldData: (state, {payload}) => {
            state.viewSalePeppolEditOldData = payload;
        },

        // Primer Client Payments
        setPrimerClientPaymentsRequestData: (state, {payload}) => {
            state.primerClientPaymentsRequestData = payload;
        },

        // Emron Client Payments
        setEmronClientPaymentsRequestData: (state, {payload}) => {
            state.emronClientPaymentsRequestData = payload;
        },

        // Client Payments Report
        setClientPaymentsReportRequestData: (state, {payload}) => {
            state.clientPaymentsReportRequestData = payload;
        },
        setClientPaymentsReportResponseData: (state, {payload}) => {
            state.clientPaymentsReportResponseData = payload;
        },

        // Sales Graphic Report
        setSalesGraphicReportRequestData: (state, {payload}) => {
            state.salesGraphicReportRequestData = payload;
        },
        setSalesGraphicReportResponseData: (state, {payload}) => {
            state.salesGraphicReportResponseData = payload;
        }
    },
});

export const {
    setSales,
    setNewSales,
    resetNewSales,
    setNewSalesExtraData,
    setNewSalesNewProduct,
    setSearchSales,
    setPerPageSS,
    setTotalItemsSS,
    setSalesTemplates,
    setSelectedSalesTemplate,
    setEditSales,

    setPrintLayout,
    setSalesDiscountObject,
    setNewSaleClientCustomData,

    // Sales Balance Sheet
    setSalesReportData,
    setSalesReportRequestData,

    // Sales My Data Report
    setSalesMyDataReportRequestData,
    setSalesMyDataReportTotals,

    // View sale peppol quick edit
    setViewSalePeppolEdit,
    setViewSalePeppolEditOldData,

    // Primer Client Payments
    setPrimerClientPaymentsRequestData,

    // Emron Client Payments
    setEmronClientPaymentsRequestData,

    // Client Payments Report
    setClientPaymentsReportRequestData,
    setClientPaymentsReportResponseData,

    // Sales Graphic Report
    setSalesGraphicReportRequestData,
    setSalesGraphicReportResponseData
} = SalesSlice.actions;
