import React from "react";
import "./styles.css";
import {formatBigName} from "./constants";

const Folder = ({name, fileCount, locked, hasSubfolders, selected, onFolderLeftClick, onContextMenu, specialCode}) => {
    const bColor = () => {
        if (specialCode === "1") {
            return locked ? "#ffb9b8" : hasSubfolders ? "#faf7af" : "#c7ffcd";
        }
        return "";
    }

    return (
        <div className={`fileFolderStyle ${selected ? "fileFolderStyleSelected" : ""}`}
             onClick={() => onFolderLeftClick(name)} style={{userSelect: "none", position: "relative", backgroundColor: bColor()}}
             onContextMenu={(e) => onContextMenu(e, "folder", name, locked)}
        >
            <div style={{position: "relative"}}>
                {fileCount === 0 ? (
                    <img src={"assets/images/cloud/empty_folder.png"} alt={"File"} style={{height: "48px", width: "48px"}} draggable={false}/>
                ) : (
                    <React.Fragment>
                        <img src={"assets/images/cloud/folder.png"} alt={"File"} style={{height: "48px", width: "48px"}} draggable={false}/>
                        <div style={{position: "absolute", bottom: "0px", left: "calc(50% - 20px)"}}>{fileCount}</div>
                    </React.Fragment>
                )}
            </div>
            <div className={"mt-1"} style={{wordBreak: "break-all"}}>
                {(specialCode === "1" && !isNaN(name)) ? (
                    <React.Fragment>{name === "0" ? "Κεντρικό" : `Υποκατάστημα ${name}`}</React.Fragment>
                ) : (
                    <React.Fragment>{formatBigName(name)}</React.Fragment>
                )}
            </div>
        </div>
    )
}

export default Folder;
