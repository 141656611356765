import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {Badge, Button, Col, Row, Spinner} from "react-bootstrap";
import {
    setPayrollHourReportDisplayData,
    setPayrollHourReportResponseData
} from "../../../../_reducers/DataPanel/Payroll/payrollSlice";
import {classicStyleBelowNavbar} from "../Statics";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Input from "../../../common/Input";
import {formatEmployeeIdentifier, getHourIntervals, greekMonthOptions, isInPublicHoliday} from "./Components/constants";
import Dropdown from "../../../primer/Dropdown";
import moment from "moment";
import {getEmployeeCategoryOptions, getKathestosOptions, isEmpty} from "../../../../_helpers/commonFunctions";
import BetterCheckBox from "../../../common/BetterCheckBox";
import "./style.css";
import EmployeeInfoModal from "./Components/employee-info-modal";
import Alert from "react-bootstrap/Alert";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import * as XLSX from "xlsx";
import {jsPDF} from "jspdf";
import {liberationSerif} from "../../../../fonts/liberationSerif";
import {dejavuSans} from "../../../../fonts/DejaVuSans-normal";
import {dejavuSansBold} from "../../../../fonts/DejaVuSans-Bold-bold";

const PayrollHourReport = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyDetails = useSelector((state) => state.COMPANY_DATA.companyDetails);
    const publicHolidays = useSelector((state) => state.PAYROLL.payrollSettings.publicHolidays);
    const responseData = useSelector((state) => state.PAYROLL.hourReportResponseData);
    const displayData = useSelector((state) => state.PAYROLL.hourReportDisplayData);

    const [loading, setLoading] = useState(false);
    const [updateDisplayData, setUpdateDisplayData] = useState(false);

    // Employee Modal
    const [showEmployeeInfoModal, setShowEmployeeInfoModal] = useState(false);
    const [employeeInfoModalId, setEmployeeInfoModalId] = useState("");

    useEffect(() => {
        if (isEmpty(displayData.month)) { // First load
            const month = moment().format("MM");
            const date_from = moment(`15/${month}/${company.year}`, "DD/MM/YYYY").startOf("month").format("DD/MM/YYYY");
            const date_to = moment(`15/${month}/${company.year}`, "DD/MM/YYYY").endOf("month").format("DD/MM/YYYY");
            dispatch(setPayrollHourReportDisplayData({...displayData, month: month, date_from: date_from, date_to: date_to}))
            fetchReport(false, false);
        }
    }, [displayData.month])

    useEffect(() => {
        if (updateDisplayData) {
            setUpdateDisplayData(false);
            setDisplayData("auto");
        }
    }, [updateDisplayData])

    const fetchReport = (forceNew = false, showToast = true) => {
        setLoading(true);
        let prepReq = { company: company.id }
        if (forceNew) prepReq.forceNew = "true";
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/fetch-hour-report`, prepReq, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (showToast) toast.success("Τα δεδομένα ανανεώθηκαν.");
                let prepObj = {
                    data: res.data.data,
                }
                if (res.data.lastUpdate) prepObj.lastUpdate = res.data.lastUpdate;

                dispatch(setPayrollHourReportResponseData({...responseData, ...prepObj}));
                setUpdateDisplayData(true);
            } else {
                if (showToast) toast.error(res.data.message);
                dispatch(setPayrollHourReportResponseData({...responseData, data: [], lastUpdate: null}));
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            if (showToast) toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const formatFromText = (val) => {
        if (val === "card") {
            return "Από κάρτα εργασίας";
        } else if (val === "program") {
            return "Από πρόγραμμα εργασίας";
        } else {
            return val;
        }
    }

    const getDayNightSundayHours = (data, employeeCategory, kathestos) => {
        const employeeMonthlyData = structuredClone(data);
        const resData = {
            totals: {
                //enshma: 0,
                daysWorked: 0,
                hoursWorked: 0,
            },
            working: {
                day: 0,
                night: 0,
                daySunday: 0,
                nightSunday: 0,
                publicHolidays: 0,
            },
            permits: {
                day: 0,
                night: 0,
                daySunday: 0,
                nightSunday: 0,

                totalDays: 0,
                totalHours: 0,
            },
            overwork: {
                day: 0,
                night: 0,
                daySunday: 0,
                nightSunday: 0,

                totalHours: 0,
            },
            overtime: {
                day: 0,
                night: 0,
                daySunday: 0,
                nightSunday: 0,

                totalHours: 0,
            },
        }
        const uniqueDays = [];
        const uniquePermitDays = [];
        const sundayUniqueDays = [];
        for (let dcData of employeeMonthlyData) {
            if (!uniqueDays.includes(dcData.date)) uniqueDays.push(dcData.date);

            const isSunday = moment(dcData.date, "DD/MM/YYYY").day() === 0;
            if (!sundayUniqueDays.includes(dcData.date) && isSunday) sundayUniqueDays.push(dcData.date);

            const hoursWorked = getHourIntervals(dcData.hourFrom, dcData.hourTo);
            const dayHours = hoursWorked.dayHours;
            const nightHours = hoursWorked.nightHours;

            const isPermit = dcData?.permit === true;
            const isPublicHoliday = isInPublicHoliday(publicHolidays, dcData.date);

            if (isPermit) {
                if (!uniquePermitDays.includes(dcData.date)) uniquePermitDays.push(dcData.date);
                if (isSunday) {
                    resData.permits.daySunday += dayHours;
                    resData.permits.nightSunday += nightHours;
                } else {
                    resData.permits.day += dayHours;
                    resData.permits.night += nightHours;
                }
            } else {
                const totalHours = +Number(dayHours + nightHours).toFixed(2);
                resData.totals.hoursWorked += totalHours;
                let overwork = 0;
                let overtime = 0;
                let overworkNight = false;
                let overtimeNight = false;

                if (dcData?.workingDaysPerWeek === "5") {
                    if (totalHours <= 9 && totalHours > 8) {
                        overwork = totalHours - 8;
                        if (dayHours <= 8) overworkNight = true;
                    } else if (totalHours > 9) {
                        overwork = 1;
                        overtime = totalHours - 9;
                        if (dayHours <= 9) overtimeNight = true;
                    }
                } else if (dcData?.workingDaysPerWeek === "6") {
                    if (totalHours > 6.67) {
                        if (totalHours <= 8 && totalHours > 6.67) {
                            overwork = totalHours - 6.67;
                            if (dayHours <= 6.67) overworkNight = true;
                        } else if (totalHours > 8) {
                            overwork = 1.33;
                            overtime = totalHours - 8;
                            if (dayHours <= 8) overtimeNight = true;
                        }
                    }
                }
                overwork = +Number(overwork).toFixed(2);
                overtime = +Number(overtime).toFixed(2);

                if (isSunday) {
                    resData.working.daySunday += dayHours;
                    resData.working.nightSunday += nightHours;

                    if (overwork && !overworkNight) resData.overwork.daySunday += overwork;
                    if (overwork && overworkNight) resData.overwork.nightSunday += overwork;
                    if (overtime && !overtimeNight) resData.overtime.daySunday += overtime;
                    if (overtime && overtimeNight) resData.overtime.nightSunday += overtime;
                } else {
                    resData.working.day += dayHours;
                    resData.working.night += nightHours;

                    if (overwork && !overworkNight) resData.overwork.day += overwork;
                    if (overwork && overworkNight) resData.overwork.night += overwork;
                    if (overtime && !overtimeNight) resData.overtime.day += overtime;
                    if (overtime && overtimeNight) resData.overtime.night += overtime;
                }
                if (overwork) resData.overwork.totalHours += overwork;
                if (overtime) resData.overtime.totalHours += overtime;
                if (isPublicHoliday) resData.working.publicHolidays += dayHours + nightHours;
            }
        }
        resData.working.day = resData.working.day - resData.overwork.day - resData.overtime.day;
        resData.working.night = resData.working.night - resData.overwork.night - resData.overtime.night;
        resData.working.daySunday = resData.working.daySunday - resData.overwork.daySunday - resData.overtime.daySunday;
        resData.working.nightSunday = resData.working.nightSunday - resData.overwork.nightSunday - resData.overtime.nightSunday;

        resData.permits.totalDays = uniquePermitDays.length;
        resData.permits.totalHours = resData.permits.day + resData.permits.night + resData.permits.daySunday + resData.permits.nightSunday;

        // General corrections
        if (employeeCategory === "salaried") {
            resData.totals.daysWorked = uniqueDays.length;
            //resData.totals.enshma = Math.min(Math.ceil(uniqueDays.length * 1.2), 25) + sundayUniqueDays.length;
        } else if (employeeCategory === "daily") {
            resData.totals.daysWorked = uniqueDays.length;
            //resData.totals.enshma = uniqueDays.length
        } else if (employeeCategory === "hourly") {
            resData.totals.daysWorked = 0;
        } else if (employeeCategory === "professor") { // ?????

        }

        if (kathestos === "part-time") {
            resData.working.day += resData.overwork.day + resData.overtime.day;
            resData.overwork.day = 0;
            resData.overtime.day = 0;

            resData.working.night += resData.overwork.night + resData.overtime.night;
            resData.overwork.night = 0;
            resData.overtime.night = 0;

            resData.working.daySunday += resData.overwork.daySunday + resData.overtime.daySunday;
            resData.overwork.daySunday = 0;
            resData.overtime.daySunday = 0;

            resData.working.nightSunday += resData.overwork.nightSunday + resData.overtime.nightSunday;
            resData.overwork.nightSunday = 0;
            resData.overtime.nightSunday = 0;

            resData.overwork.totalHours = 0;
            resData.overtime.totalHours = 0;
        }

        for (let majorKey in resData) {
            for (let innerKey in resData[majorKey]) {
                resData[majorKey][innerKey] = +Number(resData[majorKey][innerKey]).toFixed(2);
            }
        }

        return resData;
    }

    const filterBySelectedMonth = (data) => {
        return data.filter((el) => moment(el.date, "DD/MM/YYYY") >= moment(displayData.date_from, "DD/MM/YYYY")
            && moment(el.date, "DD/MM/YYYY") <= moment(displayData.date_to, "DD/MM/YYYY"));
    }

    const setDisplayData = (dayAnalysis) => {
        setLoading(true);
        if (dayAnalysis === "auto") dayAnalysis = displayData.dayAnalysis;

        const filteredData = filterBySelectedMonth(responseData.data);
        if (dayAnalysis) {
            dispatch(setPayrollHourReportDisplayData({
                ...displayData,
                page: 1,
                data: filteredData,
                totalPages: Math.ceil(filteredData.length / 31),
                dayAnalysis: dayAnalysis
            }));
        } else {
            let employeesDone = [];
            let resData = [];
            for (let rowData of filteredData) {
                const split = String(rowData.employeeIdentifier).split("|");
                const firstName = split[0];
                const lastName = split[1];
                const vatNum = split[2];
                if (vatNum) {
                    if (employeesDone.includes(vatNum)) continue;
                    employeesDone.push(vatNum);
                    const findMatchingMonthlyData = filteredData.filter((el) => String(el.vatNumber) === String(vatNum));
                    resData.push({
                        _id: isEmpty(rowData._id) ? null : rowData._id,
                        no: String(resData.length + 1),
                        employee: `${firstName} ${lastName}`,
                        employeeType: "",
                        salary: 0,
                        employeeCategory: rowData.employeeCategory,
                        kathestos: rowData.kathestos,
                        ...getDayNightSundayHours(findMatchingMonthlyData, rowData.employeeCategory, rowData.kathestos),
                    })
                }
            }
            dispatch(setPayrollHourReportDisplayData({...displayData, page: 1, data: resData, totalPages: Math.ceil(resData.length / 31), dayAnalysis: dayAnalysis}));
        }
        setTimeout(() => {
            setLoading(false);
        }, 50)
    }

    const handleExcelExport = () => {
        let rows = [];
        rows.push(["Επωνυμία:", company.name]);
        rows.push(["ΑΦΜ:", company.vatNumber]);
        rows.push(["ΔΟΥ:", companyDetails.doy]);
        rows.push(["Ημ/νια Εξαγωγής:", moment().format("DD/MM/YYYY HH:mm:ss")]);
        rows.push(["Μήνας Ωρομέτρησης:", greekMonthOptions.find((el) => el.value === displayData.month)?.label]);
        rows.push([]);

        const header1 = [
            "Στοιχεία Εργαζομένων", "", "", "",
            "Σύνολα", "",
            "Ώρες Εργασίας", "", "", "", "",
            "Ώρες Αδείας", "", "", "", "", "",
            "Ώρες Υπερεργασίας", "", "", "", "",
            "Ώρες Υπερωρίας", "", "", "", "",
        ]
        const header2 = [
            "Α/Α", "Εργαζόμενος", "Τύπος Εργαζομένου", "Μισθός",
            "Ημέρες Εργασίας", "Ώρες εργασίας",
            "ΗΜΕΡΑ", "ΝΥΧΤΑ", "ΗΜΕΡΑ ΚΥΡΙΑΚΗ", "ΝΥΧΤΑ ΚΥΡΙΑΚΗ", "Εκ των οποίων Αργίες",
            "ΗΜΕΡΑ", "ΝΥΧΤΑ", "ΗΜΕΡΑ ΚΥΡΙΑΚΗ", "ΝΥΧΤΑ ΚΥΡΙΑΚΗ", "Συνολικές Ημέρες", "Συνολικές Ώρες",
            "ΗΜΕΡΑ", "ΝΥΧΤΑ", "ΗΜΕΡΑ ΚΥΡΙΑΚΗ", "ΝΥΧΤΑ ΚΥΡΙΑΚΗ", "Σύνολο",
            "ΗΜΕΡΑ", "ΝΥΧΤΑ", "ΗΜΕΡΑ ΚΥΡΙΑΚΗ", "ΝΥΧΤΑ ΚΥΡΙΑΚΗ", "Σύνολο",
        ]
        rows.push(header1);
        rows.push(header2);
        for (let row of displayData.data) {
            rows.push([
                row["no"], row["employee"], getEmployeeCategoryOptions().find((el) => el.value === row["employeeCategory"])?.label + " " + getKathestosOptions().find((el) => el.value === row["kathestos"])?.label, row["salary"],
                row["totals"]["daysWorked"], row["totals"]["hoursWorked"],
                row["working"]["day"], row["working"]["night"], row["working"]["daySunday"], row["working"]["nightSunday"], row["working"]["publicHolidays"],
                row["permits"]["day"], row["permits"]["night"], row["permits"]["daySunday"], row["permits"]["nightSunday"], row["permits"]["totalDays"], row["permits"]["totalHours"],
                row["overwork"]["day"], row["overwork"]["night"], row["overwork"]["daySunday"], row["overwork"]["nightSunday"], row["overwork"]["totalHours"],
                row["overtime"]["day"], row["overtime"]["night"], row["overtime"]["daySunday"], row["overtime"]["nightSunday"], row["overtime"]["totalHours"]
            ])
        }
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        XLSX.utils.book_append_sheet(workBook, workSheet, "Ωρομέτρηση");
        XLSX.writeFile(workBook, 'Ωρομέτρηση.xlsx');

    }

    const handlePDFExport = () => {
        const doc = new jsPDF('landscape');
        let y = 23;
        doc.addFileToVFS("arialuni.ttf", liberationSerif);
        doc.addFont("arialuni.ttf", "Arial Unicode MS", "normal");
        doc.addFileToVFS("dejavusans.ttf", dejavuSans);
        doc.addFont("dejavusans.ttf", "DejaVu Sans Normal", "normal");
        doc.addFileToVFS("dejavusansBold.ttf", dejavuSansBold);
        doc.addFont("dejavusansBold.ttf", "DejaVu Sans Bold", "normal");
        doc.setFont("DejaVu Sans Bold");
        doc.setFontSize(12);
        const header = doc.splitTextToSize(`Ωρομέτρηση μηνός: ${greekMonthOptions.find((el) => el.value === displayData.month)?.label}`, 200);
        doc.text(header, doc.internal.pageSize.getWidth() / 4, 9, {alignment: "center"});
        doc.setFont("DejaVu Sans Normal");
        doc.setFontSize(9);
        doc.text(`${company.name}, ΑΦΜ: ${company.vatNumber}`, 15, 17, {alignment: "center"});
        doc.text(`Ημερομηνία Εξαγωγής: ${moment().format("DD/MM/YYYY HH:mm:ss")}`, 15, 21, {alignment: "center"});
        const imgData = 'assets/images/prrimer-logo.png';
        const imgX = doc.internal.pageSize.getWidth() - 45; // adjust the position as needed
        const imgY = 3; // adjust the position as needed
        doc.addImage(imgData, 'PNG', imgX, imgY, 40, 15);
        doc.setFont("DejaVu Sans Normal");
        const header1 = [
            {content: "Στοιχεία Εργαζομένων", colSpan: 4},
            {content: "Σύνολα", colSpan: 2},
            {content: "Ώρες Εργασίας", colSpan: 5},
            {content: "Ώρες Αδείας", colSpan: 6},
            {content: "Ώρες Υπερεργασίας", colSpan: 5},
            {content: "Ώρες Υπερωρίας", colSpan: 5},
        ]
        const header2 = [
            "Α/Α", "Εργαζόμενος", "Τύπος Εργ.", "Μισθός",
            "Ημέρες εργ.", "Ώρες εργ.",
            "ΗΜΕΡΑ", "ΝΥΧΤΑ", "ΗΜΕΡΑ ΚΥΡ.", "ΝΥΧΤΑ ΚΥΡ.", "Εκ των οποίων Αργίες",
            "ΗΜΕΡΑ", "ΝΥΧΤΑ", "ΗΜΕΡΑ ΚΥΡ.", "ΝΥΧΤΑ ΚΥΡ.", "Συν. Ημ.", "Συν. Ώρ.",
            "ΗΜΕΡΑ", "ΝΥΧΤΑ", "ΗΜΕΡΑ ΚΥΡ.", "ΝΥΧΤΑ ΚΥΡ.", "Σύνολο",
            "ΗΜΕΡΑ", "ΝΥΧΤΑ", "ΗΜΕΡΑ ΚΥΡ.", "ΝΥΧΤΑ ΚΥΡ.", "Σύνολο",
        ]
        let rows = [];
        for (let row of displayData.data) {
            rows.push([
                row["no"], row["employee"], getEmployeeCategoryOptions().find((el) => el.value === row["employeeCategory"])?.label + "\n" + getKathestosOptions().find((el) => el.value === row["kathestos"])?.label, row["salary"],

                {content: row["totals"]["daysWorked"], styles: {fillColor: "#edfcdc"}},
                {content: row["totals"]["hoursWorked"], styles: {fillColor: "#edfcdc"}},

                {content: row["working"]["day"], styles: {fillColor: "#d4d2fc"}},
                {content: row["working"]["night"], styles: {fillColor: "#ffd6d8"}},
                {content: row["working"]["daySunday"], styles: {fillColor: "#d4d2fc"}},
                {content: row["working"]["nightSunday"], styles: {fillColor: "#ffd6d8"}},
                {content: row["working"]["publicHolidays"], styles: {fillColor: "#ffcf75"}},

                {content: row["permits"]["day"], styles: {fillColor: "#d4d2fc"}},
                {content: row["permits"]["night"], styles: {fillColor: "#ffd6d8"}},
                {content: row["permits"]["daySunday"], styles: {fillColor: "#d4d2fc"}},
                {content: row["permits"]["nightSunday"], styles: {fillColor: "#ffd6d8"}},
                {content: row["permits"]["totalDays"], styles: {fillColor: "#edfcdc"}},
                {content: row["permits"]["totalHours"], styles: {fillColor: "#edfcdc"}},

                {content: row["overwork"]["day"], styles: {fillColor: "#d4d2fc"}},
                {content: row["overwork"]["night"], styls: {fillColor: "#ffd6d8"}},
                {content: row["overwork"]["daySunday"], styles: {fillColor: "#d4d2fc"}},
                {content: row["overwork"]["nightSunday"], styles: {fillColor: "#ffd6d8"}},
                {content: row["overwork"]["totalHours"], styles: {fillColor: "#edfcdc"}},

                {content: row["overtime"]["day"], styles: {fillColor: "#d4d2fc"}},
                {content: row["overtime"]["night"], styles: {fillColor: "#ffd6d8"}},
                {content: row["overtime"]["daySunday"], styles: {fillColor: "#d4d2fc"}},
                {content: row["overtime"]["nightSunday"], styles: {fillColor: "#ffd6d8"}},
                {content: row["overtime"]["totalHours"], styles: {fillColor: "#edfcdc"}},
            ])
        }
        doc.autoTable({
            startY: y,
            head: [header1, header2],
            body: rows,
            rowPageBreak: "avoid",
            styles: {
                font: "DejaVu Sans Normal",
                fontSize: 5, // set font size
                halign: "center", // set alignment
            },
        })
        const pageCount = doc.getNumberOfPages();
        // Add the pagination to each page
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text(`Σελίδα ${i} από ${pageCount}`, 10, doc.internal.pageSize.getHeight() - 10);
        }
        doc.save(`Ωρομέτρηση - ${greekMonthOptions.find((el) => el.value === displayData.month)?.label}.pdf`);
    }

    const thickBorderStyle = "2px solid #76aeb7";

    const aroundStyle = {
        borderTop: thickBorderStyle,
        borderBottom: thickBorderStyle
    }
    const rightStyle = {
        borderRight: thickBorderStyle
    }
    const dayStyle = {
        backgroundColor: "#d4d2fc",
    }
    const nightStyle = {
        backgroundColor: "#ffd6d8",
    }
    const totalStyle = {
        backgroundColor: "#edfcdc",
    }
    const argiesStyle = {
        backgroundColor: "#ffcf75",
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row>
                <Col md={12}>
                    <Alert variant={"secondary"}>
                        Πατώντας "Ανάκτηση δεδομένων από το Π.Σ. Εργάνη" θα ανακτηθούν τα δεδομένα ενός εργατικού έτους για τον σωστό υπολογισμό της αναφοράς της ωρομέτρησης. Η διαδικασία μπορεί να διαρκέσει έως και 5 λεπτά.
                        Κατόπιν θα αποθηκευτούν τα δεδομένα στο σύστημα και θα μπορείτε να τα φιλτράρετε με την χρήση των παρακάτω πεδίων.
                        <div style={{textAlign: "center"}}>
                            <Button size={"sm"} onClick={() => fetchReport(true)} disabled={loading} style={{marginTop: "25px"}}>
                                Ανάκτηση δεδομένων από το Π.Σ. Εργάνη
                                {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                            </Button>
                        </div>
                        {responseData.lastUpdate && (
                            <div style={{textAlign: "center"}}>
                                (*) Τελευταία ανάκτηση: {moment(responseData.lastUpdate).format("DD/MM/YYYY HH:mm")}
                            </div>
                        )}
                    </Alert>
                </Col>
            </Row>
            <Row className={"mb-1"}>
                <div className={"text-muted mb-1"}><i>Φίλτρα</i></div>
                <Col md={3}>
                    <Dropdown
                        name={"monthSelection"}
                        options={greekMonthOptions}
                        label={"Επιλογή μηνός"}
                        defaultValue={greekMonthOptions.find((el) => el.value === displayData.month)}
                        key={Math.random()}
                        onChange={(e) => {
                            const month = e.value;
                            const date_from = moment(`15/${month}/${company.year}`, "DD/MM/YYYY").startOf("month").format("DD/MM/YYYY");
                            const date_to = moment(`15/${month}/${company.year}`, "DD/MM/YYYY").endOf("month").format("DD/MM/YYYY");
                            dispatch(setPayrollHourReportDisplayData({
                                ...displayData,
                                month: month,
                                date_from: date_from,
                                date_to: date_to
                            }))
                            setUpdateDisplayData(true);
                        }}
                    />
                </Col>
                <Col md={4}>
                    <Input
                        name={"employeeFilter"}
                        label={"Φιλτράρισμα εργαζόμενου"}
                        autoComplete={"off"}
                        defaultValue={displayData.employeeFilter}
                        onBlur={(e) => dispatch(setPayrollHourReportDisplayData({...displayData, employeeFilter: e.target.value}))}
                    />
                </Col>
                <Col md={2} style={{paddingTop: "32px"}}>
                    <BetterCheckBox
                        name={"dayAnalysis"}
                        text={"Ανάλυση ημερών"}
                        checked={displayData.dayAnalysis}
                        onChange={(e) => setDisplayData(e.target.checked)}
                    />
                </Col>
            </Row>
            {displayData.data.length > 0 && (
                <React.Fragment>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex justify-content-end mb-2">
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => dispatch(setPayrollHourReportDisplayData({...displayData, page: 1}))}
                                    disabled={displayData.page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.first')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => dispatch(setPayrollHourReportDisplayData({
                                        ...displayData,
                                        page: displayData.page - 1
                                    }))}
                                    disabled={displayData.page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.previous')}
                                </Button>
                                <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                       bg="primary">{t('SalesBalanceSheet.page')} {displayData.page} / {displayData.totalPages}</Badge>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => dispatch(setPayrollHourReportDisplayData({
                                        ...displayData,
                                        page: displayData.page + 1
                                    }))}
                                    disabled={displayData.page === displayData.totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.next')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => dispatch(setPayrollHourReportDisplayData({
                                        ...displayData,
                                        page: displayData.totalPages
                                    }))}
                                    disabled={displayData.page === displayData.totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.last')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    {!loading && (
                        <React.Fragment>
                            {displayData.dayAnalysis ? (
                                <Row>
                                    <Col md={12}>
                                        <table className={"simpleClassicTable"} style={{fontSize: "16px"}}>
                                            <colgroup>
                                                <col span={1} style={{width: "15%"}}></col>
                                                <col span={1} style={{width: "35%"}}></col>
                                                <col span={1} style={{width: "15%"}}></col>
                                                <col span={1} style={{width: "15%"}}></col>
                                                <col span={1} style={{width: "20%"}}></col>
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th>Ημερομηνία</th>
                                                <th>Εργαζόμενος</th>
                                                <th>Ώρα από</th>
                                                <th>Ώρα έως</th>
                                                <th>Από</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {displayData.data.slice((displayData.page - 1) * 31, displayData.page * 31).map((row, idx) => (
                                                <tr key={`phr-${idx}`}>
                                                    <td>{row["date"]}</td>
                                                    <td>{formatEmployeeIdentifier(row["employeeIdentifier"])}</td>
                                                    <td>{row["hourFrom"]}</td>
                                                    <td>{row["hourTo"]}</td>
                                                    <td>{formatFromText(row["from"])}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            ) : (
                                <React.Fragment>
                                    <Row>
                                        <Col md={12}>
                                            <table className={"simpleClassicTable hourReportTable"} style={{fontSize: "12px", borderLeft: thickBorderStyle, borderRight: thickBorderStyle, wordWrap: "break-word"}}>
                                                <colgroup>
                                                    <col span={1} style={{width: "35px", ...aroundStyle}}></col>
                                                    <col span={1} style={{width: "150px", ...aroundStyle}}></col>
                                                    <col span={2} style={{width: "15%", ...aroundStyle}}></col>

                                                    <col span={2} style={{width: "15%", ...aroundStyle}}></col>

                                                    <col span={5} style={{width: "15%", ...aroundStyle}}></col>

                                                    <col span={6} style={{width: "15%", ...aroundStyle}}></col>

                                                    <col span={5} style={{width: "15%", ...aroundStyle}}></col>

                                                    <col span={5} style={{width: "15%", ...aroundStyle}}></col>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th colSpan={4} style={rightStyle}>Στοιχεία Εργαζομένων</th>
                                                    <th colSpan={2} style={rightStyle}>Σύνολα</th>
                                                    <th colSpan={5} style={rightStyle}>Ώρες Εργασίας</th>
                                                    <th colSpan={6} style={rightStyle}>Ώρες Αδείας</th>
                                                    <th colSpan={5} style={rightStyle}>Ώρες Υπερεργασίας</th>
                                                    <th colSpan={5} style={rightStyle}>Ώρες Υπερωρίας</th>
                                                </tr>
                                                <tr>
                                                    <th>A/A</th>
                                                    <th>Εργαζόμενος</th>
                                                    <th>Τύπος Εργ.</th>
                                                    <th style={rightStyle}>Μισθός</th>

                                                    {/*<th>Ένσημα</th>*/}
                                                    <th>Ημέρες Εργ.</th>
                                                    <th style={rightStyle}>Ώρες Εργ.</th>

                                                    <th>ΗΜΕΡΑ</th>
                                                    <th>ΝΥΧΤΑ</th>
                                                    <th>ΗΜΕΡΑ ΚΥΡ.</th>
                                                    <th>ΝΥΧΤΑ ΚΥΡ.</th>
                                                    <th style={rightStyle}>Εκ των οποίων Αργίες</th>

                                                    <th>ΗΜΕΡΑ</th>
                                                    <th>ΝΥΧΤΑ</th>
                                                    <th>ΗΜΕΡΑ ΚΥΡ.</th>
                                                    <th>ΝΥΧΤΑ ΚΥΡ.</th>
                                                    <th>Συν. Ημ.</th>
                                                    <th style={rightStyle}>Συν. Ωρ.</th>

                                                    <th>ΗΜΕΡΑ</th>
                                                    <th>ΝΥΧΤΑ</th>
                                                    <th>ΗΜΕΡΑ ΚΥΡ.</th>
                                                    <th>ΝΥΧΤΑ ΚΥΡ.</th>
                                                    <th style={rightStyle}>Σύνολο</th>

                                                    <th>ΗΜΕΡΑ</th>
                                                    <th>ΝΥΧΤΑ</th>
                                                    <th>ΗΜΕΡΑ ΚΥΡ.</th>
                                                    <th>ΝΥΧΤΑ ΚΥΡ.</th>
                                                    <th style={rightStyle}>Σύνολο</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {displayData.data.slice((displayData.page - 1) * 31, displayData.page * 31).map((row, idx) => (
                                                    <tr key={`phr-${idx}`} style={{cursor: "pointer"}} onClick={() => {
                                                        if (row._id) {
                                                            setEmployeeInfoModalId(row._id);
                                                            setShowEmployeeInfoModal(true);
                                                        }
                                                    }}>
                                                        <td>{row["no"]}</td>
                                                        <td>{row["employee"]}</td>
                                                        <td>
                                                            {getEmployeeCategoryOptions().find((el) => el.value === row["employeeCategory"])?.label}
                                                            <br/>
                                                            {getKathestosOptions().find((el) => el.value === row["kathestos"])?.label}
                                                        </td>
                                                        <td style={rightStyle}>{row["salary"]}</td>

                                                        {/*<td style={totalStyle}>{row["totals"]["enshma"]}</td>*/}
                                                        <td style={totalStyle}>{row["totals"]["daysWorked"]}</td>
                                                        <td style={{...rightStyle, ...totalStyle}}>{row["totals"]["hoursWorked"]}</td>

                                                        <td style={dayStyle}>{row["working"]["day"]}</td>
                                                        <td style={nightStyle}>{row["working"]["night"]}</td>
                                                        <td style={dayStyle}>{row["working"]["daySunday"]}</td>
                                                        <td style={nightStyle}>{row["working"]["nightSunday"]}</td>
                                                        <td style={{...argiesStyle, ...rightStyle}}>{row["working"]["publicHolidays"]}</td>

                                                        <td style={dayStyle}>{row["permits"]["day"]}</td>
                                                        <td style={nightStyle}>{row["permits"]["night"]}</td>
                                                        <td style={dayStyle}>{row["permits"]["daySunday"]}</td>
                                                        <td style={nightStyle}>{row["permits"]["nightSunday"]}</td>
                                                        <td style={totalStyle}>{row["permits"]["totalDays"]}</td>
                                                        <td style={{...rightStyle, ...totalStyle}}>{row["permits"]["totalHours"]}</td>

                                                        <td style={dayStyle}>{row["overwork"]["day"]}</td>
                                                        <td style={nightStyle}>{row["overwork"]["night"]}</td>
                                                        <td style={dayStyle}>{row["overwork"]["daySunday"]}</td>
                                                        <td style={nightStyle}>{row["overwork"]["nightSunday"]}</td>
                                                        <td style={{...rightStyle, ...totalStyle}}>{row["overwork"]["totalHours"]}</td>

                                                        <td style={dayStyle}>{row["overtime"]["day"]}</td>
                                                        <td style={nightStyle}>{row["overtime"]["night"]}</td>
                                                        <td style={dayStyle}>{row["overtime"]["daySunday"]}</td>
                                                        <td style={nightStyle}>{row["overtime"]["nightSunday"]}</td>
                                                        <td style={{...rightStyle, ...totalStyle}}>{row["overtime"]["totalHours"]}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <BootstrapDropdown className="mb-3 mt-3">
                                            <BootstrapDropdown.Toggle variant="primary" id="dropdown-basic">
                                                Αποθήκευση αποτελεσμάτων
                                            </BootstrapDropdown.Toggle>
                                            <BootstrapDropdown.Menu>
                                                <BootstrapDropdown.Item onClick={() => handlePDFExport()}>
                                                    Αποθήκευση ως PDF
                                                </BootstrapDropdown.Item>
                                                <BootstrapDropdown.Item onClick={() => handleExcelExport()}>
                                                    Αποθήκευση ως XLSX
                                                </BootstrapDropdown.Item>
                                            </BootstrapDropdown.Menu>
                                        </BootstrapDropdown>
                                    </Row>
                                </React.Fragment>
                            )}
                            <EmployeeInfoModal
                                employeeId={employeeInfoModalId}
                                show={showEmployeeInfoModal}
                                setShow={setShowEmployeeInfoModal}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}

export default PayrollHourReport;
