import React, {useEffect, useState} from "react";
import {getFilePreviewerType, getMimeType, getParentPath, popExtension} from "./Components/constants";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import {Badge, Button, Spinner} from "react-bootstrap";
import {toast} from "react-toastify";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {
    setDisableEmronRegisterPage
} from "../../../../_reducers/cloudSlice";
import "./EmronRegisterStyle.css";
import BetterCheckBox from "../../../common/BetterCheckBox";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import "./../../../primer/POPUP-COMPONENTS/modalWidths.css";
import {useTranslation} from "react-i18next";

const EmronRegisterFile = ({fileData, ssKey}) => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState(fileData.name);
    const [previewData, setPreviewData] = useState(fileData.data);
    const [currentPath, setCurrentPath] = useState(fileData.currentPath);
    const [dirData, setDirData] = useState(fileData.dirData);
    const [moveToNextOne, setMoveToNextOne] = useState(true);
    const [openCorrelationButtons, setOpenCorrelationButtons] = useState(false);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const disableEmronRegisterPage = useSelector((state) => state.CLOUD.disableEmronRegisterPage);

    const [showCorrelationModal, setShowCorrelationModal] = useState(false);
    const [section, setSection] = useState("");
    const [pageChanged, setPageChanged] = useState(false);
    const [page, setPage] = useState(1);
    const [viewData, setViewData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [selectedDocuments, setSelectedDocuments] = useState([]);

    useEffect(() => {
        if (!showCorrelationModal) {
            setPage(1);
            setViewData([]);
            setTotalPages(0);
            setSelectedDocuments([]);
            setSection("");
        }
    }, [showCorrelationModal])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchViewData();
        }
    }, [pageChanged])

    const iframeStyle = {
        display: "block",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        margin: 0,
        padding: 0,
        border: "0 none",
        boxSizing: "border-box",
    }

    const documentTypeMapping = {
        "sales-new" : "Sales",
        "buys-new" : "Buys",
        "new-client-payments" : "Client Payments",
        "new-supplier-payments" : "Supplier Payments",
        "accounting-record-new" : "Accounting Records",
        "product-movements-new" : "Product Movements",
        "new-client-movements" : "Client Movements",
        "new-supplier-movements" : "Supplier Movements",
    }

    const handleOpenNewERPTabTo = (tabName) => {
        const newURL = new URL(window.location.href);
        newURL.searchParams.set("ssKey", ssKey);
        newURL.searchParams.set("forceNewTab", tabName);

        const clientWidth = window.innerWidth;
        const clientHeight = window.innerHeight;
        const popUpWindow = window.open(newURL.toString(), "_blank", `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${clientWidth},height=${clientHeight}`);
        if (popUpWindow) {
            dispatch(setDisableEmronRegisterPage(true));
            const monitorPopup = setInterval(async () => {
                if (popUpWindow.closed) {
                    clearInterval(monitorPopup);
                    dispatch(setDisableEmronRegisterPage(false));

                    const status = localStorage.getItem(`${ssKey}-status`);
                    // status is 200|_id if successful
                    if (status && status.startsWith("200")) {
                        const split = String(status).split("|");
                        const oId = split[1];
                        const section = documentTypeMapping[tabName];

                        await attempFileRegister(section, [{_id: oId, section: section}]);
                        localStorage.removeItem(`${ssKey}-status`);
                    } else {
                        toast.warning("Η καταχώρηση δεν πραγματοποιηθήκε.");
                    }
                }
            }, 500);
        } else {
            console.error("Failed to open popup window.");
        }
    }

    const disabledPageStyle = disableEmronRegisterPage ? {
        opacity: "0.3",
        pointerEvents: "none",
        userSelect: "none",
    } : {};

    const handleArrowClick = (direction) => {
        setLoading(true);
        const parentPath = getParentPath(currentPath);
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "traverse",
            direction: direction,
            currentPath: parentPath,
            currentFileName: fileName,
            company: company.id,
            year: company.year,
        }, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                // Create blob
                const binaryString = atob(res.data.data);
                const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                const blob = new Blob([byteArray], { type: getMimeType(res.data.fileName) });
                let blobUrl = URL.createObjectURL(blob);
                if (popExtension(res.data.fileName) === "pdf") {
                    blobUrl += "#navpanes=0&view=Fit";
                }
                setFileName(res.data.fileName);
                setPreviewData(blobUrl);
                setCurrentPath(res.data.currentPath);
                setDirData(res.data.dirData);
            } else {
                if (res.data.specialCode !== "1") {
                    toast.error(res.data.message);
                }
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleCorrelate = (section) => {
        setPage(1);
        setShowCorrelationModal(true);
        setPageChanged(true);
        setSection(section);
    }

    const fetchViewData = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/cloud/fetch-doc-cor`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                year: company.year,
                section: section,
                page: page,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setViewData(res.data.data);
                setTotalPages(res.data.totalPages);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const attempFileRegister = async (section, documentHooks, isCorrelation = false) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronRegister",
            currentPath: currentPath,
            documentHooks: documentHooks,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                isCorrelation ? toast.success("Επιτυχής συσχέτιση.") : toast.success("Επιτυχής καταχώρηση.");
                if (res.data?.nextOne) {
                    // Create blob
                    const binaryString = atob(res.data.nextOne.data);
                    const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                    const blob = new Blob([byteArray], { type: getMimeType(res.data.nextOne.name) });
                    let blobUrl = URL.createObjectURL(blob);
                    if (popExtension(res.data.nextOne.name) === "pdf") {
                        blobUrl += "#navpanes=0&view=Fit";
                    }
                    setFileName(res.data.nextOne.name);
                    setPreviewData(blobUrl);
                    setCurrentPath(res.data.nextOne.currentPath);
                    setDirData(res.data.nextOne.dirData);
                } else {
                    window.close();
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const saveDocumentCorrelations = async () => {
        setLoading(true);
        let documentHooks = [];
        for (let doc of selectedDocuments) documentHooks.push({_id: doc, section: section});

        await attempFileRegister(section, documentHooks, true);

        setShowCorrelationModal(false);
    }

    const isSelected = (docId) => {
        return selectedDocuments.some((el) => el === docId);
    }

    const handleSelect = (docId) => {
        setSelectedDocuments([...selectedDocuments, docId]);
    }

    const handleDeselect = (docId) => {
        setSelectedDocuments(selectedDocuments.filter((el) => el !== docId));
    }

    const showLeftArrow = dirData.findIndex((el) => el.name === fileName) - 1 >= 0 && !loading;
    const showRightArrow = dirData.findIndex((el) => el.name === fileName) + 1 < dirData.length && !loading;

    return (
        <React.Fragment>
            {disableEmronRegisterPage && (
                <div style={{
                    left: "0",
                    width: "100%",
                    position: "absolute",
                    top: "40%",
                    fontSize: "32px",
                    fontWeight: "800",
                    opacity: "1",
                    textAlign: "center",
                    zIndex: "1000",
                }}>
                    Παρακαλώ τελειώστε την εργασία σας στην νέα καρτέλα
                </div>
            )}
            {showLeftArrow && (
                <div className={"arrowboxLeft"} onClick={() => handleArrowClick("left")}>
                    <img src={"assets/images/cloud/left-arrow.png"} alt={"Left arrow"}
                         style={{height: "32px", width: "32px", paddingLeft: "16px", paddingTop: "16px"}}/>
                </div>
            )}
            {showRightArrow && (
                <div className={"arrowboxRight"} onClick={() => handleArrowClick("right")}>
                    <img src={"assets/images/cloud/right-arrow.png"} alt={"right-arrow"}
                         style={{height: "32px", width: "32px", paddingLeft: "16px", paddingTop: "16px"}}/>
                </div>
            )}
            <div style={{
                height: "100%",
                width: "100%",
                position: "fixed",
                margin: "0",
                padding: "0", ...disabledPageStyle
            }}>
                <div style={{height: "calc(100vh - 75px)", position: "relative"}}>
                    {getFilePreviewerType(fileName) === "blobURL" && (
                        <React.Fragment>
                            {!isEmpty(previewData) && (
                                <iframe className={"mt-1"} src={previewData} style={iframeStyle}
                                        title={"File Previewer"}></iframe>
                            )}
                        </React.Fragment>
                    )}
                    {getFilePreviewerType(fileName) === "videoPlayer" && (
                        <React.Fragment>
                            <video controls src={previewData}
                                   style={{maxWidth: "100%", maxHeight: "100%"}} draggable={false}></video>
                        </React.Fragment>
                    )}
                    {getFilePreviewerType(fileName) === "imageViewer" && (
                        <React.Fragment>
                            <img src={previewData} alt={"Preview"} style={{maxWidth: "100%"}}
                                 draggable={false}/>
                        </React.Fragment>
                    )}
                    {getFilePreviewerType(fileName) === "audioPlayer" && (
                        <React.Fragment>
                            <audio controls src={previewData} draggable={false}/>
                        </React.Fragment>
                    )}
                    {getFilePreviewerType(fileName) === "none" && (
                        <React.Fragment>
                            <div style={{textAlign: "center"}}>
                                Δεν είναι δυνατή η προεπισκόπηση του αρχείου <strong>{fileName}</strong>
                            </div>
                        </React.Fragment>
                    )}
                    <div style={{
                        position: "absolute",
                        right: "0px",
                        bottom: "0px",
                        backgroundColor: "#585858",
                        display: "flex",
                        justifyContent: "space-between", // Adjust spacing between children
                        flexDirection: "column",
                    }}>
                        <div style={{
                            flex: "1",
                            display: "flex",
                            justifyContent: "left", // Align content horizontally to the end
                        }}>
                            <BetterCheckBox
                                name={"moveToNextOne"}
                                className={"pl-2 pt-2 pr-2"}
                                checked={moveToNextOne}
                                text={<span
                                    style={{color: "white"}}>Φόρτωση επόμενου αρχείου κατά την καταχώρηση</span>}
                                onChange={(e) => setMoveToNextOne(e.target.checked)}
                            />
                        </div>
                        <div style={{
                            flex: "1",
                            display: "flex",
                            justifyContent: "left", // Align content horizontally to the end
                        }}>
                            <BetterCheckBox
                                name={"openCorrelationButtons"}
                                className={"pl-2 pr-2"}
                                checked={openCorrelationButtons}
                                text={<span style={{color: "white"}}>Συσχέτιση ήδη υπάρχον εγγράφου</span>}
                                onChange={(e) => setOpenCorrelationButtons(e.target.checked)}
                            />
                        </div>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    gap: "5px",
                    bottom: "0",
                    left: "0",
                    width: "100%",
                    position: "sticky",
                    backgroundColor: "#585858",
                    height: "75px",
                    padding: "8px",
                }}>
                    {openCorrelationButtons ? (
                        <React.Fragment>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Sales")}>Συσχέτιση Πώλησης</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Buys")}>Συσχέτιση Αγοράς</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Client Payments")}>Συσχέτιση Είσπραξης</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Supplier Payments")}>Συσχέτιση Πληρωμής</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Accounting Records")}>Συσχέτιση Λογιστικής εγγραφής</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Product Movements")}>Συσχέτιση Κίνησης Ειδών</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Client Movements")}>Συσχέτιση Κίνησης Πελατών</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Supplier Movements")}>Συσχέτιση Κίνησης Προμηθευτών</div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                        <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("sales-new")}>Πώληση</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("buys-new")}>Αγορά</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("new-client-payments")}>Είσπραξη</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("new-supplier-payments")}>Πληρωμή</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("accounting-record-new")}>Λογιστική εγγραφή</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("product-movements-new")}>Κίνηση ειδών</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("new-client-movements")}>Κίνηση πελατών</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("new-supplier-movements")}>Κίνηση προμηθευτών</Button>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <Modal show={showCorrelationModal} backdrop={"static"} dialogClassName={"modal65PercentWidth"}>
                <Modal.Header>
                    <Modal.Title>Συσχέτιση Εγγράφου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {totalPages > 0 && (
                        <React.Fragment>
                            <div className="d-flex justify-content-end mb-2">
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => {
                                        setPage(1);
                                        setPageChanged(true);
                                    }}
                                    disabled={page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.first')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => {
                                        setPage(page - 1);
                                        setPageChanged(true);
                                    }}
                                    disabled={page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.previous')}
                                </Button>
                                <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1" bg="primary">{t('SalesBalanceSheet.page')} {page} / {totalPages}</Badge>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => {
                                        setPage(page + 1);
                                        setPageChanged(true);
                                    }}
                                    disabled={page === totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.next')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => {
                                        setPage(totalPages);
                                        setPageChanged(true);
                                    }}
                                    disabled={page === totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.last')}
                                </Button>
                            </div>
                            {viewData.length > 0 ? (
                                <table className={"simpleClassicTable"} style={{wordBreak: "break-all"}}>
                                    <colgroup>
                                        <col span={1} style={{width: "20%"}}></col>
                                        <col span={1} style={{width: "35%"}}></col>
                                        <col span={1} style={{width: "25%"}}></col>
                                        <col span={1} style={{width: "20%"}}></col>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Στοιχεία εγγράφου</th>
                                        <th>Ποσό</th>
                                        <th>Επιλογή</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {viewData.map((row, idx) => (
                                        <tr key={`ertable-${idx}`}
                                            style={{backgroundColor: isSelected(row._id) ? "lightgray" : ""}}>
                                            <td>{moment(row["date"]).format("DD/MM/YYYY")}</td>
                                            <td>{row["documentTypeName"]} {row["preNumber"] === "noprefix" ? row["number"] : row["preNumber"] + " " + row["number"]}</td>
                                            <td>{row["paymentAmount"] || row["sum"] || row["amount"]}</td>
                                            <td>{isSelected(row._id) ? (
                                                <Badge bg={"danger"} style={{cursor: "pointer"}}
                                                       onClick={() => handleDeselect(row._id)}>Αποεπιλογή</Badge>
                                            ) : (
                                                <Badge bg={"primary"} style={{cursor: "pointer"}}
                                                       onClick={() => handleSelect(row._id)}>Επιλογή</Badge>
                                            )}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ) : (
                                <span>Δεν βρέθηκαν δεδομένα</span>
                            )}
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowCorrelationModal(false)} disabled={loading}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => saveDocumentCorrelations()}
                            disabled={selectedDocuments.length === 0 || loading}>
                        Συσχέτιση επιλεγμένων {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default EmronRegisterFile;
