import {isEmpty} from "../../../../../_helpers/commonFunctions";

export const popExtension = (fileName) => {
    return String(fileName).split(".").pop().toLowerCase();
}

export const getMimeType = (filename) => {
    const mimeTypes = {
        "pdf": "application/pdf",
        "txt": "text/plain",
        "html": "text/html",
        "htm": "text/html",
        "jpg": "image/jpeg",
        "jpeg": "image/jpeg",
        "png": "image/png",
        "gif": "image/gif",
        "svg": "image/svg+xml",
        "json": "application/json",
        "js": "application/javascript",
        "css": "text/css",
        "xml": "application/xml",
        "zip": "application/zip",
        "rar": "application/vnd.rar",
        "7z": "application/x-7z-compressed",
        "mp3": "audio/mpeg",
        "mp4": "video/mp4",
        "avi": "video/x-msvideo",
        "webm": "video/webm",
        "doc": "application/msword",
        "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "xls": "application/vnd.ms-excel",
        "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "ppt": "application/vnd.ms-powerpoint",
        "pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    }
    return mimeTypes[popExtension(filename)] || "application/octet-stream";
}

const filePreviewerTypes = {
    "pdf": "blobURL",
    "txt": "blobURL",
    "json": "blobURL",

    "jpg": "imageViewer",
    "jpeg": "imageViewer",
    "png": "imageViewer",
    "gif": "imageViewer",

    "m4v": "videoPlayer",
    "mp4": "videoPlayer",
    "avi": "videoPlayer",
    "webm": "videoPlayer",

    "mp3": "audioPlayer",
    "m4a": "audioPlayer",
}

const imgMapping = {
    "cad" : "assets/images/cloud/types/cad.png",
    "docx" : "assets/images/cloud/types/docx.png",
    "dwg" : "assets/images/cloud/types/dwg.png",
    "exe" : "assets/images/cloud/types/exe.png",
    "gif" : "assets/images/cloud/types/gif.png",
    "html" : "assets/images/cloud/types/html.png",
    "jpg" : "assets/images/cloud/types/jpg.png",
    "mov" : "assets/images/cloud/types/mov.png",
    "mp4" : "assets/images/cloud/types/mp4.png",
    "pdf" : "assets/images/cloud/types/pdf.png",
    "png" : "assets/images/cloud/types/png.png",
    "pptx" : "assets/images/cloud/types/pptx.png",
    "rar" : "assets/images/cloud/types/rar.png",
    "txt" : "assets/images/cloud/types/txt.png",
    "xlsx" : "assets/images/cloud/types/xlsx.png",
    "zip" : "assets/images/cloud/types/zip.png",
}

export const getFileImageDisplay = (ext) => {
    return imgMapping[ext] ? imgMapping[ext] : "assets/images/cloud/file.png";
}

export const getFilePreviewerType = (fileName) => {
    const ext = popExtension(fileName);

    return filePreviewerTypes[ext] ? filePreviewerTypes[ext] : "none";
}

export const formatPath = (path) => {
    return String(path).replace(/\/\//g, '/');
}

export const formatBigName = (fileName) => {
    if (String(fileName).length > 40) {
        let ext = popExtension(fileName);
        if (isEmpty(ext)) ext = "";
        return String(fileName).slice(0, 40 - ext.length).trim() + "..." + ext;
    } else {
        return fileName;
    }
}

export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(file);
    })
}

export const getParentPath = (fullPath) => {
    const parts = fullPath.split("/");

    return formatPath(parts.slice(0, parts.length - 1).join("/"));
}
