import React, {useEffect, useState} from "react";
import {toast} from 'react-toastify';
import EditableTable from "../../../primer/Editable-Table";
import {Button, Col, Row} from 'react-bootstrap';
import Dropdown from "../../../primer/Dropdown";
import {useDispatch, useSelector} from "react-redux";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {setNewProductsCategoryMyData} from "../../../../_reducers/DataPanel/Products/productCategorySlice";
import getMyData, {
    getICCategoryOptions,
    getICTypeOptions,
    getInvoiceTypesOptions, getMyDataRowTypes, getVatExpenseClassificationType
} from '../../../../_helpers/sharedmydata';
import {useTranslation} from "react-i18next";
import {
    newAccountingAccountCategoryInitialValue, setAccountingPaymentTypes,
    setAccountingSettings
} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import {fetchAccountingPaymentTypes, postAccountingSettings} from "../../../../_apis/api";
import {
    checkPermission,
    getDefaultOptionByValue,
    getIsNetValueOptions, getOptionsByData,
    getSubaccountOptions, getTaxTypeOptions, isEmpty,
    resetTableItemsId
} from "../../../../_helpers/commonFunctions";
import {salesPaymentTypeData} from "../../../../_data/sales/sales-payment-types";
import {setSaveAccountingPaymentTypes} from "../../../../_reducers/activitySlice";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import LogTable from "../../../primer/LogTable";

export const AccountingAccountSettings = () => {
    const [t] = useTranslation("common");
    const dispatch = useDispatch();
    const invoiceTypes = getInvoiceTypesOptions();
    const icCategory = getICCategoryOptions();
    const icType = getICTypeOptions();
    const myDataRowTypes = getMyDataRowTypes();
    const vatExpenseTypes = getVatExpenseClassificationType();
    const isNetValueOptions = getIsNetValueOptions();
    const subAccountOptions = getSubaccountOptions();
    const PRODUCTS_CATEGORY = useSelector(state => state.PRODUCTS_CATEGORY);
    let vatTaxes      = getMyData({type: 'vat', option: "doNotShowZero"});
    const taxTypeOptions = getTaxTypeOptions();
    vatTaxes = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...vatTaxes]
    let zeroVatReason = getMyData({type: 'zerovatreason'});
    const ACCOUNTING_DATA = useSelector( (state) => state.ACCOUNTING_DATA );
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const SETTINGS = useSelector(state => state.SETTINGS);
    let pMDDSettings = SETTINGS.primerMyDataData;
    let otherTaxes    = getOptionsByData(pMDDSettings?.otherTaxes !== undefined && pMDDSettings?.otherTaxes !== '' ? (pMDDSettings.otherTaxes) : SETTINGS.otherHoldTaxes, 'code', 'name');
    let taxesWithhold = getOptionsByData(pMDDSettings?.withHoldTaxes !== undefined && pMDDSettings?.withHoldTaxes !== '' ? (pMDDSettings.withHoldTaxes) : SETTINGS.withHoldTaxes, 'code', 'name');
    let taxesStamp    = getOptionsByData(pMDDSettings?.stamp !== undefined && pMDDSettings?.stamp !== '' ? (pMDDSettings.stamp) : SETTINGS.stamp, 'code', 'name');
    let taxesFees     = getOptionsByData(pMDDSettings?.fees !== undefined && pMDDSettings?.fees !== '' ? (pMDDSettings.fees) : SETTINGS.fees, 'code', 'name');
    // Fix Blank In the first position
    otherTaxes = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...otherTaxes]
    taxesWithhold = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesWithhold]
    taxesStamp = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesStamp]
    taxesFees = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesFees]

    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    const productsColumns = [
        {
            name: t('ProductsNewGeneral.table.rowType'),
            field: "rowType",
            width: "10%",
            editable:true,
            inputType: {
                type: 'select',
                options: myDataRowTypes
            }
        },
        {
            name: t('ProductsSettings.table.invoiceType'),
            field: "invoiceType",
            editable: true,
            width: "30%",
            inputType: {
                type: 'select',
                multiselect: true,
                options: invoiceTypes
            }
        },
        {
            name: t('ProductsSettings.table.category'),
            field: "category",
            editable: true,
            width: "20%",
            inputType: {
                type: 'select',
                options: icCategory
            }
        },
        {
            name: t('ProductsSettings.table.type'),
            field: 'type',
            editable: true,
            width: "20%",
            inputType: {
                type: 'select',
                options: icType
            }
        },
        {
            name: t('ProductsNewGeneral.table.vatExpenseType'),
            field: 'vatExpenseType',
            editable: true,
            width: "20%",
            inputType: {
                type: 'select',
                options: vatExpenseTypes,
            }
        }
    ];
    const accountingCategoryColumns = [
        {
            name: t("General.templateAccountName"),
            field: "category",
            width: "100%",
            disabled: false,
            editable:true,
            inputType: {
                type: 'text',
            }
        }
    ];
    const [editing, setEditing] = useState(false);
    const [forceRender, setForceRender] = useState(0);
    const [showZeroVatReson, setShowZeroVatReson] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [showExtraDetails, setShowExtraDetails] = useState(false);
    useEffect(() => {
        setForceRender(0);
            if((selectedCategory.vat === '0%') || (selectedCategory.vat === '0')) {
                setShowZeroVatReson(true);
            } else {
                setShowZeroVatReson(false);
            }
    }, [dispatch, forceRender]);

    // Show 0% VAT Reason
    const toggleVatReasonField = (e) => {
        if ((e.value === '0')) {
            setShowZeroVatReson(true);
        } else {
            setShowZeroVatReson(false);
        }
    }

    const handleOnFieldChange = (e, name = '', inputType = '') => {
        setEditing(true);
        setForceRender(forceRender + 1)
        let accountingCategory = Object.assign({}, selectedCategory);
        if (inputType === 'ac' || inputType === 'dd') {
            accountingCategory[name] = e.value;
            if (name === 'isNetValue') {
                toggleVatReasonField(e);
                if(e.value !== "0"){
                    accountingCategory["vatreason"] = "";
                }
            }
        }
        setSelectedCategory({...accountingCategory});
    }

    const handleSaveCategory = async () => {
        if (editing) {
            // In case of updating existing category
            if ((Object.keys(selectedCategory).length !== 0)) {
                let saveCategory = Object.assign({}, selectedCategory);
                saveCategory.id = saveCategory._id;
                if ((Object.keys(selectedCategory).length !== 0)) {
                    let categories = ACCOUNTING_DATA.accountingCategories.slice();
                    await Promise.all(categories.map((category, index) => {
                        if (category._id === selectedCategory._id) {
                            categories[index] = selectedCategory;
                        }
                        return category;
                    }))
                    dispatch(setAccountingSettings(categories));
                    dispatch(postAccountingSettings({categories: categories}, {company: company.id}));
                }
                setEditing(false);
                setForceRender(forceRender + 1)
            }
        }
    }


    const parentActions = {
        insert: (e, data) => {
            setForceRender(forceRender + 1)
            const newAccountingAccountCategory = Object.assign({}, newAccountingAccountCategoryInitialValue);
            if(data?.category !== ""){
                newAccountingAccountCategory[data.field] = data.value;
                let tempCategoryData = ACCOUNTING_DATA.accountingCategories.slice();
                newAccountingAccountCategory._id = ACCOUNTING_DATA.accountingCategories.length + 1;
                newAccountingAccountCategory.isActive = "active";
                tempCategoryData.push(newAccountingAccountCategory);
                dispatch(setAccountingSettings(tempCategoryData));
                dispatch(postAccountingSettings({categories: tempCategoryData}, {company: company.id}));
                setSelectedCategory(newAccountingAccountCategory);
                setShowExtraDetails(true);

            } else {
                toast.error("Please enter category name");
            }
        },
        selected: (data) => {
            if (editing) {
                toast.warning('Please save your changes.');
            } else {
                if (typeof (data._id) !== 'undefined' ) {
                    const selected = getObjectFromArrayOfObjects(ACCOUNTING_DATA.accountingCategories, data._id,'_id')['0'];
                    setSelectedCategory(selected);
                    // Set Proposed My Data for selected category
                    if ((Object.keys(selected).length !== 0)) {
                            try {
                                const vat = selected.vat;
                                if((vat === '0%') || (vat === '0')) {
                                    setShowZeroVatReson(true);
                                } else {
                                    setShowZeroVatReson(false);
                                }
                            } catch (e) {
                                console.log('taxes is not an json object');
                            }
                    }
                    setShowExtraDetails(true);
                } else {
                    setShowExtraDetails(false);
                }
            }
        },
        update: async (data, id) => {
            setForceRender(forceRender + 1);
            if (data?.name === '') {
                toast.error('Category name should not be empty.');
            } else {
                let tempCategoryData = ACCOUNTING_DATA.accountingCategories.slice();
                if (tempCategoryData) {
                    const objindx = tempCategoryData.findIndex(obj => obj._id === id);
                    let catObj = tempCategoryData[objindx];
                    setSelectedCategory(catObj);
                    tempCategoryData[objindx] = {...catObj, ...data};
                    dispatch(setAccountingSettings(tempCategoryData));
                    dispatch(postAccountingSettings({categories: tempCategoryData}, {company: company.id}));
                }
            }
        },
        delete: (id) => {
            let tempCategoryData = ACCOUNTING_DATA.accountingCategories.slice();
            let deleteCategory = getObjectFromArrayOfObjects(tempCategoryData, id,'_id')['0'];
            const objindx = tempCategoryData.findIndex(obj => obj._id === id);
            tempCategoryData[objindx] = {...deleteCategory, ...{ id: id, isActive: 'inactive', deleted: true }};
            dispatch(setAccountingSettings(tempCategoryData));
            dispatch(postAccountingSettings({categories: tempCategoryData}, {company: company.id}));
        }
    }
    const parentMydataActions = {
        insert: (e, data) => {
            setEditing(true);
            setForceRender(forceRender + 1)
            let tempPcMyData = Object.assign({},  PRODUCTS_CATEGORY.newProductsCategoryMyData);
            if (e.key !== "Enter") {
                tempPcMyData[data.field] = data.value;
                dispatch(setNewProductsCategoryMyData(tempPcMyData)); // Update redux my data values
            }
            // If validated fields done add new row
            if (tempPcMyData.rowType !=='' && tempPcMyData.invoiceType !=='' && tempPcMyData.category !=='' && tempPcMyData.type !=='') {
                let updateNewPcMydata = Object.assign({},  PRODUCTS_CATEGORY.newProductsCategoryMyData);
                updateNewPcMydata.id = selectedCategory.mydata.length + 1;
                // set the last updated value
                if (tempPcMyData.type) {
                    updateNewPcMydata.type = tempPcMyData.type;
                }
                if (tempPcMyData.rowType) {
                    updateNewPcMydata.rowType = tempPcMyData.rowType;
                }
                if (tempPcMyData.invoiceType) {
                    updateNewPcMydata.invoiceType = tempPcMyData.invoiceType;
                }
                if (tempPcMyData.category) {
                    updateNewPcMydata.category = tempPcMyData.category;
                }
                let accountingCategory = Object.assign({}, selectedCategory);
                let updatedMyData = Array.isArray(accountingCategory.mydata) ? [...accountingCategory.mydata, updateNewPcMydata] : [updateNewPcMydata];
                setSelectedCategory({ ...accountingCategory, mydata: updatedMyData });
                dispatch(setNewProductsCategoryMyData(PRODUCTS_CATEGORY.newProductsCategoryMyDataInitialValue)); // Reset new category mydata
            }
        },
        update: (data, id) => {
            setEditing(true);
            setForceRender(forceRender + 1)
                let selectedCategoryMyData = Object.assign([],selectedCategory.mydata);
                if (selectedCategoryMyData.find(item => item.id === id) !== undefined) {
                    let accountingCategory = Object.assign({}, selectedCategory);
                    selectedCategoryMyData[id-1] = {...selectedCategoryMyData[id - 1], ...data};
                    if (data.invoiceType) {
                        selectedCategoryMyData[id-1].category = "";
                        selectedCategoryMyData[id-1].type = "";
                        selectedCategoryMyData[id-1].vatExpenseType = "";
                    } else if (data.category) {
                        selectedCategoryMyData[id-1].type = "";
                        selectedCategoryMyData[id-1].vatExpenseType = "";
                    } else if (data.type) {
                        selectedCategoryMyData[id-1].vatExpenseType = "";
                    }
                    setSelectedCategory({ ...accountingCategory, mydata: selectedCategoryMyData});
                }
        },
        selected: () => {},
        delete: (id) => {
            let oldMydata = Object.assign([],selectedCategory.mydata);
            setEditing(true);
            setForceRender(forceRender + 1)
            if (id > 0) {
                oldMydata.splice(id - 1, 1);
                oldMydata = resetTableItemsId(oldMydata);
                let accountingCategory = Object.assign({}, selectedCategory);
                setSelectedCategory({ ...accountingCategory, mydata: oldMydata });
                toast.success('1 row deleted.');
            } else {
                console.log('delete mydata row clicked');
            }
        }
    }
    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <div className="product-categories">
                        <EditableTable
                            tableName = "Accounting Category" // Do not change name
                            selectedRow={selectedCategory._id}
                            onUpdate={parentActions}
                            key={Math.random()}
                            allowActions={true}
                            actionButtons={{
                                print: false,
                                delete: true,
                                export: false,
                                edit: false,
                            }}
                            allowInsertRow={true}
                            data={ACCOUNTING_DATA.accountingCategories && ACCOUNTING_DATA.accountingCategories.length > 0 ? ACCOUNTING_DATA.accountingCategories : [] }  // Default Category
                            columns={ accountingCategoryColumns }
                        />
                    </div>
                </div>
                <div className="col-lg-6 ">
                    <p className="text-muted"><i>{t('AccountingAccountSettings.description')}</i></p>
                </div>
            </div>
            {/* Category Extra Details */}
            { showExtraDetails ?
                <>
                    <br />
                    <p className="text-center border-bottom pb-3 pt-3 "><i>{t('ProductsSettings.categorySettings')}</i></p>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-muted"><i>{t('ProductsSettings.proposedMyDataValue')}</i></p>
                            <EditableTable
                                tableName = "Product Category My Data" // Do not change name
                                key={Math.random()}
                                onUpdate={parentMydataActions}
                                allowActions={true}
                                allowInsertRow={ true }
                                columns={productsColumns}
                                data={selectedCategory?.mydata ? selectedCategory?.mydata : []}
                                enableNewRowCheckbox = {true}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12">
                            <p className="text-muted"><i>Άλλες Ρυθμίσεις</i></p>
                        </div>
                        { selectedCategory.vat === "" && selectedCategory.taxType === "" && (
                        <div className="col-lg-4">
                            <Dropdown
                                defaultValue={getDefaultOptionByValue(isNetValueOptions, selectedCategory.isNetValue, 'value', 'label', 'value')}
                                key={Math.random()}
                                label={t("General.isNetValue")}
                                onChange={(e) => handleOnFieldChange(e, 'isNetValue', 'dd')}
                                options={isNetValueOptions}
                            />
                        </div>
                        )}
                        { showZeroVatReson &&
                            <div className="col-lg-4">
                                <Dropdown
                                    defaultValue={{ value: selectedCategory.vatreason, label: selectedCategory.vatreason, color: "#76AEB7" }}
                                    key={Math.random()}
                                    label={t('ProductsSettings.0%vatReason')}
                                    onChange={(e) => handleOnFieldChange(e, 'vatreason', 'dd')}
                                    options={zeroVatReason}
                                />
                            </div>
                        }
                        { selectedCategory.isNetValue === "false" && selectedCategory.taxType === "" && (
                        <div className="col-lg-4">
                                <Dropdown
                                    key={Math.random()}
                                    defaultValue={{ value: selectedCategory.vat, label: selectedCategory.vat, color: "#76AEB7" }}
                                    onChange={(e) => handleOnFieldChange(e, 'vat', 'dd')}
                                    label={t('ProductsSettings.vat%')}
                                    options={vatTaxes}
                                />
                        </div>
                        )}
                        {selectedCategory.isNetValue === "false" && selectedCategory.vat === "" &&
                            <div className="col-lg-4">
                                <Dropdown
                                    key={Math.random()}
                                    defaultValue={getDefaultOptionByValue(taxTypeOptions, selectedCategory.taxType, 'value', 'label', 'value')}
                                    onChange={(e) => handleOnFieldChange(e, 'taxType', 'dd')}
                                    label={t("General.isTax")}
                                    options={taxTypeOptions}/>
                            </div>
                        }
                        {selectedCategory.taxType === "withHoldTax" &&
                            <div className="col-lg-4">
                                <Dropdown
                                    key={Math.random()}
                                    defaultValue={getDefaultOptionByValue(taxesWithhold, selectedCategory.withHoldTax, 'value', 'label', 'value')}
                                    onChange={(e) => handleOnFieldChange(e, 'withHoldTax', 'dd')}
                                    required={true}
                                    label={t("General.taxWithholding")}
                                    options={taxesWithhold}
                                />
                            </div>
                        }
                        {selectedCategory.taxType === "otherTax" &&
                            <div className="col-lg-4">
                                <Dropdown
                                    key={Math.random()}
                                    defaultValue={getDefaultOptionByValue(otherTaxes, selectedCategory.otherTax, 'value', 'label', 'value')}
                                    onChange={(e) => handleOnFieldChange(e, 'otherTax', 'dd')}
                                    required={true}
                                    label={t("General.otherTax")}
                                    options={otherTaxes}
                                />
                            </div>
                        }
                        {selectedCategory.taxType === "fee" &&
                            <div className="col-lg-4">
                                <Dropdown
                                    key={Math.random()}
                                    defaultValue={getDefaultOptionByValue(taxesFees, selectedCategory.fee, 'value', 'label', 'value')}
                                    onChange={(e) => handleOnFieldChange(e, 'fee', 'dd')}
                                    required={true}
                                    label="Τέλος"
                                    options={taxesFees}
                                />
                            </div>
                        }
                        {selectedCategory.taxType === "stamp" &&
                            <div className="col-lg-4">
                                <Dropdown
                                    key={Math.random()}
                                    defaultValue={getDefaultOptionByValue(taxesStamp, selectedCategory.stamp, 'value', 'label', 'value')}
                                    onChange={(e) => handleOnFieldChange(e, 'stamp', 'dd')}
                                    required={true}
                                    label="Χαρτόσημο"
                                    options={taxesStamp}
                                />
                            </div>
                        }
                        <Row>
                            <div className="col-lg-4">
                                <Dropdown
                                    defaultValue={getDefaultOptionByValue(subAccountOptions, selectedCategory.subAccount, 'value', 'label', 'value')}
                                    key={Math.random()}
                                    label="Υπολογαριασμός"
                                    onChange={(e) => handleOnFieldChange(e, 'subAccount', 'dd')}
                                    options={subAccountOptions}
                                />
                            </div>
                        </Row>
                    </div>
                </>
                : ''}
            <div className="d-flex justify-content-end">
                {checkPermission("accounting-settings/accounting-settings-write", permissionsData) &&
                    <Button disabled ={!editing} onClick={(e) => handleSaveCategory(e)} className="ml-2 mb-3">{t('ProductsSettings.save')}</Button>
                }
            </div>
        </>
    );
};

export const AccountingPaymentMethods = () => {
    const dispatch = useDispatch();
    const ACCOUNTING_DATA = useSelector((state) => state.ACCOUNTING_DATA);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [firstLoad, setFirstLoad] = useState(true);
    const [t] = useTranslation("common");

    useEffect(() => {
        if(firstLoad) {
            setFirstLoad(false);
            dispatch(fetchAccountingPaymentTypes(company));
        }
    }, [firstLoad])

    const paymentTypesColumns = [
        {
            name: t("General.no"),
            field: "no",
            editable: false,
        },
        {
            name: t("General.name"),
            field: "name",
            editable: true,
            inputType: {
                type: "text"
            }
        },
        {
            name: t("General.type"),
            field: "type",
            editable: true,
            width: "200px",
            inputType: {
                type: "dropdown",
                options: getOptionsByData(salesPaymentTypeData, "value", "label"),
            }
        }
    ]

    const paymentTypesActions = {
        insert: (e, data) => {
            if(e.key !== "Enter" && String(data.value) !== "") {
                let paymentTypes = ACCOUNTING_DATA.paymentTypes.slice();
                let newPaymentType = {
                    no: String(paymentTypes.length + 1),
                    id: String(paymentTypes.length + 1),
                }
                if(data.field === "type") {
                    newPaymentType.myDataPaymentId = salesPaymentTypeData.filter((el) => el.value === data.value)[0].myDataId;
                }
                newPaymentType[data.field] = data.value;
                paymentTypes.push(newPaymentType);
                dispatch(setAccountingPaymentTypes(paymentTypes));
                if(!ACTIVITY.saveAccountingPaymentTypes) dispatch(setSaveAccountingPaymentTypes(true));
            }
        },
        update: (data, id) => {
            let paymentTypes = ACCOUNTING_DATA.paymentTypes.slice();
            paymentTypes = paymentTypes.map((a) => Object.assign({}, a));
            let idx = paymentTypes.findIndex((el) => String(el.id) === String(id));
            if(idx !== -1) {
                paymentTypes[idx] = {...paymentTypes[idx], ...data}
                if(data.type) {
                    paymentTypes[idx] = {...paymentTypes[idx], myDataPaymentId: salesPaymentTypeData.filter((el) => el.value === data.type)[0].myDataId}
                }
                dispatch(setAccountingPaymentTypes(paymentTypes));
                if(!ACTIVITY.saveAccountingPaymentTypes) dispatch(setSaveAccountingPaymentTypes(true));
            }
        },
        selected: () => {},
        delete: () => {}
    }

    const handleSaveClicked = () => {
        axios.post(process.env.REACT_APP_API_URL2 + "/accounting/save-payment-types", {company: company.id, paymentTypes: ACCOUNTING_DATA.paymentTypes}, { headers: { "Content-Type": "application/json" } })
            .then((response) => {
                if(response.data.status === "200") {
                    toast.success(response.data.message);
                    dispatch(setSaveAccountingPaymentTypes(false));
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((err) => {
                toast.error("Σφάλμα κατά την αποθήκευση.");
                console.log(err);
            })
    }

    return (
        <React.Fragment>
            <Row>
                <Col md={6}>
                    <EditableTable
                        tableName={"Accounting Settings Payment Types"}
                        data={ACCOUNTING_DATA.paymentTypes}
                        columns={paymentTypesColumns}
                        onUpdate={paymentTypesActions}
                        key={Math.random()}
                        allowInsertRow={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col className={"d-flex justify-content-end"}>
                    <Button onClick={() => handleSaveClicked()} className={"mb-3"} disabled={!ACTIVITY.saveAccountingPaymentTypes}>Αποθήκευση</Button>
                </Col>
            </Row>
        </React.Fragment>
    );
}
export const AccountingYearReportSettings = () => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [t] = useTranslation("common");

    useEffect(() => {
        fetchRules();
    }, [])

    const tableColumns  = [
        {
            name: t("General.no"),
            field: "no",
            editable: false,
            width: "5%"
        },
        {
            name: t("General.ruleName"),
            field: "ruleName",
            editable: true,
            width: "25%",
            inputType: {
                type: "input"
            }
        },
        {
            name: t("General.rules"),
            field: "rules",
            editable: true,
            width: "55%",
            inputType: {
                type: "input"
            }
        },
        {
            name: "Πρόσημο",
            field: "sign",
            editable: true,
            width: "15%",
            inputType: {
                type: "select",
                options: [{label: "Πίστωση", value: "credit"}, {label: "Χρέωση", value: "debit"}]
            }
        }
    ]
    const fetchRules = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL2 + "/accounting/get-yearly-results-rules", {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
            }
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                setTableData(res.data.data);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }
    const handleSave = () => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/accounting/save-yearly-results-rules", {company: company.id, yearlyResultsRules: tableData}, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                toast.success("Η αποθήκευση έγινε επιτυχώς.");
            } else {
                toast.success(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(err);
        })
    }
    const tableFunctions = {
        insert: (e, data) => {
            if (!isEmpty(data.value)) {
                let cloneTableData = structuredClone(tableData);
                let initRow = {
                    no: String(tableData.length + 1),
                    ruleName: "",
                    rules: "",
                    sign: "credit",
                }
                initRow = {...initRow, [data.field] : data.value};
                cloneTableData.push(initRow);
                setTableData(cloneTableData);
            }
        },
        update: (data, id, rowIndex) => {
            let cloneTableData = structuredClone(tableData);
            cloneTableData[rowIndex] = {...cloneTableData[rowIndex], ...data};
            setTableData(cloneTableData);
        },
        selected: () => {},
        delete: (id, rowIndex) => {
            let cloneTableData = structuredClone(tableData);
            cloneTableData.splice(rowIndex, 1);
            setTableData(cloneTableData);
        }
    }

    return (
        <React.Fragment>
            <Row>
                <div className="text-muted mb-1"><i>{t("AccountingYearlyResults")}</i></div>
                <Col md={12}>
                    <EditableTable
                        tableName={"Accounting Yearly Results"}
                        key={Math.random()}
                        data={tableData}
                        columns={tableColumns}
                        onUpdate={tableFunctions}
                        allowInsertRow={true}
                        allowActions={true}
                    />
                    <Button size={"sm"} style={{float: "right"}} onClick={() => handleSave()} disabled={loading}>Αποθήκευση</Button>
                </Col>
            </Row>
            <Row className={"mt-2"}>
                <Col md={7}>
                    <Alert variant={"secondary"}>
                        <strong>Οδηγίες συμπλήρωσης:</strong><br/>
                        - Όνομα Κανόνα: Το όνομα του κανόνα που επιθυμούμε. Αντίστοιχα θα είναι και το όνομα της στήλης που παρουσιάζεται<br/>
                        - Κανόνες: Οι κανόνες που πρέπει να ακολουθούν οι λογαριασμοί που κινούνται έτσι ώστε να υπολογιστούν σαν σύνολο<br/>
                        Κάθε κανόνας περιγράφει ένα άθροισμα. Έχει την μορφή λογαριασμού και μπορούν να συνδυαστούν μεταξύ τους με απλές πράξεις.<br/>
                        Οι διαθέσιμες πράξεις είναι + και -<br/>
                        Η μορφή λογαριασμού έχει 3 κανόνες<br/>
                        - ΚΩΔΙΚΟΣ** : 2 αστέρια στο τέλος υποδηλώνει ότι θα θέλαμε να ξεκινά από τον κωδικό που του δίνουμε <br/>
                        - **ΚΩΔΙΚΟΣ : 2 αστέρια στην αρχή υποδηλώνει ότι θα θέλαμε να τελειώνει στον κωδικό που του δίνουμε <br/>
                        - **ΚΩΔΙΚΟΣ** : 2 αστέρια στην αρχή και 2 στο τέλος υποδηλώνει ότι θα θέλαμε να περιέχει τον κωδικό που του δίνουμε <br/>
                        - ΚΩΔΙΚΟΣ : Χωρίς κανένα αστέρι υποδηλώνει ότι θα πρέπει να ισούται με τον κωδικό που του δίνουμε <br/>
                        <strong>π.χ.</strong> Παράδειγμα απλού κανόνα αθροίσματος λογαριασμών που ξεκινούν από 60. : 60.** <br/>
                        <strong>π.χ.</strong> Παράδειγμα σύνθετου κανόνα που αφαιρεί από τους λογαριασμούς που ξεκινούν από 6 τους λογαριασμούς που ξεκινούν από 4: <br/>6** - 4**<br/>
                    </Alert>
                </Col>
                <Col md={5}>
                    <Alert variant={"secondary"}>
                        Η στήλη "Πρόσημο" λαμβάνει δύο πιθανές τιμές, της χρέωσης ή της πίστωσης. Σε περίπτωση που το υπόλοιπο βρίσκεται στην ίδια κατάσταση
                        με την επιλογή του πρόσημου τότε είναι θετικό, αλλιώς αρνητικό.<br/>
                        <strong>π.χ.</strong> Αν ένας λογαριασμός έχει υπόλοιπο ΧΡΕΩΣΗ 1300 και η επιλογή του πρόσημου είναι ΠΙΣΤΩΣΗ τότε στα ετήσια αποτελέσματα υπολογίζεται ως -1300<br/>
                        <strong>π.χ.</strong> Αν ένας λογαριασμός έχει υπόλοιπο ΠΙΣΤΩΣΗ 300 και η επιλογή του πρόσημου είναι ΠΙΣΤΩΣΗ τότε στα ετήσια αποτελέσματα υπολογίζεται ως 300
                    </Alert>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export const AccountingSettingsLog = () => {
    const { t } = useTranslation('common');

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.accountingSettingsLog')}</i></div>
            <LogTable model="accountingSetting"/>
        </React.Fragment>
    )
};
