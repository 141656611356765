export const wildcardsList = [
    { label: "Επωνυμία Οντότητας", value: "{Entity}" },
    { label: "ΑΦΜ Οντότητας", value: "{EntityVAT}" },
    { label: "Ημερομηνία Άρθρου", value: "{ArticleDate}" },
    { label: "Ώρα άρθρου", value: "{ArticleTime}" },
]

export const signs = [
    { label: "+", value: "+" },
    { label: "-", value: "-" },
]

export const accountingTypes = [
    { label: "Χρέωση", value: "debit" },
    { label: "Πίστωση", value: "credit" },
]

const bridgeTypeSales = [
    { label: "Πελάτης", value: "client" },
    { label: "Καθαρή Αξία", value: "netValue" },
    { label: "Φ.Π.Α.", value: "vat" },
    { label: "Τέλη", value: "fee" },
    { label: "Παρακρατούμενοι Φόροι", value: "withhold" },
    { label: "Χαρτόσημο", value: "stamp" },
    { label: "Λοιποί Φόροι", value: "otherTax" },
    { label: "Κρατήσεις", value: "deductions" }
]

const bridgeTypeBuys = [
    { label: "Προμηθευτής", value: "supplier" },
    { label: "Καθαρή Αξία", value: "netValue" },
    { label: "Φ.Π.Α.", value: "vat" },
    { label: "Φ.Π.Α. Εκπιπτόμενου", value: "vatDed"},
    { label: "Φ.Π.Α. Μη εκπιπτόμενου", value: "vatNonDed"},
    { label: "Τέλη", value: "fee" },
    { label: "Παρακρατούμενοι Φόροι", value: "withhold" },
    { label: "Χαρτόσημο", value: "stamp" },
    { label: "Λοιποί Φόροι", value: "otherTax" },
]

const bridgeTypeClientPayments = [
    { label: "Πελάτης", value: "client" },
    { label: "Τραπεζικός Λογαριασμός", value: "bankAccount" },
    { label: "POS", value: "pos" },
    { label: "Ταμειακή Μηχανή", value: "cashRegister" },
    { label: "Μετρητά", value: "cash" },
    { label: "IRIS", value: "iris" },
]

const bridgeTypeClientMovements = [
    { label: "Καθαρή Αξία", value: "netValue" },
    { label: "Πελάτης", value: "client" },
]

const bridgeTypeSupplierPayments = [
    { label: "Προμηθευτής", value: "supplier" },
    { label: "Τραπεζικός Λογαριασμός", value: "bankAccount" },
    { label: "POS", value: "pos" },
    { label: "Ταμειακή Μηχανή", value: "cashRegister" },
    { label: "Μετρητά", value: "cash" },
    { label: "IRIS", value: "iris" },
]

const bridgeTypeSupplierMovements = [
    { label: "Καθαρή Αξία", value: "netValue" },
    { label: "Προμηθευτής", value: "supplier" },
]

const bridgeProductMovements = [
    { label: "Εγκατάσταση", value: "installation" },
    { label: "Καθαρή Αξία", value: "netValue" },
    { label: "Φ.Π.Α.", value: "vat" },
]

const bridgeMapping = {
    "Sales" : bridgeTypeSales,
    "Client Payments" : bridgeTypeClientPayments,
    "Client Movements" : bridgeTypeClientMovements,
    "Buys" : bridgeTypeBuys,
    "Supplier Payments" : bridgeTypeSupplierPayments,
    "Supplier Movements" : bridgeTypeSupplierMovements,
    "Product Movements" : bridgeProductMovements,
}

export const getBridgeTypes = (section) => {
    if (bridgeMapping[section]) {
        return bridgeMapping[section];
    }
    return [];
}