import React, {useEffect, useRef, useState} from "react";
import {Accordion, Button, Col, Modal, Row} from "react-bootstrap";
import "./index.css";
import {useDispatch, useSelector} from "react-redux";
import Dropdown from "../../../_components/primer/Dropdown";
import {
    formEventToObjectConvertor,
} from "../../../_helpers/helperFunctions";
import DateBox from "../DateBox";
import EditableTable from "../Editable-Table";
import Input from "../../common/Input";
import Checkbox from "../../common/CheckBox";
import * as moment from "moment";
import {
    fetchAccountingAccountSearch, fetchAccountingRecordSearch,
    fetchAdvancedTypesSearch,
    fetchBankAccounts,
    fetchBasicTypesSearch,
    fetchBuysSearch, fetchCashRegisters,
    fetchClientMovementsSearch,
    fetchClientPaymentsSearch,
    fetchClientSearch, fetchEmployeeSearch, fetchPOS,
    fetchProductMovementsSearch,
    fetchProductPriceListSearch,
    fetchProductSearch,
    fetchSalesSearch,
    fetchSupplierMovementsSearch,
    fetchSupplierPaymentsSearch,
    fetchSupplierSearch, fetchWorkMovementsSearch, fetchWorkPermitsSearch,
    sendFilteredToMyDataApi
} from "../../../_apis/api";
import {toast} from "react-toastify";
import {
    checkPermission,
    clearEmptyValuesFromObject, getCurrentUser,
    getDefaultOptionByValue,
    getMultiSelectOptionsToArray, isEmpty,
} from "../../../_helpers/commonFunctions";
import {useTranslation} from "react-i18next";
import Pagination from "react-js-pagination";
import {setAccordionOpen, setActivePage, setFilters, setForceRefresh} from "../../../_reducers/SearchTableSlice";
import DateHelper from "../DateHelper/DateHelper";

const SearchTable = (props) => {
    const dispatch = useDispatch();

    const [t] = useTranslation('common');
    const permissions = localStorage.getItem("permissions");
    const permissionsData = JSON.parse(permissions);
    const currentUser = getCurrentUser();
    let tableButtons = {print: false, delete: true, export: false, edit: true}
    let currentMonth = moment().format("MM");
    const searchTableColumns = props.columns.length > 0 ? props.columns : [];
    let {filtersConfig, hideYearFilter} = props;
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);
    const SUPPLIERS_DATA = useSelector((state) => state.SUPPLIERS_DATA);
    const ACCOUNTING_DATA = useSelector((state) => state.ACCOUNTING_DATA);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const SALES = useSelector((state) => state.SALES);
    const BUYS = useSelector((state) => state.BUYS);
    const SEARCH_TABLE = useSelector((state) => state.SEARCH_TABLE);
    const PAYROLL = useSelector((state) => state.PAYROLL);

    const [accordionOpen, setSTAccordionOpen] = useState(isEmpty(SEARCH_TABLE?.accordionOpen[props.tableName]) || SEARCH_TABLE?.accordionOpen[props.tableName] === true);
    const [isActiveCheck, setIsActiveCheck] = useState('inActive');
    const [showInactive, setShowInactive] = useState(false);
    let defaultStartDate = moment().startOf("month").format("DD/MM/YYYY")
    if (SEARCH_TABLE.filters[props.tableName]?.date_from) {
        defaultStartDate = SEARCH_TABLE.filters[props.tableName].date_from;
    } else if (Number(moment().format("YYYY")) > Number(company.year)) {
        let newMoment = moment().subtract(Number(moment().format("YYYY")) - Number(company.year), "years");
        defaultStartDate = newMoment.utc().startOf("year").format("DD/MM/YYYY")
    }
    let defaultEndDate = moment().endOf("month").format("DD/MM/YYYY");
    if (SEARCH_TABLE.filters[props.tableName]?.date_to) {
        defaultEndDate = SEARCH_TABLE.filters[props.tableName].date_to;
    } else if (Number(moment().format("YYYY")) > Number(company.year)) {
        let newMoment = moment().subtract(Number(moment().format("YYYY")) - Number(company.year), "years");
        defaultEndDate = newMoment.utc().endOf("year").format("DD/MM/YYYY")
    }
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [tabCloseWarning, showTabCloseWarning] = useState(false);
    const [sendFilteredToMyDataResponse, setSendFilteredToMyDataResponse] = useState(false);
    const [showSendFilteredToMyDataResponse, setShowSendFilteredToMyDataResponse] = useState(false);
    const [loadingSendFiltered, setLoadingSendFiltered] = useState(false);
    const [requestDataPage, setRequestDataPage] = useState({
        company: company.id,
        year: company.year,
        isActive: 'active'
    });
    const [formData, setFormData] = useState({isActive: "inActive", ...SEARCH_TABLE.filters[props.tableName]});

    const formRef = useRef();
    const accordionRef = useRef();

    let searchTable = [];
    let totalItems = 0;
    let perPage = 0;
    if (props.tableName === "Search Products") {
        tableButtons = {
            print: false,
            delete: checkPermission("products-products/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = PRODUCTS_DATA.searchProducts;
        totalItems = PRODUCTS_DATA.totalItemsPS;
        perPage = PRODUCTS_DATA.perPagePS;
    } else if (props.tableName === "Employees Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("accounting-employees/search-delete", permissionsData) || checkPermission("payroll-employees/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = ACCOUNTING_DATA.searchEmployees;
        totalItems = ACCOUNTING_DATA.totalItemsES;
        perPage = ACCOUNTING_DATA.perPageES;
    } else if (props.tableName === "Accounting Account Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("accounting-accountingaccounts/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = ACCOUNTING_DATA.searchAccountingAccounts;
        totalItems = ACCOUNTING_DATA.totalItemsAAS;
        perPage = ACCOUNTING_DATA.perPageAAS;
    } else if (props.tableName === "Accounting Record Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("accounting-accountingrecords/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = ACCOUNTING_DATA.searchAccountingRecords;
        totalItems = ACCOUNTING_DATA.totalItemsAR;
        perPage = ACCOUNTING_DATA.perPageAR;
    } else if (props.tableName === "Search Product Price List") {
        tableButtons = {
            print: false,
            delete: checkPermission("products-pricelist/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = PRODUCTS_DATA.searchProductPriceList;
        totalItems = PRODUCTS_DATA.totalItemsPP;
        perPage = PRODUCTS_DATA.perPagePP;
    } else if (props.tableName === "Search Product Movements") {
        tableButtons = {
            print: false,
            delete: checkPermission("products-productsmovements/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = PRODUCTS_DATA.searchProductMovements;
        totalItems = PRODUCTS_DATA.totalItemsPM;
        perPage = PRODUCTS_DATA.perPagePM;
    } else if (props.tableName === "Client Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("clients-clients/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = CLIENTS_DATA.searchClients;
        totalItems = CLIENTS_DATA.totalItemsCS;
        perPage = CLIENTS_DATA.perPageCS;
    } else if (props.tableName === "Supplier Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("suppliers-suppliers/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = SUPPLIERS_DATA.searchSuppliers;
        totalItems = SUPPLIERS_DATA.totalItemsSSS;
        perPage = SUPPLIERS_DATA.perPageSSS;
    } else if (props.tableName === "Client Movements Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("clients-clientmovements/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = CLIENTS_DATA.searchClientMovements;
        totalItems = CLIENTS_DATA.totalItemsCMS;
        perPage = CLIENTS_DATA.perPageCMS;
    } else if (props.tableName === "Supplier Movements Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("suppliers-suppliermovements/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = SUPPLIERS_DATA.searchSupplierMovements;
        totalItems = SUPPLIERS_DATA.totalItemsSMSS
        perPage = SUPPLIERS_DATA.perPageSMSS
    } else if (props.tableName === "Basic Types Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("settings-documenttype/basictypessearch-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = SETTINGS.searchBasicTypes;
        totalItems = SETTINGS.totalItemsBTS;
        perPage = SETTINGS.perPageBTS;
        tableButtons = {
            print: false,
            delete: true,
            export: false,
            edit: false
        }
    } else if (props.tableName === "Advanced Types Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("settings-documenttype/advancedtypessearch-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = SETTINGS.searchAdvancedTypes;
        totalItems = SETTINGS.totalItemsATS;
        perPage = SETTINGS.perPageATS;
    } else if ((props.tableName === "Client Communication History")) {
        searchTable = CLIENTS_DATA.newClient.communicationHistory;
        tableButtons = {
            print: false,
            delete: true,
            export: false,
            edit: false,
        }
    } else if ((props.tableName === "Supplier Communication History")) {
        searchTable = SUPPLIERS_DATA.newSupplier.communicationHistory;
        tableButtons = {
            print: false,
            delete: true,
            export: false,
            edit: false
        }
    } else if (props.tableName === "Sales Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("sales-sales/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = SALES.searchSales;
        totalItems = SALES.totalItemsSS;
        perPage = SALES.perPageSS;
    } else if (props.tableName === "Buys Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("buys-buy/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = BUYS.searchBuys;
        totalItems = BUYS.totalItemsBS;
        perPage = BUYS.perPageBS;
    } else if (props.tableName === "Cash Registers Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("cash-cashregisters/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = CASH_DATA.searchCashRegisters;
    } else if (props.tableName === "POS Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("cash-pos/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = CASH_DATA.searchPOS;
    } else if (props.tableName === "Bank Account Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("cash-bankaccounts/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = CASH_DATA.searchBankAccounts;
    } else if (props.tableName === "Client Payments Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("sales-clientpayments/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = CLIENTS_DATA.searchClientPayments;
        totalItems = CLIENTS_DATA.totalItemsCPS
        perPage = CLIENTS_DATA.perPageCPS
    } else if (props.tableName === "Supplier Payments Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("buys-supplierpayments/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = SUPPLIERS_DATA.searchSupplierPayments;
        totalItems = SUPPLIERS_DATA.totalItemsSPS
        perPage = SUPPLIERS_DATA.perPageSPS
    } else if (props.tableName === "Work Movements Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("payroll-workmovements/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = PAYROLL.searchWorkMovements;
        totalItems = PAYROLL.totalItemsWM;
        perPage = PAYROLL.perPageWM;
    } else if (props.tableName === "Work Permits Search") {
        tableButtons = {
            print: false,
            delete: checkPermission("payroll-workpermits/search-delete", permissionsData),
            export: false,
            edit: false,
        }
        searchTable = PAYROLL.searchWorkPermits;
        totalItems = PAYROLL.totalItemsWP;
        perPage = PAYROLL.perPageWP;
    }

    const sendFilteredToMyData = () => {
        showTabCloseWarning(false);
        let type = "sale";
        if (props.tableName === "Buys Search") type = "buy";
        if (props.tableName === "Accounting Record Search") type = "accounting";

        if (type === "sale") {
            let requestData = {
                company: company.id,
                year: company.year,
                page: 1,
                documentType: formRef.current?.documentType.value, // array
                clientName: formRef.current?.clientName.value,
                productName: formRef.current?.productName.value,
                totalSum_from: formRef.current?.totalSum_from.value,
                totalSum_to: formRef.current?.totalSum_to.value,
                preNumber_from: formRef.current?.preNumber_from.value,
                preNumber_to: formRef.current?.preNumber_to.value,
                number_from: formRef.current?.number_from.value,
                number_to: formRef.current?.number_to.value,
                paymentWay: formRef.current?.paymentWay.value, // array
                myDataStatus: formRef.current?.myDataStatus.value, // array
                providerChannel: formRef.current?.providerChannel.value,
                paymentStatus: formRef.current?.paymentStatus.value,
                notes: formRef.current?.notes.value,
                date_from: startDate,
                date_to: endDate,
                documentCreator: formRef.current?.documentCreator.value,
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(sendFilteredToMyDataApi(requestData, setSendFilteredToMyDataResponse, setShowSendFilteredToMyDataResponse, setLoadingSendFiltered, "sale"));
        } else if (type === "buy") {
            let requestData = {
                company: company.id,
                year: company.year,
                page: 1,
                month: formRef.current?.filter_by?.value ? formRef.current?.filter_by?.value : currentMonth,
                documentType: formRef.current?.documentType.value, // array
                supplierName: formRef.current?.supplierName.value,
                totalSum_from: formRef.current?.totalSum_from.value,
                totalSum_to: formRef.current?.totalSum_to.value,
                preNumber_from: formRef.current?.preNumber_from.value,
                preNumber_to: formRef.current?.preNumber_to.value,
                number_from: formRef.current?.number_from.value,
                number_to: formRef.current?.number_to.value,
                paymentWay: formRef.current?.paymentWay.value, // array
                notes: formRef.current?.notes.value,
                date_from: startDate,
                date_to: endDate,
                //myDataStatus: formRef.current?.myDataStatus.value, // array
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(sendFilteredToMyDataApi(requestData, setSendFilteredToMyDataResponse, setShowSendFilteredToMyDataResponse, setLoadingSendFiltered, "buy"));
        } else if (type === "accounting") {
            let requestData = {
                company: company.id,
                year: company.year,
                page: 1,
                month: formRef.current?.filter_by?.value ? formRef.current?.filter_by?.value : currentMonth,
                documentType: formRef.current?.documentType.value, // array
                category: formRef.current?.category.value,
                subCategory: formRef.current?.subCategory.value,
                subAccount: formRef.current?.subAccount.value,
                totalSum_from: formRef.current?.totalSum_from.value,
                totalSum_to: formRef.current?.totalSum_to.value,
                preNumber_from: formRef.current?.preNumber_from.value,
                preNumber_to: formRef.current?.preNumber_to.value,
                number_from: formRef.current?.number_from.value,
                number_to: formRef.current?.number_to.value,
                date_from: startDate,
                date_to: endDate,
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(sendFilteredToMyDataApi(requestData, setSendFilteredToMyDataResponse, setShowSendFilteredToMyDataResponse, setLoadingSendFiltered, "accounting"));
        }
    }
    // Filter The Products Data
    const handleOnSubmit = (e, manageAccordion = true) => {
        if (accordionOpen && manageAccordion) {
            setTimeout(() => {
                accordionRef.current.firstChild.click();
            }, 200)
        }
        let filterObject = {...formData};
        if (e) {
            e.preventDefault();
            filterObject = formEventToObjectConvertor(e);
        }
        let page = SEARCH_TABLE.activePage[props.tableName] ? SEARCH_TABLE.activePage[props.tableName] : 1
        let requestData = {}
        if (e) {
            page = 1;
            if (SEARCH_TABLE.activePage[props.tableName] && SEARCH_TABLE.activePage[props.tableName] !== page) {
                dispatch(setActivePage({...SEARCH_TABLE.activePage, [props.tableName]: 1}));
            }
        }
        if (props.tableName === "Search Products") {
            requestData = {
                page: page,
                company: company.id,
                year: company.year,
                isActive: filterObject.isActive ? filterObject.isActive : '',
                productName: filterObject.productName ? filterObject.productName : '',
                productCode: filterObject.productCode ? filterObject.productCode : '',
                productCategory: filterObject.productCategory ? filterObject.productCategory : '',
                alternativeName: filterObject.alternativeName ? filterObject.alternativeName : '',
                alternativeCode: filterObject.alternativeCode ? filterObject.alternativeCode : '',
                supplierProductName: filterObject.supplierProductName ? filterObject.supplierProductName : '',
                supplierProductCode: filterObject.supplierProductCode ? filterObject.supplierProductCode : '',
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchProductSearch(requestData));
        } else if (props.tableName === "Search Product Price List") {
            requestData = {
                page: page,
                company: company.id,
                isActive: filterObject.isActive ? filterObject.isActive : '',
                name: filterObject.name ? filterObject.name : '',
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchProductPriceListSearch(requestData));
        } else if (props.tableName === "Search Product Movements") {
            let requestData = {
                page: page,
                company: company.id,
                year: company.year,
                isActive: 'active',
                documentType: formData.documentType ? formData.documentType : '',
                date_from: startDate,
                date_to: endDate,
                numberFrom: formData.numberFrom ?? '',
                numberTo: formData.numberTo ?? '',
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchProductMovementsSearch(requestData));
        } else if (props.tableName === "Client Search") {
            requestData = {
                page: page,
                company: company.id,
                contactName: filterObject.contactName ? filterObject.contactName : '',
                category: filterObject.category ? filterObject.category : '',
                isActive: filterObject.isActive ? filterObject.isActive : '',
                clientName: filterObject.clientName ? filterObject.clientName : '',
                vatNumber: filterObject.vatNumber ? filterObject.vatNumber : '',
                phone: filterObject.phone ? filterObject.phone : '',
                email: filterObject.email ? filterObject.email : '',
                systemCode: filterObject.systemCode ? filterObject.systemCode : '',
                manualCode: filterObject.manualCode ? filterObject.manualCode : '',
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchClientSearch(requestData));
        } else if (props.tableName === "Employees Search") {
            requestData = {
                page: page,
                company: company.id,
                firstName: filterObject.firstName ? filterObject.firstName : '',
                lastName: filterObject.lastName ? filterObject.lastName : '',
                installation: filterObject.installation ? filterObject.installation : '',
                vatNumber: filterObject.vatNumber ? filterObject.vatNumber : '',
                employmentDate: filterObject.employmentDate ? filterObject.employmentDate : '',
                employeeType: filterObject.employeeType ? filterObject.employeeType : '',
                isActive: filterObject.isActive ? filterObject.isActive : '',
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchEmployeeSearch(requestData));
        } else if (props.tableName === "Accounting Account Search") {
            requestData = {
                page: page,
                company: company.id,
                name: filterObject.name ? filterObject.name : '',
                code: filterObject.code ? filterObject.code : '',
                alternativeName: filterObject.alternativeName ? filterObject.alternativeName : '',
                category: formData.category ? formData.category : '',
                subAccount: formData.subAccount ? formData.subAccount : '',
                isActive: filterObject.isActive ? filterObject.isActive : '',
                movable: filterObject.movable ? filterObject.movable : '',
                transferable: filterObject.transferable ? filterObject.transferable : '',
                logisticOptions: filterObject.logisticOptions ? filterObject.logisticOptions : '',
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchAccountingAccountSearch(requestData));
        } else if (props.tableName === "Supplier Search") {
            requestData = {
                company: company.id,
                year: company.year,
                page: page,
                category: filterObject.category ? filterObject.category : '',
                isActive: filterObject.isActive ? filterObject.isActive : '',
                name: filterObject.name ? filterObject.name : '',
                systemCode: filterObject.systemCode ? filterObject.systemCode : '',
                manualCode: filterObject.manualCode ? filterObject.manualCode : '',
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchSupplierSearch(requestData));
        } else if (props.tableName === "Client Movements Search") {
            requestData = {
                company: company.id,
                year: company.year,
                page: page,
                name: filterObject.name ? filterObject.name : '',
                documentType: formData.documentType ? formData.documentType : '',
                sum_from: filterObject.sum_from ? filterObject.sum_from : '',
                sum_to: filterObject.sum_to ? filterObject.sum_to : '',
                date_from: startDate,
                date_to: endDate,
                numberFrom: formData.numberFrom ? formData.numberFrom : '',
                numberTo: formData.numberTo ? formData.numberTo : '',
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchClientMovementsSearch(requestData));
        } else if (props.tableName === "Supplier Movements Search") {
            requestData = {
                company: company.id,
                year: company.year,
                page: page,
                name: filterObject.name ? filterObject.name : '',
                documentType: formData.documentType ? formData.documentType : '',
                sum_from: filterObject.sum_from ? filterObject.sum_from : '',
                sum_to: filterObject.sum_to ? filterObject.sum_to : '',
                date_from: startDate,
                date_to: endDate,
                numberFrom: formData.numberFrom ? formData.numberFrom : '',
                numberTo: formData.numberTo ? formData.numberTo : '',
            }
            requestData = clearEmptyValuesFromObject(requestData); // clear empty values before sending to the api.
            dispatch(fetchSupplierMovementsSearch(requestData));
        } else if (props.tableName === "Basic Types Search") {
            requestData = {
                page: page,
                company: company.id,
                name: filterObject.name ? filterObject.name : '',
                affects_inventory: filterObject.affects_inventory ? filterObject.affects_inventory : '',
                affects_client: filterObject.affects_client ? filterObject.affects_client : '',
                affects_supplier: filterObject.affects_supplier ? filterObject.affects_supplier : '',
                isActive: "active",
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchBasicTypesSearch(requestData));
        } else if (props.tableName === "Advanced Types Search") {
            requestData = {
                page: page,
                company: company.id,
                year: company.year,
                name: filterObject.name ? filterObject.name : '',
                section: filterObject.section ? filterObject.section : '',
                documentType: filterObject.basicDocumentType ? filterObject : '',
                isActive: filterObject.isActive ? filterObject.isActive : 'inActive',
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchAdvancedTypesSearch(requestData));
        } else if (props.tableName === "Cash Registers Search") {
            requestData = {
                company: company.id,
                name: filterObject.name ? filterObject.name : '',
                serialNumber: filterObject.serialNumber ? filterObject.serialNumber : '',
                showInactive: filterObject.showInactive ? filterObject.showInactive : false
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchCashRegisters(requestData));
        } else if (props.tableName === "POS Search") {
            requestData = {
                company: company.id,
                name: filterObject.name ? filterObject.name : '',
                serialNumber: filterObject.serialNumber ? filterObject.serialNumber : '',
                showInactive: filterObject.showInactive ? filterObject.showInactive : false
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchPOS(requestData));
        } else if (props.tableName === "Bank Account Search") {
            requestData = {
                company: company.id,
                name: filterObject.name ? filterObject.name : '',
                iban: filterObject.iban ? filterObject.iban : '',
                bankName: filterObject.bankName ? filterObject.bankName : '',
                showInactive: filterObject.showInactive ? filterObject.showInactive : false
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchBankAccounts(requestData));
        } else if (props.tableName === "Sales Search") {
            requestData = {
                company: company.id,
                year: company.year,
                page: page,
                documentType: filterObject?.documentType, // array
                clientName: filterObject?.clientName,
                productName: filterObject?.productName,
                totalSum_from: filterObject?.totalSum_from,
                totalSum_to: filterObject?.totalSum_to,
                preNumber_from: filterObject?.preNumber_from,
                preNumber_to: filterObject?.preNumber_to,
                number_from: filterObject?.number_from,
                number_to: filterObject?.number_to,
                paymentWay: filterObject?.paymentWay, // array
                myDataStatus: filterObject?.myDataStatus, // array
                providerChannel: filterObject?.providerChannel,
                paymentStatus: filterObject?.paymentStatus,
                notes: filterObject?.notes,
                date_from: startDate,
                date_to: endDate,
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchSalesSearch(requestData));
        } else if (props.tableName === "Accounting Record Search") {
            requestData = {
                company: company.id,
                year: company.year,
                page: page,
                documentType: filterObject?.documentType, // array
                category: filterObject?.category,
                subCategory: filterObject?.subCategory,
                subAccount: filterObject?.subAccount,
                totalSum_from: filterObject?.totalSum_from,
                totalSum_to: filterObject?.totalSum_to,
                preNumber_from: filterObject?.preNumber_from,
                preNumber_to: filterObject?.preNumber_to,
                number_from: filterObject?.number_from,
                number_to: filterObject?.number_to,
                date_from: startDate,
                date_to: endDate,
                documentCreator: filterObject?.documentCreator,
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchAccountingRecordSearch(requestData));
        } else if (props.tableName === "Buys Search") {
            requestData = {
                company: company.id,
                year: company.year,
                page: page,
                documentType: filterObject?.documentType, // array
                supplierName: filterObject?.supplierName,
                totalSum_from: filterObject?.totalSum_from,
                totalSum_to: filterObject?.totalSum_to,
                preNumber_from: filterObject?.preNumber_from,
                preNumber_to: filterObject?.preNumber_to,
                number_from: filterObject?.number_from,
                number_to: filterObject?.number_to,
                paymentWay: filterObject?.paymentWay, // array
                myDataStatus: filterObject?.myDataStatus, // array
                notes: filterObject?.notes,
                date_from: startDate,
                date_to: endDate,
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchBuysSearch(requestData));
        } else if (props.tableName === "Client Payments Search") {
            requestData = {
                company: company.id,
                year: company.year,
                page: page,
                clientName: filterObject?.clientName,
                documentType: formData?.documentType,
                paymentWay: formData?.paymentTypes,
                amount_from: filterObject?.amount_from,
                amount_to: filterObject?.amount_to,
                date_from: startDate,
                date_to: endDate,
                numberFrom: formData?.numberFrom,
                numberTo: formData?.numberTo
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchClientPaymentsSearch(requestData));
        } else if (props.tableName === "Supplier Payments Search") {
            requestData = {
                company: company.id,
                year: company.year,
                page: page,
                date_from: filterObject.date_from,
                date_to: filterObject.date_to,
                supplierName: filterObject?.supplierName,
                documentType: filterObject?.documentType,
                paymentWay: filterObject?.paymentTypes,
                amount_from: filterObject?.amount_from,
                amount_to: filterObject?.amount_to,
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchSupplierPaymentsSearch(requestData));
        } else if (props.tableName === "Work Movements Search") {
            requestData = {
                company: company.id,
                page: page,
                date_from: filterObject.date_from,
                date_to: filterObject.date_to,
                identifier: filterObject.identifier,
                //installationMasterId: filterObject.installationMasterId,
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchWorkMovementsSearch(requestData));
        } else if (props.tableName === "Work Permits Search") {
            requestData = {
                company: company.id,
                page: page,
                date_from: filterObject.date_from,
                date_to: filterObject.date_to,
                installationMasterId: filterObject.installationMasterId,
                erganiProtocol: filterObject.erganiProtocol,
            }
            requestData = clearEmptyValuesFromObject(requestData);
            dispatch(fetchWorkPermitsSearch(requestData));
        }
        if (e) toast.info('Loading search result...');
        setRequestDataPage(requestData);
        setFormData(filterObject); // set form data on submit

        const filters = {...requestData};
        dispatch(setFilters({...SEARCH_TABLE.filters, [props.tableName]: filters}));
    }

    const handleOnChange = (e, field) => {
        // it will update the value of isActive check box
        if (field === 'isActive') {
            const isactive = (isActiveCheck === 'inActive') ? 'active' : 'inActive';
            setIsActiveCheck(isactive);
        } else if (field === 'showInactive') {
            setShowInactive(e.target.checked);
        }
    }

    const handleOnFilterChange = (e, type = "default", ddname = '') => {
        let name;
        let value;
        if (type === 'msdd') {
            name = ddname;
            value = getMultiSelectOptionsToArray(e);
        } else if (type === 'dd') {
            name = ddname;
            value = e.value;
        } else if (type === 'dp') {
            value = e;
            name = ddname;
        } else {
            name = e.target.name;
            value = e.target.value;
        }
        setFormData({...formData, [name]: value});
    }

    const [firstLoad, setFirstLoad] = useState(true);
    const handlePageChange = (page) => {
        dispatch(setActivePage({...SEARCH_TABLE.activePage, [props.tableName]: page}));
    }
    const handleAfterDelete = () => {
        handleOnSubmit(null, false);
    }
    useEffect(() => {
        let load = false;
        if (firstLoad) {
            setFirstLoad(false);
            if (SEARCH_TABLE.forceRefresh[props.tableName] === true) {
                dispatch(setForceRefresh({...SEARCH_TABLE.forceRefresh, [props.tableName]: false}));
                load = true;
            }
        } else {
            load = true;
        }
        if (load) handleOnSubmit(null, false);
    }, [SEARCH_TABLE.activePage])

    // table name -> permission prefix for the apply filter button
    const permissionsMappingSearch = {
        "Search Products": "products-products/search",
        "Search Product Movements": "products-productsmovements/search",
        "Search Product Price List": "products-pricelist/search",
        "Sales Search": "sales-sales/search",
        "Client Payments Search": "sales-clientpayments/search",
        "Buys Search": "buys-buy/search",
        "Supplier Payments Search": "buys-supplierpayments/search",
        "Client Search": "clients-clients/search",
        "Client Movements Search": "clients-clientmovements/search",
        "Supplier Search": "suppliers-suppliers/search",
        "Supplier Movements Search": "suppliers-suppliermovements/search",
        "Cash Registers Search": "cash-cashregisters/search",
        "POS Search": "cash-pos/search",
        "Bank Account Search": "cash-bankaccounts/search",
        "Basic Types Search": "settings-documenttype/basictypessearch",
        "Advanced Types Search": "settings-documenttype/advancedtypessearch",
        "Accounting Account Search": "accounting-accountingaccounts/search",
        "Accounting Record Search": "accounting-accountingrecords/search",
        "Work Movements Search": "payroll-workmovements/search",
        "Work Permits Search": "payroll-workpermits/search",

        "Employees Search": ["accounting-employees/search", "payroll-employees/search"],
    }

    const [incrKey, setIncrKey] = useState(0);
    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [searchTable])

    const constructMultiselectFromSelectedValues = (selected, options) => {
        let arr = [];
        if (selected && selected?.length > 0) {
            for (let opt of selected) {
                let filt = options.filter((el) => el.value === opt);
                if (filt.length > 0) {
                    arr.push({value: opt, label: filt[0].label});
                }
            }
        }
        return arr;
    }

    const handleAccordionClick = (e) => {
        setSTAccordionOpen(e.target.classList.value.includes("collapsed"));
        dispatch(setAccordionOpen({
            ...SEARCH_TABLE.accordionOpen,
            [props.tableName]: e.target.classList.value.includes("collapsed")
        }))
    }

    const handleDateFrom = (comingFrom, data) => {
        if (comingFrom === "dateHelper") {
            setStartDate(data); // DD/MM/YYYY format
        } else if (comingFrom === "dateBox") {
            setStartDate(moment(data).format("DD/MM/YYYY"));
        }
    }
    const handleDateTo = (comingFrom, data) => {
        if (comingFrom === "dateHelper") {
            setEndDate(data); // DD/MM/YYYY format
        } else if (comingFrom === "dateBox") {
            setEndDate(moment(data).format("DD/MM/YYYY"));
        }
    }

    const hasReadPermission = () => {
        const perm = permissionsMappingSearch[props.tableName];
        if (perm) {
            if (typeof perm === "string") {
                return checkPermission(perm + "-read", permissionsData);
            } else if (typeof perm === "object") {
                return perm.some((el) => checkPermission(el + "-read", permissionsData));
            }
        } else {
            return true;
        }
    }

    return (
        <div className="search-table">
            <form ref={formRef} onSubmit={(e) => handleOnSubmit(e)} autoComplete='off'>
                <Accordion defaultActiveKey={accordionOpen === true ? "0" : ""} className={"mb-2"}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header ref={accordionRef}
                                          onClick={(e) => handleAccordionClick(e)}>{t('SearchTableIndex.filters')}</Accordion.Header>
                        <Accordion.Body>
                            {!hideYearFilter && (
                                <Row>
                                    <Col md={3}>
                                        <DateHelper
                                            dateFromFunction={(e) => handleDateFrom("dateHelper", e)}
                                            dateToFunction={(e) => handleDateTo("dateHelper", e)}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <DateBox
                                            name="date_from"
                                            selected={moment(startDate, "DD/MM/YYYY").isValid() ? moment(startDate, "DD/MM/YYYY").toDate() : ""}
                                            label={t('SearchTableIndex.dateFrom')}
                                            onChange={(e) => handleDateFrom("dateBox", e)}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <DateBox
                                            name="date_to"
                                            selected={moment(endDate, "DD/MM/YYYY").isValid() ? moment(endDate, "DD/MM/YYYY").toDate() : ""}
                                            label={t('SearchTableIndex.dateTo')}
                                            onChange={(e) => handleDateTo("dateBox", e)}
                                        />
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                {filtersConfig && filtersConfig.filters.map((filter, i) => (
                                    <div key={i} className="col-12 col-sm-6 col-md-3">
                                        {/*<div key={i} className={filter?.type !== 'number' ? "col-12 col-sm-6 col-md-3" : "col-12 col-sm-3 col-md-1"}>*/}
                                        <div className="search-box search-fields mb-2 b-inline-block">
                                            {(filter?.type === 'input' || filter?.type === 'text') &&
                                                <Input
                                                    type="text"
                                                    label={filter.label}
                                                    placeholder={`${filter.label}`}
                                                    name={filter.name}
                                                    defaultValue={formData[filter.name]}
                                                    autoComplete="off"
                                                    onBlur={(e) => handleOnFilterChange(e)}
                                                />
                                            }
                                            {filter?.type === 'number' &&
                                                <Input
                                                    type="number"
                                                    label={filter.label}
                                                    placeholder={`${filter.label}`}
                                                    name={filter.name}
                                                    defaultValue={formData[filter.name]}
                                                    onChange={(e) => handleOnFilterChange(e)}
                                                />
                                            }
                                            {filter?.type === 'date' &&
                                                <DateBox
                                                    label={filter.label}
                                                    placeholder={`${filter.label}`}
                                                    name={filter.name}
                                                    selected={formData[filter.name] ? formData[filter.name] : ""}
                                                    onChange={(e) => handleOnFilterChange(e, 'dp', filter.name)}
                                                />
                                            }
                                            {filter?.type === 'checkbox' &&
                                                <Checkbox
                                                    text={filter.label}
                                                    className="mb-0 mr-2 mt-3 centered-checkbox"
                                                    name={filter.name}
                                                    value={isActiveCheck}
                                                    onChange={(e) => handleOnChange(e, filter.name)}
                                                />
                                            }
                                            {filter?.type === 'showInactiveCheckbox' &&
                                                <Checkbox
                                                    text={filter.label}
                                                    className="mb-0 mr-2 mt-3 centered-checkbox"
                                                    name={filter.name}
                                                    value={showInactive}
                                                    onChange={(e) => handleOnChange(e, filter.name)}
                                                />
                                            }
                                            {filter?.type === 'dropdown' &&
                                                <Dropdown
                                                    label={filter.label}
                                                    options={filter.options}
                                                    placeholder={`${filter.label}`}
                                                    name={filter.name}
                                                    value={formData[filter.name] ? getDefaultOptionByValue(filter.options, formData[filter.name], 'value', 'label', 'value') : ""}
                                                    enabledValue={true}
                                                    onChange={(e) => handleOnFilterChange(e, 'dd', filter.name)}
                                                />
                                            }
                                            {filter?.type === 'multiselect' &&
                                                <Dropdown
                                                    multiSelect={true}
                                                    label={filter.label}
                                                    options={filter.options}
                                                    placeholder={`${filter.label}`}
                                                    defaultValue={constructMultiselectFromSelectedValues(formData[filter.name], filter.options)}
                                                    name={filter.name}
                                                    onChange={(e) => handleOnFilterChange(e, 'msdd', filter.name)}
                                                />
                                            }
                                        </div>
                                    </div>
                                ))}
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Row>
                    {(filtersConfig || !hideYearFilter) &&
                        <div className="col-12 ml-auto">
                            {hasReadPermission() &&
                                <Button type="submit"
                                        className="btn btn-primary mb-2 ml-2 float-right">{t('SearchTableIndex.applyFilter')}</Button>
                            }
                            {((props?.tableName === "Sales Search" || props?.tableName === "Buys Search" || props?.tableName === "Accounting Record Search") && checkPermission("sales-sales/search-write", permissionsData)) &&
                                <Button disabled={loadingSendFiltered} onClick={() => showTabCloseWarning(true)}
                                        className="float-right">
                                    {loadingSendFiltered && (
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>)}
                                    {t('SearchTableIndex.sendFilteredToMyData')}
                                </Button>
                            }
                        </div>
                    }
                </Row>
            </form>
            <Row>
                <Col className="d-flex justify-content-end">
                    {props.allowPagination && searchTable?.length > 0 && (
                        <Pagination
                            activePage={SEARCH_TABLE.activePage[props.tableName] ? SEARCH_TABLE.activePage[props.tableName] : 1}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalItems}
                            pageRangeDisplayed={5}
                            onChange={(e) => handlePageChange(e)}
                            itemClass="page-item"
                            disabledClass="disabled"
                            activeClass="active"
                            linkClass="page-link"
                            prevPageText={currentUser?.lang && currentUser.lang === "el" ? "Προηγούμενη" : "Previous"}
                            nextPageText={currentUser?.lang && currentUser.lang === "el" ? "Επόμενη" : "Next"}
                            firstPageText={currentUser?.lang && currentUser.lang === "el" ? "Πρώτη" : "First"}
                            lastPageText={currentUser?.lang && currentUser.lang === "el" ? "Τελευταία" : "Last"}
                        />
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <EditableTable
                        filterRowConfig={props.filterRowConfig}
                        allowFilterTable={true}
                        editRedirectAllowed={true}
                        tableName={props.tableName}
                        key={props.tableName + String(incrKey)}
                        actionButtons={tableButtons}
                        allowInsertRow={props.allowInsertRow ? props.allowInsertRow : false}
                        allowFilter={true}
                        allowPagination={false} // No need, we have new built in search table
                        allowBulkDelete={false}
                        sendSelectedToMyData={props.sendSelectedToMyData ? props.sendSelectedToMyData : false}
                        cancelSelectedToMyData={props.cancelSelectedToMyData ? props.cancelSelectedToMyData : false}
                        allowBulkActions={true}
                        allowPrintResults={props.allowPrintResults ? props.allowPrintResults : false}
                        allowSorting={true}
                        allowSelectionCheckbox={props.allowSelectionCheckbox !== false}
                        allowActions={true}
                        columns={searchTableColumns}
                        data={searchTable}
                        totalItems={totalItems}
                        perPage={perPage}
                        requestData={requestDataPage}
                        formData={formData}
                        allowToggleColumns={props.allowToggleColumns}
                        toggleColumns={props.toggleColumns}
                        enableNewRowCheckbox={props.enableNewRowCheckbox ? props.enableNewRowCheckbox : false}

                        handleAfterDelete={() => handleAfterDelete()}
                    />
                </Col>
            </Row>
            <Modal className="themeModal" show={tabCloseWarning}>
                <Modal.Header>
                    <Modal.Title>{t('SearchTableIndex.warning')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('SearchTableIndex.message')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => showTabCloseWarning(false)}>{t('SearchTableIndex.no')}</Button>
                    <Button variant="primary" onClick={sendFilteredToMyData}>{t('SearchTableIndex.yes')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal className="themeModalBig" show={showSendFilteredToMyDataResponse}>
                <Modal.Header>
                    <Modal.Title>{t('SearchTableIndex.sendToMyDataReport')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('SearchTableIndex.message2')}<br/> {sendFilteredToMyDataResponse}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowSendFilteredToMyDataResponse(false)}>OK</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SearchTable;
