import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Input from "../../../../common/Input";
import Button from "react-bootstrap/Button";
import {getCurrentUser, isEmpty} from "../../../../../_helpers/commonFunctions";
import {Badge} from "react-bootstrap";

const ContextMenu = ({hasCopiedFile, show, posX, posY, type, selection, locked, onNewFolder, onFileDelete, onFolderDelete, onFileRename,
                         onFolderRename, setSomeModalOpen, onFileDownload, onFileCopy, onFilePaste, onFileHistory, onEmronDelete, onEmronUnlock, specialCode}) => {
    const [newFolderModal, setNewFolderModal] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");

    const [showRenameFile, setShowRenameFile] = useState(false);
    const [renameFileName, setRenameFileName] = useState("");
    const [renameFileExtension, setRenameFileExtension] = useState("");

    const [showRenameFolder, setShowRenameFolder] = useState(false);
    const [renameFolderName, setRenameFolderName] = useState("");

    const [showDeleteFile, setShowDeleteFile] = useState(false);

    const [showDeleteFolder, setShowDeleteFolder] = useState(false);

    const [showEmronUnlock, setShowEmronUnlock] = useState(false);
    const [emronUnlockDays, setEmronUnlockDays] = useState("2");

    const contextMenuStyle = {
        display: show ? "" : "none",
        opacity: show ? 1 : 0,
        position: "absolute",
        left: `${posX}px`,
        top: `${posY}px`,
        zIndex: "1000",
        border: "1px solid lightgray",
        backgroundColor: "white",
        width: "120px",
        fontSize: "12px",
        userSelect: "none",
    }

    useEffect(() => {
        setSomeModalOpen(showRenameFolder || showRenameFile || newFolderModal || showDeleteFile || showDeleteFolder || showEmronUnlock);
    }, [showRenameFolder, showRenameFile, newFolderModal, showDeleteFile, showDeleteFolder, showEmronUnlock])

    useEffect(() => {
        if (showRenameFile) {
            const fExt = getFileNameAndExtension(selection);
            setRenameFileName(fExt.name);
            setRenameFileExtension(fExt.extension);
        }
    }, [showRenameFile])

    useEffect(() => {
        if (showRenameFolder) setRenameFolderName(selection);
    }, [showRenameFolder])

    const getFileNameAndExtension = (fileName) => {
        const lastDotIndex = fileName.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return { name: fileName, extension: null }; // No extension found
        }
        const name = fileName.substring(0, lastDotIndex);
        const extension = fileName.substring(lastDotIndex + 1);

        return { name, extension }
    }

    const handleFileDelete = () => {
        if (specialCode === "1") {
            onEmronDelete(selection);
            setShowDeleteFile(false);
        } else {
            onFileDelete(selection);
            setShowDeleteFile(false);
        }
    }

    const handleFolderDelete = () => {
        onFolderDelete(selection);
        setShowDeleteFolder(false);
    }

    const handleFileRename = () => {
        if (!isEmpty(renameFileName)) {
            if (onFileRename(selection, renameFileName + "." + renameFileExtension)) {
                setRenameFileName("");
                setShowRenameFile(false);
            }
        }
    }

    const handleFolderRename = () => {
        if (!isEmpty(renameFolderName)) {
            if (onFolderRename(selection, renameFolderName)) {
                setRenameFolderName("");
                setShowRenameFolder(false);
            }
        }
    }

    const handleNewFolder = () => {
        if (!isEmpty(newFolderName)) {
            if (onNewFolder(newFolderName)) {
                setNewFolderName("");
                setNewFolderModal(false);
            }
        }
    }

    const invalidRenameFileName = () => {
        return selection === renameFileName;
    }

    const invalidRenameFolderName = () => {
        return selection === renameFolderName;
    }

    const handleFileDownload = () => {
        onFileDownload(null, selection, true, true);
    }

    const handleFileCopy = (isCut = false) => {
        onFileCopy(selection, isCut);
    }

    const handleFilePaste = () => {
        onFilePaste();
    }

    const handleEmronUnlock = () => {
        onEmronUnlock(selection, emronUnlockDays);
        setShowEmronUnlock(false);
    }

    const canChangeEmronUnlockDays = getCurrentUser()?.userType === "Admin"

    return (
        <React.Fragment>
            <div style={contextMenuStyle} className={"contextMenu"}>
                {type === "file" && (
                    <React.Fragment>
                        <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"}
                             onClick={(e) => {
                                 e.stopPropagation();
                                 e.preventDefault();
                                 setShowRenameFile(true);
                             }}>Μετανομασία
                        </div>
                        <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"}
                             onClick={() => setShowDeleteFile(true)}>Διαγραφή
                        </div>
                        <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"}
                             onClick={() => handleFileDownload()}>Λήψη
                        </div>
                        {specialCode !== "1" && (
                            <React.Fragment>
                                <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"} onClick={() => handleFileCopy(false)}>Αντιγραφή</div>
                                <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"} onClick={() => handleFileCopy(true)}>Αποκοπή</div>
                            </React.Fragment>
                        )}
                        <div className={"contextMenuOption"} onClick={() => onFileHistory(selection, false)}>Ιστορικό</div>
                    </React.Fragment>
                )}
                {type === "folder" && (
                    <React.Fragment>
                        {specialCode === "1" ? (
                            <React.Fragment>
                                {locked && (
                                    <div className={"contextMenuOption"}
                                         onClick={() => setShowEmronUnlock(true)}>Ξεκλείδωμα φακέλου</div>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"}
                                     onClick={(e) => {
                                         e.stopPropagation();
                                         e.preventDefault();
                                         setShowRenameFolder(true);
                                     }}>Μετανομασία
                                </div>
                                <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"}
                                     onClick={() => setShowDeleteFolder(true)}>Διαγραφή
                                </div>
                                <div className={"contextMenuOption"}
                                     onClick={() => onFileHistory(selection, true)}>Ιστορικό
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                {type === "parent" && (
                    <React.Fragment>
                        <div className={`contextMenuOption ${specialCode === "1" ? "locked" : ""}`} onClick={() => {
                            if (specialCode !== "1") setNewFolderModal(true)
                        }}>Δημιουργία φακέλου
                        </div>
                        {hasCopiedFile && (
                            <div className={"contextMenuOption"} onClick={() => handleFilePaste()}>Επικόλληση</div>
                        )}
                    </React.Fragment>
                )}
            </div>

            <Modal show={newFolderModal} onHide={() => setNewFolderModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Δημιουργία φακέλου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Input
                        name={"folderName"}
                        label={"Όνομα Φακέλου"}
                        value={newFolderName}
                        autoComplete={"off"}
                        onChange={(e) => setNewFolderName(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setNewFolderModal(false)}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => handleNewFolder()}>
                        Δημιουργία
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRenameFile} onHide={() => setShowRenameFile(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Μετανομασία αρχείου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        name={"oldFileName"}
                        label={"Παλαιό όνομα αρχείου"}
                        value={selection}
                        disabled={true}
                        className={"mb-1"}
                    />
                    <Input
                        name={"renameFileName"}
                        label={"Νέο όνομα αρχείου"}
                        value={renameFileName}
                        autoComplete={"off"}
                        onChange={(e) => setRenameFileName(e.target.value)}
                    />
                    <Badge bg={"dark"} style={{position: "absolute", right: "23px", bottom: "21px"}}>{"." + renameFileExtension}</Badge>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleFileRename()} disabled={invalidRenameFileName()}>
                        Μετανομασία
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRenameFolder} onHide={() => setShowRenameFolder(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Μετανομασία φακέλου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        name={"oldFileName"}
                        label={"Παλαιό όνομα αρχείου"}
                        value={selection}
                        disabled={true}
                        className={"mb-1"}
                    />
                    <Input
                        name={"renameFolderName"}
                        label={"Νέο όνομα φακέλου"}
                        value={renameFolderName}
                        autoComplete={"off"}
                        onChange={(e) => setRenameFolderName(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleFolderRename()} disabled={invalidRenameFolderName()}>
                        Μετανομασία
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteFile} onHide={() => setShowDeleteFile(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Διαγραφή αρχείου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να διαγράψετε το αρχείο <strong>{selection}</strong>;
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleFileDelete()}>
                        Ναι, διαγραφή
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteFolder} onHide={() => setShowDeleteFolder(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Διαγραφή φακέλου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να διαγράψετε τον φάκελο <strong>{selection}</strong>;
                    <br/>Θα διαγραφούν ΌΛΑ τα αρχεία/φάκελοι που περιέχει.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleFolderDelete()}>
                        Ναι, διαγραφή
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEmronUnlock} onHide={() => setShowEmronUnlock(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Ξεκλείδωμα φακέλου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        name={"emronUnlockDays"}
                        type={"number"}
                        label={"Ξεκλείδωμα για (μέρες)"}
                        disabled={!canChangeEmronUnlockDays}
                        value={emronUnlockDays}
                        onChange={(e) => {
                            if (canChangeEmronUnlockDays) setEmronUnlockDays(e.target.value)
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleEmronUnlock()}>
                        Ξεκλείδωμα
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ContextMenu;
