export const fuelCodes = [
    { value: "10", label: "Benzine 95RON" },
    { value: "11", label: "Benzine 95RON+" },
    { value: "12", label: "Benzine 100RON" },
    { value: "13", label: "Benzine LRP" },
    { value: "14", label: "Βενζίνη αεροπλάνων" },
    { value: "15", label: "Ειδικό καύσιμο αεριωθουμένων" },
    { value: "20", label: "Diesel" },
    { value: "21", label: "Diesel premium" },
    { value: "30", label: "Diesel Heatnn" },
    { value: "31", label: "Diesel Heat premium" },
    { value: "32", label: "Diesel Light" },
    { value: "33", label: "Diesel άλλων χρήσεων" },
    { value: "34", label: "Diesel ναυτιλίας" },
    { value: "35", label: "Κηροζίνη JP1" },
    { value: "36", label: "Κηροζίνη άλλων χρήσεων" },
    { value: "37", label: "Μαζούτ" },
    { value: "38", label: "Μαζούτ ναυτιλίας" },
    { value: "40", label: "LPG (υγραέριο)" },
    { value: "41", label: "Υγραέριο (LPG) και μεθάνιο βιομηχανικό /εμπορικό κινητήρων (χύδην)" },
    { value: "42", label: "Υγραέριο (LPG) και μεθάνιο θέρμανσης και λοιπών χρήσεων (χύδην)" },
    { value: "43", label: "Υγραέριο (LPG) και μεθάνιο βιομηχανικό /εμπορικό κινητήρων (σε φιάλες)" },
    { value: "44", label: "Υγραέριο (LPG) και μεθάνιο θέρμανσης και λοιπών χρήσεων (σε φιάλες)" },
    { value: "50", label: "CNG (πεπιεσμένο φυσικό αέριο)" },
    { value: "60", label: "Αρωματικοί Υδρογονάνθρακες Δασμολογικής Κλάσης 2707" },
    { value: "61", label: "Κυκλικοί Υδρογονάνθρακες Δασμολογικής Κλάσης 2902" },
    { value: "70", label: "Ελαφρύ πετρέλαιο (WHITE SPIRIT)" },
    { value: "71", label: "Ελαφριά λάδια" },
    { value: "72", label: "Βιοντίζελ" },
    { value: "999", label: "Λοιπές χρεώσεις υπηρεσιών" }
];
