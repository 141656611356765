import React, {useEffect, useState} from 'react';
import {classicStyleBelowNavbar} from "../Statics";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Col, Row, Spinner} from "react-bootstrap";
import {
    setErganiSubmissionsReportRequestData,
    setErganiSubmissionsReportResponseData
} from "../../../../_reducers/DataPanel/Payroll/payrollSlice";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import DateBox from "../../../primer/DateBox";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import Dropdown from "../../../primer/Dropdown";
import {submissionTypeOptions} from "./Components/constants";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {toast} from "react-toastify";
import "./Components/tableStyle.css";

const ErganiSubmissionsReport = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.PAYROLL.erganiSubmissionsReportRequestData);
    const responseData = useSelector((state) => state.PAYROLL.erganiSubmissionsReportResponseData);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);

    const [installationOptions, setInstallationOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingErganiDocument, setLoadingErganiDocument] = useState(false);
    const [pageChanged, setPageChanged] = useState(false);

    useEffect(() => {
        if (companyInstallations.length > 0) {
            let options = [{value: "", label: "Όλες"}];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
    }, [])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport(true);
        }
    }, [pageChanged])

    const fetchReport = (fromPagination = false) => {
        setLoading(true);
        let reqData = {
            ...requestData,
            company: company.id,
        }
        if (!fromPagination) reqData.page = 1;
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/ergani-submission-report`, {
            ...requestData,
            company: company.id,
        }, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                dispatch(setErganiSubmissionsReportResponseData({...responseData, data: res.data.data, totalPages: res.data.totalPages}));
                if (!fromPagination) {
                    if (res.data.totalPages > 0) {
                        toast.success("Τα δεδομένα ανανεώθηκαν.", {autoClose: 1000});
                    } else {
                        toast.info("Δεν βρέθηκαν δεδομένα.");
                    }
                    dispatch(setErganiSubmissionsReportRequestData({...requestData, page: 1}));
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleOnChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setErganiSubmissionsReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setErganiSubmissionsReportRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "dropdown") {
            dispatch(setErganiSubmissionsReportRequestData({...requestData, [name] : e.value}));
        }
    }

    const toFirstPage = () => {
        dispatch(setErganiSubmissionsReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }

    const toPreviousPage = () => {
        dispatch(setErganiSubmissionsReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }

    const toNextPage = () => {
        dispatch(setErganiSubmissionsReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }

    const toLastPage = () => {
        dispatch(setErganiSubmissionsReportRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }

    const handleErganiPrint = (subId) => {
        toast.info("Γίνεται ανάκτηση εγγράφου παρακαλώ περιμένετε...");
        setLoadingErganiDocument(true);
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/get-ergani-document`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                subId: subId,
            }
        }).then((res) => {
            setLoadingErganiDocument(false);
            if (res.data.status === "200") {
                const byteCharacters = atob(res.data.document);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                const blobURL = URL.createObjectURL(blob);
                window.open(blobURL);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoadingErganiDocument(false);
            console.log(err);
            toast.error("Σφάλμα κατά την ανάκτηση εγγράφου.");
        })
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={2}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => dispatch(setErganiSubmissionsReportRequestData({
                            ...requestData,
                            date_from: from,
                            date_to: to
                        }))}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        dateFormat="dd-MM-yyyy"
                        name="date_from"
                        selected={isEmpty(requestData.date_from) ? "" : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateFrom')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        dateFormat="dd-MM-yyyy"
                        name="date_to"
                        selected={isEmpty(requestData.date_to) ? "" : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateTo')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        name={"installationMasterId"}
                        label={"Εγκατάσταση"}
                        options={installationOptions}
                        key={Math.random()}
                        defaultValue={installationOptions.find((el) => el.value === requestData.installationMasterId)}
                        onChange={(e) => handleOnChange(e, "dropdown", "installationMasterId")}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        name={"submissionType"}
                        label={"Τύπος Εγγραφής"}
                        options={submissionTypeOptions}
                        key={Math.random()}
                        defaultValue={submissionTypeOptions.find((el) => el.value === requestData.submissionType)}
                        onChange={(e) => handleOnChange(e, "dropdown", "submissionType")}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} className={"ml-auto"}>
                    <Button onClick={() => fetchReport()} className="btn btn-primary float-right" disabled={loading}>
                        {t('General.applyFilter')}
                    </Button>
                </Col>
            </Row>
            <hr style={{color: "gray", height: 1}}/>
            {responseData.totalPages > 0 && (
                <Row>
                    <Col md={4}>
                        {loadingErganiDocument && (
                            <div>
                                <span>Γίνεται άντληση εγγράφου από την Εργάνη...</span>
                                <Spinner className={"ml-2"} animation="border" variant="dark" size={"sm"} />
                            </div>
                        )}
                    </Col>
                    <Col md={8}>
                        <div className="d-flex justify-content-end mb-2">
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toFirstPage()}
                                disabled={requestData.page === 1 || loading}
                            >
                                {t('SalesBalanceSheet.first')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toPreviousPage()}
                                disabled={requestData.page === 1 || loading}
                            >
                                {t('SalesBalanceSheet.previous')}
                            </Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                   bg="primary">{t('SalesBalanceSheet.page')} {requestData.page} / {responseData.totalPages}</Badge>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toNextPage()}
                                disabled={requestData.page === responseData.totalPages || loading}
                            >
                                {t('SalesBalanceSheet.next')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toLastPage()}
                                disabled={requestData.page === responseData.totalPages || loading}
                            >
                                {t('SalesBalanceSheet.last')}
                            </Button>
                        </div>
                    </Col>
                    <Col md={12}>
                        <table style={{width: "100%", tableLayout: "fixed"}} className={"employeeProgramTable"}>
                            <colgroup>
                                <col span={1} style={{width: "5%"}}></col>
                                <col span={1} style={{width: "15%"}}></col>
                                <col span={1} style={{width: "20%"}}></col>
                                <col span={1} style={{width: "15%"}}></col>
                                <col span={1} style={{width: "15%"}}></col>
                                <col span={1} style={{width: "25%"}}></col>
                                <col span={1} style={{width: "5%"}}></col>
                            </colgroup>
                            <thead>
                            <tr>
                                <th>Αρ.</th>
                                <th>Ημ/νια Εγγραφής</th>
                                <th>Εγκατάσταση</th>
                                <th>Κωδ. Εργάνης</th>
                                <th>Αρ. Πρωτοκόλλου Εργάνης</th>
                                <th>Τύπος Εγγραφής</th>
                                <th>Ενέργειες</th>
                            </tr>
                            </thead>
                            <tbody>
                            {responseData.data.map((row, idx) => (
                                <tr key={`est${idx}`} style={{backgroundColor: row["cancelled"] === true ? "#fdd2d2" : ""}}>
                                    <td>{row["no"]}</td>
                                    <td>{moment(row["created_at"]).format("DD/MM/YYYY HH:mm")}</td>
                                    <td>{isEmpty(row["installationMasterId"]) ? "-" : installationOptions.find((el) => el.value === row["installationMasterId"])?.label}</td>
                                    <td>{row["erganiId"]}</td>
                                    <td>
                                        {row["erganiProtocol"]}
                                        {row["cancelled"] === true && <React.Fragment><br /><strong>*** ΑΚΥΡΩΜΕΝΟ ***</strong></React.Fragment>}
                                    </td>
                                    <td>{submissionTypeOptions.find((el) => el.value === row["type"])?.label}</td>
                                    <td>
                                        {!row["cancelled"] && (
                                            <i className="fa-solid fa-print" onClick={() => handleErganiPrint(row._id)} style={{cursor: "pointer"}} title={"Ανάκτηση εγγράφου από την Εργάνη"}></i>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default ErganiSubmissionsReport;
