import React, {useEffect, useState} from "react";
import {classicStyleBelowNavbar} from "../Statics";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import {
    resetPreviewFile,
    setCopyFileCut,
    setCopyFilePath,
    setCurrentPath, setCurrentPathLocked,
    setDirData, setDisableCloudPage,
    setExtraData,
    setPreviewFile
} from "../../../../_reducers/cloudSlice";
import FileBrowser from "./Components/FileBrowser";
import Button from "react-bootstrap/Button";
import {convertToBase64, formatPath, getMimeType, popExtension} from "./Components/constants";
import {Breadcrumb, Col, OverlayTrigger, ProgressBar, Row, Tooltip} from "react-bootstrap";
import {checkPermission, isEmpty} from "../../../../_helpers/commonFunctions";
import FilePreviewer from "./Components/FilePreviewer";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const Cloud = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const currentPath = useSelector((state) => state.CLOUD.currentPath);
    const currentPathLocked = useSelector((state) => state.CLOUD.currentPathLocked);
    const dirData = useSelector((state) => state.CLOUD.dirData);
    const extraData = useSelector((state) => state.CLOUD.extraData);
    const previewFile = useSelector((state) => state.CLOUD.previewFile);
    const copyFilePath = useSelector((state) => state.CLOUD.copyFilePath);
    const copyFileCut = useSelector((state) => state.CLOUD.copyFileCut);

    const disableCloudPage = useSelector((state) => state.CLOUD.disableCloudPage);

    const [loading, setLoading] = useState(false);
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Percentage uploaded

    const [overwriteModal, setOverwriteModal] = useState(false);
    const [tempUploadFiles, setTempUploadFiles] = useState([]); // Used when overwriting is required

    const [showFileHistory, setShowFileHistory] = useState(false);
    const [fileHistoryData, setFileHistoryData] = useState({});

    const cloudSpaceProgressBar = extraData.spaceUsed / extraData.cloudSpace * 100;

    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const isEmron = checkPermission("custom-logistirio", permissionsData);
    const canUpload = extraData.specialCode !== "1" || (extraData.specialCode === "1" && !dirData.some((el) => el.folder) && !currentPathLocked);

    useEffect(() => {
        onDirectoryRefresh();
    }, [])

    useEffect(() => {
        if (!showFileHistory) setFileHistoryData({});
    }, [showFileHistory])

    useEffect(() => {
        if (!overwriteModal) setTempUploadFiles([]);
    }, [overwriteModal])

    const fetchExtraData = () => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/extra-data`, {
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                dispatch(setExtraData(res.data.data));
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onDirectoryChange = (path = "/") => {
        fetchExtraData();
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "dir",
            currentPath: path,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                dispatch(setDirData(res.data.data));
                dispatch(setCurrentPath(path));
                if (!isEmpty(res.data.currentPathLocked)) {
                    dispatch(setCurrentPathLocked(res.data.currentPathLocked));
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const fetchFileAsBlob = async (name) => {
        setLoading(true);
        // returns error message else blob
        try {
            const fetchResp = await axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
                action: "fetch",
                currentPath: formatPath(currentPath + "/" + name),
                company: company.id,
                year: company.year,
            });
            if (fetchResp.data.status === "200") {
                setLoading(false);
                const binaryString = atob(fetchResp.data.data);
                const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                const blob = new Blob([byteArray], { type: getMimeType(name) });
                let blobUrl = URL.createObjectURL(blob);
                if (popExtension(name) === "pdf") {
                    blobUrl += "#navpanes=0&view=Fit";
                }
                return blobUrl;
            } else {
                setLoading(false);
                return null;
            }
        } catch (err) {
            setLoading(false);
            return null;
        }
    }

    const onFileLeftClick = async (name) => {
        if (loading) {
            return toast.error("Παρακαλώ περιμένετε να φορτώσει η προηγούμενη προεπισκόπηση.");
        }
        setLoadingPreview(true);
        const blobURL = await fetchFileAsBlob(name);
        setLoadingPreview(false);
        if (blobURL) {
            dispatch(setPreviewFile({
                name: name,
                path: formatPath(currentPath + "/" + name),
                data: blobURL,
            }));
        } else {
            toast.error("Σφάλμα κατά την άντληση αρχείου.");
        }
    }

    const onFolderLeftClick = (name) => {
        onDirectoryChange(formatPath(currentPath + "/" + name));
    }

    const onDirectoryBack = () => {
        let split = String(currentPath).split("/").filter((el) => !isEmpty(el));
        if (split.length >= 2) {
            let prePath = split.filter((el) => !isEmpty(el)).slice(0, split.length - 1);
            onDirectoryChange(formatPath("/" + prePath.join("/")));
        } else if (split.length === 1) {
            onDirectoryChange();
        }
    }

    const onDirectoryRefresh = () => {
        onDirectoryChange(currentPath);
    }

    const onFilesDrop = (files, overwrite = false) => {
        /*
        files is array of objects like
        [{
            fileName: "...",
            base64Encoded: "..."
        }]
         */
        // Quick validations
        for (let dat of files) {
            let mbSize = new TextEncoder().encode(JSON.stringify(dat.base64Encoded)).length / (1024 * 1024);
            mbSize = 3 / 4 * mbSize;
            if (mbSize > extraData.cloudMaximumFileSize) {
                return toast.error(`Το αρχείο με όνομα ${dat.fileName} ξεπερνά το όριο μέγιστου μεγέθους αρχείου (${extraData.cloudMaximumFileSize} MB). Δεν έγινε μεταφόρτωση κανενός αρχείου.`, {autoClose: 12000});
            }
        }
        if (!overwrite) {
            for (let dat of files) {
                if (dirData.some((el) => el.name === dat.fileName && !el.folder)) {
                    setTempUploadFiles(files);
                    setOverwriteModal(true);
                    return;
                }
            }
        }
        const cloneFiles = overwrite ? structuredClone(tempUploadFiles) : files;
        if (overwriteModal || tempUploadFiles.length > 0) {
            setOverwriteModal(false);
            setTempUploadFiles([]);
        }

        // Upload
        setLoadingUpload(true);
        setUploadProgress(0);
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "upload",
            currentPath: formatPath(currentPath + "/"),
            data: cloneFiles,
            company: company.id,
            year: company.year,
        }, {
            onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent?.target?.getResponseHeader('content-length') || progressEvent?.target?.getResponseHeader('x-decompressed-content-length');
                if (totalLength !== null) setUploadProgress(Math.round( (progressEvent.loaded * 100) / totalLength));
            }
        }).then((res) => {
            setLoadingUpload(false);
            setUploadProgress(0);
            if (res.data.status === "200") {
                toast.success("Επιτυχής μεταφορά αρχείου.");
                onDirectoryRefresh();
            } else {
                toast.error(res.data.message, {autoClose: 10000});
            }
        }).catch((err) => {
            setLoadingUpload(false);
            setUploadProgress(0);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onNewFolder = (folderName) => {
        if (dirData.some((el) => el.name === folderName && el.folder)) {
            toast.error("Υπάρχει ήδη φάκελος με αυτό το όνομα.");
            return false;
        }
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "createFolder",
            currentPath: formatPath(currentPath + "/"),
            folderName: folderName,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής δημιουργία φακέλου.");
                onDirectoryRefresh();
            } else {
                if (res.data.specialCode === "1") {
                    toast.info(res.data.message);
                } else {
                    toast.error(res.data.message);
                }
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
        return true;
    }

    const onFileDelete = (fileName) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "deleteFile",
            currentPath: formatPath(currentPath + "/" + fileName),
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής διαγραφή αρχείου.");
                onDirectoryRefresh();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onFolderDelete = (folderName) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "deleteFolder",
            currentPath: formatPath(currentPath + "/" + folderName),
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής διαγραφή φακέλου.");
                onDirectoryRefresh();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const getBreadcrumbMapping = () => {
        let arr = [];
        let split = String(currentPath).split("/");
        split.filter((el) => !isEmpty(el)).forEach((item, idx) => {
            let prePath = split.filter((el) => !isEmpty(el)).slice(0, idx);
            arr.push({
                name: item,
                path: formatPath("/" + prePath.join("/") + "/" + item),
            })
        })
        return arr;
    }

    const onFileRename = (oldName, newName) => {
        if (dirData.some((el) => el.name === newName && !el.folder)) {
            toast.error(`Υπάρχει ήδη αρχείο με όνομα ${newName}`);
            return false;
        }
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "renameFile",
            currentPath: formatPath(currentPath + "/"),
            fileName: oldName,
            newFileName: newName,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής μετανομασία αρχείου.");
                onDirectoryRefresh();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
        return true;
    }

    const onFolderRename = (oldName, newName) => {
        if (dirData.some((el) => el.name === newName && el.folder)) {
            toast.error(`Υπάρχει ήδη φάκελος με όνομα ${newName}`);
            return false;
        }
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "renameFolder",
            currentPath: formatPath(currentPath + "/"),
            folderName: oldName,
            newFolderName: newName,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής μετανομασία φακέλου.");
                onDirectoryRefresh();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
        return true;
    }

    const onFileDownload = async (data, fileName, fetchNew = false, revokeURL = false) => {
        let finalData = data;
        if (fetchNew) {
            const fetchFile = await fetchFileAsBlob(fileName);
            if (fetchFile) {
                finalData = fetchFile;
            } else {
                toast.error("Σφάλμα κατά την άντληση αρχείου.");
            }
        }
        const link = document.createElement("a");
        link.href = finalData;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        if (revokeURL) URL.revokeObjectURL(finalData);
    }

    const onFileCopy = (fileName, isCut) => {
        const path = formatPath(currentPath + "/" + fileName);
        dispatch(setCopyFilePath(path));
        if (isCut) dispatch(setCopyFileCut(true));
    }

    const onFilePaste = () => {
        const split = copyFilePath.split("/");
        const fileName = split[split.length - 1];
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "pasteFile",
            oldPath: copyFilePath,
            currentPath: formatPath(currentPath + "/" + fileName),
            isCut: copyFileCut,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                if (copyFileCut) {
                    toast.success("Επιτυχής επικόλληση αρχείου.");
                } else {
                    toast.success("Επιτυχής αντιγραφή αρχείου.");
                }
                onDirectoryRefresh();
                if (copyFileCut) {
                    dispatch(setCopyFilePath(""));
                    dispatch(setCopyFileCut(false));
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleFileUpload = async (e) => {
        const files = e.target.files;
        if (files.length) {
            for (let file of files) {
                if (file.size % 4096 === 0) {
                    return toast.error("Δεν είναι δυνατή η μεταφορά φακέλου ή άδειων αρχείων.");
                } else if (file.type === "") {
                    return toast.error("Δεν είναι δυνατή η μεταφορά άμορφου αρχείου.");
                }
            }
            const arr = [];
            for (let file of files) {
                const base64Encoded = await convertToBase64(file);
                arr.push({
                    fileName: file.name,
                    base64Encoded: base64Encoded,
                })
            }
            onFilesDrop(arr);
            document.getElementById("filesInput").value = null;
        }
    }

    const onFileHistory = (name, folder = false) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "history",
            currentPath: formatPath(currentPath + "/" + name),
            folder: folder,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                setFileHistoryData(res.data.data);
                setShowFileHistory(true);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onResetPreview = () => {
        dispatch(resetPreviewFile());
    }

    /*
     Emron special functions below
     */

    const onEmronSort = (path, option = null) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronSort",
            currentPath: path,
            option: option,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής ενέργεια.");
                onDirectoryRefresh();
                dispatch(resetPreviewFile());
            } else {
                toast.error(res.data.message);
                onDirectoryRefresh();
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onEmronDelete = (selection) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronDelete",
            currentPath: formatPath(currentPath + "/" + selection),
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής ενέργεια.");
                onDirectoryRefresh();
            } else {
                toast.error(res.data.message);
                onDirectoryRefresh();
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onEmronRestore = (path) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronRestore",
            currentPath: path,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής ενέργεια.");
                onDirectoryRefresh();
                dispatch(resetPreviewFile());
            } else {
                toast.error(res.data.message);
                onDirectoryRefresh();
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onEmronRegister = (path, option = null) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronRegister",
            currentPath: path,
            option: option,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής ενέργεια.");
                onDirectoryRefresh();
                dispatch(resetPreviewFile());
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onEmronUnlock = (name, days) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronUnlock",
            currentPath: currentPath + "/" + name,
            days: days,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής ενέργεια.");
                onDirectoryRefresh();
            } else {
                toast.error(res.data.message);
                onDirectoryRefresh();
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onPreviewNewTab = (path) => {
        if (extraData.specialCode === "1" && isEmron) {
            const timestamp = String(Date.now());
            const dataToSend = {
                name: previewFile.name,
                currentPath: path,
                data: previewFile.data,
                dirData: dirData,
            }
            sessionStorage.setItem(`emronCloudData-${timestamp}`, JSON.stringify(dataToSend));

            const newURL = new URL(window.location.href);
            newURL.searchParams.set("emronCloudDataTS", timestamp);

            const clientWidth = window.innerWidth;
            const clientHeight = window.innerHeight;
            const popUpWindow = window.open(newURL.toString(), "_blank", `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${clientWidth},height=${clientHeight}`);
            if (popUpWindow) {
                dispatch(setDisableCloudPage(true));
                const monitorPopup = setInterval(() => {
                    if (popUpWindow.closed) {
                        clearInterval(monitorPopup);
                        onDirectoryRefresh();
                        dispatch(setDisableCloudPage(false));
                    }
                }, 250);
            }
        } else {
            window.open(previewFile.data, "_blank");
        }
    }

    const disabledPageStyle = disableCloudPage ? {
        opacity: "0.3",
        pointerEvents: "none",
        userSelect: "none",
    } : {};

    return (
        <React.Fragment>
            {disableCloudPage && (
                <div style={{
                    left: "0",
                    width: "100%",
                    position: "absolute",
                    top: "40%",
                    fontSize: "32px",
                    fontWeight: "800",
                    opacity: "1",
                    textAlign: "center",
                    zIndex: "1000",
                }}>
                    Παρακαλώ τελειώστε την εργασία σας στην νέα καρτέλα
                </div>
            )}
            <div style={{...classicStyleBelowNavbar, ...disabledPageStyle}}>
                <Row>
                    <Col md={2}>
                        <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                            <Button size={"sm"} onClick={() => onDirectoryBack()} className={"mr-1"}
                                    style={{height: "45px", flex: "1 1"}} disabled={currentPath === "/"}>
                                <i className="fa-solid fa-arrow-left"></i>
                            </Button>
                            <Button size={"sm"} style={{height: "45px", flex: "1 1"}} className={"mr-1"}
                                    onClick={() => onDirectoryRefresh()}>
                                <i className="fa-solid fa-arrows-rotate"></i>
                            </Button>
                        </div>
                    </Col>
                    <Col md={5}>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => onDirectoryChange("/")}>Αρχική</Breadcrumb.Item>
                            {getBreadcrumbMapping().map((bc, idx) => (
                                <Breadcrumb.Item key={idx} onClick={() => onDirectoryChange(bc.path)}>
                                    {(extraData.specialCode === "1" && !isNaN(bc.name)) ? (
                                        <React.Fragment>{bc.name === "0" ? "Κεντρικό" : `Υποκατάστημα ${bc.name}`}</React.Fragment>
                                    ) : (
                                        <React.Fragment>{bc.name}</React.Fragment>
                                    )}
                                </Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                    </Col>
                    {canUpload && (
                        <Col md={1}>
                            <input type={"file"} style={{display: "none"}} id={"filesInput"}
                                   onChange={(e) => handleFileUpload(e)}/>
                            <Button size={"sm"} onClick={() => document.getElementById("filesInput").click()}>
                                Ανέβασμα αρχείων
                            </Button>
                        </Col>
                    )}
                    <Col md={canUpload ? 4 : 5} className={"mt-1"}>
                    <span>Αποθηκευτικός χώρος
                        <OverlayTrigger
                            placement="top"
                            delay={{show: 150, hide: 150}}
                            overlay={
                                <Tooltip id="button-tooltip">
                                    <span>Μέγιστο επιτρεπτό μέγεθος αρχείου {extraData.cloudMaximumFileSize} MB</span>
                                </Tooltip>
                            }
                        >
                            <strong>{"  "}<u>({extraData.spaceUsed} / {extraData.cloudSpace} MB) - {Number(cloudSpaceProgressBar).toFixed(2)}%</u></strong>
                        </OverlayTrigger>
                    </span>
                        <div style={{position: "relative"}}>
                            <ProgressBar variant={cloudSpaceProgressBar < 90 ? "info" : "danger"} striped
                                         now={cloudSpaceProgressBar} style={{height: "20px", textAlign: "center"}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={7} className={"mt-1"}>
                        <FileBrowser
                            dirData={dirData}
                            hasCopiedFile={!isEmpty(copyFilePath)}

                            onFileLeftClick={onFileLeftClick}
                            onFolderLeftClick={onFolderLeftClick}
                            onFilesDrop={onFilesDrop}
                            onNewFolder={onNewFolder}
                            onFileDelete={onFileDelete}
                            onFolderDelete={onFolderDelete}
                            onFileRename={onFileRename}
                            onFolderRename={onFolderRename}
                            onFileDownload={onFileDownload}
                            onFileCopy={onFileCopy}
                            onFilePaste={onFilePaste}
                            onFileHistory={onFileHistory}

                            onEmronDelete={onEmronDelete}
                            onEmronUnlock={onEmronUnlock}
                            specialCode={extraData.specialCode}
                            canUpload={canUpload}
                        />
                    </Col>
                    <Col md={5} className={"mt-1"}>
                        <FilePreviewer
                            path={previewFile.path}
                            previewData={previewFile.data}
                            fileName={previewFile.name}
                            loading={loadingPreview}
                            onFileDownload={onFileDownload}
                            onResetPreview={onResetPreview}
                            onPreviewNewTab={onPreviewNewTab}

                            onEmronSort={onEmronSort}
                            onEmronRestore={onEmronRestore}
                            onEmronRegister={onEmronRegister}

                            specialCode={extraData.specialCode}
                            isEmron={isEmron}
                        />
                    </Col>
                </Row>

                <div style={{height: "200px"}}></div>

                <Modal show={loadingUpload}>
                    <Modal.Header>
                        <Modal.Title>Μεταφόρτωση αρχείων</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"mb-2"}>Γίνεται μεταφόρτωση αρχείων παρακαλώ περιμένετε...</div>
                        <ProgressBar variant={"info"} striped now={uploadProgress}/>
                    </Modal.Body>
                </Modal>

                <Modal show={overwriteModal} onHide={() => setOverwriteModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Προσοχή!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Κάποια αρχεία υπάρχουν ήδη. Πατώντας αντικατάσταση θα αντικατασταθούν με τα καινούργια και τα
                        παλαιά θα διαγραφούν.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => onFilesDrop(tempUploadFiles, true)}>
                            Αντικατάσταση
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showFileHistory} onHide={() => setShowFileHistory(false)}
                       dialogClassName={"modal50PercentWidth"}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Ιστορικό
                            {Object.keys(fileHistoryData).length > 0 && (
                                <span>{" "}{fileHistoryData.folder ? "φακέλου" : "αρχείου"}
                                    <strong>{String(fileHistoryData.path).split("/")[String(fileHistoryData.path).split("/").length - 1]}</strong></span>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {Object.keys(fileHistoryData).length > 0 && (
                            <React.Fragment>
                                <table className={"simpleClassicTable mb-3"}>
                                    <colgroup>
                                        <col span={1} style={{width: "30%"}}></col>
                                        <col span={1} style={{width: "40%"}}></col>
                                        <col span={1} style={{width: "30%"}}></col>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Ενέργεια</th>
                                        <th>Χρήστης</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {fileHistoryData.history.map((row, idx) => (
                                        <tr key={`fsHistory-${idx}`}>
                                            <td>{moment(row["date"]).format("DD/MM/YYYY HH:mm:ss")}</td>
                                            <td>{row["details"]}</td>
                                            <td>{row["user"]}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Cloud
