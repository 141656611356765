import React, {useRef, useState} from "react";
import {Badge, Button, Card, ListGroup} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {setSaveSalesSettings} from "../../../../_reducers/activitySlice";
import {setSalesEmailSettings} from "../../../../_reducers/DataPanel/Sales/salesSettingsSlice";
import Input from '../../../common/Input';
import {EditorState, ContentState, convertFromHTML} from 'draft-js';
import {convertToHTML} from 'draft-convert';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {salesEmailWildcardsList} from "../../../../_helpers/commonFunctions";
import {useTranslation} from "react-i18next";

const SalesEmailSettings = () => {
    const [t] = useTranslation('common');
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
    const dispatch = useDispatch();
    let editorStateValue = EditorState.createEmpty();
    if (SALES_SETTINGS.salesEmailSettings.emailBody) {
        const blocksFromHTML = convertFromHTML(SALES_SETTINGS.salesEmailSettings?.emailBody);
        editorStateValue = EditorState.createWithContent(ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
        ))
    }
    const [editorState, setEditorState] = useState(editorStateValue);
    const [showWildcardsList, setShowWildcardsList] = useState(false);
    const handleOnChange = (e, type = "default", ddname = '') => {
        if (!ACTIVITY.saveSalesSettings) {
            dispatch(setSaveSalesSettings(true));
        }

        let name;
        let value;

        if (type === 'dd') {
            name = ddname;
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        // Set settings data
        let newSettings = Object.assign({}, SALES_SETTINGS.salesEmailSettings);
        let newSettingsData = {};
        newSettingsData[name] = value;
        newSettings = {...newSettings, ...newSettingsData}
        dispatch(setSalesEmailSettings(newSettings));
    }

    // HTML to state
    const handleEditorChange = (state) => {
        // Set if editing the form
        if (!ACTIVITY.saveSalesSettings) {
            dispatch(setSaveSalesSettings(true));
        }
        setEditorState(state);
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        // Set settings data
        let newSettings = Object.assign({}, SALES_SETTINGS.salesEmailSettings);
        let newSettingsData = {};
        newSettingsData['emailBody'] = currentContentAsHTML;
        newSettings = {...newSettings, ...newSettingsData}
        dispatch(setSalesEmailSettings(newSettings));

    }

    const handleAddWildcard = (e, item) => {
        // Set if editing the form
        if (!ACTIVITY.saveSalesSettings) {
            dispatch(setSaveSalesSettings(true));
        }
        // Set settings data
        let newSettings = Object.assign({}, SALES_SETTINGS.salesEmailSettings);
        let newSettingsData = {};
        newSettingsData['emailBody'] = `${newSettings?.emailBody} ${item}`;
        newSettings = {...newSettings, ...newSettingsData}
        dispatch(setSalesEmailSettings(newSettings));

        // Update Editor State
        const blocksFromHTML = convertFromHTML(newSettingsData['emailBody']);
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
        )));
    }
    let wildcardsList = salesEmailWildcardsList();
    const wcRef = useRef(null)

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="mb-3 d-flex">
                        <Input
                            label="Email Subject:"
                            placeholder="Enter subject"
                            name="subject"
                            value={SALES_SETTINGS.salesEmailSettings.subject}
                            onChange={(e) => handleOnChange(e)}
                        />
                        <Button
                            className="ml-3 w-25 btn-sm add-wildcards-btn"
                            varient="primary"
                            onClick={() => setShowWildcardsList(!showWildcardsList)}
                        >Add Wildcards
                        </Button>
                    </div>

                    {/* Wildcards List */}
                    {showWildcardsList && (
                        <React.Fragment>
                            <div style={{borderRadius: "8px", border: "1px solid lightgray", padding: "10px"}}>
                                Μεταβλητές<br/>
                                {wildcardsList.map((item, idx) => (
                                    <Badge bg={"primary"} key={`wildcard${idx}`} style={{cursor: "pointer"}}
                                           onClick={(e) => handleAddWildcard(e, item)}
                                           className={"mr-1 mt-1"}>{item}</Badge>
                                ))}
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className="col-12">
                    <label>Email Body</label>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        onEditorStateChange={handleEditorChange}
                    />

                    <div className="col-12 mt-3">
                        <p>Attachments: <i>invoice</i></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalesEmailSettings;
