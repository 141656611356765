import React, {useEffect, useState} from 'react';
import {classicStyleBelowNavbar} from "../Statics";
import axios from "axios";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import Input from "../../../common/Input";
import QR from "qrcode-base64";
import moment from "moment/moment";
import {colorMapping, dayTranslate, decimalToHourMinutesFormat, getWorkType} from "./Components/constants";
import {useMediaQuery} from "@mui/material";

const EmployeeView = () => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const isMobileScreen = useMediaQuery("(max-width: 980px)");
    const [displayData, setDisplayData] = useState({
        firstName: "",
        lastName: "",
        vatNumber: "",
        dateHired: "",
        cardDetails: "",
        enableCard: false,

        program: [],
        cardHistory: [],
    })
    const [loading, setLoading] = useState(false);
    const [QRModal, setQRModal] = useState(false);

    useEffect(() => {
        fetchEmployeeView();
    }, [])

    const fetchEmployeeView = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/get-employee-view`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setDisplayData({...displayData, ...res.data.data});
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση δεδομένων.");
        })
    }

    const handleSendEmployeeCard = () => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/post-qr-movement`, {
            company: company.id,
            qr: displayData.cardDetails,
            installationMasterId: "auto"
        }, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                toast.success("Η αποστολή έγινε επιτυχώς");
                fetchEmployeeView();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος, η κάρτα ΔΕΝ πέρασε.");
        })
    }

    const renderFetchedEmployeeProgram = (dailyCalendar, idx) => {
        let compArr = [];
        let daysDone = [];
        dailyCalendar.forEach((dc, idx2) => {
            if (daysDone.includes(dc.day)) return;
            daysDone.push(dc.day);
            let findSameDay = dailyCalendar.filter((el) => el.day === dc.day);
            let momentMatch = moment().startOf("week").add(dc.day, "days");
            const dayFormat = dayTranslate[momentMatch.clone().format("dddd")] + " " + momentMatch.clone().format("DD/MM/YYYY");
            if (findSameDay.length === 1) {
                compArr.push(
                    <div style={{
                        padding: "8px",
                        borderRadius: "8px",
                        width: "33.3%",
                        backgroundColor: colorMapping[dc.workType],
                        marginRight: "1%",
                        textAlign: "center",
                        minHeight: "120px",
                    }} key={`emp-${idx}-${idx2}`}>
                        <span style={{textDecoration: "underline", fontWeight: "600"}}>{dayFormat}</span><br/>
                        {getWorkType(dc)}<br/>
                        {dc?.allDay ? (
                            <React.Fragment>
                                Όλη μέρα<br/>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {decimalToHourMinutesFormat(dc.hourFrom)} - {decimalToHourMinutesFormat(dc.hourTo)}<br/>
                            </React.Fragment>
                        )}
                        Εγκατάσταση: {dc.installationMasterId}
                    </div>
                )
            } else {
                let compSemiArr = [];
                for (let subDc of findSameDay) {
                    compSemiArr.push(
                        <div style={{
                            padding: "8px",
                            borderRadius: "8px",
                            backgroundColor: colorMapping[subDc.workType],
                            width: "100%",
                            textAlign: "center",
                            height: `${Number(100 / findSameDay.length)}%`,
                            minHeight: `${Number(120 / findSameDay.length)}px`,
                            marginBottom: "2px"
                        }} key={`emp-${idx}-${idx2}`}>
                            <span style={{textDecoration: "underline", fontWeight: "600"}}>{dayFormat}</span><br/>
                            {getWorkType(dc)}<br/>
                            {subDc?.allDay ? (
                                <React.Fragment>
                                    Όλη μέρα<br/>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {decimalToHourMinutesFormat(subDc.hourFrom)} - {decimalToHourMinutesFormat(subDc.hourTo)}<br/>
                                </React.Fragment>
                            )}
                            Εγκατάσταση: {subDc.installationMasterId}
                        </div>
                    )
                }
                compArr.push(
                    <div style={{width: "33%", marginRight: "1%"}}>
                        {compSemiArr}
                    </div>
                )
            }
        })
        return <div style={{display: "flex"}}>{compArr}</div>;
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"d-flex justify-content-center"}>
                <Col md={6}>
                    <div style={{
                        borderRadius: "12px",
                        border: "1px solid #6ea2a9",
                        padding: "12px",
                        width: "100%",
                        textAlign: "center"
                    }} className={"mb-3"}>
                        <div className={"text-muted"}><i>Στοιχεία εργαζομένου</i></div>
                        <Row>
                            <Col md={12}>
                                <Input
                                    label={"Ονοματεπώνυμο"}
                                    value={`${displayData.firstName} ${displayData.lastName}`}
                                    disabled={true}
                                    className={"mb-1"}
                                />
                            </Col>
                            <Col md={6}>
                                <Input
                                    label={"Α.Φ.Μ."}
                                    value={displayData.vatNumber}
                                    disabled={true}
                                    className={"mb-1"}
                                />
                            </Col>
                            <Col md={6}>
                                <Input
                                    label={"Ημερομηνία Πρόσληψης"}
                                    value={displayData.dateHired}
                                    disabled={true}
                                    className={"mb-1"}
                                />
                            </Col>
                        </Row>
                        {displayData.program.length > 0 && (
                            <React.Fragment>
                                <div className={"text-muted mb-2"}><i>Πρόγραμμα εργασίας</i></div>
                                {renderFetchedEmployeeProgram(displayData.program)}
                                <div className={"mt-3 mb-3"}></div>
                            </React.Fragment>
                        )}
                        {displayData.enableCard && (
                            <React.Fragment>
                                <div className={"text-muted mb-2"}><i>Κάρτα εργασίας</i></div>
                                <Row>
                                    <Col md={6}>
                                        <Button size={"sm"} style={{width: "100%"}} onClick={() => setQRModal(true)} className={"mb-2"}>Προβολή του QR μου</Button>
                                    </Col>
                                    <Col md={6}>
                                        <Button size={"sm"} style={{width: "100%"}} onClick={() => handleSendEmployeeCard()} disabled={loading}>
                                            Αποστολή τώρα {loading && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                                        </Button>
                                    </Col>
                                </Row>
                                {displayData.cardHistory.length > 0 && (
                                    <React.Fragment>
                                        <div className={"text-muted mb-2"}><i>Ιστορικό κινήσεων</i></div>
                                        <table className={"simpleClassicTable mt-2"}>
                                            <thead>
                                            <tr>
                                                <th>Ημερομηνία</th>
                                                <th>Είδος Κίνησης</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {displayData.cardHistory.map((row, idx) => (
                                                <tr key={`employeeView${idx}`}>
                                                    <td style={{padding: "8px"}}>
                                                        {dayTranslate[moment(row["date"]).format("dddd")]} {moment(row["date"]).format("DD/MM/YYYY")} {row["time"]}
                                                    </td>
                                                    <td style={{padding: "8px"}}>{row["movementType"] === "arrive" ? "Προσέλευση" : "Αναχώρηση"}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    <Modal show={QRModal} onHide={() => setQRModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Το QR μου</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={"d-flex justify-content-center"}>
                                <img style={{width: "100%"}} src={QR.drawImg(displayData.cardDetails, {
                                    typeNumber: 4,
                                    errorCorrectLevel: 'M',
                                })} alt="QR Code"/>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={() => setQRModal(false)}>
                                Κλείσιμο
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
            </Row>
            {isMobileScreen && <div style={{height: "150px"}}></div>}
        </div>
    )
}

export default EmployeeView
