import { createSlice } from "@reduxjs/toolkit";
import { excelOptions } from "../_data/settings/doc-types";
import { DoyOptions } from "../_data/clients/doy-options";
import moment from "moment";

let DoyOptionsStringified = "";
for (let i = 0; i < DoyOptions.length; i++) {
    DoyOptionsStringified += DoyOptions[i].value + " = " + DoyOptions[i].label + "<br/>";
}
const initialNewWithHoldTax = {
  "name": "",
  "id": "",
  "myData": "",
  "tax": "",
  "code": "",
  "taxPer": "true",
  "taxAmount": "false",
  "inUse": "new",
  "isActive": "active",
}

const initialNewOtherHoldTax = {
  "name": "",
  "id": "",
  "myData": "",
  "tax": "",
  "code": "",
  "taxPer": "true",
  "taxAmount": "false",
  "inUse": "new",
  "isActive": "active",
}

const withHoldTaxesInitialValues = [
  {
      id: "1",
      code: "1",
      name: "Περίπτ. β' - Τόκοι - 15%",
      tax: "15",
      myData: 1,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "2",
      code: "2",
      name: "Περίπτ. Γ' - Δικαιώματα- 20%",
      tax: "20",
      myData: 2,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "3",
      code: "3",
      name: "Περίπτ. Δ΄- Αμοιβές Συμβουλών Διοίκησης - 20%",
      tax: "20",
      myData: 3,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "4",
      code: "4",
      name: "Περίπτ. δ'- Τεχνικά Έργα- 3%",
      tax: "3",
      myData: 4,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "5",
      code: "5",
      name: "Υγρά καύσιμα και προϊόντα καπνοβιομηχανίας 1%",
      tax: "1",
      myData: 5,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "6",
      code: "6",
      name: "Λοιπά Αγαθά 4%",
      tax: "4",
      myData: 6,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "7",
      code: "7",
      name: "Παροχή Υπηρεσιών 8%",
      tax: "8",
      myData: 7,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "8",
      code: "8",
      name: "Προκαταβλητέος Φόρος Αρχιτεκτόνων και Μηχανικών επί συμβατικών Αμοιβών, για εκπόνηση μελετών και σχεδίων 4%",
      tax: "4",
      myData: 8,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "9",
      code: "9",
      name: "Προκαταβλητέος Φόρος Αρχιτεκτόνων και Μηχανικών επί συμβατικών Αμοιβών, που αφορούν οποιασδήποτε άλλης φύσης έργα 10%",
      tax: "10",
      myData: 9,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "10",
      code: "10",
      name: "Προκαταβλητέος Φόρος στις Αμοιβές  Δικηγόρων 15%",
      tax: "15",
      myData: 10,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "11",
      code: "11",
      name: "Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.1 αρ. 15 ν. 4172/2013",
      tax: "ποσό",
      myData: 11,
      taxPer: "false",
      taxAmount: "true",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "12",
      code: "12",
      name: "Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.2 αρ. 15 ν. 4172/2013 - Αξιωματικών Εμπορικού Ναυτικού",
      tax: "15",
      myData: 12,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "13",
      code: "13",
      name: "Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.2 αρ. 15 ν. 4172/2013 - Κατώτερο Πλήρωμα Εμπορικού Ναυτικού",
      tax: "10",
      myData: 13,
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "14",
      code: "14",
      name: "Παρακράτηση Ειδικής εισφοράς αλληλεγγύης",
      tax: "ποσό",
      myData: 14,
      taxPer: "false",
      taxAmount: "true",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "15",
      code: "15",
      name: "Παρακράτηση Φόρου Αποζημίωσης λόγω Διακοπής Σχέσης Εργασίας παρ.3 αρ. 15 ν. 4172/2013",
      tax: "ποσό",
      myData: 15,
      taxPer: "false",
      taxAmount: "true",
      taxLevel: 1,
      vatIncluded : 3
  },
    {
        id: "16",
        code: "16",
        name: "Παρακρατήσεις συναλλαγών αλλοδαπής βάσει συμβάσεων αποφυγής διπλής φορολογίας (Σ.Α.Δ.Φ.)",
        tax: "ποσό",
        myData: 16,
        taxPer: "false",
        taxAmount: "true",
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "17",
        code: "17",
        name: "Λοιπές Παρακρατήσεις Φόρου",
        tax: "ποσό",
        myData: 17,
        taxPer: "false",
        taxAmount: "true",
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "18",
        code: "18",
        name: "Παρακράτηση Φόρου Μερίσματα περ.α παρ. 1 αρ. 64 ν. 4172/2013",
        tax: "5",
        myData: 18,
        taxPer: "true",
        taxAmount: "false",
        taxLevel: 1,
        vatIncluded : 3
    }
]

const otherTaxesInitialValues = [
  {
      id: "1",
      code: "1",
      myData: 1,
      name: "α1) ασφάλιστρα κλάδου πυρός 20%",
      tax: "20",
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "2",
      code: "2",
      myData: 2,
      name: "α2) ασφάλιστρα κλάδου πυρός 20%",
      tax: "20",
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "3",
      code: "3",
      myData: 3,
      name: "β)ασφάλιστρα κλάδου ζωής 4%",
      tax: "4",
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "4",
      code: "4",
      myData: 4,
      name: "γ) ασφάλιστρα λοιπών κλάδων 15%",
      tax: "15",
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "5",
      code: "5",
      myData: 5,
      name: "δ) απαλλασσόμενα φόρου ασφαλίστρων 0%",
      tax: "0",
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "6",
      code: "6",
      myData: 6,
      name: "Ξενοδοχεία 1-2 αστέρων 0,50€",
      tax: "0.5",
      taxPer: "false",
      taxAmount: "true",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "7",
      code: "7",
      myData: 7,
      name: "Ξενοδοχεία 3 αστέρων 1,50€",
      tax: "1.5",
      taxPer: "false",
      taxAmount: "true",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "8",
      code: "8",
      myData: 8,
      name: "Ξενοδοχεία 4 αστέρων 3,00€",
      tax: "3",
      taxPer: "false",
      taxAmount: "true",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "9",
      code: "9",
      myData: 9,
      name: "Ξενοδοχεία 4 αστέρων 4,00€",
      tax: "4",
      taxPer: "false",
      taxAmount: "true",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "10",
      code: "10",
      myData: 10,
      name: "Ενοικιαζόμενα- Επιπλωμένα δωμάτια- διαμερίσματα 0,50€",
      tax: "0.5",
      taxPer: "false",
      taxAmount: "true",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "11",
      code: "11",
      myData: 11,
      name: "Ειδικός φόρος στις διαφημίσεις που προβάλλονται απο την τηλεόραση (ΕΦΤΔ) 5%",
      tax: "5",
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "12",
      code: "12",
      myData: 12,
      name: "3.1 Φόρος Πολυτελείας 10% επί της φορολογούμενης αξίας για τα ενδοικοινοτικώς αποκτούμενα και εισαγόμενα από τρίτες χώρες",
      tax: "10",
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "13",
      code: "13",
      myData: 13,
      name: "3.2 Φόρος πολυτελείας 10% επί της τιμής πώλησης προ ΦΠΑ για τα εγχωρίως παραγόμενα είδη",
      tax: "10",
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "14",
      code: "14",
      myData: 14,
      name: "Δικαίωμα του Δημοσίου στα εισιτήρια των καζίνο (80% επί του εισιτηρίου)",
      tax: "80",
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
  {
      id: "15",
      code: "15",
      myData: 15,
      name: "ασφάλιστρα κλάδου πυρός 20%",
      tax: "20",
      taxPer: "true",
      taxAmount: "false",
      taxLevel: 1,
      vatIncluded : 3
  },
    {
        id: "16",
        code: "16",
        myData: 16,
        name: "Λοιποί Τελωνειακοί Δασμοί-Φόροι",
        tax: "ποσό",
        taxPer: "false",
        taxAmount: "true",
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "17",
        code: "17",
        myData: 17,
        name: "Λοιποί Φόροι",
        tax: "ποσό",
        taxPer: "false",
        taxAmount: "true",
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "18",
        code: "18",
        myData: 18,
        name: "Επιβαρύνσεις Λοιπών Φόρων",
        tax: "ποσό",
        taxPer: "false",
        taxAmount: "true",
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "19",
        code: "19",
        myData: 19,
        name: "ΕΦΚ",
        tax: "ποσό",
        taxPer: "false",
        taxAmount: "true",
        taxLevel: 1,
        vatIncluded : 3
    }
];

export const otherDataInitialValues = [
    {
        id: "1",
        name: "allowOlderDate",
        isActive: "active",
        value: "4800"
    }
];

const feesInitialValues = [
    {
    id: "1",
    code: "1",
    name: "Για μηνιαίο λογαριασμό μέχρι και 50 ευρώ 12%",
    tax: "12",
    myData: 1,
    taxPer: "true",
    taxAmount: "false",
    vatPercentage: 0,
    taxLevel: 1,
    vatIncluded : 3
},
    {
        id: "2",
        code: "2",
        name: "Για μηνιαίο λογαριασμό από 50,01 μέχρι και 100 Ευρώ 15%",
        tax: "15",
        myData: 2,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "3",
        code: "3",
        name: "Για μηνιαίο λογαριασμό από 100,01 μέχρι και 150 Ευρώ 18%",
        tax: "18",
        myData: 3,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "4",
        code: "4",
        name: "Για μηνιαίο λογαριασμό από 150,01 ευρώ μέχρι και άνω 20%",
        tax: "20",
        myData: 4,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "5",
        code: "5",
        name: "Τέλος καρτοκινητής επί της αξίας χρόνου ομιλίας (12%)",
        tax: "12",
        myData: 5,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "6",
        code: "6",
        name: "Τέλος στην συνδρομητική τηλεόραση 10%",
        tax: "10",
        myData: 6,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "7",
        code: "7",
        name: "Τέλος συνδρομητών σταθερής τηλεφωνίας 5%",
        tax: "5",
        myData: 7,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "8",
        code: "8",
        name: "Περιβαλλοντικό Τέλος & πλαστικής σακούλας ν.2339/2001 αρ. 6α 0,07 ευρώ ανα τεμάχιο",
        tax: "0.07",
        myData: 8,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 24,
        taxLevel: 1,
        vatIncluded : 2
    },
    {
        id: "9",
        code: "9",
        name: "Εισφορά δακοκτονίας 2%",
        tax: "2",
        myData: 9,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "10",
        code: "10",
        name: "Λοιπά τέλη",
        tax: "ποσό",
        myData: 10,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "11",
        code: "11",
        name: "Τέλη Λοιπών Φόρων",
        tax: "ποσό",
        myData: 11,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "12",
        code: "12",
        name: "Εισφορά δακοκτονίας",
        tax: "ποσό",
        myData: 12,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "13",
        code: "13",
        name: "Για μηνιαίο λογαριασμό κάθε σύνδεσης (10%)",
        tax: "10",
        myData: 13,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "14",
        code: "14",
        name: "Τέλος καρτοκινητής επί της αξίας του χρόνου ομιλίας (10%)",
        tax: "10",
        myData: 14,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "15",
        code: "15",
        name: "Τέλος κινητής και καρτοκινητής για φυσικά πρόσωπα ηλικίας 15 έως και 29 ετών (0%)",
        tax: "0",
        myData: 15,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "16",
        code: "16",
        name: "Εισφορά προστασίας περιβάλλοντος πλαστικών προϊόντων 0,04 λεπτά ανά τεμάχιο [άρθρο 4 ν. 4736/2020] ",
        tax: "0.04",
        myData: 16,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 24,
        taxLevel: 1,
        vatIncluded : 2
    },
    {
        id: "17",
        code: "17",
        name: "Τέλος ανακύκλωσης 0,08 λεπτά ανά τεμάχιο [άρθρο 80 ν. 4819/2021]",
        tax: "0.08",
        myData: 17,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 24,
        taxLevel: 1,
        vatIncluded : 2
    },
    {
        id: "18",
        code: "18",
        name: "Τέλος διαμονής παρεπιδημούντων",
        tax: "ποσό",
        myData: 18,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "19",
        code: "19",
        name: "Tέλος επί των ακαθάριστων εσόδων των εστιατορίων και συναφών καταστημάτων",
        tax: "ποσό",
        myData: 19,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "20",
        code: "20",
        name: "Τέλος επί των ακαθάριστων εσόδων των κέντρων διασκέδασης",
        tax: "ποσό",
        myData: 20,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "21",
        code: "21",
        name: "Τέλος επί των ακαθάριστων εσόδων των καζίνο",
        tax: "ποσό",
        myData: 21,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "22",
        code: "22",
        name: "Λοιπά τέλη επί των ακαθάριστων εσόδων",
        tax: "ποσό",
        myData: 22,
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    }
    ];
const stampInitialValues = [
    {
        id: "1",
        code: "1",
        name: "Συντελεστής 1.2%",
        tax: "1.20",
        myData: 1,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "2",
        code: "2",
        name: "Συντελεστής 2.4%",
        tax: "2.40",
        myData: 2,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "3",
        code: "3",
        name: "Συντελεστής 3.6%",
        tax: "3.60",
        myData: 3,
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    }
];
const documentNumberingInitialValue = {
  id: 1,
  no: 1,
  installation: "",
  installationMasterId: 0,
  installation_id: "",
    installationType: "",
  numberingMode: "",
  preNumber: "",
  number: "",
  selected: "false",
  isActive: 'active',
  countNumber: "",
}

const basicTypeInitialValues = {
  name: '',
  affects_inventory: 'No',
  affects_client: 'No',
  affects_supplier: 'No',
}

const newAdvancedTypeInitialValues = {
    section: '',
    documentType: undefined,
    documentTypeName: '',
    name: '',
    printTemplate: '',
    printTemplateName: '',
    printAfterSave: 'inactive',
    useLogo: 'active',
    isCensus: 'inactive',
    sendEmailAfterSave: 'inactive',
    printPreference: '',
    printPreferenceName: '',
    proposedInstallation: '',
    isActive: 'active',
    canBePaidOff: 'active',
    thirdPartyCollection: "inactive",
    returnPaymentDocument: "",
    returnPaymentDocumentPreNumber: "",
    documentNumbering: [],
    enablePeppol: "inactive",
    accounting: {
        category: "",
        categoryName: "",
        subCategory: "",
        subCategoryName: "",
        documentType: "",
        documentTypeName: "",
        reason: "",
        recordType: "normal",
        bridge: [],
    },
}

const clientFields = [
  {
    checkBox: true,
    fieldName: "Name",
    required: true,
    fieldKey: "name",
    column: null,
    tooltip: null,
    disabled: true,
  },
  {
    checkBox: false,
    fieldName: "Small Name",
    fieldKey: "smallName",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Manual Code",
    fieldKey: "manualCode",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: true,
    fieldName: "Category",
    fieldKey: "category",
    required: true,
    column: null,
    tooltip: {
      title: "Κατηγορίες Πελατών",
      content: `1 = Retail</br>
        2 = Freelancer</br> 
        3 = O.E</br>
        4 = E.E.</br>
        5 = E.P.E</br>
        6 = I.K.E</br>
        7 = A.E.</br>
        8 = Patient</br>
        9 = Non-Profit Organization</br>
        10 = Client within E.U.</br>
        11 = Client outside E.U.</br>`,
    },
    disabled: true,
  },
  {
    checkBox: true,
    fieldName: "VAT Number",
    fieldKey: "vatNumber",
    required: true,
    column: null,
    tooltip: null,
    disabled: true,
  },
  {
    checkBox: true,
    fieldName: "VAT Category",
    fieldKey: "vatCategory",
    required: true,
    column: null,
    tooltip: {
      title: "Κατηγορίες Φ.Π.Α.",
      content:
        "1 = Normal VAT</br>2 = Reduced VAT</br>3 = Zero VAT</br>4 = Custom VAT",
    },
    disabled: true,
  },
    {
        checkBox: false,
        fieldName: "VAT Reason",
        fieldKey: "vatReason",
        required: false,
        column: null,
        tooltip: {
            title: "Κατηγορίες Εξαίρεσης Φ.Π.Α.",
            content: `
                1 = Χωρίς ΦΠΑ- άρθρο 2 και 3 του κώδικα ΦΠΑ<br/>
                2 = Χωρίς ΦΠΑ- άρθρο 5 του Κώδικα ΦΠΑ<br/>
                3 = Χωρίς ΦΠΑ- άρθρο 13 του Κώδικα ΦΠΑ<br/>
                4 = Χωρίς ΦΠΑ- άρθρο 14 του Κώδικα ΦΠΑ<br/>
                5 = Χωρίς ΦΠΑ- άρθρο 16 του Κώδικα ΦΠΑ<br/>
                6 = Χωρίς ΦΠΑ- άρθρο 19 του Κώδικα ΦΠΑ<br/>
                7 = Χωρίς ΦΠΑ- άρθρο 22 του Κώδικα ΦΠΑ<br/>
                8 = Χωρίς ΦΠΑ- άρθρο 24 του Κώδικα ΦΠΑ<br/>
                9 = Χωρίς ΦΠΑ- άρθρο 25 του Κώδικα ΦΠΑ<br/>
                10 = Χωρίς ΦΠΑ- άρθρο 26 του Κώδικα ΦΠΑ<br/>
                11 = Χωρίς ΦΠΑ- άρθρο 27 του Κώδικα ΦΠΑ<br/>
                12 = Χωρίς ΦΠΑ- άρθρο 27 -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ<br/>
                13 = Χωρίς ΦΠΑ- άρθρο 27.1.γ -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ<br/>
                14 = Χωρίς ΦΠΑ- άρθρο 28 του Κώδικα ΦΠΑ<br/>
                15 = Χωρίς ΦΠΑ- άρθρο 39 του Κώδικα ΦΠΑ<br/>
                16 = Χωρίς ΦΠΑ- άρθρο 39α του Κώδικα ΦΠΑ<br/>
                17 = Χωρίς ΦΠΑ- άρθρο 40 του Κώδικα ΦΠΑ<br/>
                18 = Χωρίς ΦΠΑ- άρθρο 41 του Κώδικα ΦΠΑ<br/>
                19 = Χωρίς ΦΠΑ- άρθρο 47 του Κώδικα ΦΠΑ<br/>
                20 = ΦΠΑ Εμπεριχόμενος - άρθρο 43 του Κώδικα ΦΠΑ<br/>
                21 = ΦΠΑ Εμπεριχόμενος - άρθρο 44 του Κώδικα ΦΠΑ<br/>
                22 = ΦΠΑ Εμπεριχόμενος - άρθρο 45 του Κώδικα ΦΠΑ<br/>
                23 = ΦΠΑ Εμπεριχόμενος - άρθρο 46 του Κώδικα ΦΠΑ<br/>
                24 = Χωρίς ΦΠΑ- άρθρο 6 του Κώδικα ΦΠΑ<br/>
                25 = Χωρίς ΦΠΑ - ΠΟΛ.1029/1995<br/>
                26 = Χωρίς ΦΠΑ - ΠΟΛ.1167/2015<br/>
                27 = Λοιπές Εξαιρέσεις ΦΠΑ<br/>
                28 = Χωρίς ΦΠΑ – άρθρο 24 περ. β' παρ.1 του Κώδικα ΦΠΑ, (Tax Free)<br/>
            `,
        },
        disabled: false,
    },
  {
    checkBox: true,
    fieldName: "Country",
    fieldKey: "country",
    required: true,
    column: null,
    tooltip: {
        title: "Χώρες ISO 3166",
        content:
        "Η χώρα πρέπει να ακολουθούν τη μορφή του ISO 3166. Περισσότερες λεπτομέρειες <a target='_blank' href='https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements'>εδώ. Για παράδειγμα η Ελλάδα είναι GR</a>"
    },
    disabled: true,
  },
  {
    checkBox: true,
    fieldName: "City",
    fieldKey: "city",
    required: true,
    column: null,
    tooltip: null,
    disabled: true,
  },
  {
    checkBox: true,
    fieldName: "Address",
    fieldKey: "address",
    required: true,
    column: null,
    tooltip: null,
    disabled: true,
  },
  {
    checkBox: true,
    fieldName: "Postal Code",
    fieldKey: "postalCode",
    required: true,
    column: null,
    tooltip: null,
    disabled: true,
  },
    {
    checkBox: false,
    fieldName: "DOY",
    fieldKey: "DOY",
    required: false,
    column: null,
    tooltip: {
      title: "Όλα τα ΔΟΥ",
      content: DoyOptionsStringified,
    },
    disabled: false,
    },
  {
    checkBox: true,
    fieldName: "Activity",
    fieldKey: "activity",
    required: true,
    column: null,
    tooltip: null,
    disabled: true,
  },
  {
    checkBox: false,
    fieldName: "GEMH",
    fieldKey: "GEMH",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },

  {
    checkBox: false,
    fieldName: "Webpage",
    fieldKey: "webpage",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Email",
    fieldKey: "email",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Phone",
    fieldKey: "phone",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "AMKA",
    fieldKey: "amka",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Notes",
    fieldKey: "clientNotes",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Contact Name",
    fieldKey: "contactName",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Contact Phone",
    fieldKey: "contactPhone",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Contact Phone 2",
    fieldKey: "contactPhone2",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Contact landline",
    fieldKey: "contactLandline",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Contact Email",
    fieldKey: "contactEmail",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Contact Notes",
    fieldKey: "contactNotes",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Contact Accept Notifications",
    fieldKey: "contactAcceptNotifications",
    required: false,
    column: null,
    tooltip: {
      title: "Κωδικές ονομασίες για αποδοχή ειδοποιήσεων",
      content: "1 = Δέχεται ειδοποιήσεις</br>2 = Δε δέχεται ειδοποιήσεις",
    },
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Installation No",
    fieldKey: "installationNo",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Installation address",
    fieldKey: "installationAddress",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Installation DOY",
    fieldKey: "installationDOY",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Installation city",
    fieldKey: "installationCity",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Installation Postal Code",
    fieldKey: "installationPostalCode",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Installation VAT Category",
    fieldKey: "installationVatCategory",
    required: false,
    column: null,
    tooltip: {
      title: "Κατηγορίες Φ.Π.Α. Εγκατάστασης",
      content: "1 = Normal</br>2 = Reduced</br>3 = Zero",
    },
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Installation Vat Reason",
    fieldKey: "installationVatReason",
    required: false,
    column: null,
    tooltip: {
      title: "Κατηγορίες Εξαίρεσης Φ.Π.Α. Εγκατάστασης",
      content: `1 = Χωρίς ΦΠΑ- άρθρο 2 και 3 του κώδικα ΦΠΑ<br/>
                2 = Χωρίς ΦΠΑ- άρθρο 5 του Κώδικα ΦΠΑ<br/>
                3 = Χωρίς ΦΠΑ- άρθρο 13 του Κώδικα ΦΠΑ<br/>
                4 = Χωρίς ΦΠΑ- άρθρο 14 του Κώδικα ΦΠΑ<br/>
                5 = Χωρίς ΦΠΑ- άρθρο 16 του Κώδικα ΦΠΑ<br/>
                6 = Χωρίς ΦΠΑ- άρθρο 19 του Κώδικα ΦΠΑ<br/>
                7 = Χωρίς ΦΠΑ- άρθρο 22 του Κώδικα ΦΠΑ<br/>
                8 = Χωρίς ΦΠΑ- άρθρο 24 του Κώδικα ΦΠΑ<br/>
                9 = Χωρίς ΦΠΑ- άρθρο 25 του Κώδικα ΦΠΑ<br/>
                10 = Χωρίς ΦΠΑ- άρθρο 26 του Κώδικα ΦΠΑ<br/>
                11 = Χωρίς ΦΠΑ- άρθρο 27 του Κώδικα ΦΠΑ<br/>
                12 = Χωρίς ΦΠΑ- άρθρο 27 -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ<br/>
                13 = Χωρίς ΦΠΑ- άρθρο 27.1.γ -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ<br/>
                14 = Χωρίς ΦΠΑ- άρθρο 28 του Κώδικα ΦΠΑ<br/>
                15 = Χωρίς ΦΠΑ- άρθρο 39 του Κώδικα ΦΠΑ<br/>
                16 = Χωρίς ΦΠΑ- άρθρο 39α του Κώδικα ΦΠΑ<br/>
                17 = Χωρίς ΦΠΑ- άρθρο 40 του Κώδικα ΦΠΑ<br/>
                18 = Χωρίς ΦΠΑ- άρθρο 41 του Κώδικα ΦΠΑ<br/>
                19 = Χωρίς ΦΠΑ- άρθρο 47 του Κώδικα ΦΠΑ<br/>
                20 = ΦΠΑ Εμπεριχόμενος - άρθρο 43 του Κώδικα ΦΠΑ<br/>
                21 = ΦΠΑ Εμπεριχόμενος - άρθρο 44 του Κώδικα ΦΠΑ<br/>
                22 = ΦΠΑ Εμπεριχόμενος - άρθρο 45 του Κώδικα ΦΠΑ<br/>
                23 = ΦΠΑ Εμπεριχόμενος - άρθρο 46 του Κώδικα ΦΠΑ<br/>
                24 = Χωρίς ΦΠΑ- άρθρο 6 του Κώδικα ΦΠΑ<br/>
                25 = Χωρίς ΦΠΑ - ΠΟΛ.1029/1995<br/>
                26 = Χωρίς ΦΠΑ - ΠΟΛ.1167/2015<br/>
                27 = Λοιπές Εξαιρέσεις ΦΠΑ<br/>
                28 = Χωρίς ΦΠΑ – άρθρο 24 περ. β' παρ.1 του Κώδικα ΦΠΑ, (Tax Free)<br/>`,
    },
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Installation Custom Vat",
    fieldKey: "installationCustomVat",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Accounting Bridge Prefix",
    fieldKey: "accounting",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Accounting Bridge Bypass Code",
    fieldKey: "bypassAccounting",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
];

const productFields = [
  {
    checkBox: true,
    fieldName: "Product Name",
    required: true,
    fieldKey: "name",
    column: null,
    tooltip: null,
    disabled: true,
  },
  {
    checkBox: false,
    fieldName: "Alternative Name",
    fieldKey: "alternativeName",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: true,
    fieldName: "Category",
    fieldKey: "category",
    required: true,
    column: null,
    tooltip: {
      title: "",
      content: "",
    },
    disabled: true,
  },
  {
    checkBox: false,
    fieldName: "Alternative Code",
    fieldKey: "alternativeCode",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Bar Code",
    fieldKey: "barCode",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: true,
    fieldName: "VAT",
    fieldKey: "vat",
    required: true,
    column: null,
    tooltip: {
      title: "Δεκτά % Φ.Π.Α.",
      content:
          "24</br> 13</br> 6</br> 17</br> 9</br> 4</br> 3</br> 0</br> -",
    },
    disabled: true,
  },
  {
    checkBox: true,
    fieldName: "Measurement Unit",
    fieldKey: "measurementUnit",
    required: true,
    column: null,
    tooltip: {
      title: "Κωδικοί μονάδων μέτρησης",
        content: "1 = Τεμάχια</br> 2 = Κιλά</br> 3 = Λίτρα</br> 4 = Υπηρεσία</br> 5 = Μέτρα</br> 6 = Τετραγωνικά Μέτρα</br> 7 = Κυβικά Μέτρα",
    },
    disabled: true,
  },
  {
    checkBox: false,
    fieldName: "0% VAT Reason",
    fieldKey: "vatReason",
    required: false,
    column: null,
    tooltip: {
      title: "Κωδικοί εξαίρεσης Φ.Π.Α. είδους",
      content: `1 = Χωρίς ΦΠΑ- άρθρο 2 και 3 του κώδικα ΦΠΑ<br/>
                2 = Χωρίς ΦΠΑ- άρθρο 5 του Κώδικα ΦΠΑ<br/>
                3 = Χωρίς ΦΠΑ- άρθρο 13 του Κώδικα ΦΠΑ<br/>
                4 = Χωρίς ΦΠΑ- άρθρο 14 του Κώδικα ΦΠΑ<br/>
                5 = Χωρίς ΦΠΑ- άρθρο 16 του Κώδικα ΦΠΑ<br/>
                6 = Χωρίς ΦΠΑ- άρθρο 19 του Κώδικα ΦΠΑ<br/>
                7 = Χωρίς ΦΠΑ- άρθρο 22 του Κώδικα ΦΠΑ<br/>
                8 = Χωρίς ΦΠΑ- άρθρο 24 του Κώδικα ΦΠΑ<br/>
                9 = Χωρίς ΦΠΑ- άρθρο 25 του Κώδικα ΦΠΑ<br/>
                10 = Χωρίς ΦΠΑ- άρθρο 26 του Κώδικα ΦΠΑ<br/>
                11 = Χωρίς ΦΠΑ- άρθρο 27 του Κώδικα ΦΠΑ<br/>
                12 = Χωρίς ΦΠΑ- άρθρο 27 -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ<br/>
                13 = Χωρίς ΦΠΑ- άρθρο 27.1.γ -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ<br/>
                14 = Χωρίς ΦΠΑ- άρθρο 28 του Κώδικα ΦΠΑ<br/>
                15 = Χωρίς ΦΠΑ- άρθρο 39 του Κώδικα ΦΠΑ<br/>
                16 = Χωρίς ΦΠΑ- άρθρο 39α του Κώδικα ΦΠΑ<br/>
                17 = Χωρίς ΦΠΑ- άρθρο 40 του Κώδικα ΦΠΑ<br/>
                18 = Χωρίς ΦΠΑ- άρθρο 41 του Κώδικα ΦΠΑ<br/>
                19 = Χωρίς ΦΠΑ- άρθρο 47 του Κώδικα ΦΠΑ<br/>
                20 = ΦΠΑ Εμπεριχόμενος - άρθρο 43 του Κώδικα ΦΠΑ<br/>
                21 = ΦΠΑ Εμπεριχόμενος - άρθρο 44 του Κώδικα ΦΠΑ<br/>
                22 = ΦΠΑ Εμπεριχόμενος - άρθρο 45 του Κώδικα ΦΠΑ<br/>
                23 = ΦΠΑ Εμπεριχόμενος - άρθρο 46 του Κώδικα ΦΠΑ<br/>
                24 = Χωρίς ΦΠΑ- άρθρο 6 του Κώδικα ΦΠΑ<br/>
                25 = Χωρίς ΦΠΑ - ΠΟΛ.1029/1995<br/>
                26 = Χωρίς ΦΠΑ - ΠΟΛ.1167/2015<br/>
                27 = Λοιπές Εξαιρέσεις ΦΠΑ<br/>
                28 = Χωρίς ΦΠΑ – άρθρο 24 περ. β' παρ.1 του Κώδικα ΦΠΑ, (Tax Free)<br/>`,
    },
    disabled: false,
  },
  {
    checkBox: true,
    fieldName: "Price",
    fieldKey: "price",
    required: true,
    column: null,
    tooltip: null,
    disabled: true,
  },
  {
    checkBox: true,
    fieldName: "VAT Included",
    fieldKey: "vatIncluded",
    required: true,
    column: null,
    tooltip: {
      title: "Κωδικοί Συμπερίληψης Φ.Π.Α. ",
      content: "1 = Περιέχεται</br>2 = Δεν περιέχεται",
    },
    disabled: true,
  },
  {
    checkBox: false,
    fieldName: "Product Notes",
    fieldKey: "productNotes",
    required: false,
    column: null,
    tooltip: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Fee Tax",
    fieldKey: "feeTax",
    required: false,
    column: null,
    tooltip: {
      title: "Κωδικοί Τελών",
      content: `
      1 = Για μηνιαίο λογαριασμό μέχρι και 50 ευρώ 12%</br>
      2 = Για μηνιαίο λογαριασμό από 50,01 μέχρι και 100 Ευρώ 15%</br>
      3 = Για μηνιαίο λογαριασμό από 100,01 μέχρι και 150 Ευρώ 18%</br>
      4 = Για μηνιαίο λογαριασμό από 150,01 ευρώ μέχρι και άνω 20%</br>
      5 = Τέλος καρτοκινητής επί της αξίας χρόνου ομιλίας (12%)</br>
      6 = Τέλος στη συνδρομητική τηλεόραση 10%</br>
      7 = Τέλος συνδρομητών σταθερής τηλεφωνίας 5%</br>
      8 = Περιβαλλοντικό Τέλος & πλαστικής σακούλας ν.2339/2001 αρ. 6α 0,07 ευρώ ανα τεμάχιο</br>
      9 = Εισφορά δακοκτονίας 2%</br>
      10 = Λοιπά τέλη</br>
      11 = Τέλη Λοιπών Φόρων</br>
      12 = Εισφορά δακοκτονίας</br>
      13 = Μηνιαίο λογαριασμό κάθε σύνδεσης (10%)</br>
      14 = Τέλος καρτοκινητής επί της αξίας του χρόνου ομιλίας (10%)</br>
      15 = Τέλος κινητής και καρτοκινητής για φυσικά πρόσωπα ηλικίας 15 έως και 29 ετών (0%)</br>
      16 = Εισφορά προστασίας περιβάλλοντος πλαστικών προϊόντων 0,04 λεπτά ανά τεμάχιο [άρθρο 4 ν. 4736/2020]</br>
      17 = Τέλος ανακύκλωσης 0,08 λεπτά ανά τεμάχιο [άρθρο 80 ν. 4819/2021]</br>
      18 = Τέλος διαμονής παρεπιδημούντων</br>
      19 = Τέλος επί των ακαθάριστων εσόδων των εστιατορίων και συναφών καταστημάτων</br>
      20 = Τέλος επί των ακαθάριστων εσόδων των κέντρων διασκέδασης</br>
      21 = Τέλος επί των ακαθάριστων εσόδων των καζίνο</br>
      22 = Λοιπά τέλη επί των ακαθάριστων εσόδων</br>
      `,
    },
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Other Tax",
    fieldKey: "otherTax",
    required: false,
    column: null,
    tooltip: {
      title: "Κωδικοί άλλων φόρων",
      content: `
      1 = α1) ασφάλιστρα κλάδου πυρός 20%</br>
      2 = α2) ασφάλιστρα κλάδου πυρός 20%</br>
      3 = β) ασφάλιστρα κλάδου ζωής 4%</br>
      4 = γ) ασφάλιστρα λοιπών κλάδων 15%</br>
      5 = δ) απαλλασσόμενα φόρου ασφαλίστρων 0%</br>
      6 = δ) απαλλασσόμενα φόρου ασφαλίστρων 0%</br>
      7 = Ξενοδοχεία 3 αστέρων 1,50€</br>
      8 = Ξενοδοχεία 4 αστέρων 3,00€</br>
      9 = Ξενοδοχεία 4 αστέρων 4,00€</br>
      10 = Ενοικιαζόμενα- Επιπλωμένα δωμάτια- διαμερίσματα 0,50€</br>
      11 = Ειδικός φόρος στις διαφημίσεις που προβάλλονται απο την τηλεόραση (ΕΦΤΔ) 5%</br>
      12 = 3.1 Φόρος Πολυτελείας 10% επί της φορολογούμενης αξίας για τα ενδοικοινοτικώς αποκτούμενα και εισαγόμενα από τρίτες χώρες</br>
      13 = 3.2 Φόρος πολυτελείας 10% επί της τιμής πώλησης προ ΦΠΑ για τα εγχωρίως παραγόμενα είδη</br>
      14 = Δικαίωμα του Δημοσίου στα εισιτήρια των καζίνο (80% επί του εισιτηρίου)</br>
      15 = ασφάλιστρα κλάδου πυρός 20%</br>
      16 = Λοιποί Τελωνειακοί Δασμοί-Φόροι</br>
      17 = Λοιποί Φόροι</br>
      18 = Επιβαρύνσεις Λοιπών Φόρων</br>
      19 = ΕΦΚ</br>
      `,
    },
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Withold Tax",
    fieldKey: "withholdTax",
    required: false,
    column: null,
    tooltip: {
      title: "Κωδικοί παρακρατούμενων φόρων",
      content: `
        1 = Περίπτ. β' - Τόκοι - 15%</br>
        2 = Περίπτ. Γ' - Δικαιώματα- 20%</br>
        3 = Περίπτ. Δ΄- Αμοιβές Συμβουλών Διοίκησης - 20%</br>
        4 = Περίπτ. δ'- Τεχνικά Έργα- 3%</br>
        5 = Υγρά καύσιμα και προϊόντα καπνοβιομηχανίας 1%</br>
        6 = Λοιπά Αγαθά 4%"</br>
        7 = Παροχή Υπηρεσιών 8%</br>
        8 = Προκαταβλητέος Φόρος Αρχιτεκτόνων και Μηχανικών επί συμβατικών Αμοιβών, για εκπόνηση μελετών και σχεδίων 4%</br>
        9 = Προκαταβλητέος Φόρος Αρχιτεκτόνων και Μηχανικών επί συμβατικών Αμοιβών, που αφορούν οποιασδήποτε άλλης φύσης έργα 10%</br>
        10 = Προκαταβλητέος Φόρος στις Αμοιβές  Δικηγόρων 15%</br>
        11 = Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.1 αρ. 15 ν. 4172/2013</br>
        12 = Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.2 αρ. 15 ν. 4172/2013 - Αξιωματικών Εμπορικού Ναυτικού</br>
        13 = Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.2 αρ. 15 ν. 4172/2013 - Κατώτερο Πλήρωμα Εμπορικού Ναυτικού</br>
        14 = Παρακράτηση Ειδικής εισφοράς αλληλεγγύης</br>
        15 = Παρακράτηση Φόρου Αποζημίωσης λόγω Διακοπής Σχέσης Εργασίας παρ.3 αρ. 15 ν. 4172/2013</br>
        16 = Παρακρατήσεις συναλλαγών αλλοδαπής βάσει συμβάσεων αποφυγής διπλής φορολογίας (Σ.Α.Δ.Φ.)</br>
        17 = Λοιπές Παρακρατήσεις Φόρου</br>
        18 = Παρακράτηση Φόρου Μερίσματα περ.α παρ. 1 αρ. 64 ν. 4172/2013</br>
      `,
    },
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Stamp Duty Tax",
    fieldKey: "stampDutyTax",
    required: false,
    column: null,
    tooltip: {
      title: "Κωδικοί χαρτοσήμων",
      content: `
      1 = Συντελεστής 1.2%</br>
      2 = Συντελεστής 2.4%</br>
      3 = Συντελεστής 3.6%</br>`,
    },
    disabled: false,
  },

  {
    checkBox: false,
    fieldName: "My Data Income Expense",
    fieldKey: "myDataIncExp",
    required: false,
    column: null,
    tooltip: {
        title: "Κωδικοί εσόδων/εξόδων",
        content: "1 = Έσοδο<br/>2 = Έξοδο"
    },
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "My Data Row Type",
    fieldKey: "myDataRowType",
    required: false,
    column: null,
    tooltip: {
      title: "Κωδικοί τύπων My Data",
      content: `
        E3_106 = Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις - Καταστροφές αποθεμάτων/Εμπορεύματα</br>
        E3_205 = Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις - Καταστροφές αποθεμάτων/Πρώτες ύλες και λοιπά υλικά</br>
        E3_210 = Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις - Καταστροφές αποθεμάτων/Προϊόντα και παραγωγή σε εξέλιξη</br>
        E3_305 = Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις – Καταστροφές αποθεμάτων/Πρώτες ύλες και λοιπά υλικά</br>
        E3_310 = Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις - Καταστροφές αποθεμάτων/Προϊόντα και παραγωγή σε εξέλιξη</br>
        E3_318 = Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις - Καταστροφές αποθεμάτων/Έξοδα παραγωγής</br>
        E3_561_001 = Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών</br>
        E3_561_002 = Πωλήσεις αγαθών και υπηρεσιών Χονδρικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)</br>
        E3_561_003 = Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία</br>
        E3_561_004 = Πωλήσεις αγαθών και υπηρεσιών Λιανικές βάσει άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α. (Ν.2859/2000)</br>
        E3_561_005 = Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές</br>
        E3_561_006 = Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες</br>
        E3_561_007 = Πωλήσεις αγαθών και υπηρεσιών Λοιπά</br>
        E3_562 = Λοιπά συνήθη έσοδα</br>
        E3_563 = Πιστωτικοί τόκοι και συναφή έσοδα</br>
        E3_564 = Πιστωτικές συναλλαγματικές διαφορές</br>
        E3_565 = Έσοδα συμμετοχών</br>
        E3_566 = Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων</br>
        E3_567 = Κέρδη από αναστροφή προβλέψεων και απομειώσεων</br>
        E3_568 = Κέρδη από επιμέτρηση στην εύλογη αξία</br>
        E3_570 = Ασυνήθη έσοδα και κέρδη</br>
        E3_595 = Έξοδα σε ιδιοπαραγωγή</br>
        E3_596 = Επιδοτήσεις - Επιχορηγήσεις</br>
        E3_597 = Επιδοτήσεις - Επιχορηγήσεις για επενδυτικούς σκοπούς - κάλυψη δαπανών</br>
        E3_880_001 = Πωλήσεις Παγίων Χονδρικές</br>
        E3_880_002 = Πωλήσεις Παγίων Λιανικές</br>
        E3_880_003 = Πωλήσεις Παγίων Εξωτερικού Ενδοκοινοτικές</br>
        E3_880_004 = Πωλήσεις Παγίων Εξωτερικού Τρίτες Χώρες</br>
        E3_881_001 = Πωλήσεις για λογ/σμο Τρίτων Χονδρικές</br>
        E3_881_002 = Πωλήσεις για λογ/σμο Τρίτων Λιανικές</br>
        E3_881_003 = Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές</br>
        E3_881_004 = Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες</br>
        E3_598_001 = Πωλήσεις αγαθών που υπάγονται σε ΕΦΚ</br>
        E3_598_003 = Πωλήσεις για λογαριασμό αγροτών μέσω αγροτικού συνεταιρισμού κ.λ.π.</br>
        `,
    },
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "My Data Row Category",
    fieldKey: "myDataRowCategory",
    required: false,
    column: null,
    tooltip: {
      title: "Κωδικοί κατηγοριών My Data",
      content: `
        category1_1 = Έσοδα από πώληση Εμπορευμάτων </br>
        category1_2 = Έσοδα από πώληση Προιόντων</br>
        category1_3 = Έσοδα από Παροχή Υπηρεσιών </br>
        category1_4 = Έσοδα από Πώληση Παγίων</br>
        category1_5 = Λοιπά Έσοδα/Κέρδη</br>
        category1_6 = Αυτοπαραδόσεις/ Ιδιοχρησιμοποιήσεις</br>
        category1_7 = Έσοδα από λογ/σμο τρίτων</br>
        category1_8 = Έσοδα προηγούμενων χρήσεων</br>
        category1_9 = Έσοδα επομένων χρήσεων</br>
        category1_10 = Λοιπές εγγραφές τακτοποίησης εσόδων</br> 
        category1_95 = Λοιπά Πληροφοριακά στοιχεία εσόδων</br>
      `,
    },
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "My Data Row Invoice Type",
    fieldKey: "myDataRowInvoiceType",
    required: false,
    column: null,
    tooltip: {
        title: "Κωδικοί τύπων παραστατικών My Data",
        content: `
            1.1 Τιμολόγιο Πώλησης</br>
            1.2 Τιμολόγιο Πώλησης / Ενδοκοινοτικές Παραδόσεις</br>
            1.3 Τιμολόγιο Πώλησης / Παραδόσεις Τρίτων Χωρών</br>
            1.4 Τιμολόγιο Πώλησης / Πώληση για Λογαριασμό Τρίτων</br>
            1.5 Τιμολόγιο Πώλησης / Εκκαθάριση Πωλήσεων Τρίτων - Αμοιβή από Πωλήσεις Τρίτων</br>
            1.6 Τιμολόγιο Πώλησης / Συμπληρωματικό Παραστατικό</br>
            2.1 Τιμολόγιο Παροχής</br>
            2.2 Τιμολόγιο Παροχής / Ενδοκοινοτική Παροχή Υπηρεσιών</br>
            2.3 Τιμολόγιο Παροχής / ΠαροχήΥπηρεσιών σε λήπτη Τρίτης Χώρας</br>
            2.4 Τιμολόγιο Παροχής / Συμπληρωματικό Παραστατικό</br>
            3.1 Τίτλος Κτήσης (μη υπόχρεος Εκδότης)</br>
            3.2 Τίτλος Κτήσης (άρνηση έκδοσης από υπόχρεο Εκδότη)</br>
            5.1 Πιστωτικό Τιμολόγιο / Συσχετιζόμενο</br>
            5.2 Πιστωτικό Τιμολόγιο / Μη Συσχετιζόμενο</br>
            6.1 Στοιχείο Αυτοπαράδοσης</br>
            6.2 Στοιχείο Ιδιοχρησιμοποίησης</br>
            7.1 Συμβόλαιο - Έσοδο</br>
            8.1 Ενοίκια - Έσοδο</br>
            8.2 Ειδικό Στοιχείο – Απόδειξης Είσπραξης Φόρου Διαμονής</br>
            11.1 ΑΛΠ</br>
            11.2 ΑΠΥ</br>
            11.3 Απλοποιημένο Τιμολόγιο</br>
            11.4 Πιστωτικό Στοιχ. Λιανικής</br>
            11.5 Απόδειξη Λιανικής Πώλησης για Λογ/σμό Τρίτων</br>
            13.1 Έξοδα - Αγορές Λιανικών Συναλλαγών ημεδαπής / αλλοδαπής</br>
            13.2 Παροχή Λιανικών Συναλλαγών ημεδαπής / αλλοδαπής</br>
            13.3 Κοινόχρηστα</br>
            13.4 Συνδρομές</br>
            13.30 Παραστατικά Οντότητας ως Αναγράφονται από την ίδια (Δυναμικό)</br>
            13.31 Πιστωτικό Στοιχ. Λιανικής ημεδαπής / αλλοδαπής</br>
            14.1 Τιμολόγιο / Ενδοκοινοτικές Αποκτήσεις</br>
            14.2 Τιμολόγιο / Αποκτήσεις Τρίτων Χωρών</br>
            14.3 Τιμολόγιο / Ενδοκοινοτική Λήψη Υπηρεσιών</br>
            14.4 Τιμολόγιο / Λήψη Υπηρεσιών Τρίτων Χωρών</br>
            14.5 ΕΦΚΑ και λοιποί Ασφαλιστικοί Οργανισμοί</br>
            14.30 Παραστατικά Οντότητας ως Αναγράφονται από την ίδια (Δυναμικό)</br>
            14.31 Πιστωτικό ημεδαπής / αλλοδαπής</br>
            15.1 Συμβόλαιο - Έξοδο</br>
            16.1 Ενοίκιο Έξοδο</br>
            17.1 Μισθοδοσία</br>
            17.2 Αποσβέσεις</br>
            17.3 Λοιπές Εγγραφές Τακτοποίησης Εσόδων - Λογιστική Βάση</br>
            17.4 Λοιπές Εγγραφές Τακτοποίησης Εσόδων - Φορολογική Βάση</br>
            17.5 Λοιπές Εγγραφές Τακτοποίησης Εξόδων - Λογιστική Βάση</br>
            17.6 Λοιπές Εγγραφές Τακτοποίησης Εξόδων - Φορολογική Βάση</br>
        `,
    },
    disabled: false,
  },
  {
      checkBox: false,
      fieldName: "My Data Row VAT Expense Type",
      fieldKey: "myDataRowVatExpenseType",
      required: false,
      column: null,
      tooltip: {
          title: "Τύποι Εξόδων Φ.Π.Α.",
          content: `
            Άδειο αν πρόκειται για έσοδο<br>
            VAT_361 = Αγορές & δαπάνες στο εσωτερικό της χώρας<br>
            VAT_362 = Αγορές & εισαγωγές επενδ. Αγαθών (πάγια)<br>
            VAT_363 = Λοιπές εισαγωγές εκτός επενδ. Αγαθών (πάγια)<br>
            VAT_364 = Ενδοκοινοτικές αποκτήσεις αγαθών<br>
            VAT_365 = Ενδοκοινοτικές λήψεις υπηρεσιών άρθρ. 14.2.α<br>
            VAT_366 = Λοιπές πράξεις λήπτη<br>
            NOT_VAT_295 = Μη συμμετοχή στο ΦΠΑ (έξοδα – εισροές)<br>
          `
      },
      disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Accounting Code",
    fieldKey: "accountingCode",
    required: false,
    column: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Net Amount Accounting Code",
    fieldKey: "netAmountAccountingCode",
    required: false,
    column: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Vat Accounting Code",
    fieldKey: "vatAccountingCode",
    required: false,
    column: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Tax Accounting Code",
    fieldKey: "taxAccountCode",
    required: false,
    column: null,
    disabled: false,
  },
  {
    checkBox: false,
    fieldName: "Accounting Non Deductible Vat",
    fieldKey: "accountingNonDeductibleVAT",
    required: false,
    column: null,
    tooltip: {
        title: "Μη εκπιπτώμενος Φ.Π.Α.",
        content: `
            1 = Ναι,<br/>
            2 = Όχι
        `,
    },
    disabled: false,
  },
];

const clientPaymentsFields = [
    {
      checkBox: true,
      fieldName: "Document Type Name",
      required: true,
      fieldKey: "documentTypeName",
      column: null,
      tooltip: {
          title: ``,
          content: ``
      },
      disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Installation Master ID",
        required: true,
        fieldKey: "installation",
        column: null,
        tooltip: {
            title: ``,
            content: ``
        },
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Serial Number",
        required: true,
        fieldKey: "serialNumber",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Number",
        required: true,
        fieldKey: "number",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Client VAT Number",
        required: true,
        fieldKey: "vatNumber",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Payment Date",
        required: true,
        fieldKey: "paymentDate",
        column: null,
        tooltip:
            {
                title: `Μορφή ημερομηνίας`,
                content: `Η ημερομηνία πρέπει να έχει την ακόλουθη μορφή: ΗΗ/ΜΜ/ΧΧΧΧ (Ημέρα/Μήνας/Χρονιά)<br/> Για παράδειγμα, 23/06/2023`
            },
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Payment Reason",
        required: false,
        fieldKey: "paymentReason",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Payment Notes",
        required: false,
        fieldKey: "paymentNotes",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Payment Type ID",
        required: true,
        fieldKey: "paymentType",
        column: null,
        tooltip: {
            title: "",
            content: ""
        },
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Bank Number",
        required: false,
        fieldKey: "bankNumber",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "POS serial number",
        required: false,
        fieldKey: "posSerial",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Amount",
        required: true,
        fieldKey: "amount",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Cheque First Date",
        required: false,
        fieldKey: "chequeFirstDate",
        column: null,
        tooltip: {
            title: `Μορφή ημερομηνίας`,
            content: `Η ημερομηνία πρέπει να έχει την ακόλουθη μορφή: ΗΗ/ΜΜ/ΧΧΧΧ (Ημέρα/Μήνας/Χρονιά)<br/> Για παράδειγμα, 23/06/2023`
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Cheque Last Date",
        required: false,
        fieldKey: "chequeLastDate",
        column: null,
        tooltip: {
            title: `Μορφή ημερομηνίας`,
            content: `Η ημερομηνία πρέπει να έχει την ακόλουθη μορφή: ΗΗ/ΜΜ/ΧΧΧΧ (Ημέρα/Μήνας/Χρονιά)<br/> Για παράδειγμα, 23/06/2023`
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Client Phone",
        required: false,
        fieldKey: "clientPhone",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Client Email",
        required: false,
        fieldKey: "clientEmail",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Client AMKA",
        required: false,
        fieldKey: "clientAMKA",
        column: null,
        tooltip: null,
        disabled: false,
    },
];
const clientMovementsFields = [
    {
        checkBox: true,
        fieldName: "Document Type Name",
        required: true,
        fieldKey: "documentTypeName",
        column: null,
        tooltip: {
            title: "",
            content: ""
        },
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Installation Master ID",
        required: true,
        fieldKey: "installationMasterID",
        column: null,
        tooltip: {
            title: "",
            content: ""
        },
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Serial Number",
        required: true,
        fieldKey: "serialNumber",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Number",
        required: true,
        fieldKey: "number",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Date",
        required: true,
        fieldKey: "date",
        column: null,
        tooltip: {
            title: `Μορφή ημερομηνίας`,
            content: `Η ημερομηνία πρέπει να έχει την ακόλουθη μορφή: ΗΗ/ΜΜ/ΧΧΧΧ (Ημέρα/Μήνας/Χρονιά)<br/> Για παράδειγμα, 23/06/2023`
        },
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Reason",
        required: false,
        fieldKey: "reason",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Client VAT Number",
        required: true,
        fieldKey: "clientVATNumber",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Client Movement Amount",
        required: true,
        fieldKey: "clientMovementAmount",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Notes",
        required: false,
        fieldKey: "notes",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Client Phone",
        required: false,
        fieldKey: "clientPhone",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Client Email",
        required: false,
        fieldKey: "clientEmail",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Client AMKA",
        required: false,
        fieldKey: "clientAMKA",
        column: null,
        tooltip: null,
        disabled: false,
    },
]

const salesFields = [
    {
        checkBox: true,
        fieldName: "Document Type Name",
        required: true,
        fieldKey: "documentTypeName",
        column: null,
        tooltip: {
            title: "",
            content: ""
        },
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Sale Date",
        required: true,
        fieldKey: "date",
        column: null,
        tooltip: {
            title: `Μορφή ημερομηνίας`,
            content: `Η ημερομηνία πρέπει να έχει την ακόλουθη μορφή: ΗΗ/ΜΜ/ΧΧΧΧ (Ημέρα/Μήνας/Χρονιά)<br/> Για παράδειγμα, 23/06/2023`
        },
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Payment Time",
        required: false,
        fieldKey: "time",
        column: null,
        tooltip:
            {
                title: `Μορφή ώρας και λεπτών`,
                content: `Η ώρα πρέπει να έχει μορφή HH:MM, παράδειγμα 17:06 . Αν δεν επιλέξετε αυτό το πεδίο θα εισαχθεί η ώρα 00:00`
            },
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Installation Master ID",
        required: true,
        fieldKey: "installationMasterID",
        column: null,
        tooltip: {
            title: "",
            content: ""
        },
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Serial Number",
        required: true,
        fieldKey: "serialNumber",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Number",
        required: true,
        fieldKey: "number",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Client VAT Number",
        required: true,
        fieldKey: "clientVATNumber",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Client Phone",
        required: false,
        fieldKey: "clientPhone",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Client Email",
        required: false,
        fieldKey: "clientEmail",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Client AMKA",
        required: false,
        fieldKey: "clientAMKA",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Client Installation",
        required: false,
        fieldKey: "clientInstallation",
        column: null,
        tooltip: {
            title: "",
            content: ""
        },
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Payment Type",
        required: true,
        fieldKey: "paymentType",
        column: null,
        tooltip: {
            title: "",
            content: ""
        },
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Bank Number",
        required: false,
        fieldKey: "bankNumber",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "POS serial number",
        required: false,
        fieldKey: "posSerial",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Product Name",
        required: true,
        fieldKey: "productName",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Quantity",
        required: true,
        fieldKey: "quantity",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Unit Price",
        required: true,
        fieldKey: "unitPrice",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Discount",
        required: false,
        fieldKey: "discount",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Net Value",
        required: true,
        fieldKey: "netValue",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "VAT",
        required: true,
        fieldKey: "vat",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "VAT Percentage",
        required: true,
        fieldKey: "vatPercentage",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "0% VAT Reason",
        required: false,
        fieldKey: "vatReason",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Total Value",
        required: true,
        fieldKey: "totalValue",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Is Tax Line",
        required: false,
        fieldKey: "isTaxLine",
        column: null,
        tooltip: {
            title: "Κωδικοί φόρου γραμμής",
            content: "1 = Είναι Φόρος Γραμμή<br/>2 = Δεν είναι φόρος γραμμή"
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Product Extra Tax Type",
        required: false,
        fieldKey: "productExtraTaxType",
        column: null,
        tooltip: {
            title: "Τύποι Επιπλέον Φόρων",
            content: "1 = Άλλοι Φόροι<br/>2 = Χαρτόσημο<br/>3 = Τέλη<br/>4 = Παρακράτηση Φόρου",
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Product Extra Tax Code",
        required: false,
        fieldKey: "productExtraTaxCode",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Product Extra Tax Amount",
        required: false,
        fieldKey: "productExtraTaxAmount",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "My Data UID",
        required: false,
        fieldKey: "myDataUID",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "My Data Mark",
        required: false,
        fieldKey: "myDataMark",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "My Data Auth Code",
        required: false,
        fieldKey: "myDataAuthCode",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Extra Data Tax Type",
        required: false,
        fieldKey: "extraDataTaxType",
        column: null,
        tooltip: {
            title: "Τύποι Επιπλέον Φόρων",
            content: "1 = Άλλοι Φόροι<br/>2 = Χαρτόσημο<br/>3 = Τέλη<br/>4 = Παρακράτηση Φόρου",
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Extra Data Tax Code",
        required: false,
        fieldKey: "extraDataTaxCode",
        column: null,
        tooltip: {
            title: "",
            content: "",
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Extra Data Tax Amount",
        required: false,
        fieldKey: "extraDataTaxAmount",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Delivery Location",
        required: false,
        fieldKey: "deliveryLocation",
        column: null,
        tooltip: {
            title: "Σημείο Παράδοσης",
            content: "Αν δεν επιλεχτεί το πεδίο αυτόματα μπαίνει η έδρα της εταιρείας. Υπάρχουν 3 επιλογές:<br/>" +
                "Company Location = Έδρα εταιρείας<br/>" +
                "Client Location = Έδρα πελάτη<br/>" +
                "Custom = Ειδική διεύθυνση<br/>" +
                "Σε περίπτωση της επιλογής custom, συμπληρώνετε και τα επόμενα 4 πεδία. Στις πρώτες δύο επιλογές, τα στοιχεία συμπληρώνονται αυτόματα και δεν λαμβάνονται υπόψιν τα επόμενα πεδία."
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Delivery Location Address",
        required: false,
        fieldKey: "deliveryLocationAddress",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Delivery Location City",
        required: false,
        fieldKey: "deliveryLocationCity",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Delivery Location Country",
        required: false,
        fieldKey: "deliveryLocationCountry",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Delivery Location Postal Code",
        required: false,
        fieldKey: "deliveryLocationPostalCode",
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Way of sending goods",
        required: false,
        fieldKey: "wayOfSendingGoods",
        column: null,
        tooltip: {
            title: "",
            content: "",
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Car Number",
        required: false,
        fieldKey: "carNumber",
        column: null,
        tooltip: {
            title: "",
            content: "",
        },
        disabled: false,
    }
]
const posFields = [
    {
        checkBox: true,
        fieldName: "Name",
        required: true,
        fieldKey: "name",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Date",
        required: false,
        fieldKey: "date",
        column: null,
        tooltip: {
            title: `Μορφή ημερομηνίας`,
            content: `Η ημερομηνία πρέπει να έχει την ακόλουθη μορφή: ΗΗ/ΜΜ/ΧΧΧΧ (Ημέρα/Μήνας/Χρονιά)<br/> Για παράδειγμα, 23/06/2023. Αν δεν επιλέξετε το πεδίο, θα εισαχθεί αυτόματα η σημερινή.`
        },
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Serial Number",
        required: true,
        fieldKey: "serialNumber",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Send to AADE",
        required: false,
        fieldKey: "sendToAADE",
        column: null,
        tooltip: {
            title: "Κωδικοί αποστολής στη ΑΑΔΕ",
            content: "1 = Αποστέλεται<br/>2 = Δεν αποστέλεται"
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Notes",
        required: false,
        fieldKey: "notes",
        column: null,
        tooltip: null,
        disabled: false,
    },
]
const cashRegisterFields = [
    {
        checkBox: true,
        fieldName: "Name",
        required: true,
        fieldKey: "name",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Date",
        required: false,
        fieldKey: "date",
        column: null,
        tooltip: {
            title: `Μορφή ημερομηνίας`,
            content: `Η ημερομηνία πρέπει να έχει την ακόλουθη μορφή: ΗΗ/ΜΜ/ΧΧΧΧ (Ημέρα/Μήνας/Χρονιά)<br/> Για παράδειγμα, 23/06/2023. Αν δεν επιλέξετε το πεδίο, θα εισαχθεί αυτόματα η σημερινή.`
        },
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Serial Number",
        required: true,
        fieldKey: "serialNumber",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Send to AADE",
        required: false,
        fieldKey: "sendToAADE",
        column: null,
        tooltip: {
            title: "Κωδικοί αποστολής στη ΑΑΔΕ",
            content: "1 = Αποστέλεται<br/>2 = Δεν αποστέλεται"
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Notes",
        required: false,
        fieldKey: "notes",
        column: null,
        tooltip: null,
        disabled: false,
    },
]

const bankAccountsFields = [
    {
        checkBox: true,
        fieldName: "Name",
        required: true,
        fieldKey: "name",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Bank Swift",
        required: true,
        fieldKey: "bankSwift",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Date",
        required: false,
        fieldKey: "date",
        column: null,
        tooltip: {
            title: `Μορφή ημερομηνίας`,
            content: `Η ημερομηνία πρέπει να έχει την ακόλουθη μορφή: ΗΗ/ΜΜ/ΧΧΧΧ (Ημέρα/Μήνας/Χρονιά)<br/> Για παράδειγμα, 23/06/2023. Αν δεν επιλέξετε το πεδίο, θα εισαχθεί αυτόματα η σημερινή.`
        },
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "IBAN",
        required: true,
        fieldKey: "iban",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Send to AADE",
        required: false,
        fieldKey: "sendToAADE",
        column: null,
        tooltip: {
            title: "Κωδικοί αποστολής στη ΑΑΔΕ",
            content: "1 = Αποστέλεται<br/>2 = Δεν αποστέλεται"
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Notes",
        required: false,
        fieldKey: "notes",
        column: null,
        tooltip: null,
        disabled: false,
    },
]

const supplierFields = [
    {
        checkBox: true,
        fieldName: "Name",
        required: true,
        fieldKey: "name",
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Small Name",
        fieldKey: "smallName",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Manual Code",
        fieldKey: "manualCode",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Category",
        fieldKey: "category",
        required: true,
        column: null,
        tooltip: {
            title: "Κατηγορίες Προμηθευτών",
            content: `1 = Retail</br>
            2 = Freelancer</br> 
            3 = O.E</br>
            4 = E.E.</br>
            5 = E.P.E</br>
            6 = I.K.E</br>
            7 = A.E.</br>
            9 = Non-Profit Organization</br>
            10 = Supplier within E.U.</br>
            11 = Supplier outside E.U.</br>
            12 = Wholesale Others</br>`,
        },
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "VAT Number",
        fieldKey: "vatNumber",
        required: true,
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "VAT Category",
        fieldKey: "vatCategory",
        required: true,
        column: null,
        tooltip: {
            title: "Κατηγορίες Φ.Π.Α.",
            content:
                "1 = Normal VAT</br>2 = Reduced VAT</br>3 = Zero VAT",
        },
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "VAT Reason",
        fieldKey: "vatReason",
        required: false,
        column: null,
        tooltip: {
            title: "Κατηγορίες Εξαίρεσης Φ.Π.Α.",
            content: `
                1 = Χωρίς ΦΠΑ- άρθρο 2 και 3 του κώδικα ΦΠΑ<br/>
                2 = Χωρίς ΦΠΑ- άρθρο 5 του Κώδικα ΦΠΑ<br/>
                3 = Χωρίς ΦΠΑ- άρθρο 13 του Κώδικα ΦΠΑ<br/>
                4 = Χωρίς ΦΠΑ- άρθρο 14 του Κώδικα ΦΠΑ<br/>
                5 = Χωρίς ΦΠΑ- άρθρο 16 του Κώδικα ΦΠΑ<br/>
                6 = Χωρίς ΦΠΑ- άρθρο 19 του Κώδικα ΦΠΑ<br/>
                7 = Χωρίς ΦΠΑ- άρθρο 22 του Κώδικα ΦΠΑ<br/>
                8 = Χωρίς ΦΠΑ- άρθρο 24 του Κώδικα ΦΠΑ<br/>
                9 = Χωρίς ΦΠΑ- άρθρο 25 του Κώδικα ΦΠΑ<br/>
                10 = Χωρίς ΦΠΑ- άρθρο 26 του Κώδικα ΦΠΑ<br/>
                11 = Χωρίς ΦΠΑ- άρθρο 27 του Κώδικα ΦΠΑ<br/>
                12 = Χωρίς ΦΠΑ- άρθρο 27 -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ<br/>
                13 = Χωρίς ΦΠΑ- άρθρο 27.1.γ -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ<br/>
                14 = Χωρίς ΦΠΑ- άρθρο 28 του Κώδικα ΦΠΑ<br/>
                15 = Χωρίς ΦΠΑ- άρθρο 39 του Κώδικα ΦΠΑ<br/>
                16 = Χωρίς ΦΠΑ- άρθρο 39α του Κώδικα ΦΠΑ<br/>
                17 = Χωρίς ΦΠΑ- άρθρο 40 του Κώδικα ΦΠΑ<br/>
                18 = Χωρίς ΦΠΑ- άρθρο 41 του Κώδικα ΦΠΑ<br/>
                19 = Χωρίς ΦΠΑ- άρθρο 47 του Κώδικα ΦΠΑ<br/>
                20 = ΦΠΑ Εμπεριχόμενος - άρθρο 43 του Κώδικα ΦΠΑ<br/>
                21 = ΦΠΑ Εμπεριχόμενος - άρθρο 44 του Κώδικα ΦΠΑ<br/>
                22 = ΦΠΑ Εμπεριχόμενος - άρθρο 45 του Κώδικα ΦΠΑ<br/>
                23 = ΦΠΑ Εμπεριχόμενος - άρθρο 46 του Κώδικα ΦΠΑ<br/>
                24 = Χωρίς ΦΠΑ- άρθρο 6 του Κώδικα ΦΠΑ<br/>
                25 = Χωρίς ΦΠΑ - ΠΟΛ.1029/1995<br/>
                26 = Χωρίς ΦΠΑ - ΠΟΛ.1167/2015<br/>
                27 = Λοιπές Εξαιρέσεις ΦΠΑ<br/>
                28 = Χωρίς ΦΠΑ – άρθρο 24 περ. β' παρ.1 του Κώδικα ΦΠΑ, (Tax Free)<br/>
            `,
        },
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Country",
        fieldKey: "country",
        required: true,
        column: null,
        tooltip: {
            title: "Χώρες ISO 3166",
            content:
                "Η χώρα πρέπει να ακολουθούν τη μορφή του ISO 3166. Περισσότερες λεπτομέρειες <a target='_blank' href='https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements'>εδώ. Για παράδειγμα, η Ελλάδα είναι GR</a>"
        },
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "City",
        fieldKey: "city",
        required: true,
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Address",
        fieldKey: "address",
        required: true,
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Postal Code",
        fieldKey: "postalCode",
        required: true,
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "DOY",
        fieldKey: "DOY",
        required: false,
        column: null,
        tooltip: {
            title: "Όλα τα ΔΟΥ",
            content: DoyOptionsStringified,
        },
        disabled: false,
    },
    {
        checkBox: true,
        fieldName: "Activity",
        fieldKey: "activity",
        required: true,
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "GEMH",
        fieldKey: "GEMH",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },

    {
        checkBox: false,
        fieldName: "Webpage",
        fieldKey: "webpage",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Email",
        fieldKey: "email",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Phone",
        fieldKey: "phone",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "AMKA",
        fieldKey: "amka",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Notes",
        fieldKey: "notes",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Contact Name",
        fieldKey: "contactName",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Contact Phone",
        fieldKey: "contactPhone",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Contact Phone 2",
        fieldKey: "contactPhone2",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Contact landline",
        fieldKey: "contactLandline",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Contact Email",
        fieldKey: "contactEmail",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Contact Notes",
        fieldKey: "contactNotes",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Contact Accept Notifications",
        fieldKey: "contactAcceptNotifications",
        required: false,
        column: null,
        tooltip: {
            title: "Κωδικές ονομασίες για αποδοχή ειδοποιήσεων",
            content: "1 = Δέχεται ειδοποιήσεις</br>2 = Δε δέχεται ειδοποιήσεις",
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Installation No",
        fieldKey: "installationNo",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Installation address",
        fieldKey: "installationAddress",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Installation DOY",
        fieldKey: "installationDOY",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Installation city",
        fieldKey: "installationCity",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Installation Postal Code",
        fieldKey: "installationPostalCode",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Installation VAT Category",
        fieldKey: "installationVatCategory",
        required: false,
        column: null,
        tooltip: {
            title: "Κατηγορίες Φ.Π.Α. Εγκατάστασης",
            content: "1 = Normal</br>2 = Reduced</br>3 = Zero",
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Installation Vat Reason",
        fieldKey: "installationVatReason",
        required: false,
        column: null,
        tooltip: {
            title: "Κατηγορίες Εξαίρεσης Φ.Π.Α. Εγκατάστασης",
            content: `1 = Χωρίς ΦΠΑ- άρθρο 2 και 3 του κώδικα ΦΠΑ<br/>
                2 = Χωρίς ΦΠΑ- άρθρο 5 του Κώδικα ΦΠΑ<br/>
                3 = Χωρίς ΦΠΑ- άρθρο 13 του Κώδικα ΦΠΑ<br/>
                4 = Χωρίς ΦΠΑ- άρθρο 14 του Κώδικα ΦΠΑ<br/>
                5 = Χωρίς ΦΠΑ- άρθρο 16 του Κώδικα ΦΠΑ<br/>
                6 = Χωρίς ΦΠΑ- άρθρο 19 του Κώδικα ΦΠΑ<br/>
                7 = Χωρίς ΦΠΑ- άρθρο 22 του Κώδικα ΦΠΑ<br/>
                8 = Χωρίς ΦΠΑ- άρθρο 24 του Κώδικα ΦΠΑ<br/>
                9 = Χωρίς ΦΠΑ- άρθρο 25 του Κώδικα ΦΠΑ<br/>
                10 = Χωρίς ΦΠΑ- άρθρο 26 του Κώδικα ΦΠΑ<br/>
                11 = Χωρίς ΦΠΑ- άρθρο 27 του Κώδικα ΦΠΑ<br/>
                12 = Χωρίς ΦΠΑ- άρθρο 27 -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ<br/>
                13 = Χωρίς ΦΠΑ- άρθρο 27.1.γ -Πλοία Ανοικτής Θαλάσσης του Κώδικα ΦΠΑ<br/>
                14 = Χωρίς ΦΠΑ- άρθρο 28 του Κώδικα ΦΠΑ<br/>
                15 = Χωρίς ΦΠΑ- άρθρο 39 του Κώδικα ΦΠΑ<br/>
                16 = Χωρίς ΦΠΑ- άρθρο 39α του Κώδικα ΦΠΑ<br/>
                17 = Χωρίς ΦΠΑ- άρθρο 40 του Κώδικα ΦΠΑ<br/>
                18 = Χωρίς ΦΠΑ- άρθρο 41 του Κώδικα ΦΠΑ<br/>
                19 = Χωρίς ΦΠΑ- άρθρο 47 του Κώδικα ΦΠΑ<br/>
                20 = ΦΠΑ Εμπεριχόμενος - άρθρο 43 του Κώδικα ΦΠΑ<br/>
                21 = ΦΠΑ Εμπεριχόμενος - άρθρο 44 του Κώδικα ΦΠΑ<br/>
                22 = ΦΠΑ Εμπεριχόμενος - άρθρο 45 του Κώδικα ΦΠΑ<br/>
                23 = ΦΠΑ Εμπεριχόμενος - άρθρο 46 του Κώδικα ΦΠΑ<br/>
                24 = Χωρίς ΦΠΑ- άρθρο 6 του Κώδικα ΦΠΑ<br/>
                25 = Χωρίς ΦΠΑ - ΠΟΛ.1029/1995<br/>
                26 = Χωρίς ΦΠΑ - ΠΟΛ.1167/2015<br/>
                27 = Λοιπές Εξαιρέσεις ΦΠΑ<br/>
                28 = Χωρίς ΦΠΑ – άρθρο 24 περ. β' παρ.1 του Κώδικα ΦΠΑ, (Tax Free)<br/>`,
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Installation Custom Vat",
        fieldKey: "installationCustomVat",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Accounting Bridge Prefix",
        fieldKey: "accounting",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Accounting Bridge Bypass Code",
        fieldKey: "bypassAccounting",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
]

const accountingAccountFields = [
    {
        checkBox: true,
        fieldName: "Category",
        fieldKey: "category",
        required: true,
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Code",
        fieldKey: "code",
        required: true,
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: true,
        fieldName: "Name",
        fieldKey: "name",
        required: true,
        column: null,
        tooltip: null,
        disabled: true,
    },
    {
        checkBox: false,
        fieldName: "Alternative Name",
        fieldKey: "alternativeName",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Movable",
        fieldKey: "movable",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Transferable",
        fieldKey: "transferable",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Is Net Value",
        fieldKey: "isNetValue",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Is VAT",
        fieldKey: "vat",
        required: false,
        column: null,
        tooltip: null,
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Is Tax",
        fieldKey: "taxType",
        required: false,
        column: null,
        tooltip: {
            title: "Κωδικοί φόρων",
            content: "Κενό<br/>Fee = Τέλος<br/>Withhold = Παρακρατούμενοι Φόροι<br/>Other = Άλλοι φόροι<br/>Stamp = Χαρτόσημο<br/>"
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Sub Account",
        fieldKey: "subAccount",
        required: false,
        column: null,
        tooltip: {
            title: "Κωδικοί υποκατηγοριών",
            content: "Κενό = Δεν υποστηρίζει<br/>" +
                "Installation = Εγκατάσταση<br/>" +
                "Client = Πελάτες<br/>" +
                "Supplier = Προμηθευτές<br/>" +
                "Employee = Εργαζόμενοι<br/>" +
                "Bank Account = Τραπεζικός Λογαριασμός<br/>" +
                "POS <br/>" +
                "Cash Register = Ταμειακή Μηχανή"
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Fee Tax",
        fieldKey: "fee",
        required: false,
        column: null,
        tooltip: {
            title: "Κωδικοί Τελών",
            content: `
                  1 = Για μηνιαίο λογαριασμό μέχρι και 50 ευρώ 12%</br>
                  2 = Για μηνιαίο λογαριασμό από 50,01 μέχρι και 100 Ευρώ 15%</br>
                  3 = Για μηνιαίο λογαριασμό από 100,01 μέχρι και 150 Ευρώ 18%</br>
                  4 = Για μηνιαίο λογαριασμό από 150,01 ευρώ μέχρι και άνω 20%</br>
                  5 = Τέλος καρτοκινητής επί της αξίας χρόνου ομιλίας (12%)</br>
                  6 = Τέλος στη συνδρομητική τηλεόραση 10%</br>
                  7 = Τέλος συνδρομητών σταθερής τηλεφωνίας 5%</br>
                  8 = Περιβαλλοντικό Τέλος & πλαστικής σακούλας ν.2339/2001 αρ. 6α 0,07 ευρώ ανα τεμάχιο</br>
                  9 = Εισφορά δακοκτονίας 2%</br>
                  10 = Λοιπά τέλη</br>
                  11 = Τέλη Λοιπών Φόρων</br>
                  12 = Εισφορά δακοκτονίας</br>
                  13 = Μηνιαίο λογαριασμό κάθε σύνδεσης (10%)</br>
                  14 = Τέλος καρτοκινητής επί της αξίας του χρόνου ομιλίας (10%)</br>
                  15 = Τέλος κινητής και καρτοκινητής για φυσικά πρόσωπα ηλικίας 15 έως και 29 ετών (0%)</br>
                  16 = Εισφορά προστασίας περιβάλλοντος πλαστικών προϊόντων 0,04 λεπτά ανά τεμάχιο [άρθρο 4 ν. 4736/2020]</br>
                  17 = Τέλος ανακύκλωσης 0,08 λεπτά ανά τεμάχιο [άρθρο 80 ν. 4819/2021]</br>
                  18 = Τέλος διαμονής παρεπιδημούντων</br>
                  19 = Τέλος επί των ακαθάριστων εσόδων των εστιατορίων και συναφών καταστημάτων</br>
                  20 = Τέλος επί των ακαθάριστων εσόδων των κέντρων διασκέδασης</br>
                  21 = Τέλος επί των ακαθάριστων εσόδων των καζίνο</br>
                  22 = Λοιπά τέλη επί των ακαθάριστων εσόδων</br>
                  `,
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Other Tax",
        fieldKey: "otherTax",
        required: false,
        column: null,
        tooltip: {
            title: "Κωδικοί άλλων φόρων",
            content: `
              1 = α1) ασφάλιστρα κλάδου πυρός 20%</br>
              2 = α2) ασφάλιστρα κλάδου πυρός 20%</br>
              3 = β) ασφάλιστρα κλάδου ζωής 4%</br>
              4 = γ) ασφάλιστρα λοιπών κλάδων 15%</br>
              5 = δ) απαλλασσόμενα φόρου ασφαλίστρων 0%</br>
              6 = δ) απαλλασσόμενα φόρου ασφαλίστρων 0%</br>
              7 = Ξενοδοχεία 3 αστέρων 1,50€</br>
              8 = Ξενοδοχεία 4 αστέρων 3,00€</br>
              9 = Ξενοδοχεία 4 αστέρων 4,00€</br>
              10 = Ενοικιαζόμενα- Επιπλωμένα δωμάτια- διαμερίσματα 0,50€</br>
              11 = Ειδικός φόρος στις διαφημίσεις που προβάλλονται απο την τηλεόραση (ΕΦΤΔ) 5%</br>
              12 = 3.1 Φόρος Πολυτελείας 10% επί της φορολογούμενης αξίας για τα ενδοικοινοτικώς αποκτούμενα και εισαγόμενα από τρίτες χώρες</br>
              13 = 3.2 Φόρος πολυτελείας 10% επί της τιμής πώλησης προ ΦΠΑ για τα εγχωρίως παραγόμενα είδη</br>
              14 = Δικαίωμα του Δημοσίου στα εισιτήρια των καζίνο (80% επί του εισιτηρίου)</br>
              15 = ασφάλιστρα κλάδου πυρός 20%</br>
              16 = Λοιποί Τελωνειακοί Δασμοί-Φόροι</br>
              17 = Λοιποί Φόροι</br>
              18 = Επιβαρύνσεις Λοιπών Φόρων</br>
              19 = ΕΦΚ</br>
            `,
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Withold Tax",
        fieldKey: "withHoldTax",
        required: false,
        column: null,
        tooltip: {
            title: "Κωδικοί παρακρατούμενων φόρων",
            content: `
                1 = Περίπτ. β' - Τόκοι - 15%</br>
                2 = Περίπτ. Γ' - Δικαιώματα- 20%</br>
                3 = Περίπτ. Δ΄- Αμοιβές Συμβουλών Διοίκησης - 20%</br>
                4 = Περίπτ. δ'- Τεχνικά Έργα- 3%</br>
                5 = Υγρά καύσιμα και προϊόντα καπνοβιομηχανίας 1%</br>
                6 = Λοιπά Αγαθά 4%"</br>
                7 = Παροχή Υπηρεσιών 8%</br>
                8 = Προκαταβλητέος Φόρος Αρχιτεκτόνων και Μηχανικών επί συμβατικών Αμοιβών, για εκπόνηση μελετών και σχεδίων 4%</br>
                9 = Προκαταβλητέος Φόρος Αρχιτεκτόνων και Μηχανικών επί συμβατικών Αμοιβών, που αφορούν οποιασδήποτε άλλης φύσης έργα 10%</br>
                10 = Προκαταβλητέος Φόρος στις Αμοιβές  Δικηγόρων 15%</br>
                11 = Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.1 αρ. 15 ν. 4172/2013</br>
                12 = Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.2 αρ. 15 ν. 4172/2013 - Αξιωματικών Εμπορικού Ναυτικού</br>
                13 = Παρακράτηση Φόρου Μισθωτών Υπηρεσιών παρ.2 αρ. 15 ν. 4172/2013 - Κατώτερο Πλήρωμα Εμπορικού Ναυτικού</br>
                14 = Παρακράτηση Ειδικής εισφοράς αλληλεγγύης</br>
                15 = Παρακράτηση Φόρου Αποζημίωσης λόγω Διακοπής Σχέσης Εργασίας παρ.3 αρ. 15 ν. 4172/2013</br>
                16 = Παρακρατήσεις συναλλαγών αλλοδαπής βάσει συμβάσεων αποφυγής διπλής φορολογίας (Σ.Α.Δ.Φ.)</br>
                17 = Λοιπές Παρακρατήσεις Φόρου</br>
                18 = Παρακράτηση Φόρου Μερίσματα περ.α παρ. 1 αρ. 64 ν. 4172/2013</br>
            `,
        },
        disabled: false,
    },
    {
        checkBox: false,
        fieldName: "Stamp Duty Tax",
        fieldKey: "stamp",
        required: false,
        column: null,
        tooltip: {
            title: "Κωδικοί χαρτοσήμων",
            content: `
              1=Συντελεστής 1.2%</br>
              2=Συντελεστής 2.4%</br>
              3=Συντελεστής 3.6%</br>`,
        },
        disabled: false,
    },
]

const importExportDefaultSettings = {
  section: "",
  fields: [],
  result: [],
  insertNew: false,
  updateExisting: true,
  firstLine: "",
  checkData: false,
};
const exportDefaultSettings = {
    section: "",
    fields: [],
    result: [],
    textInstead: false,
}

export const primerApiInitialData = {
  username: "",
  password: "",
  serialNumber: "",
  enableSyncProviderInvoices: "inactive",
    enableAdminSyncProviderInvoices: "inactive",
  myDataMode: "live",
  primerEnvironment: "test",
  primerRemainingDocuments: "",
  primerMonthlyRemainingDocuments: "",
  primerActivationDate: "",
  primerEndDate: "",
  aade_user_id: "",
  Ocp_Apim_Subscription_Key: "",
  myDataOfflineEnvironment: "test",
};

export const primerMyDataDataInitialValue = {
  "highlight": '',
  "measurementUnits": '',
  "myDataProductLineCoding": '',
  "paymentWays": '',
  "salePurpose": '',
  "vat": '',
  "vatMatching": '',
  "year": "",
  "company": "",
}

export const companyEmailSmtp = {
    smtpOption: 'primer',
    username: '',
    password: '',
    fromEmail: '',
    encryptionType: 'SSL/TLS',
    smtpAuthentication: 'true',
    smtpServer: '',
    smtpPort: '',
    subject: "To παραστατικο σας απο {CompanySmallName}",
    mailBody: '<p>Προς {ClientName},</p><p>Επισυναπτομενο θα βρειτε το παραστατικο σας.</p>\n' +
        '<p>Με Εκτιμηση,</p>\n' +
        '<p>{CompanySmallName}</p>\n' +
        '<p><br></p><p><em>Το παρων email δημιουργηθηκε αυτοματα. Μην απαντησετε στο παρων email, διοτι δεν παρακολουθειτε απο ανθρωπινο χρηστη. Για οποιαδηποτε εποικοινωνια με την επιχειρηση {CompanySmallName} παρακαλουμε χρησιμοποιηστε τις επισημες διευθυνσεις email και τηλεφωνα.</em></p>\n' +
        '<p><em>Σε περίπτωση που περιέλθει σε σας από λάθος χωρίς να είσθε ο σκοπούμενος παραλήπτης, παρακαλούμε να το διαγράψετε άμεσα από το σύστημά σας και να ειδοποιήσετε τον αποστολέα.</em></p>',
    correspondentName: 'Primer Software',
    company: '',
}

const initialState = {
  basicTypeInitialValues: basicTypeInitialValues,
  newAdvancedTypeInitialValues: newAdvancedTypeInitialValues,
  settings: {
    newBasicType: basicTypeInitialValues,
    editBasicType: basicTypeInitialValues,
    newAdvancedType: newAdvancedTypeInitialValues,
    editAdvancedType: newAdvancedTypeInitialValues,
    basicTypes: [],
    advancedTypes: [],
    advancedTypesSales: [],
    advancedTypesBuys: [],
  },
  settingImportTab: 0,
    settingExportTab: 0,
  importExport: importExportDefaultSettings,
    export: exportDefaultSettings,
  searchBasicTypes: [],
  totalItemsBTS: 0,
  perPageBTS: 1,
  searchAdvancedTypes: [],
  totalItemsATS: 0,
  perPageATS: 1,
  withHoldTaxesInitialValues: withHoldTaxesInitialValues,
  withHoldTaxes: withHoldTaxesInitialValues,
  otherTaxesInitialValues: otherTaxesInitialValues,
  otherHoldTaxes: otherTaxesInitialValues,
  feesInitialValues: feesInitialValues,
  fees: feesInitialValues,
  otherData: otherDataInitialValues,
  stampInitialValues: stampInitialValues,
  stamps: stampInitialValues,
  initialNewWithHoldTax: initialNewWithHoldTax,
  newWithHoldTax: initialNewWithHoldTax,
  initialNewOtherHoldTax: initialNewOtherHoldTax,
  newOtherHoldTax: initialNewOtherHoldTax,
  initialNewFee: initialNewOtherHoldTax,
  newFee: initialNewOtherHoldTax,
  initialNewStamp: initialNewOtherHoldTax,
  newStamp: initialNewOtherHoldTax,
  documentNumberingInitialValue: documentNumberingInitialValue,
  newDocumentNumbering: documentNumberingInitialValue,
  editDocumentNumbering: documentNumberingInitialValue,
  installationPrefix: [],
  primerApiData: primerApiInitialData,
  primerMyDataData: primerMyDataDataInitialValue,
    emailSmtp: companyEmailSmtp,


    masterLogTableRequestData: {
        page: 1,
        user: "",
        logTypes: [],
        dateFrom: moment().startOf("month").format("DD/MM/YYYY"),
        dateTo: moment().endOf("month").format("DD/MM/YYYY"),
    },
    masterLogTableResponseData: {
        data: [],
        totalPages: 0
    }
};

export const SettingsSlice = createSlice({
  name: "Settings",
  initialState,
  reducers: {
    setSettings: (state, { payload }) => {
      state.settings = payload;
    },
    setSettingImportTab: (state, { payload }) => {
      state.settingImportTab = payload;
    },
      setSettingExportTab: (state, { payload }) => {
          state.settingExportTab = payload;
      },
    setBasicTypes: (state, { payload }) => {
      state.settings.basicTypes = payload;
    },
    clearNewBasicType: (state) => {
      state.settings.newBasicType = state.basicTypeInitialValues;
    },
    setEditBasicType: (state, { payload }) => {
      state.settings.editBasicType = payload;
    },
    setAdvancedTypes: (state, { payload }) => {
      state.settings.advancedTypes = payload;
    },
    setAdvancedTypesSales: (state, { payload }) => {
      state.settings.advancedTypesSales = payload;
    },
    setAdvancedTypesBuys: (state, { payload }) => {
      state.settings.advancedTypesBuys = payload;
    },
    clearNewAdvancedType: (state) => {
      state.settings.newAdvancedType = state.newAdvancedTypeInitialValues;
    },
    setNewWithHoldTax: (state, { payload }) => {
      state.newWithHoldTax = payload;
    },
    setNewOtherHoldTax: (state, { payload }) => {
      state.newOtherHoldTax = payload;
    },
    setNewFee: (state, { payload }) => {
      state.newFee = payload;
    },
    setImport: (state, { payload }) => {
      switch (payload) {
        case "Products":
          state.importExport = {
            section: "Products",
            fields: productFields,
            insertNew: false,
            updateExisting: true,
            firstLine: "",
            result: productFields
              .filter((field) => field.checkBox)
              .map((field, index) => ({
                no: index + 1,
                fieldName: field.fieldName,
                column: field.column,
                fieldKey: field.fieldKey,
              })),
            checkData: false,
          };
          break;

        case "Clients":
          state.importExport = {
            section: "Clients",
            fields: clientFields,
            insertNew: false,
            updateExisting: true,
            firstLine: "",
            result: clientFields
              .filter((field) => field.checkBox)
              .map((field, index) => ({
                no: index + 1,
                fieldName: field.fieldName,
                column: field.column,
                fieldKey: field.fieldKey,
              })),
            checkData: false,
          };
          break;

          case "Client Payments":
              state.importExport = {
                  section: "Client Payments",
                  fields: clientPaymentsFields,
                  insertNew: false,
                  updateExisting: true,
                  firstLine: "",
                  result: clientPaymentsFields
                      .filter((field) => field.checkBox)
                      .map((field, index) => ({
                          no: index + 1,
                          fieldName: field.fieldName,
                          column: field.column,
                          fieldKey: field.fieldKey,
                      })),
                  checkData: false,
              };
              break;
          case "Client Movements":
              state.importExport = {
                  section: "Client Movements",
                  fields: clientMovementsFields,
                  insertNew: false,
                  updateExisting: true,
                  firstLine: "",
                  result: clientMovementsFields
                      .filter((field) => field.checkBox)
                      .map((field, index) => ({
                          no: index + 1,
                          fieldName: field.fieldName,
                          column: field.column,
                          fieldKey: field.fieldKey,
                      })),
                  checkData: false,
              };
              break;
          case "Sales":
              state.importExport = {
                  section: "Sales",
                  fields: salesFields,
                  insertNew: false,
                  updateExisting: true,
                  firstLine: "",
                  result: salesFields
                      .filter((field) => field.checkBox)
                      .map((field, index) => ({
                          no: index + 1,
                          fieldName: field.fieldName,
                          column: field.column,
                          fieldKey: field.fieldKey,
                      })),
                  checkData: false,
              };
              break;
          case "POS":
              state.importExport = {
                  section: "POS",
                  fields: posFields,
                  insertNew: false,
                  updateExisting: true,
                  firstLine: "",
                  result: posFields
                      .filter((field) => field.checkBox)
                      .map((field, index) => ({
                          no: index + 1,
                          fieldName: field.fieldName,
                          column: field.column,
                          fieldKey: field.fieldKey,
                      })),
                  checkData: false,
              };
              break;
          case "Cash Registers":
              state.importExport = {
                  section: "Cash Registers",
                  fields: cashRegisterFields,
                  insertNew: false,
                  updateExisting: true,
                  firstLine: "",
                  result: cashRegisterFields
                      .filter((field) => field.checkBox)
                      .map((field, index) => ({
                          no: index + 1,
                          fieldName: field.fieldName,
                          column: field.column,
                          fieldKey: field.fieldKey,
                      })),
                  checkData: false,
              };
              break;
          case "Bank Accounts":
              state.importExport = {
                  section: "Bank Accounts",
                  fields: bankAccountsFields,
                  insertNew: false,
                  updateExisting: true,
                  firstLine: "",
                  result: bankAccountsFields
                      .filter((field) => field.checkBox)
                      .map((field, index) => ({
                          no: index + 1,
                          fieldName: field.fieldName,
                          column: field.column,
                          fieldKey: field.fieldKey,
                      })),
                  checkData: false,
              };
              break;
          case "Suppliers":
              state.importExport = {
                  section: "Suppliers",
                  fields: supplierFields,
                  insertNew: false,
                  updateExisting: true,
                  firstLine: "",
                  result: supplierFields
                      .filter((field) => field.checkBox)
                      .map((field, index) => ({
                          no: index + 1,
                          fieldName: field.fieldName,
                          column: field.column,
                          fieldKey: field.fieldKey,
                      })),
                  checkData: false,
              };
              break;
          case "Accounting Accounts":
              state.importExport = {
                  section: "Accounting Accounts",
                  fields: accountingAccountFields,
                  insertNew: false,
                  updateExisting: true,
                  firstLine: "",
                  result: accountingAccountFields
                      .filter((field) => field.checkBox)
                      .map((field, index) => ({
                          no: index + 1,
                          fieldName: field.fieldName,
                          column: field.column,
                          fieldKey: field.fieldKey,
                      })),
                  checkData: false,
              };
              break;
        default:
          break;
      }
      // state.importExport = payload;
    },

    setImportSingle: (state, { payload }) => {
      state.importExport = {
        section: payload.section,
        fields: payload.fields,
        insertNew: state.importExport.insertNew,
        updateExisting: state.importExport.updateExisting,
        firstLine: state.importExport.firstLine,
        checkData: false,
        result: payload.result,
      };
    },
    setImportChecks: (state, { payload }) => {
      state.importExport = payload;
    },
      setExport: (state, {payload}) => {
          switch (payload) {
              case "Products":
                  state.export = {
                      section: "Products",
                      fields: productFields,
                      textInstead: false,
                      result: productFields
                          .filter((field) => field.checkBox)
                          .map((field, index) => ({
                              no: index + 1,
                              fieldName: field.fieldName,
                              column: field.column,
                              fieldKey: field.fieldKey,
                          })),
                  };
                  break;

              case "Clients":
                  state.export = {
                      section: "Clients",
                      fields: clientFields,
                      textInstead: false,
                      result: clientFields
                          .filter((field) => field.checkBox)
                          .map((field, index) => ({
                              no: index + 1,
                              fieldName: field.fieldName,
                              column: field.column,
                              fieldKey: field.fieldKey,
                          })),
                  };
                  break;

              case "Client Payments":
                  state.export = {
                      section: "Client Payments",
                      fields: clientPaymentsFields,
                      textInstead: false,
                      result: clientPaymentsFields
                          .filter((field) => field.checkBox)
                          .map((field, index) => ({
                              no: index + 1,
                              fieldName: field.fieldName,
                              column: field.column,
                              fieldKey: field.fieldKey,
                          })),
                  };
                  break;
              case "Client Movements":
                  state.export = {
                      section: "Client Movements",
                      fields: clientMovementsFields,
                      textInstead: false,
                      result: clientMovementsFields
                          .filter((field) => field.checkBox)
                          .map((field, index) => ({
                              no: index + 1,
                              fieldName: field.fieldName,
                              column: field.column,
                              fieldKey: field.fieldKey,
                          })),
                  };
                  break;
              case "Sales":
                  state.export = {
                      section: "Sales",
                      fields: salesFields,
                      textInstead: false,
                      result: salesFields
                          .filter((field) => field.checkBox)
                          .map((field, index) => ({
                              no: index + 1,
                              fieldName: field.fieldName,
                              column: field.column,
                              fieldKey: field.fieldKey,
                          })),
                  };
                  break;
              case "POS":
                  state.export = {
                      section: "POS",
                      fields: posFields,
                      textInstead: false,
                      result: posFields
                          .filter((field) => field.checkBox)
                          .map((field, index) => ({
                              no: index + 1,
                              fieldName: field.fieldName,
                              column: field.column,
                              fieldKey: field.fieldKey,
                          })),
                  };
                  break;
              case "Cash Registers":
                  state.export = {
                      section: "Cash Registers",
                      fields: cashRegisterFields,
                      textInstead: false,
                      result: cashRegisterFields
                          .filter((field) => field.checkBox)
                          .map((field, index) => ({
                              no: index + 1,
                              fieldName: field.fieldName,
                              column: field.column,
                              fieldKey: field.fieldKey,
                          })),
                  };
                  break;
              case "Bank Accounts":
                  state.export = {
                      section: "Bank Accounts",
                      fields: bankAccountsFields,
                      textInstead: false,
                      result: bankAccountsFields
                          .filter((field) => field.checkBox)
                          .map((field, index) => ({
                              no: index + 1,
                              fieldName: field.fieldName,
                              column: field.column,
                              fieldKey: field.fieldKey,
                          })),
                  };
                  break;
              case "Suppliers":
                  state.export = {
                      section: "Suppliers",
                      fields: supplierFields,
                      textInstead: false,
                      result: supplierFields
                          .filter((field) => field.checkBox)
                          .map((field, index) => ({
                              no: index + 1,
                              fieldName: field.fieldName,
                              column: field.column,
                              fieldKey: field.fieldKey,
                          })),
                  };
                  break;
              case "Accounting Accounts":
                  state.export = {
                      section: "Accounting Accounts",
                      fields: accountingAccountFields,
                      textInstead: false,
                      result: accountingAccountFields
                          .filter((field) => field.checkBox)
                          .map((field, index) => ({
                              no: index + 1,
                              fieldName: field.fieldName,
                              column: field.column,
                              fieldKey: field.fieldKey,
                          })),
                  };
                  break;
              default:
                  break;
          }
      },
      setExportSingle: (state, { payload }) => {
          state.export = {
              section: payload.section,
              fields: payload.fields,
              result: payload.result,
              textInstead: payload.textInstead,
          };
      },
      setExportChecks: (state, { payload }) => {
          state.export = payload;
      },
    setValues: (state, { payload }) => {
      // Access the fields array in the importExport object
      const fields = state.importExport.fields;
      const result = state.importExport.result;
      if(payload.type === "cb" || payload.type === "checkAll") {
          if(state.importExport.section === "Sales") {
              let fieldsToUnCheck = [];
              if(fields[payload.index].fieldKey === "myDataUID" || fields[payload.index].fieldKey === "myDataMark") {
                  fieldsToUnCheck = ["myDataAuthCode"];
              } else if (fields[payload.index].fieldKey === "deliveryLocation") {
                  fieldsToUnCheck = ["deliveryLocationAddress", "deliveryLocationCity", "deliveryLocationCountry", "deliveryLocationPostalCode"];
              }
              fieldsToUnCheck.forEach((item) => {
                  const index = result.findIndex((obj) => obj.fieldKey === item);
                  if(index !== -1) result.splice(index, 1);
              })
              for(let i = 0; i < fields.length; i++) {
                  if(fieldsToUnCheck.includes(fields[i].fieldKey)) {
                      fields[i].checkBox = false;
                      fields[i].column = null;
                  }
              }
          }
      }
      switch (payload.type) {
        case "cb":
          fields[payload.index].checkBox = !fields[payload.index].checkBox;
          fields[payload.index].column = null;

          if (!fields[payload.index].checkBox) {
            // find the index of the object with matching fieldKey and remove it from the result array
            const resultIndex = result.findIndex(
              (obj) => obj.fieldKey === fields[payload.index].fieldKey
            );
            if (resultIndex !== -1) {
              result.splice(resultIndex, 1);
            }
          } else {
            // if the checkbox is checked, add the object to the result array if it doesn't exist
            const resultIndex = result.findIndex(
              (obj) => obj.fieldKey === fields[payload.index].fieldKey
            );
            if (resultIndex === -1) {
              const obect = {
                no: result.length + 1,
                fieldKey: fields[payload.index].fieldKey,
                column: fields[payload.index].column,
                fieldName: fields[payload.index].fieldName,
              };
              result.push(obect);
            }
          }
          setImportSingle(state.importExport.section, fields, result);
          break;
        case "dd":
          const value = payload.value;
          fields[payload.index].column = payload.value;
          const resultIndex = result.findIndex(
            (obj) => obj.fieldKey === fields[payload.index].fieldKey
          );
          if (resultIndex !== -1) {
            result[resultIndex].column = excelOptions.map((op) => {
              return value === op.value ? op.label : "";
            });
          }
          setImportSingle(state.importExport.section, fields, result);
          break;
        case "checkAll":
          if (state.importExport.section === "Products") {
            // loop through the fields array and set the checkBox key to true for the specified fields
            const fieldsToCheck = [
              "myDataIncExp",
              "myDataRowType",
              "myDataRowCategory",
              "myDataRowInvoiceType",
              "myDataRowVatExpenseType",
            ];
            fields[payload.index].checkBox = !fields[payload.index].checkBox;
            if (fields[payload.index].checkBox) {
              for (let i = 0; i < fields.length; i++) {
                  if (fieldsToCheck.includes(fields[i].fieldKey)) {
                      fields[i].checkBox = fields[payload.index].checkBox;
                      fields[i].column = null;
                      const resultIndex = result.findIndex(
                          (obj) => obj.fieldKey === fields[i].fieldKey
                      );
                      if (resultIndex === -1) {
                          const obect = {
                              no: result.length + 1,
                              fieldKey: fields[i].fieldKey,
                              column: fields[i].column,
                              fieldName: fields[i].fieldName,
                          };
                          result.push(obect);
                      }
                  }
              }
            } else {
              fieldsToCheck.forEach((item) => {
                const index = result.findIndex((obj) => obj.fieldKey === item);
                if (index !== -1) {
                  result.splice(index, 1);
                }
              });
              for (let i = 0; i < fields.length; i++) {
                if (fieldsToCheck.includes(fields[i].fieldKey)) {
                  fields[i].checkBox = fields[payload.index].checkBox;
                  fields[i].column = null;
                }
              }

              fields[payload.index].column = null;
            }
            setImportSingle(state.importExport.section, fields, result);
          } else if (state.importExport.section === "Clients" || state.importExport.section === "Suppliers") {
            // loop through the fields array and set the checkBox key to true for the specified fields
            const fieldsToCheck = [
              "contactAcceptNotifications",
              "contactNotes",
              "contactEmail",
              "contactLandline",
              "contactPhone2",
              "contactPhone",
              "contactName",
            ];
            const fieldsToCheckInstallation = [
              "installationCustomVat",
              "installationVatReason",
              "installationVatCategory",
              "installationPostalCode",
              "installationCity",
              "installationDOY",
              "installationAddress",
              "installationNo"
            ];
            const indexChecker = fieldsToCheck.findIndex( (obj) => obj === fields[payload.index].fieldKey)

            if(indexChecker !== -1 ) {
            fields[payload.index].checkBox = !fields[payload.index].checkBox;
            if (fields[payload.index].checkBox) {
              for (let i = 0; i < fields.length; i++) {
                if (fieldsToCheck.includes(fields[i].fieldKey)) {
                  fields[i].checkBox = fields[payload.index].checkBox;
                  fields[i].column = null;
                  const resultIndex = result.findIndex(
                    (obj) => obj.fieldKey === fields[i].fieldKey
                  );
                  if (resultIndex === -1) {
                    const obect = {
                      no: result.length + 1,
                      fieldKey: fields[i].fieldKey,
                      column: fields[i].column,
                      fieldName: fields[i].fieldName,
                    };
                    result.push(obect);
                  }
                }
              }
            } else {
              fieldsToCheck.forEach((item) => {
                const index = result.findIndex((obj) => obj.fieldKey === item);
                if (index !== -1) {
                  result.splice(index, 1);
                }
              });
              for (let i = 0; i < fields.length; i++) {
                if (fieldsToCheck.includes(fields[i].fieldKey)) {
                  fields[i].checkBox = fields[payload.index].checkBox;
                  fields[i].column = null;
                }
              }

              fields[payload.index].column = null;
            }
          } else {
            fields[payload.index].checkBox = !fields[payload.index].checkBox;
            if (fields[payload.index].checkBox) {
              for (let i = 0; i < fields.length; i++) {
                if (fieldsToCheckInstallation.includes(fields[i].fieldKey)) {
                  fields[i].checkBox = fields[payload.index].checkBox;
                  fields[i].column = null;
                  const resultIndex = result.findIndex(
                    (obj) => obj.fieldKey === fields[i].fieldKey
                  );
                  if (resultIndex === -1) {
                    const obect = {
                      no: result.length + 1,
                      fieldKey: fields[i].fieldKey,
                      column: fields[i].column,
                      fieldName: fields[i].fieldName,
                    };
                    result.push(obect);
                  }
                }
              }
            } else {
              fieldsToCheckInstallation.forEach((item) => {
                const index = result.findIndex((obj) => obj.fieldKey === item);
                if (index !== -1) {
                  result.splice(index, 1);
                }
              });
              for (let i = 0; i < fields.length; i++) {
                if (fieldsToCheckInstallation.includes(fields[i].fieldKey)) {
                  fields[i].checkBox = fields[payload.index].checkBox;
                  fields[i].column = null;
                }
              }

              fields[payload.index].column = null;
            }

          }
            setImportSingle(state.importExport.section, fields, result);
          } else if (state.importExport.section === "Client Payments") {
              // loop through the fields array and set the checkBox key to true for the specified fields
              const fieldsToCheck = [
                  "chequeFirstDate",
                  "chequeLastDate",
              ];
              fields[payload.index].checkBox = !fields[payload.index].checkBox;
              if (fields[payload.index].checkBox) {
                  for (let i = 0; i < fields.length; i++) {
                      if (fieldsToCheck.includes(fields[i].fieldKey)) {
                          fields[i].checkBox = fields[payload.index].checkBox;
                          fields[i].column = null;
                          const resultIndex = result.findIndex(
                              (obj) => obj.fieldKey === fields[i].fieldKey
                          );
                          if (resultIndex === -1) {
                              const obect = {
                                  no: result.length + 1,
                                  fieldKey: fields[i].fieldKey,
                                  column: fields[i].column,
                                  fieldName: fields[i].fieldName,
                              };
                              result.push(obect);
                          }
                      }
                  }
              } else {
                  fieldsToCheck.forEach((item) => {
                      const index = result.findIndex((obj) => obj.fieldKey === item);
                      if (index !== -1) {
                          result.splice(index, 1);
                      }
                  });
                  for (let i = 0; i < fields.length; i++) {
                      if (fieldsToCheck.includes(fields[i].fieldKey)) {
                          fields[i].checkBox = fields[payload.index].checkBox;
                          fields[i].column = null;
                      }
                  }
                  fields[payload.index].column = null;
              }
              setImportSingle(state.importExport.section, fields, result);
          } else if(state.importExport.section === "Sales") {
              const fieldsToCheck = [
                  "myDataUID",
                  "myDataMark",
              ];
              const fieldsToCheck2 = [
                  "extraDataTaxType",
                  "extraDataTaxCode",
                  "extraDataTaxAmount",
              ];
              const fieldsToCheck3 = [
                  "isTaxLine",
                  "productExtraTaxType",
                  "productExtraTaxCode",
                  "productExtraTaxAmount"
              ]
              const fieldsToCheck4 = [
                  "deliveryLocationAddress",
                  "deliveryLocationCity",
                  "deliveryLocationCountry",
                  "deliveryLocationPostalCode"
              ];
              const isMyData = fieldsToCheck.findIndex((obj) => obj === fields[payload.index].fieldKey) >= 0;
              const isExtraData = fieldsToCheck2.findIndex((obj) => obj === fields[payload.index].fieldKey) >= 0;
              const isProductExtraData = fieldsToCheck3.findIndex((obj) => obj === fields[payload.index].fieldKey) >= 0;
              const isLocation = fieldsToCheck4.findIndex((obj) => obj === fields[payload.index].fieldKey) >= 0;

              if(isMyData) {
                  fields[payload.index].checkBox = !fields[payload.index].checkBox;
                  if (fields[payload.index].checkBox) {
                      for (let i = 0; i < fields.length; i++) {
                          if (fieldsToCheck.includes(fields[i].fieldKey)) {
                              fields[i].checkBox = fields[payload.index].checkBox;
                              fields[i].column = null;
                              const resultIndex = result.findIndex(
                                  (obj) => obj.fieldKey === fields[i].fieldKey
                              );
                              if (resultIndex === -1) {
                                  const obect = {
                                      no: result.length + 1,
                                      fieldKey: fields[i].fieldKey,
                                      column: fields[i].column,
                                      fieldName: fields[i].fieldName,
                                  };
                                  result.push(obect);
                              }
                          }
                      }
                  } else {
                      fieldsToCheck.forEach((item) => {
                          const index = result.findIndex((obj) => obj.fieldKey === item);
                          if (index !== -1) {
                              result.splice(index, 1);
                          }
                      });
                      for (let i = 0; i < fields.length; i++) {
                          if (fieldsToCheck.includes(fields[i].fieldKey)) {
                              fields[i].checkBox = fields[payload.index].checkBox;
                              fields[i].column = null;
                          }
                      }
                      fields[payload.index].column = null;
                  }
              } else if (isExtraData) {
                  fields[payload.index].checkBox = !fields[payload.index].checkBox;
                  if (fields[payload.index].checkBox) {
                      for (let i = 0; i < fields.length; i++) {
                          if (fieldsToCheck2.includes(fields[i].fieldKey)) {
                              fields[i].checkBox = fields[payload.index].checkBox;
                              fields[i].column = null;
                              const resultIndex = result.findIndex(
                                  (obj) => obj.fieldKey === fields[i].fieldKey
                              );
                              if (resultIndex === -1) {
                                  const obect = {
                                      no: result.length + 1,
                                      fieldKey: fields[i].fieldKey,
                                      column: fields[i].column,
                                      fieldName: fields[i].fieldName,
                                  };
                                  result.push(obect);
                              }
                          }
                      }
                  } else {
                      fieldsToCheck2.forEach((item) => {
                          const index = result.findIndex((obj) => obj.fieldKey === item);
                          if (index !== -1) {
                              result.splice(index, 1);
                          }
                      });
                      for (let i = 0; i < fields.length; i++) {
                          if (fieldsToCheck2.includes(fields[i].fieldKey)) {
                              fields[i].checkBox = fields[payload.index].checkBox;
                              fields[i].column = null;
                          }
                      }
                      fields[payload.index].column = null;
                  }
              } else if (isProductExtraData) {
                  fields[payload.index].checkBox = !fields[payload.index].checkBox;
                  if (fields[payload.index].checkBox) {
                      for (let i = 0; i < fields.length; i++) {
                          if (fieldsToCheck3.includes(fields[i].fieldKey)) {
                              fields[i].checkBox = fields[payload.index].checkBox;
                              fields[i].column = null;
                              const resultIndex = result.findIndex(
                                  (obj) => obj.fieldKey === fields[i].fieldKey
                              );
                              if (resultIndex === -1) {
                                  const obect = {
                                      no: result.length + 1,
                                      fieldKey: fields[i].fieldKey,
                                      column: fields[i].column,
                                      fieldName: fields[i].fieldName,
                                  };
                                  result.push(obect);
                              }
                          }
                      }
                  } else {
                      fieldsToCheck3.forEach((item) => {
                          const index = result.findIndex((obj) => obj.fieldKey === item);
                          if (index !== -1) {
                              result.splice(index, 1);
                          }
                      });
                      for (let i = 0; i < fields.length; i++) {
                          if (fieldsToCheck3.includes(fields[i].fieldKey)) {
                              fields[i].checkBox = fields[payload.index].checkBox;
                              fields[i].column = null;
                          }
                      }
                      fields[payload.index].column = null;
                  }
              } else if (isLocation) {
                  fields[payload.index].checkBox = !fields[payload.index].checkBox;
                  if (fields[payload.index].checkBox) {
                      for (let i = 0; i < fields.length; i++) {
                          if (fieldsToCheck4.includes(fields[i].fieldKey)) {
                              fields[i].checkBox = fields[payload.index].checkBox;
                              fields[i].column = null;
                              const resultIndex = result.findIndex(
                                  (obj) => obj.fieldKey === fields[i].fieldKey
                              );
                              if (resultIndex === -1) {
                                  const obect = {
                                      no: result.length + 1,
                                      fieldKey: fields[i].fieldKey,
                                      column: fields[i].column,
                                      fieldName: fields[i].fieldName,
                                  };
                                  result.push(obect);
                              }
                          }
                      }
                  } else {
                      fieldsToCheck4.forEach((item) => {
                          const index = result.findIndex((obj) => obj.fieldKey === item);
                          if (index !== -1) {
                              result.splice(index, 1);
                          }
                      });
                      for (let i = 0; i < fields.length; i++) {
                          if (fieldsToCheck4.includes(fields[i].fieldKey)) {
                              fields[i].checkBox = fields[payload.index].checkBox;
                              fields[i].column = null;
                          }
                      }
                      fields[payload.index].column = null;
                  }
              }
              setImportSingle(state.importExport.section, fields, result);
          }
          break;

          default:
            break;
      }
    },
      setValuesExport: (state, { payload }) => {
          // Access the fields array in the importExport object
          const fields = state.export.fields;
          const result = state.export.result;
          if(payload.type === "cb" || payload.type === "checkAll") {
              if(state.export.section === "Sales") {
                  let fieldsToUnCheck = [];
                  if(fields[payload.index].fieldKey === "myDataUID" || fields[payload.index].fieldKey === "myDataMark") {
                      fieldsToUnCheck = ["myDataAuthCode"];
                  } else if (fields[payload.index].fieldKey === "deliveryLocation") {
                      fieldsToUnCheck = ["deliveryLocationAddress", "deliveryLocationCity", "deliveryLocationCountry", "deliveryLocationPostalCode"];
                  }
                  fieldsToUnCheck.forEach((item) => {
                      const index = result.findIndex((obj) => obj.fieldKey === item);
                      if(index !== -1) result.splice(index, 1);
                  })
                  for(let i = 0; i < fields.length; i++) {
                      if(fieldsToUnCheck.includes(fields[i].fieldKey)) {
                          fields[i].checkBox = false;
                          fields[i].column = null;
                      }
                  }
              }
          }
          switch (payload.type) {
              case "cb":
                  fields[payload.index].checkBox = !fields[payload.index].checkBox;
                  fields[payload.index].column = null;

                  if (!fields[payload.index].checkBox) {
                      // find the index of the object with matching fieldKey and remove it from the result array
                      const resultIndex = result.findIndex(
                          (obj) => obj.fieldKey === fields[payload.index].fieldKey
                      );
                      if (resultIndex !== -1) {
                          result.splice(resultIndex, 1);
                      }
                  } else {
                      // if the checkbox is checked, add the object to the result array if it doesn't exist
                      const resultIndex = result.findIndex(
                          (obj) => obj.fieldKey === fields[payload.index].fieldKey
                      );
                      if (resultIndex === -1) {
                          const obect = {
                              no: result.length + 1,
                              fieldKey: fields[payload.index].fieldKey,
                              column: fields[payload.index].column,
                              fieldName: fields[payload.index].fieldName,
                          };
                          result.push(obect);
                      }
                  }
                  setExportSingle(state.export.section, fields, result);
                  break;
              case "dd":
                  const value = payload.value;
                  fields[payload.index].column = payload.value;
                  const resultIndex = result.findIndex(
                      (obj) => obj.fieldKey === fields[payload.index].fieldKey
                  );
                  if (resultIndex !== -1) {
                      result[resultIndex].column = excelOptions.map((op) => {
                          return value === op.value ? op.label : "";
                      });
                  }
                  setExportSingle(state.export.section, fields, result);
                  break;
              case "checkAll":
                  if (state.export.section === "Products") {
                      // loop through the fields array and set the checkBox key to true for the specified fields
                      const fieldsToCheck = [
                          "myDataIncExp",
                          "myDataRowType",
                          "myDataRowCategory",
                          "myDataRowInvoiceType",
                          "myDataRowVatExpenseType",
                      ];
                      fields[payload.index].checkBox = !fields[payload.index].checkBox;
                      if (fields[payload.index].checkBox) {
                          for (let i = 0; i < fields.length; i++) {
                              if (fieldsToCheck.includes(fields[i].fieldKey)) {
                                  fields[i].checkBox = fields[payload.index].checkBox;
                                  fields[i].column = null;
                                  const resultIndex = result.findIndex(
                                      (obj) => obj.fieldKey === fields[i].fieldKey
                                  );
                                  if (resultIndex === -1) {
                                      const obect = {
                                          no: result.length + 1,
                                          fieldKey: fields[i].fieldKey,
                                          column: fields[i].column,
                                          fieldName: fields[i].fieldName,
                                      };
                                      result.push(obect);
                                  }
                              }
                          }
                      } else {
                          fieldsToCheck.forEach((item) => {
                              const index = result.findIndex((obj) => obj.fieldKey === item);
                              if (index !== -1) {
                                  result.splice(index, 1);
                              }
                          });
                          for (let i = 0; i < fields.length; i++) {
                              if (fieldsToCheck.includes(fields[i].fieldKey)) {
                                  fields[i].checkBox = fields[payload.index].checkBox;
                                  fields[i].column = null;
                              }
                          }

                          fields[payload.index].column = null;
                      }
                      setExportSingle(state.export.section, fields, result);
                  } else if (state.export.section === "Clients" || state.export.section === "Suppliers") {

                      // loop through the fields array and set the checkBox key to true for the specified fields
                      const fieldsToCheck = [
                          "contactAcceptNotifications",
                          "contactNotes",
                          "contactEmail",
                          "contactLandline",
                          "contactPhone2",
                          "contactPhone",
                          "contactName",
                      ];
                      const fieldsToCheckInstallation = [
                          "installationCustomVat",
                          "installationVatReason",
                          "installationVatCategory",
                          "installationPostalCode",
                          "installationCity",
                          "installationDOY",
                          "installationAddress",
                          "installationNo"
                      ];
                      const indexChecker = fieldsToCheck.findIndex( (obj) => obj === fields[payload.index].fieldKey)

                      if(indexChecker !== -1 ) {
                          fields[payload.index].checkBox = !fields[payload.index].checkBox;
                          if (fields[payload.index].checkBox) {
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheck.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                      const resultIndex = result.findIndex(
                                          (obj) => obj.fieldKey === fields[i].fieldKey
                                      );
                                      if (resultIndex === -1) {
                                          const obect = {
                                              no: result.length + 1,
                                              fieldKey: fields[i].fieldKey,
                                              column: fields[i].column,
                                              fieldName: fields[i].fieldName,
                                          };
                                          result.push(obect);
                                      }
                                  }
                              }
                          } else {
                              fieldsToCheck.forEach((item) => {
                                  const index = result.findIndex((obj) => obj.fieldKey === item);
                                  if (index !== -1) {
                                      result.splice(index, 1);
                                  }
                              });
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheck.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                  }
                              }

                              fields[payload.index].column = null;
                          }
                      } else {
                          fields[payload.index].checkBox = !fields[payload.index].checkBox;
                          if (fields[payload.index].checkBox) {
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheckInstallation.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                      const resultIndex = result.findIndex(
                                          (obj) => obj.fieldKey === fields[i].fieldKey
                                      );
                                      if (resultIndex === -1) {
                                          const obect = {
                                              no: result.length + 1,
                                              fieldKey: fields[i].fieldKey,
                                              column: fields[i].column,
                                              fieldName: fields[i].fieldName,
                                          };
                                          result.push(obect);
                                      }
                                  }
                              }
                          } else {
                              fieldsToCheckInstallation.forEach((item) => {
                                  const index = result.findIndex((obj) => obj.fieldKey === item);
                                  if (index !== -1) {
                                      result.splice(index, 1);
                                  }
                              });
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheckInstallation.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                  }
                              }

                              fields[payload.index].column = null;
                          }

                      }
                      setExportSingle(state.export.section, fields, result);
                  } else if (state.export.section === "Client Payments") {
                      // loop through the fields array and set the checkBox key to true for the specified fields
                      const fieldsToCheck = [
                          "chequeFirstDate",
                          "chequeLastDate",
                      ];
                      fields[payload.index].checkBox = !fields[payload.index].checkBox;
                      if (fields[payload.index].checkBox) {
                          for (let i = 0; i < fields.length; i++) {
                              if (fieldsToCheck.includes(fields[i].fieldKey)) {
                                  fields[i].checkBox = fields[payload.index].checkBox;
                                  fields[i].column = null;
                                  const resultIndex = result.findIndex(
                                      (obj) => obj.fieldKey === fields[i].fieldKey
                                  );
                                  if (resultIndex === -1) {
                                      const obect = {
                                          no: result.length + 1,
                                          fieldKey: fields[i].fieldKey,
                                          column: fields[i].column,
                                          fieldName: fields[i].fieldName,
                                      };
                                      result.push(obect);
                                  }
                              }
                          }
                      } else {
                          fieldsToCheck.forEach((item) => {
                              const index = result.findIndex((obj) => obj.fieldKey === item);
                              if (index !== -1) {
                                  result.splice(index, 1);
                              }
                          });
                          for (let i = 0; i < fields.length; i++) {
                              if (fieldsToCheck.includes(fields[i].fieldKey)) {
                                  fields[i].checkBox = fields[payload.index].checkBox;
                                  fields[i].column = null;
                              }
                          }
                          fields[payload.index].column = null;
                      }
                      setExportSingle(state.export.section, fields, result);
                  } else if (state.export.section === "Sales") {
                      const fieldsToCheck = [
                          "myDataUID",
                          "myDataMark",
                      ];
                      const fieldsToCheck2 = [
                          "extraDataTaxType",
                          "extraDataTaxCode",
                          "extraDataTaxAmount",
                      ];
                      const fieldsToCheck3 = [
                          "isTaxLine",
                          "productExtraTaxType",
                          "productExtraTaxCode",
                          "productExtraTaxAmount"
                      ]
                      const fieldsToCheck4 = [
                          "deliveryLocationAddress",
                          "deliveryLocationCity",
                          "deliveryLocationCountry",
                          "deliveryLocationPostalCode"
                      ];
                      const isMyData = fieldsToCheck.findIndex((obj) => obj === fields[payload.index].fieldKey) >= 0;
                      const isExtraData = fieldsToCheck2.findIndex((obj) => obj === fields[payload.index].fieldKey) >= 0;
                      const isProductExtraData = fieldsToCheck3.findIndex((obj) => obj === fields[payload.index].fieldKey) >= 0;
                      const isLocation = fieldsToCheck4.findIndex((obj) => obj === fields[payload.index].fieldKey) >= 0;

                      if(isMyData) {
                          fields[payload.index].checkBox = !fields[payload.index].checkBox;
                          if (fields[payload.index].checkBox) {
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheck.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                      const resultIndex = result.findIndex(
                                          (obj) => obj.fieldKey === fields[i].fieldKey
                                      );
                                      if (resultIndex === -1) {
                                          const obect = {
                                              no: result.length + 1,
                                              fieldKey: fields[i].fieldKey,
                                              column: fields[i].column,
                                              fieldName: fields[i].fieldName,
                                          };
                                          result.push(obect);
                                      }
                                  }
                              }
                          } else {
                              fieldsToCheck.forEach((item) => {
                                  const index = result.findIndex((obj) => obj.fieldKey === item);
                                  if (index !== -1) {
                                      result.splice(index, 1);
                                  }
                              });
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheck.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                  }
                              }
                              fields[payload.index].column = null;
                          }
                      } else if (isExtraData) {
                          fields[payload.index].checkBox = !fields[payload.index].checkBox;
                          if (fields[payload.index].checkBox) {
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheck2.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                      const resultIndex = result.findIndex(
                                          (obj) => obj.fieldKey === fields[i].fieldKey
                                      );
                                      if (resultIndex === -1) {
                                          const obect = {
                                              no: result.length + 1,
                                              fieldKey: fields[i].fieldKey,
                                              column: fields[i].column,
                                              fieldName: fields[i].fieldName,
                                          };
                                          result.push(obect);
                                      }
                                  }
                              }
                          } else {
                              fieldsToCheck2.forEach((item) => {
                                  const index = result.findIndex((obj) => obj.fieldKey === item);
                                  if (index !== -1) {
                                      result.splice(index, 1);
                                  }
                              });
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheck2.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                  }
                              }
                              fields[payload.index].column = null;
                          }
                      } else if (isProductExtraData) {
                          fields[payload.index].checkBox = !fields[payload.index].checkBox;
                          if (fields[payload.index].checkBox) {
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheck3.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                      const resultIndex = result.findIndex(
                                          (obj) => obj.fieldKey === fields[i].fieldKey
                                      );
                                      if (resultIndex === -1) {
                                          const obect = {
                                              no: result.length + 1,
                                              fieldKey: fields[i].fieldKey,
                                              column: fields[i].column,
                                              fieldName: fields[i].fieldName,
                                          };
                                          result.push(obect);
                                      }
                                  }
                              }
                          } else {
                              fieldsToCheck3.forEach((item) => {
                                  const index = result.findIndex((obj) => obj.fieldKey === item);
                                  if (index !== -1) {
                                      result.splice(index, 1);
                                  }
                              });
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheck3.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                  }
                              }
                              fields[payload.index].column = null;
                          }
                      } else if (isLocation) {
                          fields[payload.index].checkBox = !fields[payload.index].checkBox;
                          if (fields[payload.index].checkBox) {
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheck4.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                      const resultIndex = result.findIndex(
                                          (obj) => obj.fieldKey === fields[i].fieldKey
                                      );
                                      if (resultIndex === -1) {
                                          const obect = {
                                              no: result.length + 1,
                                              fieldKey: fields[i].fieldKey,
                                              column: fields[i].column,
                                              fieldName: fields[i].fieldName,
                                          };
                                          result.push(obect);
                                      }
                                  }
                              }
                          } else {
                              fieldsToCheck4.forEach((item) => {
                                  const index = result.findIndex((obj) => obj.fieldKey === item);
                                  if (index !== -1) {
                                      result.splice(index, 1);
                                  }
                              });
                              for (let i = 0; i < fields.length; i++) {
                                  if (fieldsToCheck4.includes(fields[i].fieldKey)) {
                                      fields[i].checkBox = fields[payload.index].checkBox;
                                      fields[i].column = null;
                                  }
                              }
                              fields[payload.index].column = null;
                          }
                      }
                      setExportSingle(state.export.section, fields, result);
                  }
                  break;

              default:
                  break;
          }
      },
    setValuesChecks: (state, { payload }) => {
      console.log("payload", payload);
      // Access the fields array in the importExport object
      const importExport = state.importExport;
      switch (payload.type) {
        case "cb":
          if (payload.name === "insertNew") {
            importExport.insertNew = payload.value;
          }
          if (payload.name === "updateExisting") {
            importExport.updateExisting = payload.value;
          }
          if (payload.name === "checkData") {
            importExport.checkData = payload.value;
          }
          setImportChecks({ ...importExport });
          break;
        case "input":
          importExport.firstLine = payload.value;
          setImportChecks({ ...importExport });
          break;
        default:
          break;
      }
    },
      setValuesChecksExport: (state, { payload }) => {
          console.log("payload", payload);
          // Access the fields array in the importExport object
          const eexport = state.export;
          switch (payload.type) {
              case "cb":
                  if (payload.name === "textInstead") {
                      eexport.textInstead = payload.value;
                  }
                  setExportChecks({ ...eexport });
                  break;
              default:
                  break;
          }
      },
    setNewDocumentNumbering: (state, { payload }) => {
      state.newDocumentNumbering = payload;
    },
    resetNewDocumentNumbering: (state) => {
      state.newDocumentNumbering = state.documentNumberingInitialValue;
    },
    setNewAdvancedType: (state, { payload }) => {
      state.settings.newAdvancedType = payload;
    },
    setEditDocumentNumbering: (state, { payload }) => {
      state.editDocumentNumbering = payload;
    },
    resetEditDocumentNumbering: (state) => {
      state.editDocumentNumbering = state.documentNumberingInitialValue;
    },
    setEditAdvancedType: (state, { payload }) => {
      state.settings.editAdvancedType = payload;
    },
    setSearchBasicTypes: (state, { payload }) => {
      state.searchBasicTypes = payload;
    },
    setTotalItemsBTS: (state, { payload }) => {
      state.totalItemsBTS = payload;
    },
    setPerPageBTS: (state, { payload }) => {
      state.perPageBTS = payload;
    },
    setTotalItemsATS: (state, { payload }) => {
      state.totalItemsATS = payload;
    },
    setPerPageATS: (state, { payload }) => {
      state.perPageATS = payload;
    },
    setSearchAdvancedTypes: (state, { payload }) => {
      state.searchAdvancedTypes = payload;
    },
    setPrimerApiData: (state, { payload }) => {
      state.primerApiData = payload;
    },
    setPrimerMyDataData: (state, { payload }) => {
      state.primerMyDataData = payload;
    },
      setEmailSmtp: (state, { payload }) => {
          state.emailSmtp = payload;
      },
      setTooltip: (state, { payload }) => {
        state.importExport.fields.forEach((field, i) => {
            if(field.fieldKey === payload.key) {
                field.tooltip = {
                    title : payload.title,
                    content : payload.content,
                };
            }
        })
      },
      setTooltipExport: (state, { payload }) => {
          state.export.fields.forEach((field, i) => {
              if(field.fieldKey === payload.key) {
                  field.tooltip = {
                      title : payload.title,
                      content : payload.content,
                  };
              }
          })
      },
      setMasterLogTableRequestData: (state, {payload}) => {
        state.masterLogTableRequestData = payload;
      },
      setMasterLogTableResponseData: (state, {payload}) => {
        state.masterLogTableResponseData = payload;
      }
  },
});

export const {
  setSettings,
  setPerPageBTS,
  setTotalItemsBTS,
  setPerPageATS,
  setTotalItemsATS,
  setAdvancedTypes,
  setBasicTypes,
  clearNewBasicType,
  clearNewAdvancedType,
  setNewWithHoldTax,
  setNewOtherHoldTax,
    setNewFee,
  setNewDocumentNumbering,
  resetNewDocumentNumbering,
  setNewAdvancedType,
  setAdvancedTypesSales,
  setAdvancedTypesBuys,
  setSearchBasicTypes,
  setSearchAdvancedTypes,
  setEditBasicType,
  setEditAdvancedType,
  setEditDocumentNumbering,
  resetEditDocumentNumbering,
  setPrimerApiData,
  setPrimerMyDataData,
    setEmailSmtp,
  primerMyDataData,
  setImport,
  setImportSingle,
  setImportChecks,
  setExport,
  setExportSingle,
  setExportChecks,
  setValues,
    setValuesExport,
  setValuesChecks,
    setValuesChecksExport,
  setSettingImportTab,
    setSettingExportTab,
    setTooltip,
    setTooltipExport,
    setMasterLogTableRequestData,
    setMasterLogTableResponseData
} = SettingsSlice.actions;
