import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import Dropdown from "../../../primer/Dropdown";
import Input from '../../../common/Input';
import {
  getCountryOptions,
  getDefaultOptionByValue,
  getDOYOptions,
  getGeneratedMaskCodeCLient, getInstallationsTypes,
  getMainInstallation,
  getMaskCodeWithNewPrefix,
  getOptionsByData,
  getVatCategoryAbroadOptions,
  getVatCategoryOptions,
  isValidEmail, validateAFM
} from '../../../../_helpers/commonFunctions';
import { useSelector, useDispatch } from "react-redux";
import {
  setSaveNewClient
} from "../../../../_reducers/activitySlice";
import {
  resetNewClientModal,
  setNewClientModal
} from '../../../../_reducers/DataPanel/Clients/clientSlice';
import { findObjectFromArrayOfObjects, getLabelByValue, getObjectFromArrayOfObjects } from '../../../../_helpers/helperFunctions';
import {
  fetchClientsSettings,
  fetchInitialClients,
} from '../../../../_apis/api';
import './clientModal.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { setNewSales } from '../../../../_reducers/DataPanel/Sales/salesSlice';
import getMyData from "../../../../_helpers/sharedmydata";
import {useTranslation} from "react-i18next";
const apiURL2 = process.env.REACT_APP_API_URL2;

const NewClientModal = (props) => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const SALES = useSelector( (state) => state.SALES );
  const CLIENTS_DATA = useSelector( (state) => state.CLIENTS_DATA );
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const company = useSelector(state => state.COMPANY_DATA.company)
  let [clientCategories,setClientCategories] = useState(getObjectFromArrayOfObjects(CLIENTS_DATA.clientsSettings.categories, 'active', 'isActive'));
  const zeroVatReasonOptions = getMyData({type: 'zerovatreason'});
  const clientCategoriesOption = getOptionsByData(clientCategories, '_id', 'category');
  let vatCategoryList = CLIENTS_DATA.newClientModal.categoryName === "Client within E.U." || CLIENTS_DATA.newClientModal.categoryName === "Client outside E.U." ? getVatCategoryAbroadOptions() : getVatCategoryOptions();
  const isValidCustomVat = (value) => {
    let error;
    if (+value > 100) {
      error = 'Can not be greater than 100.';
    }
    return error;
  }
  let requestData = {
    company:company.id,
    year:company.year,
  }
  useEffect(() => {
    // It will use for validate client name
    dispatch(
        fetchInitialClients({
          company: company.id,
          year: company.year,
          isActive: "active",
        })
    );
    dispatch(
        fetchClientsSettings({
          company: company.id,
          year: company.year.toString(),
          isActive: "active"
        })
    );
  }, [dispatch]);

  const [showClientModal, setShowClientModal] = useState(false);
  const [showErrors, setShowErrors] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false);
  // let countryData = getCountryOptions(); // get all countries data
  // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.: Select Greece, Accept only Greece, show only Greece
  let countryData = getCountryOptions('greece'); // Only Greece Country Data
  let [countriesList, setCountriesList] = useState(countryData);
  // Data From The API
  let disabledFields = false;
  if (CLIENTS_DATA.newClientModal.category === undefined || CLIENTS_DATA.newClientModal.category === '') {
    disabledFields = true;
  }

  const handleOnChange = (e, type="default", ddname = '') => {
    setShowErrors(true);

    // Set if editing the form
    if (!ACTIVITY.saveNewClient) {
      dispatch(setSaveNewClient(true));
    }

    let name;
    let value;

    if (type === 'dd')  {
      name = ddname;
      value = e.value;
    } else if (type === 'ac') {
      name = ddname;
      value = e.row.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    if(name === 'customVat'){
      let error;
      if (+value > 100) {
        error = 'Can not be greater than 100.';
        toast.error(error);
        value = "";
      }
      value = +String(value).slice(0, 3);
      if (value === 0) {
        value = "";
      }
    }
    // Set settings data
    let newClient = Object.assign({}, CLIENTS_DATA.newClientModal);
    let newClientData = {};
    if (name === 'category') {
      newClientData[name] = value;
      newClientData.categoryName = getLabelByValue(clientCategoriesOption, value, 'value');

      const selectedCategory = findObjectFromArrayOfObjects(clientCategories, value,'_id');
      if (selectedCategory) {
        newClient.categoryMaskCodePrefix = selectedCategory.maskCodePrefix;
        newClient.systemCode = getGeneratedMaskCodeCLient(selectedCategory._id, clientCategories, selectedCategory.maskCodePrefix); // New With Updated Prefix
      }

      // If Category: Retail: Add 000000000, do not validate VAT number. Field not mandatory
      if (newClientData.categoryName === 'Retail' || newClientData.categoryName === 'Patient') {
        newClientData.vatNumber = "000000000";
      } else {
        newClientData.vatNumber = "";
      }


      if (newClientData.categoryName === "Client within E.U.") {
        // Update country list for inside EU.
        // Client inside E.U., show only countries within E.U. except Greece
        let EuCountryData = getCountryOptions("insideEU");
        setCountriesList(EuCountryData);
      } else if (newClientData.categoryName === "Client outside E.U.") {
        // Update country list for outside EU
        // outside E.U., show only countries outside E.U.
        let NonEuCountryData = getCountryOptions("outsideEU");
        setCountriesList(NonEuCountryData);
      }else if(newClientData.categoryName === "Retail" || newClientData.categoryName === "Patient"){
        setCountriesList(getCountryOptions('all'));
        newClientData.country = "GR";
      } else {
        // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:
        // Select Greece, Accept only Greece, show only Greece
        let onlyGreeceCountryData = getCountryOptions("greece");
        setCountriesList(onlyGreeceCountryData);
        if (onlyGreeceCountryData?.length > 0) {
          newClientData.country = onlyGreeceCountryData["0"].value;
        }
      }
    } else {
      if(name === 'isActive' && value === 'inactive') {
        setShowClientModal(true);
      } else {
        newClientData[name] = value;
      }
    }
    vatCategoryList = CLIENTS_DATA.newClientModal.categoryName === "Client within E.U." || CLIENTS_DATA.newClientModal.categoryName === "Client outside E.U." ? getVatCategoryAbroadOptions() : getVatCategoryOptions();
    let updatedContactPoints = CLIENTS_DATA.newClientModal.contactPoints.slice();
    let newContacts = {
      name: newClient.name,
      email: newClient.email,
      mobile: newClient.phone
    }
    if (e) {
      if (name === "name") {
        newContacts["name"] = value;
      } else if (name === "email") {
        newContacts["email"] = value;
      } else if (name === "phone") {
        newContacts["mobile"] = value;
      } else if (name === "category") {
        newContacts["mobile"] = "";
      }
    }

    updatedContactPoints["0"] = {
      ...updatedContactPoints["0"],
      ...newContacts,
    };
    newClient = { ...newClient, ...newClientData };

    // Default Installation
    let defaultInstallation = [
      {
        id: 0,
        no: 0,
        type: getMainInstallation()?.value,
        address: newClient.address,
        DOY: newClient.DOY,
        city: newClient.city,
        county: newClient.county,
        vatCategory: newClient.vatCategory,
        vatReason: newClient.vatReason,
        customVat: newClient.customVat,
        postalCode: newClient.postalCode
      },
    ];
    dispatch(
        setNewClientModal({
          ...newClient,
          contactPoints: updatedContactPoints,
          installations: defaultInstallation,
        })
    );
  }

  const fetchTaxisNetDetails = async (data) => {
    let jsonData = {
      vatNumber: data,
      onlyAADE: "true"
    }
    const abort = axios.CancelToken.source();
    const timer = setTimeout(
        () => abort.cancel(`timeout`),
        10000
    )
    await axios.post(apiURL2 + `/client/fetch-vat-details/`, JSON.stringify(jsonData), { headers: { 'Content-Type': 'application/json'}})
        .then((res) => {
          if (res.data && res.data.status === "200") {
            clearTimeout(timer)
            let companyDetails = res.data.data;
            let newClient = Object.assign({}, CLIENTS_DATA.newClientModal);
            newClient.name = companyDetails?.name !== undefined ? companyDetails?.name : "";
            newClient.smallName = companyDetails?.smallName !== undefined ? companyDetails?.smallName : "";
            newClient.activity = companyDetails?.activity !== undefined ? companyDetails?.activity : "";
            newClient.postalCode = companyDetails?.tk !== undefined ? companyDetails?.tk : "";
            newClient.address =  companyDetails?.address !== undefined ? companyDetails?.address : "";
            newClient.DOY = getDefaultOptionByValue(getDOYOptions(), companyDetails?.doy, "label", "label", "value").value;
            newClient.city = companyDetails?.city !== undefined ? companyDetails?.city : "";
            if(companyDetails?.vatCategory === 'Y'){
              newClient.vatCategory = 'Normal VAT';
            }
            let updatedContactPoints = CLIENTS_DATA.newClientModal.contactPoints.slice();
            let newContacts = {
              name: newClient.name,
              email: newClient.email,
              mobile: newClient.phone,
            };
            updatedContactPoints["0"] = {
              ...updatedContactPoints["0"],
              ...newContacts,
            };
            let defaultInstallation = [
              {
                id: 0,
                no: 0,
                type: getMainInstallation()?.value,
                address: newClient.address,
                DOY: newClient.DOY,
                city: newClient.city,
                county: newClient.county,
                vatCategory: newClient.vatCategory,
                vatReason: newClient.vatReason,
                customVat: newClient.customVat,
                postalCode: newClient.postalCode
              },
            ];
            dispatch(setNewClientModal({...newClient, contactPoints: updatedContactPoints, installations: defaultInstallation}));
            toast.success(res.data.message);
          } else {
            console.log(res)
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response?.status === 400){
            alert({
              message: error.response.data?.Error ? error.response.data?.Error : error?.message === "timeout" ? "Timeout. Please try again later!" : error.code,
              type: 'warning',
            })
          }
        });
  }

  const handleCancelClientModal = () => {
    setShowClientModal(false);
    dispatch(resetNewClientModal());    // Set New Client
  }

  const handleClientCategories = () => {
    if(props && props.myDataType){
      let clArray = [];
      let allCategories = getObjectFromArrayOfObjects(CLIENTS_DATA.clientsSettings.categories, 'active', 'isActive');
      allCategories.map((category) => {
        if(props.myDataType === "1.1" ||
            props.myDataType === "1.4" ||
            props.myDataType === "1.5" ||
            props.myDataType === "1.6" ||
            props.myDataType === "2.1" ||
            props.myDataType === "2.4" ||
            props.myDataType === "3.1" ||
            props.myDataType === "3.2" ||
            props.myDataType === "5.1" ||
            props.myDataType === "5.2" ||
            props.myDataType === "6.1" ||
            props.myDataType === "6.2" ||
            props.myDataType === "7.1" ||
            props.myDataType === "8.1" ||
            props.myDataType === "14.1" ||
            props.myDataType === "14.2" ||
            props.myDataType === "14.3" ||
            props.myDataType === "14.4" ||
            props.myDataType === "14.5" ||
            props.myDataType === "14.30" ||
            props.myDataType === "14.31" ||
            props.myDataType === "15.1" ||
            props.myDataType === "16.1") {
          if(category.category !== 'Retail' && category.category !== 'Patient' && category.category !== 'Client within E.U.' && category.category !== 'Client outside E.U.'){
            clArray.push(category);
          }
        } else if (props.myDataType === "8.2" || props.myDataType === "11.1" || props.myDataType === "11.2" || props.myDataType === "11.3" || props.myDataType === "11.4" || props.myDataType === "11.5" || props.myDataType === "13.1" || props.myDataType === "13.2" || props.myDataType === "13.3" || props.myDataType === "13.4" || props.myDataType === "13.30" || props.myDataType === "13.31"){
          if(category.category === 'Retail' || category.category === 'Patient'){
            clArray.push(category);
          }
        } else if(props.myDataType === "1.2" || props.myDataType === "2.2") {
          if(category.category === 'Client within E.U.'){
            clArray.push(category);
          }
        } else if(props.myDataType === "1.3" || props.myDataType === "2.3") {
          if(category.category === 'Client outside E.U.'){
            clArray.push(category);
          }
        } else if(props.myDataType === "9.3") {
          clArray.push(category);
        }
        return category;
      })
      setClientCategories(clArray);
    }
  }

  const handleSaveClientModal = () => {
    let newClientData = {...CLIENTS_DATA.newClientModal, ...requestData}
    let validCheck = true;
    let isValidVatNumber = true;
    let isValidDOY = true;
    let isValidActivity = true;
    let isValidCountry = true;
    let isValidAddress = true;
    let isValidCity = true;
    let isValidPC = true;
    let isValidVatCategory = true;
    let isValidEmailPhone = true;

    // Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:  Mandatory field
    if(newClientData.categoryName === 'Freelancer' ||
    newClientData.categoryName === 'O.E.' ||
    newClientData.categoryName === 'E.E.' ||
    newClientData.categoryName === 'E.P.E.' ||
    newClientData.categoryName === 'I.K.E.' ||
        newClientData.categoryName === "Non-Profit Organization" ||
        newClientData.categoryName === 'Wholesale Others' ||
    newClientData.categoryName === 'A.E.'){
      if (newClientData.vatNumber === '') {
        isValidVatNumber = false;
        console.log('invalid vat number')
      }
      if (newClientData.DOY === '' && newClientData.categoryName !== "Client within E.U." && newClientData.categoryName !== "Client outside E.U.") {
        isValidDOY = false;
        console.log('invalid doy')
      }
    }

    // Mandatory field EXCEPT if category retail & patient
    if(
      newClientData.categoryName !== 'Retail' &&
      newClientData.categoryName !== 'Patient'
      ){
      if (newClientData.activity === '' && newClientData.categoryName !== "Client within E.U." && newClientData.categoryName !== "Client outside E.U.") {
        isValidActivity = false;
        console.log('invalid activity')
      }
      if (newClientData.country === '') {
        isValidCountry = false;
        console.log('invalid country')
      }
      if (newClientData.address === '') {
        isValidAddress = false;
        console.log('invalid address')
      }
      if (newClientData.city === '') {
        isValidCity = false;
        console.log('invalid city')
      }
      if (newClientData.postalCode === '') {
        isValidPC = false;
        console.log('invalid pc')
      }
    }
    //mandatory address in patients
    if(newClientData.categoryName === "Patient"){
      if (newClientData.address === "") {
        isValidAddress = false;
        console.log("invalid address");
      }
    }

    // Do not show if Client Category is "Client within E.U." or "Client outside E.U."
    if (
      newClientData.vatCategory === '' &&
      newClientData.categoryName !== 'Client within E.U.' &&
      newClientData.categoryName !== 'Client outside E.U.'
    ) {
      isValidVatCategory = false
      console.log('invalid category');
    }
    if (newClientData.email === "" && newClientData.phone === "" && newClientData.categoryName !== "Patient") {
      isValidEmailPhone = false;
      console.log("invalid email or phone");
    }
    if (newClientData.email === "" && newClientData.phone === "" && newClientData.amka === "" && newClientData.categoryName === "Patient") {
      isValidEmailPhone = false;
      console.log("invalid email or phone or amka");
    }

    if (
      newClientData.category === '' ||
      newClientData.name === '' ||
      !isValidVatNumber ||
      !isValidDOY ||
      !isValidActivity ||
      !isValidCountry ||
      !isValidAddress ||
      !isValidCity ||
      !isValidPC ||
      !isValidEmailPhone ||
      !isValidVatCategory
    ) {
      toast.error('Please check all mandatory fields.');
      validCheck = false
    }
    if(validateAFM(newClientData.vatNumber) === false &&
        (newClientData.categoryName === "Freelancer" ||
            newClientData.categoryName === "O.E." ||
            newClientData.categoryName === "E.E." ||
            newClientData.categoryName === "E.P.E." ||
            newClientData.categoryName === 'Wholesale Others' ||
            newClientData.categoryName === "I.K.E." ||
            newClientData.categoryName === "Non-Profit Organization" ||
            newClientData.categoryName === "A.E.")){
      toast.error("Vat Number is wrong. Please check again!");
      validCheck = false;
    }

    if (validCheck) {
      setLoadingSave(true);
      axios.post(apiURL2 + `/client/add-client`, newClientData, { headers: { 'Content-Type': 'application/json'}})
      .then((res) => {
        console.log(res.data)
        if (res.data &&  res.data.status === '200') {
          toast.success("Client added successfully.");
          dispatch(fetchInitialClients(requestData));  // Fetch list again
          // Set New Client
          dispatch(resetNewClientModal());
          const newClientData = res.data.data;
          if (newClientData) {
            const clientBasicData = {
              clientName: newClientData.name,
              client: newClientData._id,
              clientCode: newClientData.systemCode,
              clientType: 'Existing',
              address: newClientData.address,
              vat: newClientData.vatNumber,
              doy: getDOYOptions().find((el) => String(el.value) === String(newClientData.DOY))?.label,
              city: newClientData.city,
              pc: newClientData.postalCode,
              country: newClientData.country,
              clientInstallationId: "0",
              clientInstallationType: "1",
              email: newClientData.email,
              phone: newClientData.phone,
              activity: newClientData.activity
            }
            newClientData.installations.map(installation => {
              installation.type = getLabelByValue(getInstallationsTypes(), installation.type, 'value');
              return installation;
            })
            clientBasicData.clientInstallationType = newClientData.installations[0]?.type;
            clientBasicData.clientInstallationId = newClientData.installations[0]?.no;

            const salesData = SALES.newSales;
            const newSalesData = {...salesData, ...clientBasicData}

            dispatch(setNewSales(newSalesData)); // Set auto select the newly added client
          }
          setShowClientModal(false); // Close modal
        } else {
          toast.error(res.data.message);
        }
        setLoadingSave(false);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.errors) {
          toast.error(error.response.data.errors);
          setShowClientModal(false); // Close modal
        }
        setLoadingSave(false);
      })

    }

  }
  return (
    <div className="new-client-modal">
      <i title='Add new client' onClick={ () =>{ setShowClientModal(true); handleClientCategories();} } className='fas border ml-2 fa-plus add-client-icon'></i>
      <Modal size="xl" className="themeModal" show={showClientModal} onHide={() => handleCancelClientModal()} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t('NewClientModal.message')}</Modal.Title>
        </Modal.Header>
          <Modal.Body className='new-client-modal-body'>
            {disabledFields && <div className="overlay_w">{t('NewClientModal.message2')}</div>}
              <Row>
                <Col sm='12' md="3" className={CLIENTS_DATA.newClientModal.category === '' ? "showWithOverlay" : ""}>
                  <Dropdown
                    label={t('NewClientModal.category')}
                    required={true}
                    options={clientCategoriesOption}
                    classes="w-100 mb-3"
                    name="category"
                    isCLientCategory={true}
                    key={ Math.random() }
                    defaultValue={getDefaultOptionByValue(clientCategories, CLIENTS_DATA.newClientModal.category, '_id', 'category', '_id')}
                    onChange = {(e) => handleOnChange(e, 'dd', 'category') }
                  />
                </Col>
                <Col sm='12' md="3">
                  <Input
                    label={t('NewClientModal.systemCode.systemCode')}
                    className="mb-3 w-100"
                    disabled={true}
                    name="systemCode"
                    value={getMaskCodeWithNewPrefix(CLIENTS_DATA.newClientModal.systemCode, CLIENTS_DATA.newClientModal.categoryMaskCodePrefix)}
                    placeholder={t('NewClientModal.systemCode.placeholder')}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm='12' md="3">
                <div className="mb-3">
                  <Input
                      label={t('NewClientModal.name.name')}
                      required = {true}
                      className="w-100"
                      name="name"
                      type="text"
                      value={CLIENTS_DATA.newClientModal.name}
                      placeholder={t('NewClientModal.name.placeholder')}
                      onChange = {(e) => handleOnChange(e) }
                    />
                    {showErrors && CLIENTS_DATA.newClientModal.name === '' && <div className="text-danger">{t('NewClientModal.name.required')}</div> }
                  </div>
                </Col>

                <Col sm='12' md="3">
                  <div className='mb-3'>
                    <Input
                      label={t('NewClientModal.email.email')}
                      className="w-100"
                      name="email"
                      type="email"
                      value={CLIENTS_DATA.newClientModal.email}
                      placeholder={t('NewClientModal.email.placeholder')}
                      onChange = {(e) => handleOnChange(e) }
                    />
                    { showErrors &&
                      CLIENTS_DATA.newClientModal.email === '' && !isValidEmail(CLIENTS_DATA.newClientModal.email) && CLIENTS_DATA.newClientModal.categoryName !== "Patient" &&
                      <div className="text-danger">{t('NewClientModal.email.required')}</div>
                    }
                    {showErrors &&
                        CLIENTS_DATA.newClientModal.categoryName === "Patient"  &&
                        CLIENTS_DATA.newClientModal.email === "" &&
                        CLIENTS_DATA.newClientModal.amka === "" &&
                        CLIENTS_DATA.newClientModal.phone === "" && (
                            <div className="text-danger">{t("ClientsNewGeneral.amka.required")}</div>
                        )}
                  </div>
                </Col>

                <Col sm='12' md="3">
                  <Input
                    label={t('NewClientModal.phone.phone')}
                    className="mb-3 w-100"
                    name="phone"
                    type="tel"
                    charLimit={20}
                    value={CLIENTS_DATA.newClientModal.phone}
                    placeholder={t('NewClientModal.phone.placeholder')}
                    onChange = {(e) => handleOnChange(e) }
                  />
                  {showErrors &&
                      CLIENTS_DATA.newClientModal.categoryName === "Patient"  &&
                      CLIENTS_DATA.newClientModal.email === "" &&
                      CLIENTS_DATA.newClientModal.amka === "" &&
                      CLIENTS_DATA.newClientModal.phone === "" && (
                          <div className="text-danger">{t("ClientsNewGeneral.amka.required")}</div>
                      )}
                </Col>

                {/* Do not show if Client Category is "Client within E.U." or "Client outside E.U." */}
                <Col sm='12' md="3">
                  <div className='mb-3'>
                    <Dropdown
                      label={t('NewClientModal.vatCategory.vatCategory')}
                      classes="w-100"
                      required={true}
                      name="vatCategory"
                      key = {Math.random()}
                      defaultValue={getDefaultOptionByValue(vatCategoryList, CLIENTS_DATA.newClientModal.vatCategory, 'value', 'label', 'value')}
                      options={vatCategoryList}
                      onChange = {(e) => handleOnChange(e, 'dd', 'vatCategory') }
                    />
                    {showErrors && CLIENTS_DATA.newClientModal.vatCategory === '' && <div className="text-danger">{t('NewClientModal.vatCategory.requirement')}</div> }
                  </div>
                  </Col>
                {(CLIENTS_DATA.newClientModal.vatCategory !== "" && CLIENTS_DATA.newClientModal.vatCategory === 'Zero VAT') && (
                    <Col sm="12" md="4">
                      <div className="mb-3">
                        <Dropdown
                            classes='w-100'
                            label={t('NewClientModal.0%VatReason.0%VatReason')}
                            key = {Math.random()}
                            name="vatReason"
                            required={true}
                            options={zeroVatReasonOptions}
                            defaultValue={CLIENTS_DATA.newClientModal.vatReason ? { value: CLIENTS_DATA.newClientModal.vatReason, label: getLabelByValue(zeroVatReasonOptions, CLIENTS_DATA.newClientModal.vatReason,'value'), color: "#76AEB7" } : null}
                            onChange = {(e) => handleOnChange(e, 'dd', 'vatReason')}
                        />
                        {showErrors && CLIENTS_DATA.newClientModal.vatCategory === 'Zero VAT' && CLIENTS_DATA.newClientModal.vatReason === "" && (
                            <div className="text-danger">{t('NewClientModal.0%VatReason.required')}</div>
                        )}
                      </div>
                    </Col>
                )}
                { (CLIENTS_DATA.newClientModal.vatCategory !== "" && CLIENTS_DATA.newClientModal.vatCategory === 'Custom VAT') &&
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Input
                            name="customVat"
                            type="number"
                            validate={isValidCustomVat}
                            placeholder={t('NewClientModal.customVat.placeholder')}
                            label={t('NewClientModal.customVat.customVat')}
                            charLimit={3}
                            onChange = {(e) => handleOnChange(e)}
                            value={ CLIENTS_DATA.newClientModal.customVat }
                        />
                        { (CLIENTS_DATA.newClientModal.vatCategory === 'Custom VAT' &&
                                CLIENTS_DATA.newClientModal.customVat === "") &&
                            <div className="text-danger">{t('NewClientModal.customVat.required')}</div>
                        }
                      </div>
                    </Col>
                }
              </Row>
              <Row>
                {/* Retail: Add 999999999, do not validate VAT number. Field not mandatory */}
                {/* Client inside E.U.,outside E.U., Do not validate VAT, do not enter anything. Not mandatory field */}
                { (CLIENTS_DATA.newClientModal.categoryName === 'Client within E.U.' ||
                  CLIENTS_DATA.newClientModal.categoryName === 'Client outside E.U.' ||
                  CLIENTS_DATA.newClientModal.categoryName === 'Retail' ||
                  CLIENTS_DATA.newClientModal.categoryName === 'Patient'
                  ) &&
                  <Col sm='12' md="3">
                      <Input
                        label={t('NewClientModal.vatNumber.vatNumber')}
                        className="mb-3 w-100 bg-white"
                        name="vatNumber"
                        charLimit={20}
                        type="number"
                        value={CLIENTS_DATA.newClientModal.vatNumber}
                        placeholder={t("General.vatNumber")}
                        onChange = {(e) => handleOnChange(e) }
                    />
                  </Col>
                }

                {/* Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.: Validate VAT number, use "Get Details" button to get company details. Mandatory field */}
                { (
                  CLIENTS_DATA.newClientModal.categoryName === 'Freelancer' ||
                  CLIENTS_DATA.newClientModal.categoryName === 'O.E.' ||
                  CLIENTS_DATA.newClientModal.categoryName === 'E.E.' ||
                  CLIENTS_DATA.newClientModal.categoryName === 'E.P.E.' ||
                  CLIENTS_DATA.newClientModal.categoryName === 'Wholesale Others' ||
                  CLIENTS_DATA.newClientModal.categoryName === 'I.K.E.' ||
                  CLIENTS_DATA.newClientModal.categoryName === "Non-Profit Organization" ||
                  CLIENTS_DATA.newClientModal.categoryName === 'A.E.'
                  ) &&
                  <Col sm='12' md="4">
                    <div className='mb-3'>
                      <Input
                        label={t('NewClientModal.vatNumber2.vatNumber2')}
                        className="w-100 bg-white"
                        name="vatNumber"
                        required={true}
                        charLimit={20}
                        type="number"
                        value={CLIENTS_DATA.newClientModal.vatNumber}
                        placeholder={t('NewClientModal.vatNumber2.placeholder')}
                        onChange = {(e) => handleOnChange(e) }
                      />
                      {showErrors && CLIENTS_DATA.newClientModal.vatNumber === '' && <div className="text-danger">{t('NewClientModal.vatNumber2.required')}</div> }
                    </div>
                  </Col>
                }

                {/* Show only if category is NOT Retail or Patient */}
                {/* Use "Get Greek Company Details from taxisnet" algorithm */}
                { (CLIENTS_DATA.newClientModal.categoryName !== '' &&
                  CLIENTS_DATA.newClientModal.categoryName !== 'Retail' &&
                  CLIENTS_DATA.newClientModal.categoryName !== 'Patient') &&
                  <Col sm='12' md="2" className="d-flex align-items-center">
                    <Button onClick={() => fetchTaxisNetDetails(CLIENTS_DATA.newClientModal.vatNumber)} size="sm" className="mb-3" type='button'>{t('NewClientModal.getDetails')}</Button>
                  </Col>
                }

                <Col sm='12' md="3">
                  {/* Retail or patient: not mandatory fields
                  Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:  Mandatory field
                  Client inside E.U.,outside E.U., Not mandatory field */}
                  <Dropdown
                    label={t('NewClientModal.doy.doy')}
                    classes="w-100"
                    required={
                        (
                            CLIENTS_DATA.newClientModal.categoryName !== 'Retail' &&
                            CLIENTS_DATA.newClientModal.categoryName !== 'Patient' &&
                            CLIENTS_DATA.newClientModal.categoryName !== 'Client within E.U.' &&
                            CLIENTS_DATA.newClientModal.categoryName !== 'Client outside E.U.'
                        )
                    }
                    searchEnable = {true}
                    name="DOY"
                    key = {Math.random()}
                    defaultValue={getDefaultOptionByValue(getDOYOptions(), CLIENTS_DATA.newClientModal.DOY, 'value', 'label', 'value')}
                    options={getDOYOptions()}
                    onChange = {(e) => handleOnChange(e, 'dd', 'DOY') }
                  />
                  { (
                    CLIENTS_DATA.newClientModal.categoryName !== 'Retail' &&
                    CLIENTS_DATA.newClientModal.categoryName !== 'Patient' &&
                    CLIENTS_DATA.newClientModal.categoryName !== 'Client within E.U.' &&
                    CLIENTS_DATA.newClientModal.categoryName !== 'Client outside E.U.'
                    ) &&
                    showErrors &&
                    CLIENTS_DATA.newClientModal.DOY === '' &&
                    <div className="text-danger">{t('NewClientModal.doy.required')}</div>
                  }
                </Col>
                <Col sm="12" md="3">
                  <Input
                      label={t('NewClientModal.activity.activity')}
                      className="w-100"
                      name="activity"
                      type="text"
                      charLimit={100}
                      required={
                          CLIENTS_DATA.newClientModal.categoryName !== "Retail" &&
                          CLIENTS_DATA.newClientModal.categoryName !== "Patient"
                      }
                      value={CLIENTS_DATA.newClientModal.activity}
                      placeholder={t('NewClientModal.activity.placeholder')}
                      onChange={(e) => handleOnChange(e)}
                  />
                  {CLIENTS_DATA.newClientModal.categoryName !== "Retail" &&
                      CLIENTS_DATA.newClientModal.categoryName !== "Patient" &&
                      showErrors &&
                      CLIENTS_DATA.newClientModal.activity === "" && (
                          <div className="text-danger">{t('NewClientModal.activity.required')}</div>
                      )}
                </Col>
                {/* show only if Category is "Patient" */}
                { (CLIENTS_DATA.newClientModal.categoryName === 'Patient') &&
                  <Col sm='12' md="3">
                    <div className='mb-3'>
                      <Input
                        label={t('NewClientModal.amka.amka')}
                        className="mb-3 w-100"
                        name="amka"
                        type="text"
                        charLimit={30}
                        value={CLIENTS_DATA.newClientModal.amka}
                        placeholder={t('NewClientModal.amka.placeholder')}
                        onChange = {(e) => handleOnChange(e) }
                      />
                    </div>
                    {showErrors &&
                        CLIENTS_DATA.newClientModal.categoryName === "Patient"  &&
                        CLIENTS_DATA.newClientModal.email === "" &&
                        CLIENTS_DATA.newClientModal.amka === "" &&
                        CLIENTS_DATA.newClientModal.phone === "" && (
                            <div className="text-danger">{t("ClientsNewGeneral.amka.required")}</div>
                        )}
                  </Col>
                }
              </Row>
              <Row>
                <Col sm='12' md="3">
                  {/* Mandatory field EXCEPT if category retail & patient */}
                  <Dropdown
                    label={t('NewClientModal.country.country')}
                    classes="w-100"
                    required={(CLIENTS_DATA.newClientModal.categoryName !== 'Retail' && CLIENTS_DATA.newClientModal.categoryName !== 'Patient')}
                    name="country"
                    searchEnable = {true}
                    key = {Math.random()}
                    defaultValue={getDefaultOptionByValue(countriesList, CLIENTS_DATA.newClientModal.country, 'value', 'label', 'value')}
                    options={countriesList}
                    onChange = {(e) => handleOnChange(e, 'dd', 'country') }
                  />
                  { (CLIENTS_DATA.newClientModal.categoryName !== 'Retail' &&
                    CLIENTS_DATA.newClientModal.categoryName !== 'Patient') &&
                    showErrors &&
                    CLIENTS_DATA.newClientModal.country === '' &&
                    <div className="text-danger">{t('NewClientModal.country.required')}</div>
                  }
                </Col>
                <Col sm='12' md="3">
                  <div className='mb-3'>
                    {/* Mandatory field EXCEPT if category retail & patient */}
                    <Input
                      label={t('NewClientModal.address.address')}
                      className="w-100"
                      name="address"
                      type="text"
                      charLimit={100}
                      required={(CLIENTS_DATA.newClientModal.categoryName !== 'Retail')}
                      value={CLIENTS_DATA.newClientModal.address}
                      placeholder={t('NewClientModal.address.placeholder')}
                      onChange = {(e) => handleOnChange(e) }
                    />
                    { (CLIENTS_DATA.newClientModal.categoryName !== 'Retail') &&
                      showErrors &&
                      CLIENTS_DATA.newClientModal.address === '' &&
                      <div className="text-danger">{t('NewClientModal.address.required')}</div>
                    }
                  </div>
                </Col>
                <Col sm="12" md="3">
                  <div className="mb-3">
                    {/* Mandatory field EXCEPT if category retail & patient */}
                    <Input
                        label={t('NewClientModal.city.city')}
                        className="w-100"
                        name="city"
                        type="text"
                        charLimit={100}
                        required={
                            CLIENTS_DATA.newClientModal.categoryName !== "Retail" &&
                            CLIENTS_DATA.newClientModal.categoryName !== "Patient"
                        }
                        value={CLIENTS_DATA.newClientModal.city}
                        placeholder={t('NewClientModal.city.placeholder')}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {CLIENTS_DATA.newClientModal.categoryName !== "Retail" &&
                        CLIENTS_DATA.newClientModal.categoryName !== "Patient" &&
                        showErrors &&
                        CLIENTS_DATA.newClientModal.city === "" && (
                            <div className="text-danger">{t('NewClientModal.city.required')}</div>
                        )}
                  </div>
                </Col>
                <Col sm='12' md="3">
                  {/* Mandatory field EXCEPT if category retail & patient */}
                  <div className='mb-3'>
                    <Input
                      label={t('NewClientModal.postalCode.postalCode')}
                      className="w-100"
                      name="postalCode"
                      type="text"
                      required={(CLIENTS_DATA.newClientModal.categoryName !== 'Retail' && CLIENTS_DATA.newClientModal.categoryName !== 'Patient')}
                      value={CLIENTS_DATA.newClientModal.postalCode}
                      placeholder={t('NewClientModal.postalCode.placeholder')}
                      onChange = {(e) => handleOnChange(e) }
                    />
                    { (CLIENTS_DATA.newClientModal.categoryName !== 'Retail' &&
                      CLIENTS_DATA.newClientModal.categoryName !== 'Patient') &&
                      showErrors &&
                      CLIENTS_DATA.newClientModal.postalCode === '' &&
                      <div className="text-danger">{t('NewClientModal.postalCode.required')}</div>
                    }
                  </div>
                </Col>
              </Row>
          </Modal.Body>
        <Modal.Footer>
          <Button disabled = {loadingSave} variant="outline-primary" onClick={ () => handleCancelClientModal() }>{t('NewClientModal.cancel')}</Button>
          <Button disabled = {loadingSave} variant="primary" onClick={ () => handleSaveClientModal() } >
            {
              loadingSave &&
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            }
            {t('NewClientModal.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default NewClientModal;
