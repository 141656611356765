// Used in Advanced Combobox
export const otherTaxesCols = [
  {
    label: "ID",
    name: "id",
  },
  {
    label: "Code",
    name: "code",
  },
  {
    label: "Description",
    name: "description",
  },
  {
    label: "Taxes",
    name: "taxes",
  },
]; 