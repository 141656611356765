import { createSlice } from "@reduxjs/toolkit";
import { measurementUnitsData } from "../../../_data/mydata/measurementUnits";
import { getObjectFromArrayOfObjects } from "../../../_helpers/helperFunctions";
import moment from "moment";
const currentMonth = moment().format("MM");
const current = new Date();

const productInitialValue = {
  name: "",
  alternateName: "",
  code: "PC-001",
  alternateCode: "",
  barcode: "",
  vat: "",
  customVat: "",
  vatReason: "",
  isAsset: "",
  isAsset2: "",
  assetCategory: "",
  price: "0",
  buyPrice: "0",
  vatIncluded: true,
  vatIncludedBuy: true,
  measurementUnit: "",
  notes: "",
  isTaxLine: "false",
  isTaxLineWithoutVat: "false",
  otherTaxes: "",
  withholdTax: "",
  accountingCode: "",
  netAmountAccountingCode: "",
  vatAccountingCode: "",
  taxAccountCode: "",
  accountingNonDeductibleVAT: "inactive",
  stamp: "",
  fee: "",
  category: "",
  categoryName: "",
  installationsInventory: [],
  mydata: [],
  isActive: "active",
  inventoryBalance: 0,
  productCodes: [],
  productProperties: [],
  variants: [],
  displayedVariants: [],
  tags: [],
  showAlternativeNameOnPrint: "false",
  showAlternativeCodeOnPrint: "false",
  cpvCode: "",
}

const newVariantInitialValue= {
  name: "",
  isActive: "active",
  type: "variant",
  properties: {},
  price: 0,
  priceVariations: {},
  totalPrice: 0,
  inventoryBalance: 0
}

const newProductMovementInitialValue = {
  documentType: '',
  documentTypeName: "",
  document_file: '',
  number: '',
  date: new Date(),
  reason: '',
  sum: 0,
  products: [],
  notes: '',
  installation: "",
  preNumber: { value: "", label: "Κενή" },
}

const newProductsMyDataInitialValue = {
  id: 0,
  rowType: '',
  invoiceType: '',
  category: '',
  type: '',
  vatExpenseType: ''
}

const newProductCodeInitialValue = {
  no: 0,
  id: 0,
  pcid: 0,
  name: '',
  supplierName: '',
  code: '',
  isActive: 'active'
}

const priceListNewProductInitialValue = {
  no: 1,
  name: "",
  code: "",
  client: [],
  price: 0,
  vat: true,
  discount: 0,
  isPerDiscount: "false",
  isDiscountAmount: "true",
}

const newPriceListInitialValue = {
  name: "",
  price: "0",
  productList:[],
  notes:"",
  isActive: 'active',
}

const productMovementsNewProductInitialValue = {
  no: 1,
  name: "",
  code: "",
  measurementUnit: "",
  quantity: 0,
}

const productPropertyInitialState = {
  name: "",
  values: ""
}

const initialState = {
  firstLoad: true,
  loading: false,
  hasErrors: false,
  editProduct: false,
  products: [],
  searchProducts: [],
  totalItemsPS: 0,
  perPagePS: 1,
  totalItemsPM: 0,
  perPagePM: 1,
  totalItemsPP: 0,
  perPagePP: 1,

  selectedStartDateFrom : null,
  selectedZero: false,
  selectedInactive: false,
  selectedStartDateTo : null,
  selectedFilterBy : currentMonth,
  selectedInactiveBalance: false,
  searchProductMovements: [],
  searchProductPriceList: [],
  selectedProduct: productInitialValue,
  selectedEditProduct: {},
  selectedProductFrom: {},
  selectedProductTo: {},
  selectedBalanceProductFrom: {},
  selectedBalanceProductTo: {},
  productInitialValue: productInitialValue,
  newProduct: productInitialValue,
  newProductsMyDataInitialValue: newProductsMyDataInitialValue,
  newProductsMyData: newProductsMyDataInitialValue,
  editProductsMyData: newProductsMyDataInitialValue,
  selectedNewVariantsRows: [],
  selectedEditVariantsRows: [],
  productTemplates: [],
  selectedProductTemplate: {},
  productMovements: [],
  productMovementsTemplates: [],
  selectedProductMovementsTemplate: {},
  productPriceListTemplates: [],
  selectedProductPriceListTemplate: {},
  submitting: false,
  allCheckedNewVariants: false,
  allCheckedEditVariants: false,
  selectedCategoryData: {},
  productMovementsInitialValues : {
    documentType: '',
    document_file: '',
    number: '',
    date: '',
    reason: '',
    sum: 0,
    notes: '',
  },
  newProductMovementInitialValue: newProductMovementInitialValue,
  newProductMovements: newProductMovementInitialValue,
  isNumberExists: false,
  editProductMovements: newProductMovementInitialValue,
  productMovementsNewProductInitialValue: productMovementsNewProductInitialValue,
  productMovementsNewProduct: productMovementsNewProductInitialValue,
  productMovementsEditProduct: productMovementsNewProductInitialValue,
  priceListProducts: [], // used for price list
  selectedInstallationProductBalance: "",
  selectedZeroBalance: false,
  newPriceListInitialValue : newPriceListInitialValue,
  newPriceList: newPriceListInitialValue,
  editPriceList: newPriceListInitialValue,
  selectedPriceList: {},
  selectedDateBalance : current,
  priceListNewProductInitialValue: priceListNewProductInitialValue,
  priceListNewProduct: priceListNewProductInitialValue,
  priceListEditProduct: priceListNewProductInitialValue,
  newProductCodeInitialValue: newProductCodeInitialValue,
  newProductCode: newProductCodeInitialValue,
  editProductCode: newProductCodeInitialValue,
  productCodes: [],
  newVariantSearchTerm: "",
  editVariantSearchTerm: "",
  tempNewProductProperties: productPropertyInitialState,
  tempEditProductProperties: productPropertyInitialState,
  newVariantInitialValue: newVariantInitialValue,
  newVariant: newVariantInitialValue,
  variants: [],
  measurementUnits: measurementUnitsData,

  // Balance Sheet Report
  productBalanceReportRequestData: {
    page: 1,
    totalPages: 0,
    date_to: "",
    productName: "",
    product_inactive: "inActive",
    product_zero: "inActive",
    installation: [],
  },
  productBalanceReportData: [],
  productBalanceReportTotalPages : 0,
  productBalanceReportPage: 1,

  // Product Movement Report
  productMovementsRequestData: {
    page: 1,
    totalPages: 0,
    date_from: "",
    date_to: "",
    productName: "",
    product_inactive: "inActive",
    product_zero: "inActive",
    numberFrom: "",
    numberTo: "",
    installation: [],
  },
  productMovementsReportData: {},
  productMovementsReportCollectData: [],
}

// Product Slice
export const ProductsSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setFirstLoad: (state, { payload }) => void (state.firstLoad = payload),

    setProducts: (state, { payload }) => {
      state.products = payload;
    },

    setSearchProducts: (state, { payload }) => {
      state.searchProducts = payload;
    },

    setTotalItemsPS: (state, { payload }) => {
      state.totalItemsPS = payload;
    },
    setTotalItemsPM: (state, { payload }) => {
      state.totalItemsPM = payload;
    },
    setTotalItemsPP: (state, { payload }) => {
      state.totalItemsPP = payload;
    },
    setPerPagePS: (state, { payload }) => {
      state.perPagePS = payload;
    },
    setPerPagePM: (state, { payload }) => {
      state.perPagePM = payload;
    },
    setPerPagePP: (state, { payload }) => {
      state.perPagePP = payload;
    },

    setSearchProductMovements: (state, { payload }) => {
      state.searchProductMovements = getObjectFromArrayOfObjects(payload, 'active', 'isActive');
    },

    setSearchProductPriceList: (state, { payload }) => {
     state.searchProductPriceList = payload;
    },

    setSelectedProduct: (state, { payload }) => {
      state.selectedProduct = payload;
    },

    resetSelectedProduct: (state) => {
      state.selectedProduct = state.productInitialValue;
    },

    setSelectedEditProduct: (state, { payload }) => {
      state.selectedEditProduct = payload;
    },

    setProductTemplates: (state, { payload }) => {
      state.productTemplates = payload;
    },

    setSelectedProductTemplate: (state, { payload }) => {
      state.selectedProductTemplate = payload;
    },

    setProductMovements: (state, { payload }) => {
      state.productMovements = payload;
    },

    setNewProductMovements: (state, { payload }) => {
      state.newProductMovements = payload;
    },

    setEditProductMovements: (state, { payload }) => {
      state.editProductMovements = payload;
    },

    resetNewProductMovements: (state) => {
      state.newProductMovements = state.newProductMovementInitialValue;
    },

    setProductMovementsTemplates: (state, { payload }) => {
      state.productMovementsTemplates = payload;
    },

    setSelectedProductMovementsTemplate: (state, { payload }) => {
      state.selectedProductMovementsTemplate = payload;
    },

    setProductPriceListTemplates: (state, { payload }) => {
      state.productPriceListTemplates = payload;
    },

    setSelectedProductPriceListTemplate: (state, { payload }) => {
      state.selectedProductPriceListTemplate = payload;
    },

    setProductSubmitting: (state, { payload }) => {
      state.submitting = payload;
    },

    setProductsSelectedCategoryData: (state, { payload }) => {
      state.selectedCategoryData = payload;
    },

    setNewPriceList: (state, { payload }) => {
      state.newPriceList = payload;
    },

    resetNewPriceList: (state, { payload }) => {
      state.newPriceList = state.newPriceListInitialValue;
    },

    setEditPriceList: (state, { payload }) => {
      state.editPriceList = payload;
    },

    setPriceListProducts: (state, { payload }) => {
      state.searchProductPriceList = payload;
    },

    setNewProductsMyData: (state, { payload }) => {
      state.newProductsMyData = payload;
    },

    setEditProductsMyData: (state, { payload }) => {
      state.editProductsMyData = payload;
    },

    setProductCodes: (state, { payload }) => {
      state.productCodes = payload;
      state.newProduct.productCodes = payload;
      state.selectedProduct.productCodes = payload;
    },

    setEditProductCodes: (state, { payload }) => {
      state.selectedEditProduct.productCodes = payload;
    },

    setNewProductCode: (state, { payload }) => {
      state.newProductCode = payload;
    },

    setEditProductCode: (state, { payload }) => {
      state.editProductCode = payload;
    },

    setPriceListNewProduct: (state, { payload }) => {
      state.priceListNewProduct = payload;
    },

    setPriceListEditProduct: (state, { payload }) => {
      state.priceListEditProduct = payload;
    },

    setSelectedProductFrom: (state, { payload }) => {
      state.selectedProductFrom = payload;
    },
    setSelectedProductTo: (state, { payload }) => {
      state.selectedProductTo = payload;
    },
    setSelectedBalanceProductFrom: (state, { payload }) => {
      state.selectedBalanceProductFrom = payload;
    },
    setSelectedBalanceProductTo: (state, { payload }) => {
      state.selectedBalanceProductTo = payload;
    },

    setProductMovementsNewProduct: (state, { payload }) => {
      state.productMovementsNewProduct = payload;
    },

    resetProductMovementsNewProduct: (state, { payload }) => {
      state.productMovementsNewProduct = state.productMovementsNewProductInitialValue;
    },

    setProductMovementsEditProduct: (state, { payload }) => {
      state.productMovementsEditProduct = payload;
    },

    setSelectedStartDateFrom: (state, { payload }) => {
      state.selectedStartDateFrom = payload;
    },
    setSelectedZero: (state, { payload }) => {
      state.selectedZero = payload;
    },
    setSelectedZeroBalance: (state, { payload }) => {
      state.selectedZeroBalance = payload;
    },
    setSelectedInactive: (state, { payload }) => {
      state.selectedInactive = payload;
    },

    setSelectedStartDateTo: (state, { payload }) => {
      state.selectedStartDateTo = payload;
    },
    setSelectedFilterBy: (state, { payload }) => {
      state.selectedFilterBy = payload;
    },
    setSelectedInactiveBalance: (state, { payload }) => {
      state.selectedInactiveBalance = payload;
    },
    setProductBalanceReportData: (state, { payload }) => {
      state.productBalanceReportData = payload;
    },
    setSelectedInstallationProductBalance: (state, { payload }) => {
      state.selectedInstallationProductBalance = payload;
    },
    setSelectedDateBalance: (state, { payload }) => {
      state.selectedDateBalance = payload;
    },

    // Balance Sheet Report
    setProductBalanceReportRequestData: (state, { payload }) => {
      state.productBalanceReportRequestData = payload;
    },

    // Product Movements Report
    setProductMovementsReportRequestData: (state, { payload }) => {
      state.productMovementsRequestData = payload;
    },
    setProductMovementsReportData: (state, { payload }) => {
      state.productMovementsReportData = payload;
    },
    setProductMovementsReportCollectData: (state, { payload }) => {
      state.productMovementsReportCollectData = payload;
    },

    // Product Properties
    setTempNewProductProperties: (state, { payload }) => {
      state.tempNewProductProperties = payload;
    },
    resetTempNewProductProperties: (state, { payload }) => {
      state.tempNewProductProperties = {...productPropertyInitialState};
    },
    setTempEditProductProperties: (state, { payload }) => {
      state.tempEditProductProperties = payload;
    },
    resetTempEditProductProperties: (state, { payload }) => {
      state.tempEditProductProperties = {...productPropertyInitialState};
    },

    // Variants
    addProductEditVariant: (state, { payload }) => {
      if (!state.selectedEditProduct.variants) state.selectedEditProduct.variants = [];
      state.selectedEditProduct.variants.push(payload);
      state.selectedEditProduct.displayedVariants = Array.from(state.selectedEditProduct.variants);
    },
    addProductNewVariant: (state, { payload }) => {
      if (!state.selectedProduct.variants) state.selectedProduct.variants = [];
      state.selectedProduct.variants.push(payload);
      state.selectedProduct.displayedVariants = Array.from(state.selectedProduct.variants);
    },
    addProductEditAllVariants: (state, { payload }) => {
      state.selectedEditProduct.variants = [...state.selectedEditProduct.variants, ...payload];
      state.selectedEditProduct.displayedVariants = [...state.selectedEditProduct.variants, ...payload];
    },
    addProductNewAllVariants: (state, { payload }) => {
      state.selectedProduct.variants = [...state.selectedProduct.variants, ...payload];
      state.selectedProduct.displayedVariants = [...state.selectedProduct.variants, ...payload];
    },
    setNewVariant: (state, { payload }) => {
      state.newVariant = payload;
    },
    resetNewVariant: (state) => {
      state.newVariant = state.newVariantInitialValue;
    },
    resetNewVariantProps: (state) => {
      state.newVariant.properties = {};
      state.newVariant.priceVariations = {};
    },
    setNewVariantIds: (state) => {
      state.selectedProduct.variants.map((variant, index) => {
        return variant.id = index;
      });
      state.selectedProduct.displayedVariants = Array.from(state.selectedProduct.variants);
    },
    setEditVariantIds: (state) => {
      state.selectedEditProduct.variants.map((variant, index) => {
        return variant.id = index;
      });
      state.selectedEditProduct.displayedVariants = Array.from(state.selectedEditProduct.variants);
    },
    updateProductEditVariants: (state, { payload }) => {
      state.selectedEditProduct.variants = payload;
    },
    updateProductNewVariants: (state, { payload }) => {
      state.selectedProduct.variants = payload;
    },
    setNewVariantSearchTerm: (state, { payload }) => {
      state.newVariantSearchTerm = payload;
    },
    setEditVariantSearchTerm: (state, { payload }) => {
      state.editVariantSearchTerm = payload;
    },
    setNewProductDisplayedVariants: (state, { payload }) => {
      state.selectedProduct.displayedVariants = payload;
    },
    setEditProductDisplayedVariants: (state, { payload }) => {
      state.selectedEditProduct.displayedVariants = payload;
    },
    setNewSelectedVariantsRows: (state, { payload }) => {
      state.selectedNewVariantsRows = payload;
    },
    setEditSelectedVariantsRows: (state, { payload }) => {
      state.selectedEditVariantsRows = payload;
    },
    setAllCheckedNewVariants: (state, { payload }) => {
      state.allCheckedNewVariants = payload;
    },
    setAllCheckedEditVariants: (state, { payload }) => {
      state.allCheckedEditVariants = payload;
    },
  },
});

export const {
  setProducts,
  setSearchProducts,
  setTotalItemsPS,
  setPerPagePS,
  setTotalItemsPM,
  setPerPagePM,
  setTotalItemsPP,
  setPerPagePP,
  setSelectedProduct,
  setSelectedEditProduct,
  setProductSubmitting,
  setProductTemplates,
  setSelectedProductTemplate,
  setProductsSelectedCategoryData,
  setNewProductMovements,
  setProductMovementsTemplates,
  setSelectedProductMovementsTemplate,
  setProductPriceListTemplates,
  setSelectedProductPriceListTemplate,
  setNewPriceList,
  resetNewPriceList,
  setPriceListProducts,
  setNewProductsMyData,
  setEditProductsMyData,
  setProductCodes,
  setNewProductCode,
  setPriceListNewProduct,
  setProductMovementsNewProduct,
  resetProductMovementsNewProduct,
  setSearchProductMovements,
  setSearchProductPriceList,
  resetNewProductMovements,
  setEditPriceList,
  setPriceListEditProduct,
  setEditProductMovements,
  setProductMovementsEditProduct,
  setEditProductCodes,
  setEditProductCode,
  resetSelectedProduct,
  setProductBalanceReportData,
  setProductBalanceReportRequestData,
  setProductMovementsReportRequestData,
  setProductMovementsReportData,
  setProductMovementsReportCollectData,
  setFirstLoad,
  setTempNewProductProperties,
  resetTempNewProductProperties,
  setTempEditProductProperties,
  resetTempEditProductProperties,
  setNewVariant,
  addProductEditVariant,
  addProductEditAllVariants,
  addProductNewAllVariants,
  resetNewVariantProps,
  setNewVariantIds,
  setEditVariantIds,
  updateProductEditVariants,
  addProductNewVariant,
  updateProductNewVariants,
  setNewVariantSearchTerm,
  setEditVariantSearchTerm,
  setNewProductDisplayedVariants,
  setEditProductDisplayedVariants,
  setNewSelectedVariantsRows,
  setEditSelectedVariantsRows,
  setAllCheckedNewVariants,
  setAllCheckedEditVariants
} = ProductsSlice.actions;
