import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import Dropdown from "../../../primer/Dropdown";
import Input from '../../../common/Input';
import {
  getCountryOptions,
  getDefaultOptionByValue,
  getDOYOptions,
   getGeneratedMaskCodeCLient,
   getMainInstallation,
  getMaskCodeWithNewPrefix,
  getOptionsByData,
  getVatCategoryAbroadOptions,
  getVatCategoryOptions,
  isValidEmail
} from '../../../../_helpers/commonFunctions';
import { useSelector, useDispatch } from "react-redux";
import {
  setSaveNewSupplier
} from "../../../../_reducers/activitySlice";
import { resetNewSupplier, setNewSupplier } from '../../../../_reducers/DataPanel/Suppliers/supplierSlice';
import { findObjectFromArrayOfObjects, getLabelByValue, getObjectFromArrayOfObjects } from '../../../../_helpers/helperFunctions';
import {
  fetchSuppliersSettings,
  fetchInitialSuppliers,
} from '../../../../_apis/api';
import './supplierModal.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { setNewSales } from '../../../../_reducers/DataPanel/Sales/salesSlice';
import getMyData from "../../../../_helpers/sharedmydata";
const apiURL2 = process.env.REACT_APP_API_URL2;

const NewSupplierModal = (props) => {
  const dispatch = useDispatch();
  const SALES = useSelector( (state) => state.SALES );
  const SUPPLIERS_DATA = useSelector( (state) => state.SUPPLIERS_DATA );
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const company = useSelector(state => state.COMPANY_DATA.company)
  let [supplierCategories,setSupplierCategories] = useState(getObjectFromArrayOfObjects(SUPPLIERS_DATA.suppliersSettings.categories, 'active', 'isActive'));
  const zeroVatReasonOptions = getMyData({type: 'zerovatreason'});
  const supplierCategoriesOption = getOptionsByData(supplierCategories, '_id', 'category');
  let vatCategoryList = SUPPLIERS_DATA.newSupplier.categoryName === "Supplier within E.U." || SUPPLIERS_DATA.newSupplier.categoryName === "Supplier outside E.U." ? getVatCategoryAbroadOptions() : getVatCategoryOptions();
  const isValidCustomVat = (value) => {
    let error;
    if (+value > 100) {
      error = 'Can not be greater than 100.';
    }
    return error;
  }
  let requestData = {
    company:company.id,
    year:company.year,
  }
  useEffect(() => {
    // It will use for validate supplier name
    dispatch(
        fetchInitialSuppliers({
          company: company.id,
          year: company.year,
          isActive: "active",
        })
    );

    dispatch(
        fetchSuppliersSettings({
          company: company.id,
          year: company.year.toString(),
          isActive: "active"
        })
    );
  }, [dispatch]);

  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [showErrors, setShowErrors] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false);
  // let countryData = getCountryOptions(); // get all countries data
  // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.: Select Greece, Accept only Greece, show only Greece
  let countryData = getCountryOptions('greece'); // Only Greece Country Data
  let [countriesList, setCountriesList] = useState(countryData);
  // Data From The API
  let disabledFields = false;
  if (SUPPLIERS_DATA.newSupplier.category === undefined || SUPPLIERS_DATA.newSupplier.category === '') {
    disabledFields = true;
  }

  const handleOnChange = (e, type="default", ddname = '') => {
    setShowErrors(true);

    // Set if editing the form
    if (!ACTIVITY.saveNewSupplier) {
      dispatch(setSaveNewSupplier(true));
    }

    let name;
    let value;

    if (type === 'dd')  {
      name = ddname;
      value = e.value;
    } else if (type === 'ac') {
      name = ddname;
      value = e.row.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    // 9 numbers only
    if (name === "vatNumber" && SUPPLIERS_DATA.newSupplier.categoryName !== "Supplier within E.U." && SUPPLIERS_DATA.newSupplier.categoryName !== "Supplier outside E.U." ) {
      value = +String(value).slice(0, 9);

      // Set Empty if zero
      if (value === 0) {
        value = "";
      }
    }
    if(name === 'customVat'){
      let error;
      if (+value > 100) {
        error = 'Can not be greater than 100.';
        toast.error(error);
        value = "";
      }
      value = +String(value).slice(0, 3);
      if (value === 0) {
        value = "";
      }
    }
    // Set settings data
    let newSupplier = Object.assign({}, SUPPLIERS_DATA.newSupplier);
    let newSupplierData = {};
    if (name === 'category') {
      newSupplierData[name] = value;
      newSupplierData.categoryName = getLabelByValue(supplierCategoriesOption, value, 'value');

      const selectedCategory = findObjectFromArrayOfObjects(supplierCategories, value,'_id');
      if (selectedCategory) {
        newSupplier.categoryMaskCodePrefix = selectedCategory.maskCodePrefix;
        newSupplier.systemCode = getGeneratedMaskCodeCLient(selectedCategory._id, supplierCategories, selectedCategory.maskCodePrefix); // New With Updated Prefix
      }

      // If Category: Retail: Add 999999999, do not validate VAT number. Field not mandatory
      if (newSupplierData.categoryName === 'Retail') {
        newSupplierData.vatNumber = 999999999;
      } else {
        newSupplierData.vatNumber = "";
      }


      if (newSupplierData.categoryName === "Supplier within E.U.") {
        // Update country list for inside EU.
        // Supplier inside E.U., show only countries within E.U. except Greece
        let EuCountryData = getCountryOptions("insideEU");
        setCountriesList(EuCountryData);
      } else if (newSupplierData.categoryName === "Supplier outside E.U.") {
        // Update country list for outside EU
        // outside E.U., show only countries outside E.U.
        let NonEuCountryData = getCountryOptions("outsideEU");
        setCountriesList(NonEuCountryData);
      } else {
        // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:
        // Select Greece, Accept only Greece, show only Greece
        let onlyGreeceCountryData = getCountryOptions("greece");
        setCountriesList(onlyGreeceCountryData);
        if (onlyGreeceCountryData?.length > 0) {
          newSupplierData.country = onlyGreeceCountryData["0"].value;
        }
      }
    } else {
      if(name === 'isActive' && value === 'inactive') {
        setShowSupplierModal(true);
      } else {
        newSupplierData[name] = value;
      }
    }
    vatCategoryList = SUPPLIERS_DATA.newSupplier.categoryName === "Supplier within E.U." || SUPPLIERS_DATA.newSupplier.categoryName === "Supplier outside E.U." ? getVatCategoryAbroadOptions() : getVatCategoryOptions();
    let updatedContactPoints = SUPPLIERS_DATA.newSupplier.contactPoints.slice();
    let newContacts = {
      name: newSupplier.name,
      email: newSupplier.email,
      mobile: newSupplier.phone
    }
    if (e) {
      if (name === "name") {
        newContacts["name"] = value;
      } else if (name === "email") {
        newContacts["email"] = value;
      } else if (name === "phone") {
        newContacts["mobile"] = value;
      } else if (name === "category") {
        newContacts["mobile"] = "";
      }
    }

    updatedContactPoints["0"] = {
      ...updatedContactPoints["0"],
      ...newContacts,
    };
    newSupplier = { ...newSupplier, ...newSupplierData };

    // Default Installation
    let defaultInstallation = [
      {
        id: 0,
        no: 0,
        type: getMainInstallation()?.value,
        address: newSupplier.address,
        DOY: newSupplier.DOY,
        city: newSupplier.city,
        county: newSupplier.county,
        vatCategory: newSupplier.vatCategory,
        vatReason: newSupplier.vatReason,
        customVat: newSupplier.customVat,
        postalCode: newSupplier.postalCode
      },
    ];
    dispatch(
        setNewSupplier({
          ...newSupplier,
          contactPoints: updatedContactPoints,
          installations: defaultInstallation,
        })
    );
  }

  const fetchTaxisNetDetails = async (data) => {
    let jsonData = {
      vatNumber: data,
      onlyAADE: "true"
    }
    const abort = axios.CancelToken.source();
    const timer = setTimeout(
        () => abort.cancel(`timeout`),
        10000
    )
    await axios.post(apiURL2 + `/supplier/fetch-vat-details/`, JSON.stringify(jsonData), { headers: { 'Content-Type': 'application/json'}})
        .then((res) => {
          if (res.data && res.data.status === "200") {
            clearTimeout(timer)
            let companyDetails = res.data.data;
            let newSupplier = Object.assign({}, SUPPLIERS_DATA.newSupplier);
            newSupplier.name = companyDetails?.name !== undefined ? companyDetails?.name : "";
            newSupplier.smallName = companyDetails?.smallName !== undefined ? companyDetails?.smallName : "";
            newSupplier.activity = companyDetails?.activity !== undefined ? companyDetails?.activity : "";
            newSupplier.postalCode = companyDetails?.tk !== undefined ? companyDetails?.tk : "";
            newSupplier.address =  companyDetails?.address !== undefined ? companyDetails?.address : "";
            newSupplier.DOY = getDefaultOptionByValue(getDOYOptions(), companyDetails?.doy, "label", "label", "value").value;
            newSupplier.city = companyDetails?.city !== undefined ? companyDetails?.city : "";
            if(companyDetails?.vatCategory === 'Y'){
              newSupplier.vatCategory = 'Normal VAT';
            }
            let updatedContactPoints = SUPPLIERS_DATA.newSupplier.contactPoints.slice();
            let newContacts = {
              name: newSupplier.name,
              email: newSupplier.email,
              mobile: newSupplier.phone,
            };
            updatedContactPoints["0"] = {
              ...updatedContactPoints["0"],
              ...newContacts,
            };
            let defaultInstallation = [
              {
                id: 0,
                no: 0,
                type: getMainInstallation()?.value,
                address: newSupplier.address,
                DOY: newSupplier.DOY,
                city: newSupplier.city,
                county: newSupplier.county,
                vatCategory: newSupplier.vatCategory,
                vatReason: newSupplier.vatReason,
                customVat: newSupplier.customVat,
                postalCode: newSupplier.postalCode
              },
            ];
            dispatch(setNewSupplier({...newSupplier, contactPoints: updatedContactPoints, installations: defaultInstallation}));
            toast.success(res.data.message);
          } else {
            console.log(res)
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response?.status === 400){
            alert({
              message: error.response.data?.Error ? error.response.data?.Error : error?.message === "timeout" ? "Timeout. Please try again later!" : error.code,
              type: 'warning',
            })
          }
        });
  }

  const handleCancelSupplierModal = () => {
    setShowSupplierModal(false);
    dispatch(resetNewSupplier());    // Set New Supplier
  }

  const handleSupplierCategories = () => {
    if(props && props.myDataType){
      let clArray = [];
      supplierCategories.map((category) => {
        if(props.myDataType === "1.1" ||
            props.myDataType === "1.4" ||
            props.myDataType === "1.5" ||
            props.myDataType === "1.6" ||
            props.myDataType === "2.1" ||
            props.myDataType === "2.4" ||
            props.myDataType === "3.1" ||
            props.myDataType === "3.2" ||
            props.myDataType === "5.1" ||
            props.myDataType === "5.2" ||
            props.myDataType === "6.1" ||
            props.myDataType === "6.2" ||
            props.myDataType === "7.1" ||
            props.myDataType === "8.1" ||
            props.myDataType === "14.1" ||
            props.myDataType === "14.2" ||
            props.myDataType === "14.3" ||
            props.myDataType === "14.4" ||
            props.myDataType === "14.5" ||
            props.myDataType === "14.30" ||
            props.myDataType === "14.31" ||
            props.myDataType === "15.1" ||
            props.myDataType === "16.1") {
          if(category.category !== 'Retail' && category.category !== 'Patient' && category.category !== 'Supplier within E.U.' && category.category !== 'Supplier outside E.U.'){
            clArray.push(category);
          }
        } else if (props.myDataType === "8.2" || props.myDataType === "11.1" || props.myDataType === "11.2" || props.myDataType === "11.3" || props.myDataType === "11.4" || props.myDataType === "11.5" || props.myDataType === "13.1" || props.myDataType === "13.2" || props.myDataType === "13.3" || props.myDataType === "13.4" || props.myDataType === "13.30" || props.myDataType === "13.31"){
          if(category.category === 'Retail' || category.category === 'Patient'){
            clArray.push(category);
          }
        } else if(props.myDataType === "1.2" || props.myDataType === "2.2") {
          if(category.category === 'Supplier within E.U.'){
            clArray.push(category);
          }
        } else if(props.myDataType === "1.3" || props.myDataType === "2.3") {
          if(category.category === 'Supplier outside E.U.'){
            clArray.push(category);
          }
        }
        return category;
      })
      setSupplierCategories(clArray);
    }
  }

  const handleSaveSupplierModal = () => {

    let newSupplierData = {...SUPPLIERS_DATA.newSupplier, ...requestData}
    // let newSupplierData = SUPPLIERS_DATA.newSupplier;

    let validCheck = true;
    let isValidVatNumber = true;
    let isValidDOY = true;
    let isValidActivity = true;
    let isValidCountry = true;
    let isValidAddress = true;
    let isValidCity = true;
    let isValidPC = true;
    let isValidVatCategory = true;
    let isValidEmailPhone = true;

    // Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:  Mandatory field
    if(newSupplierData.categoryName === 'Freelancer' ||
    newSupplierData.categoryName === 'O.E.' ||
    newSupplierData.categoryName === 'E.E.' ||
    newSupplierData.categoryName === 'E.P.E.' ||
    newSupplierData.categoryName === 'I.K.E.' ||
        newSupplierData.categoryName === 'Wholesale Others' ||
    newSupplierData.categoryName === 'A.E.'){
      if (newSupplierData.vatNumber === '') {
        isValidVatNumber = false;
        console.log('invalid vat number')
      }
      if (newSupplierData.DOY === '') {
        isValidDOY = false;
        console.log('invalid doy')
      }
    }

    // Mandatory field EXCEPT if category retail & patient
    if(
      newSupplierData.categoryName !== 'Retail' &&
      newSupplierData.categoryName !== 'Patient'
      ){
      if (newSupplierData.activity === '') {
        isValidActivity = false;
        console.log('invalid activity')
      }
      if (newSupplierData.country === '') {
        isValidCountry = false;
        console.log('invalid country')
      }
      if (newSupplierData.address === '') {
        isValidAddress = false;
        console.log('invalid address')
      }
      if (newSupplierData.city === '') {
        isValidCity = false;
        console.log('invalid city')
      }
      if (newSupplierData.postalCode === '') {
        isValidPC = false;
        console.log('invalid pc')
      }
    }

    // Do not show if Supplier Category is "Supplier within E.U." or "Supplier outside E.U."
    if (
      newSupplierData.vatCategory === '' &&
      newSupplierData.categoryName !== 'Supplier within E.U.' &&
      newSupplierData.categoryName !== 'Supplier outside E.U.'
    ) {
      isValidVatCategory = false
      console.log('invalid category');
    }

    if (
      newSupplierData.email === '' &&  newSupplierData.phone === ''
    ) {
      isValidEmailPhone = false
      console.log('Email or Phone is require.');
    }
      if (
      newSupplierData.category === '' ||
      newSupplierData.name === '' ||
      !isValidVatNumber ||
      !isValidDOY ||
      !isValidActivity ||
      !isValidCountry ||
      !isValidAddress ||
      !isValidCity ||
      !isValidPC ||
      !isValidEmailPhone ||
      !isValidVatCategory
    ) {
      toast.error('Please check all mandatory fields.');
      validCheck = false
    }

    if (validCheck) {
      setLoadingSave(true);
      // axios.defaults.headers.common["token"] = getToken();
      axios.post(apiURL2 + `/supplier/add-supplier`, JSON.stringify(newSupplierData), { headers: { 'Content-Type': 'application/json'}})
      .then((res) => {
        if (res.data &&  res.data.status === '200') {
          toast.success("Supplier added successfully.");
          dispatch(fetchInitialSuppliers(requestData));  // Fetch list again
          // Set New Supplier
          dispatch(resetNewSupplier());
          const newSupplierData = res.data.data;
          if (newSupplierData) {
            const supplierBasicData = {
              supplierName: newSupplierData.name,
              supplier: newSupplierData._id,
              supplierCode: newSupplierData.systemCode,
              supplierType: 'Existing',
              address: newSupplierData.address,
              vat: newSupplierData.vatNumber,
              doy: getDOYOptions().find((el) => String(el.value) === String(newSupplierData.DOY))?.label,
              city: newSupplierData.city,
              pc: newSupplierData.postalCode,
              email: newSupplierData.email,
              phone: newSupplierData.phone,
              activity: newSupplierData.activity
            }

            const salesData = SALES.newSales;
            const newSalesData = {...salesData, ...supplierBasicData}

            dispatch(setNewSales(newSalesData)); // Set auto select the newly added supplier
          }

        } else {
          toast.error(res.data.error);
        }
        setShowSupplierModal(false); // Close modal
        setLoadingSave(false);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.errors) {
          toast.error(error.response.data.errors);
          setShowSupplierModal(false); // Close modal
        }
        setLoadingSave(false);
      })

    }

  }
  return (
    <div className="new-supplier-modal">
      <i title='Add new supplier' onClick={ () =>{ setShowSupplierModal(true); handleSupplierCategories();} } className='fas border ml-2 fa-plus add-supplier-icon'></i>
      <Modal size="xl" className="themeModal" show={showSupplierModal} onHide={() => handleCancelSupplierModal()} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add Supplier</Modal.Title>
        </Modal.Header>
          <Modal.Body className='new-supplier-modal-body'>
            {disabledFields && <div className="overlay_w">Please select category first..</div>}
            <Row>
              <Col sm='12' md="3" className={SUPPLIERS_DATA.newSupplier.category === '' ? "showWithOverlay" : ""}>
                <Dropdown
                  label="Category"
                  isCLientCategory={true}
                  required={true}
                  options={supplierCategoriesOption}
                  classes="w-100 mb-3"
                  name="category"
                  key={ Math.random() }
                  defaultValue={getDefaultOptionByValue(supplierCategories, SUPPLIERS_DATA.newSupplier.category, '_id', 'category', '_id')}
                  onChange = {(e) => handleOnChange(e, 'dd', 'category') }
                />
              </Col>
              <Col sm='12' md="3">
                <Input
                  label="System Code"
                  className="mb-3 w-100"
                  disabled={true}
                  name="systemCode"
                  value={getMaskCodeWithNewPrefix(SUPPLIERS_DATA.newSupplier.systemCode, SUPPLIERS_DATA.newSupplier.categoryMaskCodePrefix)}
                  placeholder="eg. sc-***"
                />
              </Col>
            </Row>
            <Row>
              <Col sm='12' md="3">
              <div className="mb-3">
                <Input
                    label="Name"
                    required = {true}
                    className="w-100"
                    name="name"
                    type="text"
                    value={SUPPLIERS_DATA.newSupplier.name}
                    placeholder="Enter name"
                    onChange = {(e) => handleOnChange(e) }
                  />
                  {showErrors && SUPPLIERS_DATA.newSupplier.name === '' && <div className="text-danger">Name is required.</div> }
                  {/* {showErrors && SUPPLIERS_DATA.newSupplier.name !== '' && isValueAlreadyExists(SUPPLIERS_DATA.suppliers, SUPPLIERS_DATA.newSupplier.name, 'name') && <div className="text-danger">Name already exists.</div> } */}
                </div>
              </Col>

              <Col sm='12' md="3">
                <div className='mb-3'>
                  <Input
                    label="Email"
                    className="w-100"
                    name="email"
                    type="email"
                    value={SUPPLIERS_DATA.newSupplier.email}
                    placeholder="eg. example@example.com"
                    onChange = {(e) => handleOnChange(e) }
                  />
                  { showErrors &&
                    SUPPLIERS_DATA.newSupplier.email !== '' && !isValidEmail(SUPPLIERS_DATA.newSupplier.email) &&
                    <div className="text-danger">Invalid email address.</div>
                  }
                  { showErrors &&
                    SUPPLIERS_DATA.newSupplier.email === '' && SUPPLIERS_DATA.newSupplier.phone === '' &&
                    <div className="text-danger">Email or Phone is required.</div>
                  }
                </div>
              </Col>

              <Col sm='12' md="3">
                <Input
                  label="Phone"
                  className="mb-3 w-100"
                  name="phone"
                  type="tel"
                  charLimit={20}
                  value={SUPPLIERS_DATA.newSupplier.phone}
                  placeholder="Enter Phone Number"
                  onChange = {(e) => handleOnChange(e) }
                />
              </Col>

              {/* Do not show if Supplier Category is "Supplier within E.U." or "Supplier outside E.U." */}
              <Col sm='12' md="3">
                <div className='mb-3'>
                  <Dropdown
                    label="VAT Category"
                    classes="w-100"
                    required={true}
                    name="vatCategory"
                    key = {Math.random()}
                    defaultValue={getDefaultOptionByValue(vatCategoryList, SUPPLIERS_DATA.newSupplier.vatCategory, 'value', 'label', 'value')}
                    options={vatCategoryList}
                    onChange = {(e) => handleOnChange(e, 'dd', 'vatCategory') }
                  />
                  {showErrors && SUPPLIERS_DATA.newSupplier.vatCategory === '' && <div className="text-danger">VAT Category is required.</div> }
                </div>
                </Col>
              {(SUPPLIERS_DATA.newSupplier.vatCategory !== "" && SUPPLIERS_DATA.newSupplier.vatCategory === 'Zero VAT') && (
                  <Col sm="12" md="4">
                    <div className="mb-3">
                      <Dropdown
                          classes='w-100'
                          label="0% VAT Reason"
                          key = {Math.random()}
                          name="vatReason"
                          required={true}
                          options={zeroVatReasonOptions}
                          defaultValue={SUPPLIERS_DATA.newSupplier.vatReason ? { value: SUPPLIERS_DATA.newSupplier.vatReason, label: getLabelByValue(zeroVatReasonOptions, SUPPLIERS_DATA.newSupplier.vatReason,'value'), color: "#76AEB7" } : null}
                          onChange = {(e) => handleOnChange(e, 'dd', 'vatReason')}
                      />
                      {showErrors && SUPPLIERS_DATA.newSupplier.vatCategory === 'Zero VAT' && SUPPLIERS_DATA.newSupplier.vatReason === "" && (
                          <div className="text-danger">VAT Reason is required.</div>
                      )}
                    </div>
                  </Col>
              )}
              { (SUPPLIERS_DATA.newSupplier.vatCategory !== "" && SUPPLIERS_DATA.newSupplier.vatCategory === 'Custom VAT') &&
                  <Col xs="12" md="4">
                    <div className="mb-3">
                      <Input
                          name="customVat"
                          type="number"
                          validate={isValidCustomVat}
                          placeholder="Custom VAT"
                          label="Custom VAT %"
                          charLimit={3}
                          onChange = {(e) => handleOnChange(e)}
                          value={ SUPPLIERS_DATA.newSupplier.customVat }
                      />
                      { (SUPPLIERS_DATA.newSupplier.vatCategory === 'Custom VAT' &&
                              SUPPLIERS_DATA.newSupplier.customVat === "") &&
                          <div className="text-danger">Custom Vat is required.</div>
                      }
                    </div>
                  </Col>
              }
            </Row>
            <Row>
              {/* Retail: Add 999999999, do not validate VAT number. Field not mandatory */}
              {/* Supplier inside E.U.,outside E.U., Do not validate VAT, do not enter anything. Not mandatory field */}
              { (SUPPLIERS_DATA.newSupplier.categoryName === 'Supplier within E.U.' ||
                SUPPLIERS_DATA.newSupplier.categoryName === 'Supplier outside E.U.' ||
                SUPPLIERS_DATA.newSupplier.categoryName === 'Retail'
                ) &&
                <Col sm='12' md="3">
                    <Input
                    label="VAT Number"
                    className="mb-3 w-100 bg-white"
                    name="vatNumber"
                    type="number"
                    value={SUPPLIERS_DATA.newSupplier.vatNumber}
                    placeholder="Do not enter anything"
                  />
                </Col>
              }

              {/* Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.: Validate VAT number, use "Get Details" button to get company details. Mandatory field */}
              { (
                SUPPLIERS_DATA.newSupplier.categoryName === 'Freelancer' ||
                SUPPLIERS_DATA.newSupplier.categoryName === 'O.E.' ||
                SUPPLIERS_DATA.newSupplier.categoryName === 'E.E.' ||
                SUPPLIERS_DATA.newSupplier.categoryName === 'E.P.E.' ||
                SUPPLIERS_DATA.newSupplier.categoryName === 'Wholesale Others' ||
                SUPPLIERS_DATA.newSupplier.categoryName === 'I.K.E.' ||
                SUPPLIERS_DATA.newSupplier.categoryName === 'A.E.'
                ) &&
                <Col sm='12' md="4">
                  <div className='mb-3'>
                    <Input
                      label="VAT Number"
                      className="w-100 bg-white"
                      name="vatNumber"
                      required={true}
                      charLimit={9}
                      type="number"
                      value={SUPPLIERS_DATA.newSupplier.vatNumber}
                      placeholder="Add Vat Number"
                      onChange = {(e) => handleOnChange(e) }
                    />
                    {showErrors && SUPPLIERS_DATA.newSupplier.vatNumber === '' && <div className="text-danger">Vat Number is required.</div> }
                  </div>
                </Col>
              }

              {/* Show only if category is NOT Retail or Patient */}
              {/* Use "Get Greek Company Details from taxisnet" algorithm */}
              { (SUPPLIERS_DATA.newSupplier.categoryName !== '' &&
                SUPPLIERS_DATA.newSupplier.categoryName !== 'Retail' &&
                SUPPLIERS_DATA.newSupplier.categoryName !== 'Patient') &&
                <Col sm='12' md="2" className="d-flex align-items-center">
                  <Button onClick={() => fetchTaxisNetDetails(SUPPLIERS_DATA.newSupplier.vatNumber)} size="sm" className="mb-3" type='button'>Get Details</Button>
                </Col>
              }

              <Col sm='12' md="3">
                {/* Retail or patient: not mandatory fields
                Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:  Mandatory field
                Supplier inside E.U.,outside E.U., Not mandatory field */}
                <Dropdown
                  label="DOY"
                  classes="w-100"
                  required={
                      (
                          SUPPLIERS_DATA.newSupplier.categoryName !== 'Retail' &&
                          SUPPLIERS_DATA.newSupplier.categoryName !== 'Patient' &&
                          SUPPLIERS_DATA.newSupplier.categoryName !== 'Supplier within E.U.' &&
                          SUPPLIERS_DATA.newSupplier.categoryName !== 'Supplier outside E.U.'
                      )
                  }
                  searchEnable = {true}
                  name="DOY"
                  key = {Math.random()}
                  defaultValue={getDefaultOptionByValue(getDOYOptions(), SUPPLIERS_DATA.newSupplier.DOY, 'value', 'label', 'value')}
                  options={getDOYOptions()}
                  onChange = {(e) => handleOnChange(e, 'dd', 'DOY') }
                />
                { (
                  SUPPLIERS_DATA.newSupplier.categoryName !== 'Retail' &&
                  SUPPLIERS_DATA.newSupplier.categoryName !== 'Patient' &&
                  SUPPLIERS_DATA.newSupplier.categoryName !== 'Supplier within E.U.' &&
                  SUPPLIERS_DATA.newSupplier.categoryName !== 'Supplier outside E.U.'
                  ) &&
                  showErrors &&
                  SUPPLIERS_DATA.newSupplier.DOY === '' &&
                  <div className="text-danger">DOY is required.</div>
                }
              </Col>
              <Col sm="12" md="3">
                <Input
                    label="Activity"
                    className="w-100"
                    name="activity"
                    type="text"
                    charLimit={100}
                    required={
                        SUPPLIERS_DATA.newSupplier.categoryName !== "Retail" &&
                        SUPPLIERS_DATA.newSupplier.categoryName !== "Patient"
                    }
                    value={SUPPLIERS_DATA.newSupplier.activity}
                    placeholder="Enter Activity"
                    onChange={(e) => handleOnChange(e)}
                />
                {SUPPLIERS_DATA.newSupplier.categoryName !== "Retail" &&
                    SUPPLIERS_DATA.newSupplier.categoryName !== "Patient" &&
                    showErrors &&
                    SUPPLIERS_DATA.newSupplier.activity === "" && (
                        <div className="text-danger">Activity is required.</div>
                    )}
              </Col>
              {/* show only if Category is "Patient" */}
              { (SUPPLIERS_DATA.newSupplier.categoryName === 'Patient') &&
                <Col sm='12' md="3">
                  <div className='mb-3'>
                    <Input
                      label="AMKA"
                      className="mb-3 w-100"
                      name="amka"
                      type="text"
                      charLimit={30}
                      value={SUPPLIERS_DATA.newSupplier.amka}
                      placeholder="Enter AMKA"
                      onChange = {(e) => handleOnChange(e) }
                    />
                  </div>
                </Col>
              }
            </Row>
            <Row>
              <Col sm='12' md="3">
                {/* Mandatory field EXCEPT if category retail & patient */}
                <Dropdown
                  label="Country"
                  classes="w-100"
                  required={(SUPPLIERS_DATA.newSupplier.categoryName !== 'Retail' && SUPPLIERS_DATA.newSupplier.categoryName !== 'Patient')}
                  name="country"
                  searchEnable = {true}
                  key = {Math.random()}
                  defaultValue={getDefaultOptionByValue(countriesList, SUPPLIERS_DATA.newSupplier.country, 'value', 'label', 'value')}
                  options={countriesList}
                  onChange = {(e) => handleOnChange(e, 'dd', 'country') }
                />
                { (SUPPLIERS_DATA.newSupplier.categoryName !== 'Retail' &&
                  SUPPLIERS_DATA.newSupplier.categoryName !== 'Patient') &&
                  showErrors &&
                  SUPPLIERS_DATA.newSupplier.country === '' &&
                  <div className="text-danger">Country is required.</div>
                }
              </Col>
              <Col sm='12' md="3">
                <div className='mb-3'>
                  {/* Mandatory field EXCEPT if category retail & patient */}
                  <Input
                    label="Address"
                    className="w-100"
                    name="address"
                    type="text"
                    charLimit={100}
                    required={(SUPPLIERS_DATA.newSupplier.categoryName !== 'Retail' && SUPPLIERS_DATA.newSupplier.categoryName !== 'Patient')}
                    value={SUPPLIERS_DATA.newSupplier.address}
                    placeholder="Enter Address"
                    onChange = {(e) => handleOnChange(e) }
                  />
                  { (SUPPLIERS_DATA.newSupplier.categoryName !== 'Retail' &&
                    SUPPLIERS_DATA.newSupplier.categoryName !== 'Patient') &&
                    showErrors &&
                    SUPPLIERS_DATA.newSupplier.address === '' &&
                    <div className="text-danger">Address is required.</div>
                  }
                </div>
              </Col>
              <Col sm="12" md="3">
                <div className="mb-3">
                  {/* Mandatory field EXCEPT if category retail & patient */}
                  <Input
                      label="City"
                      className="w-100"
                      name="city"
                      type="text"
                      charLimit={100}
                      required={
                          SUPPLIERS_DATA.newSupplier.categoryName !== "Retail" &&
                          SUPPLIERS_DATA.newSupplier.categoryName !== "Patient"
                      }
                      value={SUPPLIERS_DATA.newSupplier.city}
                      placeholder="Enter City"
                      onChange={(e) => handleOnChange(e)}
                  />
                  {SUPPLIERS_DATA.newSupplier.categoryName !== "Retail" &&
                      SUPPLIERS_DATA.newSupplier.categoryName !== "Patient" &&
                      showErrors &&
                      SUPPLIERS_DATA.newSupplier.city === "" && (
                          <div className="text-danger">City is required.</div>
                      )}
                </div>
              </Col>
              <Col sm='12' md="3">
                {/* Mandatory field EXCEPT if category retail & patient */}
                <div className='mb-3'>
                  <Input
                    label="Postal Code"
                    className="w-100"
                    name="postalCode"
                    type="text"
                    required={(SUPPLIERS_DATA.newSupplier.categoryName !== 'Retail' && SUPPLIERS_DATA.newSupplier.categoryName !== 'Patient')}
                    value={SUPPLIERS_DATA.newSupplier.postalCode}
                    placeholder="Enter Postal Code"
                    onChange = {(e) => handleOnChange(e) }
                  />
                  { (SUPPLIERS_DATA.newSupplier.categoryName !== 'Retail' &&
                    SUPPLIERS_DATA.newSupplier.categoryName !== 'Patient') &&
                    showErrors &&
                    SUPPLIERS_DATA.newSupplier.postalCode === '' &&
                    <div className="text-danger">Postal Code is required.</div>
                  }
                </div>
              </Col>
            </Row>
          </Modal.Body>
        <Modal.Footer>
          <Button disabled = {loadingSave} variant="outline-primary" onClick={ () => handleCancelSupplierModal() }>Cancel</Button>
          <Button disabled = {loadingSave} variant="primary" onClick={ () => handleSaveSupplierModal() } >
            {
              loadingSave &&
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            }
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default NewSupplierModal;
