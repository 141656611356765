import React, {useEffect} from "react";
import Input from "../../../common/Input";
import { Row, Col, Container } from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import EditableTable from '../../../primer/Editable-Table';
import {preNumberGreek} from '../../../../_data/settings/advanced-types-search';
import { useSelector, useDispatch } from "react-redux";
import {
  setSaveEditAdvancedType,
} from "../../../../_reducers/activitySlice";
import {
  resetEditDocumentNumbering,
  setEditAdvancedType,
  setEditDocumentNumbering,
  setSettings
} from "../../../../_reducers/settingsSlice";
import {
  fetchAdvancedTypes, fetchAdvancedTypesBuys,
  fetchAdvancedTypesSales, fetchBankAccounts,
  fetchBasicTypes, fetchBuysGeneralSettings, fetchPOS,
  fetchSalesGeneralSettings
} from "../../../../_apis/api";
import {
  checkPermission,
  clientPaymentsPrintTemplates,
  getDefaultOptionByValue,
  getLastHighestPrefixNumber,
  getLastHighestValue,
  getOptionsByData, isEmpty,
  resetAdvancedTypeItemsID,
  salesPrintTemplates,
  setAdvancedTypesSelectedRow
} from "../../../../_helpers/commonFunctions";
import Checkbox from "../../../common/CheckBox";
import { toast } from "react-toastify";
import { deleteObjectFromArrayOfObjects, getObjectFromArrayOfObjects, getLabelByValue, isValueExistsReturn } from "../../../../_helpers/helperFunctions";
import { getHighlightData, getInvoiceTypesOptions, getSalesPurposeData } from "../../../../_helpers/sharedmydata";
import {useTranslation} from "react-i18next";
import BetterCheckBox from "../../../common/BetterCheckBox";
import LogTable from "../../../primer/LogTable";
import {isDeliveryNoteTypes} from "../../../../_data/mydata/isDeliveryNoteTypes";

export const EditAdvancedTypes = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const SETTINGS = useSelector( (state) => state.SETTINGS );
  const company = useSelector(state => state.COMPANY_DATA.company);
  const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
  const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
  const CASH_DATA = useSelector( (state) => state.CASH_DATA );
  const permissionsData = JSON.parse(localStorage.getItem("permissions"));

  let posData = CASH_DATA.posList;
  let bankData = CASH_DATA.bankAccounts;
  let posOptions =  getOptionsByData(posData, '_id', 'name');
  posOptions = [{value: '', label: t("Null")}, ...posOptions];
  if (SETTINGS.settings.editAdvancedType.section === "Client Payments" && SETTINGS.settings.editAdvancedType?.myDataType === "8.4") {
    let opt = [];
    for (let pos of posData) {
      if (pos?.isETameiaki === "active") {
        opt.push({
          value: pos._id,
          label: pos.name,
        })
      }
    }
    posOptions = opt;
  }
  let bankOptions =  getOptionsByData(bankData, '_id', 'name');
  bankOptions = [{value: '', label: t("Null")}, ...bankOptions];
  let printTemplateOptions = [];
  if (SETTINGS.settings?.editAdvancedType?.section === 'Sales') {
    printTemplateOptions = salesPrintTemplates();
  } else if (SETTINGS.settings?.editAdvancedType?.section === "Client Payments") {
    printTemplateOptions = clientPaymentsPrintTemplates();
  }
  let requestData = { company:company.id }

  useEffect(() => {
    dispatch(fetchPOS(requestData));
    dispatch(fetchBankAccounts(requestData));
    dispatch(fetchBasicTypes(requestData))
    dispatch(fetchAdvancedTypes(requestData));
    dispatch(fetchAdvancedTypesSales(requestData));
    dispatch(fetchAdvancedTypesBuys(requestData));
    dispatch(fetchSalesGeneralSettings(requestData));
    dispatch(fetchBuysGeneralSettings(requestData));
  }, [dispatch])

  const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
  const advancedTypeSectionOptions = [
    { value: "Product Movements", label: t('AdvanceTypesNewDropDown.productMovements'), color: "#76AEB7" },
    { value: "Sales", label: t('AdvanceTypesNewDropDown.sales'), color: "#76AEB7" },
    { value: "Client Movements", label: t('AdvanceTypesNewDropDown.clientMovements'), color: "#76AEB7" },
    { value: "Client Payments", label: t('AdvanceTypesNewDropDown.clientPayments'), color: "#76AEB7" },
    { value: "Buys", label: t('AdvanceTypesNewDropDown.buys'), color: "#76AEB7" },
    { value: "Supplier Payments", label: t('AdvanceTypesNewDropDown.supplierPayments'), color: "#76AEB7" },
    { value: "Accounting Records", label: t('AdvanceTypesNewDropDown.accountingRecords'), color: "#76AEB7" },
    { value: "Supplier Movements", label: "Κινήσεις Προμηθευτών", color: "#76AEB7" },
  ];
  const installationsColumns = [
    {
      label: t('DocumentTypeAdvancedNewEdit.tableSmall.address'),
      name: "address",
    },
    {
      label: t('DocumentTypeAdvancedNewEdit.tableSmall.city'),
      name: "city",
    },
    {
      label: t('DocumentTypeAdvancedNewEdit.tableSmall.type'),
      name: "type",
    },
  ];

  // const hideFutureUpdates = false; // It will hide the fields that we will use in next upgrade
  let myDataTypeOptions = SETTINGS.settings.editAdvancedType?.mydataProvider === "active" ? getInvoiceTypesOptions("provider", SETTINGS.settings.editAdvancedType?.section) : SETTINGS.settings.editAdvancedType?.section === "Buys" ? getInvoiceTypesOptions("buys") : getInvoiceTypesOptions();
  if(SETTINGS.settings.editAdvancedType?.section === "Accounting Records") {
    myDataTypeOptions = getInvoiceTypesOptions("all")
  } else if (SETTINGS.settings.editAdvancedType?.section === "Client Payments" && SETTINGS.settings.editAdvancedType?.mydataProvider === "active") {
    myDataTypeOptions = getInvoiceTypesOptions("provider", "Client Payments");
  }
  const selectedMyDataType = getDefaultOptionByValue(myDataTypeOptions, SETTINGS.settings.editAdvancedType?.myDataType, 'value', 'label', 'value')
  // For Sales
  let creditDocumentData1 = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, '5.1', 'myDataType');
  let creditDocumentData2 = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, '11.4', 'myDataType');
  let creditDocumentData3 = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, '5.2', 'myDataType');
  let creditDocumentData = [...creditDocumentData1, ...creditDocumentData2, ...creditDocumentData3];
  let creditDocumentOptions = getOptionsByData(creditDocumentData, '_id', 'name');
  creditDocumentOptions = [{ value: "", label: t("Null") }, ...creditDocumentOptions];
  let conversionDocumentOptions = getOptionsByData(SETTINGS.settings.advancedTypesSales, '_id', 'name');
  conversionDocumentOptions = deleteObjectFromArrayOfObjects(conversionDocumentOptions, SETTINGS.settings?.editAdvancedType?._id, 'value');
  conversionDocumentOptions = [{ value: "", label: t("Null") }, ...conversionDocumentOptions];
  const proposedSalePurposeOptions = getSalesPurposeData(SETTINGS.primerMyDataData);
  const thirdPartySalesOptions = getHighlightData(SETTINGS.primerMyDataData);
  const installationOptions = getOptionsByData(installations, "installationMasterId", "address");

  let proposedPaymentTypeOptions = [];
  if (SALES_SETTINGS.salesGeneralSettings.paymentTypes && SALES_SETTINGS.salesGeneralSettings.paymentTypes instanceof Array) {
    for (let paymentType of SALES_SETTINGS.salesGeneralSettings.paymentTypes) {
      proposedPaymentTypeOptions.push({
        value: paymentType._id,
        label: paymentType.name,
        type: paymentType.type,
      })
    }
  }
  let proposedPaymentTypeOptionsBuys = getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.paymentTypes, "_id", "name");
  let proposedPosBankAcountOptions = [];
  if(SETTINGS.settings.editAdvancedType.proposedPaymentTypeType === 'pos') {
    proposedPosBankAcountOptions = posOptions;
  }
  if(SETTINGS.settings.editAdvancedType.proposedPaymentTypeType === 'bank-account') {
    proposedPosBankAcountOptions = bankOptions;
  }
  let etDocumentNumbering = SETTINGS.settings.editAdvancedType.documentNumbering;

  const advancedDocumentColumns = [
    {
      name: "No",
      field: "no",
      width: '50px',
    },
    {
      name: t('DocumentTypeAdvancedNew.table.installation'),
      field: "installation",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: installationsColumns,
          data: installations,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('DocumentTypeAdvancedNew.table.placeholder')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('DocumentTypeAdvancedNew.table.numberingMode'),
      field: "numberingMode",
      width: '130px',
      editable: true,
      inputType: {
        type: 'select',
        multiselect: true,
        options: null
      }
    },
    {
      name: t('DocumentTypeAdvancedNew.table.preNumber'),
      field: 'preNumber',
      width: '100px',
      editable: true,
      inputType: {
        type: 'select',
        options: preNumberGreek
      }
    },
    {
      name: t('DocumentTypeAdvancedNew.table.selected'),
      field: 'selected',
      width: '120px',
      editable: true,
      inputType: {
        type: 'checkbox',
        options: [{
          value: 'true',
          label: 'True'
        }, {
          value: 'false',
          label: 'False'
        }]
      }
    },
    {
      name: t('DocumentTypeAdvancedNew.table.number'),
      field: 'number',
      width: '100px',
      editable: true,
      inputType: {
        type: 'text',
      }
    },
  ];

  let returnPaymentDocuments = [{value: "", label: "Κενό"}];
  if (SETTINGS.settings.advancedTypes) {
    let docs = SETTINGS.settings.advancedTypes.filter((el) => el.section === "Client Payments" && el?.myDataType !== "8.4");
    if(SETTINGS.settings.editAdvancedType?.myDataType !== "8.4") { // Filter out 8.5, if doc is not 8.4
      docs = docs.filter((el) => el.section === "Client Payments" && el?.myDataType !== "8.5");
    } else if (SETTINGS.settings.editAdvancedType?.myDataType === "8.4") { // Filter only 8.5, if doc is 8.4
      docs = docs.filter((el) => el.section === "Client Payments" && el?.myDataType === "8.5");
    }
    for (let doc of docs) {
      returnPaymentDocuments.push({ value: doc._id, label: doc.name });
    }
  }

  const handleOnChange = (e, type="default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.saveEditAdvancedType) {
      dispatch(setSaveEditAdvancedType(true));
    }

    let name;
    let value = '';
    let selectedSection = SETTINGS.settings.editAdvancedType.section;

    if (type === 'dd')  {
      name = ddname;
      value = e.value;
    } else if (type === "checkbox") {
      name = ddname;
      value = e.target.checked === true ? "active" : "inactive";
    } else if (type === "msdd") {
      name = ddname;
      if (e?.length > 0) {
        let tmp = [];
        for (let c of e) {
          tmp.push(c.value);
        }
        value = tmp;
      } else {
        value = [];
      }
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    // Set settings data
    let newSettings = Object.assign({}, SETTINGS.settings);
    let newSettingsData = {};
    newSettingsData[name] = value;

    // Document Type
    if (name === 'documentType') {
      const selectedDT = getObjectFromArrayOfObjects(SETTINGS.settings.basicTypes, value, '_id');
      if (selectedDT.length > 0) {
        // Sales Fields
        newSettingsData['documentTypeName'] = selectedDT['0'].name;
        if (selectedSection === 'Sales') {
          newSettingsData['basicDocumentType'] = value;
          newSettingsData['basicDocumentTypeName'] = selectedDT['0'].name;
        }
      }
    }

    // Print Preferences
    if (name === 'printPreference') {
      newSettingsData['printPreferenceName'] = getLabelByValue;
    }

    // Print Preferences
    if (name === 'printTemplate') {
      newSettingsData['printTemplateName'] = getLabelByValue(printTemplateOptions, value, 'value');
    }

    // Name
    if (name === 'name' && (selectedSection === 'Sales' || selectedSection === 'Buys' || selectedSection === "Accounting Records")) {
      newSettingsData['name'] = value;
    }
    if(name === "section"){
      myDataTypeOptions = SETTINGS.settings.editAdvancedType?.mydataProvider === "active" ? getInvoiceTypesOptions("provider", newSettingsData.section) : value === "Buys" ? getInvoiceTypesOptions("buys") : getInvoiceTypesOptions();
      newSettingsData.proposedPaymentType = "";
      newSettingsData.proposedPaymentTypeType = "";
      newSettingsData.proposedPaymentTypeName = "";
      newSettingsData.proposedPosBankAccount = "";
      newSettingsData.returnPaymentDocument = "";
      newSettingsData.returnPaymentDocumentPreNumber = "";
      newSettingsData.enablePeppol = "inactive";
      newSettingsData.enableMyDataRules = "inactive";
      newSettingsData.enableMyData = "inactive";
      newSettingsData.mydataProvider = "inactive";
      if (value === "Accounting Records") newSettingsData.accounting = SETTINGS.newAdvancedTypeInitialValues.accounting;
    }

    // My Data Rule for Sales
    // If "Enable MyData provider" is enabled, this option is enabled by default.
    if (name === 'enableMyData' && value === 'active' && (selectedSection === 'Sales' || selectedSection === "Accounting Records")) {
      newSettingsData.enableMyDataRules = 'active';
      newSettingsData.mydataProvider = 'inactive';
      myDataTypeOptions = getInvoiceTypesOptions("all");
      myDataTypeOptions = [{value: '', label: t("Null")}, ...myDataTypeOptions];
    }
    if (name === 'mydataProvider' && value === 'active' && (selectedSection === 'Sales' || selectedSection === "Accounting Records")) {
      newSettingsData.enableMyDataRules = 'active';
      newSettingsData.enableMyData = 'inactive';
      myDataTypeOptions = getInvoiceTypesOptions("provider", newSettingsData.section);
      myDataTypeOptions = [{value: '', label: t("Null")}, ...myDataTypeOptions];
    }


    // My Data Rule for Sales
    // If "Enable MyData provider" is enabled, this option is enabled by default.
    if (name === 'enableMyData' && value === 'active' && selectedSection === 'Buys') {
      newSettingsData.enableMyDataRules = 'active';
      newSettingsData.mydataProvider = 'inactive';
      myDataTypeOptions = getInvoiceTypesOptions();
      myDataTypeOptions = [{value: '', label: 'Null (Blank)'}, ...myDataTypeOptions];
    }
    if (name === 'mydataProvider' && value === 'active' && selectedSection === 'Buys') {
      newSettingsData.enableMyDataRules = 'active';
      newSettingsData.enableMyData = 'inactive';
      myDataTypeOptions = getInvoiceTypesOptions("provider", newSettingsData.section);
      myDataTypeOptions = [{value: '', label: 'Null (Blank)'}, ...myDataTypeOptions];
    }

    if (name === "mydataProvider" && value === "active" && selectedSection === "Client Payments") {
      newSettingsData.enableMyDataRules = 'active';
      newSettingsData.enableMyData = 'inactive';
    }

    if (name === "mydataProvider" && value === "inactive" && selectedSection === "Client Payments") {
      newSettingsData.enableMyDataRules = 'inactive';
      newSettingsData.enableMyData = 'inactive';
      newSettingsData.enablePeppol = "inactive";
    }
    if (name === "myDataType" && SETTINGS.settings.editAdvancedType.section === "Sales" && value !== SETTINGS.settings.editAdvancedType.myDataType) {
      newSettingsData.enablePeppol = "inactive";
    }
    if (name === "myDataType" && SETTINGS.settings.editAdvancedType.section === "Client Payments") {
      newSettingsData.proposedPaymentType = "";
      newSettingsData.proposedPaymentTypeType = "";
      newSettingsData.proposedPaymentTypeName = "";
      newSettingsData.proposedPosBankAccount = "";
      newSettingsData.returnPaymentDocument = "";
      newSettingsData.returnPaymentDocumentPreNumber = "";
    }

    newSettings.editAdvancedType = {...newSettings.editAdvancedType, ...newSettingsData}
    if(newSettings.editAdvancedType.mydataProvider !== "active" && newSettings.editAdvancedType.enableMyData !== "active"){
      newSettingsData.allowOlderDate = "inactive";
      newSettings.editAdvancedType = {...newSettings.editAdvancedType, ...newSettingsData}
    }
    if(name === "proposedPaymentType" && (SETTINGS.settings.editAdvancedType.section !== 'Supplier Payments' && SETTINGS.settings.editAdvancedType.section !== 'Buys')) {
      if(SALES_SETTINGS.salesGeneralSettings.paymentTypes.length > 0) {
        const paymentTypeNameOption = SALES_SETTINGS.salesGeneralSettings.paymentTypes.filter(paymentType => paymentType._id === value);
        newSettings.editAdvancedType.proposedPaymentTypeType = paymentTypeNameOption[0].type;
        newSettings.editAdvancedType.proposedPaymentTypeName = paymentTypeNameOption[0].name;
      }
    }
    if(name === "proposedPaymentType" && (SETTINGS.settings.editAdvancedType.section === 'Supplier Payments' || SETTINGS.settings.editAdvancedType.section === "Buys")) {
      if(BUYS_SETTINGS.buysGeneralSettings.paymentTypes.length > 0) {
        const paymentTypeNameOption = BUYS_SETTINGS.buysGeneralSettings.paymentTypes.filter(paymentType => paymentType._id === value);
        newSettings.editAdvancedType.proposedPaymentTypeType = paymentTypeNameOption[0].type;
        newSettings.editAdvancedType.proposedPaymentTypeName = paymentTypeNameOption[0].name;
      }
    }
    if (!isDeliveryNoteTypes.includes(newSettings.editAdvancedType.myDataType)) {
      newSettings.editAdvancedType.isDeliveryNote = "inactive";
    }
    dispatch(setSettings(newSettings));
  }

  const duplicateDocumentNumberingCheck = (data, filter, id = '') => {
    if (data.length > 0) {
      let newData;
      newData = data.filter(function(item) {
        let dcheck = true;
        for (let key in filter) {
          if (item[key] !== filter[key]) {
            dcheck = false;
          }
          if (id !== '' && id === item.no) {
            dcheck = false;
          }
        }
        return dcheck;
      });
      return newData.length > 0;
    }
    return false;
  }

  const documentNumberingActions = {
    insert: (e, data) => {

      // Set if editing the form
      if (!ACTIVITY.saveEditAdvancedType) {
        dispatch(setSaveEditAdvancedType(true));
      }

      let tempDocumentNumbering = Object.assign({},  SETTINGS.editDocumentNumbering);
      if (e.key !== "Enter") {
        tempDocumentNumbering[data.field] = data.value;
        if(data.field === "installation") {
          tempDocumentNumbering.installation = e.row.type + ", " + e.row.address + ", " + e.row.city;
          tempDocumentNumbering.installationType = e.row.type;
          tempDocumentNumbering.installationMasterId = e.row.installationMasterId;
          tempDocumentNumbering.installation_id = e.row._id;
        }
        dispatch(setEditDocumentNumbering(tempDocumentNumbering)); // Update redux

        // Add on change
        if (( tempDocumentNumbering.installation !== '' && tempDocumentNumbering.numberingMode === 'manual') ||
          ( tempDocumentNumbering.installation !== '' && tempDocumentNumbering.numberingMode === 'automatic' && tempDocumentNumbering.preNumber !== '')
          ) {
          let oldDocumentNumbering = SETTINGS.settings.editAdvancedType.documentNumbering;
          let updatedDocumentNumbering = Object.assign({},  tempDocumentNumbering);
          let highestValue = getLastHighestValue(oldDocumentNumbering)

          updatedDocumentNumbering.no = highestValue + 1;
          updatedDocumentNumbering.id = highestValue + 1;

          const numberModeCounts = getLastHighestPrefixNumber(oldDocumentNumbering, 'number', tempDocumentNumbering.preNumber);
          if (numberModeCounts > 0) {
            updatedDocumentNumbering.number = parseInt(numberModeCounts) + 1;
            updatedDocumentNumbering.countNumber = parseInt(numberModeCounts) + 1;
          } else {
            updatedDocumentNumbering.number = 0;
            updatedDocumentNumbering.countNumber = 0;
          }

          if(tempDocumentNumbering.numberingMode === 'manual') {
            updatedDocumentNumbering.number = "";
            updatedDocumentNumbering.preNumber = "";
          }
          // Duplicate Check
          let filterDocumentNumbering  = {
            // number: tempDocumentNumbering.number,
            numberingMode: tempDocumentNumbering.numberingMode,
            preNumber: tempDocumentNumbering.preNumber,
            installation: tempDocumentNumbering.installation,
          }
          let isDuplicate = duplicateDocumentNumberingCheck(SETTINGS.settings.editAdvancedType.documentNumbering, filterDocumentNumbering);
          if (isDuplicate) {
            toast.error('Duplicate Entry Not Allowed');
          } else {

            // Deselect existing selected rows
            if (updatedDocumentNumbering.selected && updatedDocumentNumbering.selected === 'true') {
              oldDocumentNumbering = setAdvancedTypesSelectedRow(oldDocumentNumbering, 0);
            }

            let documentNumbering = [...oldDocumentNumbering, updatedDocumentNumbering];

            dispatch(setEditAdvancedType({...SETTINGS.settings.editAdvancedType, documentNumbering: documentNumbering}));
            dispatch(resetEditDocumentNumbering()); // Reset new category mydata
          }
        }

      } else {
        let updatedDocumentNumbering = Object.assign({},  SETTINGS.editDocumentNumbering);
        let addCheck = true;

        if (updatedDocumentNumbering.installation === '') {
          toast.error("Installation should not be empty.");
          addCheck = false;
        }

        let oldDocumentNumbering = SETTINGS.settings.editAdvancedType.documentNumbering;
        if ((data.field === 'add' || e.key === 'Enter') && addCheck) {
          let highestValue = getLastHighestValue(oldDocumentNumbering)
          updatedDocumentNumbering.no = highestValue + 1;
          updatedDocumentNumbering.id = highestValue + 1;

          // Duplicate Check
          let filterDocumentNumbering  = {
            // number: updatedDocumentNumbering.number,
            numberingMode: updatedDocumentNumbering.numberingMode,
            preNumber: updatedDocumentNumbering.preNumber,
            installation: updatedDocumentNumbering.installation,
          }

          // For Manual Mode
          if (updatedDocumentNumbering.numberingMode === 'manual') {
            filterDocumentNumbering  = {
              numberingMode: updatedDocumentNumbering.numberingMode,
              installation: updatedDocumentNumbering.installation,
            }
          }

          let isDuplicate = duplicateDocumentNumberingCheck(SETTINGS.settings.editAdvancedType.documentNumbering, filterDocumentNumbering);
          if (isDuplicate) {
            toast.error('Duplicate Entry Not Allowed');
            if (data.field === 'add') {
              dispatch(resetEditDocumentNumbering()); // Reset new mydata
            }
          } else {
            // Deselect existing selected rows
            if (updatedDocumentNumbering.selected && updatedDocumentNumbering.selected === 'true') {
              oldDocumentNumbering = setAdvancedTypesSelectedRow(oldDocumentNumbering, 0);
            }

            let documentNumbering = [...oldDocumentNumbering, updatedDocumentNumbering];

            dispatch(setEditAdvancedType({...SETTINGS.settings.editAdvancedType, documentNumbering: documentNumbering}));
            dispatch(resetEditDocumentNumbering()); // Reset new mydata
          }
        }
      }
    },
    update: (data, id) => {

      // Set if editing the form
      if (!ACTIVITY.saveEditAdvancedType) {
        dispatch(setSaveEditAdvancedType(true));
      }

      let validCheck = true;
      if (data.installation && (data.installation.length === 0)) {
        toast.error("Installation is mandatory field.");
        validCheck = false;
      }

      if (data.numberingMode && (data.numberingMode.length === 0)) {
        toast.error("Numbering Mode is mandatory field.");
        validCheck = false;
      }
      if (validCheck) {
        let documentNumbering = SETTINGS.settings.editAdvancedType.documentNumbering.slice();
        if (documentNumbering.find(item => item.id === id) !== undefined) {
          let updatedPmProducts = { ...documentNumbering[id - 1], ...data }
          if (data.preNumber && data.preNumber !== '') {
              updatedPmProducts.number = 0;
          }
          if(data?.installation && data.installation?.row && data.installation !== '') {
            updatedPmProducts.installation = data.installation.row.type + ", " + data.installation.row.address + ", " + data.installation.row.city;
            updatedPmProducts.installationType = data.installation.row.type;
            updatedPmProducts.installationMasterId = data.installation.row.installationMasterId;
            updatedPmProducts.installation_id = data.installation.row._id;
          }
          // Duplicate Check
          let filterDocumentNumbering  = {
            number: updatedPmProducts.number,
            numberingMode: updatedPmProducts.numberingMode,
            preNumber: updatedPmProducts.preNumber,
          }

          let isDuplicate = duplicateDocumentNumberingCheck(documentNumbering, filterDocumentNumbering, id);

          if (data.selected === 'true') {
            // Set only 1 as selected row
            documentNumbering = setAdvancedTypesSelectedRow(documentNumbering, id);
          }

          if (isDuplicate && data.numberingMode !== 'manual' && updatedPmProducts.preNumber !== "" && updatedPmProducts.number) {
            toast.error('Number already exists. Please choose another number.');
            dispatch(setEditAdvancedType({...SETTINGS.settings.editAdvancedType, documentNumbering: documentNumbering})); // update data
          } else {
            documentNumbering[id-1] = updatedPmProducts;
            dispatch(setEditAdvancedType({...SETTINGS.settings.editAdvancedType, documentNumbering: documentNumbering})); // update data
          }
       }
      }
    },
    selected: () => {},
    delete: (id) => {

      // Set if editing the form
      if (!ACTIVITY.saveEditAdvancedType) {
        dispatch(setSaveEditAdvancedType(true));
      }

      let documentNumbering = SETTINGS.settings.editAdvancedType.documentNumbering.slice();
      if (id && Number.isInteger(id)) {
        let deletedDocumentNumbering = deleteObjectFromArrayOfObjects(documentNumbering, id,'id');
        deletedDocumentNumbering = resetAdvancedTypeItemsID(deletedDocumentNumbering);
        dispatch(setEditAdvancedType({...SETTINGS.settings.editAdvancedType, documentNumbering: deletedDocumentNumbering }))
      } else {
        let deletedDocumentNumbering = deleteObjectFromArrayOfObjects(documentNumbering, id,'_id');
        deletedDocumentNumbering = resetAdvancedTypeItemsID(deletedDocumentNumbering);
        dispatch(setEditAdvancedType({...SETTINGS.settings.editAdvancedType, documentNumbering: deletedDocumentNumbering }))
      }
    }
  }

  const disabledFields = (SETTINGS.settings.editAdvancedType.section === undefined || SETTINGS.settings.editAdvancedType.section === '');

  let nameExists = isValueExistsReturn(SETTINGS.settings.advancedTypes, SETTINGS.settings.editAdvancedType.name , 'name');
  let isNameExists = false;
  if (nameExists.length > 0) {
    if (nameExists['0']._id !== SETTINGS.settings.editAdvancedType._id) {
      isNameExists = true;
    }
  }

  let selectedSection = getDefaultOptionByValue(advancedTypeSectionOptions, SETTINGS.settings.editAdvancedType.section, 'value', 'label', 'value');
  let basicTypesData = SETTINGS.settings.basicTypes;
  // Check if selected before, It will show the last selected value
  if (basicTypesData.length === 0 && SETTINGS.settings.editAdvancedType.documentType !== "") {
    basicTypesData = [{
      _id: SETTINGS.settings.editAdvancedType.documentType,
      name: SETTINGS.settings.editAdvancedType.documentTypeName
    }]
  }
  return (
      <Container fluid className="p-0 client-form product-form position-relative">
        {disabledFields && <div className="overlay_w">{t('DocumentTypeAdvancedNewEdit.message')}</div>}
        <Row>
          <Col sm="12" md="6" className="showWithOverlay">
            <Dropdown
                label={t('DocumentTypeAdvancedNewEdit.section')}
                options={advancedTypeSectionOptions}
                value={selectedSection ? selectedSection : ''}
                name="section"
                searchEnable={true}
                defaultValue={null}
                enabledValue={true}
                disabled={true}
                onChange={(e) => handleOnChange(e, 'dd', 'section')}
            />
          </Col>
        </Row>

        <Container fluid className="border py-3 mb-3">
          <Row>
            <Col sm="12" md="6">
              {SETTINGS.settings.editAdvancedType.section !== 'Accounting Records' && (
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNewEdit.basicDocumentType.basicDocumentType')}
                        options={getOptionsByData(basicTypesData, '_id', 'name')}
                        value={getDefaultOptionByValue(basicTypesData, SETTINGS.settings.editAdvancedType.documentType, '_id', 'name', 'value')}
                        name="documentType"
                        searchEnable={true}
                        enabledValue={true}
                        required={true}
                        onChange={(e) => handleOnChange(e, 'dd', 'documentType')}
                        classes="w-100"
                    />
                    {ACTIVITY.saveEditAdvancedType && SETTINGS.settings.editAdvancedType.documentType === '' &&
                        <div className="text-danger">{t('DocumentTypeAdvancedNewEdit.basicDocumentType.required')}</div>}
                  </div>
              )}
            </Col>
            <Col sm="12" md="6" className="d-flex align-items-center justify-content-end">
              {t('DocumentTypeAdvancedNewEdit.active')}
              <BetterCheckBox
                  className="ml-2 mb-2"
                  name="isActive"
                  checked={SETTINGS.settings.editAdvancedType.isActive === 'active'}
                  onChange={(e) => handleOnChange(e, "checkbox", "isActive")}
              />
            </Col>
            <Col sm="12" md="6">
              <div className="mb-3">
                <Input
                    label={t('DocumentTypeAdvancedNewEdit.name.name')}
                    placeholder={t('DocumentTypeAdvancedNewEdit.name.placeholder')}
                    name="name"
                    value={SETTINGS.settings.editAdvancedType.name}
                    className="mb-0"
                    required={true}
                    charLimit={70}
                    onChange={(e) => handleOnChange(e)}
                />
                {ACTIVITY.saveEditAdvancedType && SETTINGS.settings.editAdvancedType.name === '' &&
                    <div className="text-danger">{t('DocumentTypeAdvancedNewEdit.name.required')}</div>
                }
                {isNameExists &&
                    SETTINGS.settings.editAdvancedType.name !== '' &&
                    <div className="text-danger">{t('DocumentTypeAdvancedNewEdit.name.warning')}</div>
                }
              </div>
            </Col>
          </Row>
          <>
            {(SETTINGS.settings.editAdvancedType.section === 'Sales' || SETTINGS.settings.editAdvancedType.section === 'Client Payments' || SETTINGS.settings.editAdvancedType.section === 'Supplier Payments') &&
                <Row>
                  <Col sm="12" md="6">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNewEdit.printTemplate')}
                        options={printTemplateOptions}
                        value={getDefaultOptionByValue(printTemplateOptions, SETTINGS.settings.editAdvancedType.printTemplate, 'value', 'label', 'value')}
                        name="printTemplate"
                        searchEnable={true}
                        enabledValue={true}
                        onChange={(e) => handleOnChange(e, 'dd', 'printTemplate')}
                        classes="w-100 mb-3"
                    />
                  </Col>
                </Row>
            }
            <Row>
              {(SETTINGS.settings.editAdvancedType.section === 'Sales' || SETTINGS.settings.editAdvancedType.section === 'Client Payments' || SETTINGS.settings.editAdvancedType.section === 'Supplier Payments') &&
                  <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                    {t('DocumentTypeAdvancedNewEdit.alwaysPrintAfterSave')}
                    <Checkbox
                        className="ml-2 mb-2"
                        name="printAfterSave"
                        key={Math.random()}
                        defaultValue={SETTINGS.settings.editAdvancedType.printAfterSave === 'active'}
                        value={SETTINGS.settings.editAdvancedType.printAfterSave === 'active' ? "inactive" : "active"}
                        onChange={(e) => handleOnChange(e)}
                    />
                  </Col>
              }
              {(SETTINGS.settings.editAdvancedType.section === 'Sales' || SETTINGS.settings.editAdvancedType.section === 'Client Payments' || SETTINGS.settings.editAdvancedType.section === 'Supplier Payments') &&
                  <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                    {t('DocumentTypeAdvancedNewEdit.alwaysSendEmailAfterSave')}
                    <Checkbox
                        className="ml-2 mb-2"
                        name="sendEmailAfterSave"
                        key={Math.random()}
                        defaultValue={SETTINGS.settings.editAdvancedType.sendEmailAfterSave === 'active'}
                        value={SETTINGS.settings.editAdvancedType.sendEmailAfterSave === 'active' ? "inactive" : "active"}
                        onChange={(e) => handleOnChange(e)}
                    />
                  </Col>
              }
              {(SETTINGS.settings.editAdvancedType.section === 'Sales' || SETTINGS.settings.editAdvancedType.section === 'Client Payments' || SETTINGS.settings.editAdvancedType.section === 'Supplier Payments') &&
                  <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                    {t('DocumentTypeAdvancedNew.useLogo')}
                    <Checkbox
                        className="ml-2 mb-2"
                        name="useLogo"
                        key={Math.random()}
                        defaultValue={SETTINGS.settings.editAdvancedType.useLogo === 'active'}
                        value={SETTINGS.settings.editAdvancedType.useLogo === 'active' ? "inactive" : "active"}
                        onChange={(e) => handleOnChange(e)}
                    />
                  </Col>
              }
              {(SETTINGS.settings.editAdvancedType.section === 'Client Payments') && (
                  <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                    Third Party Collection
                    <BetterCheckBox
                        className="ml-2 mb-2"
                        name="thirdPartyCollection"
                        checked={SETTINGS.settings.editAdvancedType.thirdPartyCollection === 'active'}
                        onChange={(e) => handleOnChange(e, "checkbox", "thirdPartyCollection")}
                    />
                  </Col>
              )}
              {(SETTINGS.settings.editAdvancedType.section === 'Product Movements' || SETTINGS.settings.editAdvancedType.section === 'Client Movements' || SETTINGS.settings.editAdvancedType.section === 'Supplier Movements') &&
                  <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                    {t('DocumentTypeAdvancedNew.isCensus')}
                    <Checkbox
                        className="ml-2 mb-2"
                        name="isCensus"
                        key={Math.random()}
                        defaultValue={SETTINGS.settings.editAdvancedType.isCensus === 'active'}
                        value={SETTINGS.settings.editAdvancedType.isCensus === 'active' ? "inactive" : "active"}
                        onChange={(e) => handleOnChange(e)}
                    />
                  </Col>
              }
              {(SETTINGS.settings.editAdvancedType.section === 'Sales') && (
                  <Col sm="12" md="3" className="d-flex align-items-center justify-content-start mb-3">
                    {t('DocumentTypeAdvancedNew.canBePaidOff')}
                    <Checkbox
                        className="ml-2 mb-2"
                        name="canBePaidOff"
                        key={Math.random()}
                        defaultValue={(SETTINGS.settings.editAdvancedType.canBePaidOff === 'active' || !SETTINGS.settings.editAdvancedType.canBePaidOff)}
                        value={(SETTINGS.settings.editAdvancedType.canBePaidOff === 'active' || !SETTINGS.settings.editAdvancedType.canBePaidOff) ? "inactive" : "active"}
                        onChange={(e) => handleOnChange(e)}
                    />
                  </Col>
              )}
            </Row>
          </>
          {(SETTINGS.settings.editAdvancedType.section === 'Sales' || SETTINGS.settings.editAdvancedType.section === 'Buys' || SETTINGS.settings.editAdvancedType.section === 'Accounting Records' || SETTINGS.settings.editAdvancedType.section === 'Client Payments') &&
            <Row key={Math.random()}>
              {SETTINGS.settings.editAdvancedType.section !== 'Accounting Records' &&
                  <Col sm="12" md="3" className="mb-3 d-flex align-items-center justify-content-start">
                    {t('DocumentTypeAdvancedNewEdit.enableMyDataProvider')}
                    <Checkbox
                        className='ml-2 mb-2'
                        name="mydataProvider"
                        key={Math.random()}
                        defaultValue={SETTINGS.settings.editAdvancedType.mydataProvider === 'active'}
                        value={SETTINGS.settings.editAdvancedType.mydataProvider === 'active' ? "inactive" : "active"}
                        onChange={(e) => handleOnChange(e)}
                    />
                  </Col>
              }
              {SETTINGS.settings.editAdvancedType.section !== 'Accounting Records' &&
                  <Col sm="12" md="3">
                    <div
                        className={(SETTINGS.settings.editAdvancedType?.enableMyData !== 'active' && SETTINGS.settings.editAdvancedType?.mydataProvider !== 'active') ? 'readonly-field mb-3 d-flex align-items-center justify-content-start' : "mb-3 d-flex align-items-center justify-content-start"}>
                      {t('DocumentTypeAdvancedNewEdit.handwrittenDocument')}
                      <Checkbox
                          className='ml-2 mb-2'
                          name="allowOlderDate"
                          key={Math.random()}
                          defaultValue={SETTINGS.settings.editAdvancedType.allowOlderDate === 'active'}
                          value={SETTINGS.settings.editAdvancedType.allowOlderDate === 'active' ? "inactive" : "active"}
                          disabled={SETTINGS.settings.editAdvancedType?.enableMyData !== 'active' && SETTINGS.settings.editAdvancedType?.mydataProvider !== 'active'}
                          onChange={(e) => handleOnChange(e)}
                      />
                    </div>
                  </Col>
              }
              {SETTINGS.settings.editAdvancedType.section !== "Client Payments" && (
                  <Col sm="12" md="3">
                    <div className="mb-3 d-flex align-items-center justify-content-start">
                      {t('DocumentTypeAdvancedNewEdit.enableMyData')}
                      <Checkbox
                          className="ml-2 mb-2"
                          name="enableMyData"
                          key={Math.random()}
                          defaultValue={SETTINGS.settings.editAdvancedType.enableMyData === 'active'}
                          value={SETTINGS.settings.editAdvancedType.enableMyData === 'active' ? "inactive" : "active"}
                          onChange={(e) => handleOnChange(e)}
                      />
                    </div>
                  </Col>
              )}
              <Col sm="12" md="3" hidden={SETTINGS.settings.editAdvancedType?.section === "Client Payments"}>
                <div
                    className={(SETTINGS.settings.editAdvancedType?.enableMyData === 'active' || SETTINGS.settings.editAdvancedType?.mydataProvider === 'active') ? 'readonly-field mb-3 d-flex align-items-center justify-content-start' : "mb-3 d-flex align-items-center justify-content-start"}>
                  {t('DocumentTypeAdvancedNewEdit.enableMyDataRules')}
                  <Checkbox
                      className="ml-2 mb-2"
                      name="enableMyDataRules"
                      key={Math.random()}
                      defaultValue={SETTINGS.settings.editAdvancedType.enableMyDataRules === 'active'}
                      value={SETTINGS.settings.editAdvancedType.enableMyDataRules === 'active' ? "inactive" : "active"}
                      disabled={SETTINGS.settings.editAdvancedType?.enableMyData === 'active' || SETTINGS.settings.editAdvancedType?.mydataProvider === 'active'}
                      onChange={(e) => handleOnChange(e)}
                  />
                </div>
              </Col>
              <Row>
                {SETTINGS.settings.editAdvancedType?.section === 'Sales' &&
                    <React.Fragment>
                      <Col md="3" className="d-flex align-items-center justify-content-start mb-3">
                        {t('DocumentTypeAdvancedNew.enableDisplayClientBalance')}
                        <BetterCheckBox
                            name={"enableDisplayClientBalance"}
                            className="mb-2"
                            checked={SETTINGS.settings.editAdvancedType.enableDisplayClientBalance === 'active'}
                            onChange={(e) => handleOnChange(e, "checkbox", "enableDisplayClientBalance")}
                        />
                      </Col>
                      <Col md="3" className="d-flex align-items-center justify-content-start mb-3">
                        Χρησιμοποιείται για έκδοση παραστατικού καυσίμων
                        <BetterCheckBox
                            name={"fuelInvoice"}
                            className="mb-2"
                            checked={SETTINGS.settings.editAdvancedType.fuelInvoice === 'active'}
                            onChange={(e) => handleOnChange(e, "checkbox", "fuelInvoice")}
                        />
                      </Col>
                    </React.Fragment>
                }
                {isDeliveryNoteTypes.includes(SETTINGS.settings.editAdvancedType.myDataType) && (
                    <Col md="3" className="d-flex align-items-center justify-content-start mb-3">
                      <span className={"mr-2"}>Αποτελεί και δελτίο αποστολής</span>
                      <BetterCheckBox
                          name={"isDeliveryNote"}
                          className="mb-2"
                          checked={SETTINGS.settings.editAdvancedType.isDeliveryNote === 'active'}
                          onChange={(e) => handleOnChange(e, "checkbox", "isDeliveryNote")}
                      />
                    </Col>
                )}
              </Row>
              {SETTINGS.settings.editAdvancedType.enableMyDataRules === 'active' &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNewEdit.myDataInvoiceType.myDataInvoiceType')}
                        options={SETTINGS.settings.editAdvancedType.section === 'Accounting Records' ? getInvoiceTypesOptions("all") : myDataTypeOptions}
                        value={selectedMyDataType}
                        searchEnable={true}
                        enabledValue={true}
                        name="myDataType"
                        onChange={(e) => handleOnChange(e, 'dd', 'myDataType')}
                    />
                    {ACTIVITY.saveAdvancedType && !SETTINGS.settings.editAdvancedType.myDataType &&
                        <div
                            className="text-danger">{t('DocumentTypeAdvancedNewEdit.myDataInvoiceType.required')}</div>
                    }
                  </div>
                </Col>
              }
              {(SETTINGS.settings.editAdvancedType.section === 'Accounting Records' || SETTINGS.settings.editAdvancedType.section === 'Buys') &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label="Προτεινόμενη Εγκατάσταση"
                        options={installationOptions}
                        value={getDefaultOptionByValue(installationOptions, SETTINGS.settings.editAdvancedType.proposedInstallation, 'value', 'label', 'value')}
                        enabledValue={true}
                        searchEnable={true}
                        required={false}
                        name="proposedInstallation"
                        onChange={(e) => handleOnChange(e, 'dd', 'proposedInstallation')}
                    />
                  </div>
                </Col>
              }
              {SETTINGS.settings.editAdvancedType.section === 'Buys' && (
                <Row>
                  <Col md={3}>
                    <Dropdown
                        label={"Χρησιμοποιείται για χαρακτηρισμό"}
                        name={"classificationsArray"}
                        options={getInvoiceTypesOptions()}
                        multiSelect={true}
                        key={Math.random()}
                        defaultValue={getInvoiceTypesOptions().filter((el) => SETTINGS.settings.editAdvancedType?.classificationsArray?.includes(el.value))}
                        onChange={(e) => handleOnChange(e, "msdd", "classificationsArray")}
                    />
                  </Col>
                </Row>
              )}
              {(SETTINGS.settings.editAdvancedType.section === "Sales" && SETTINGS.settings.editAdvancedType.mydataProvider === "active"
                  && (SETTINGS.settings.editAdvancedType.myDataType === "1.1" || SETTINGS.settings.editAdvancedType.myDataType === "2.1"
                      || SETTINGS.settings.editAdvancedType.myDataType === "5.1" || SETTINGS.settings.editAdvancedType.myDataType === "5.2")
                  && checkPermission("custom-b2g", permissionsData)) && (
                  <Col md={3}>
                    <BetterCheckBox
                        name={"enablePeppol"}
                        className={"mt-4"}
                        text={"Αφορά δημόσια σύμβαση"}
                        checked={SETTINGS.settings.editAdvancedType?.enablePeppol === "active"}
                        onChange={(e) => handleOnChange(e, "checkbox", "enablePeppol")}
                    />
                  </Col>
              )}
              {(SETTINGS.settings.editAdvancedType.section !== 'Accounting Records' && SETTINGS.settings.editAdvancedType.section !== 'Buys' && SETTINGS.settings.editAdvancedType.section !== 'Client Payments') &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNewEdit.creditDocument')}
                        options={creditDocumentOptions}
                        value={getDefaultOptionByValue(creditDocumentOptions, SETTINGS.settings.editAdvancedType.creditDocument, 'value', 'label', 'value')}
                        searchEnable={true}
                        enabledValue={true}
                        name="creditDocument"
                        onChange={(e) => handleOnChange(e, 'dd', 'creditDocument')}
                    />
                  </div>
                </Col>
              }
              {SETTINGS.settings.editAdvancedType.creditDocument !== null && SETTINGS.settings.editAdvancedType.creditDocument !== "" && SETTINGS.settings.editAdvancedType.creditDocument !== undefined &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNewEdit.creditDocumentPreNumber')}
                        name="creditDocumentPreNumber"
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(preNumberGreek, SETTINGS.settings.editAdvancedType.creditDocumentPreNumber, 'value', 'label', 'value')}
                        options={preNumberGreek}
                        onChange={(e) => handleOnChange(e, "dd", "creditDocumentPreNumber")}
                    />
                  </div>
                </Col>
              }
              {(SETTINGS.settings.editAdvancedType.section === "Client Payments") && (
                  <Col sm="12" md="8">
                    <Dropdown
                        label={"Παραστατικό Επιστροφής Είσπραξης"}
                        options={returnPaymentDocuments}
                        className={"mb-3"}
                        defaultValue={getDefaultOptionByValue(returnPaymentDocuments, SETTINGS.settings.editAdvancedType.returnPaymentDocument, 'value', 'label', 'value')}
                        searchEnable={true}
                        name={"returnPaymentDocument"}
                        onChange={(e) => handleOnChange(e, "dd", "returnPaymentDocument")}
                    />
                  </Col>
              )}
              {(SETTINGS.settings.editAdvancedType.section === "Client Payments" && !isEmpty(SETTINGS.settings.editAdvancedType.returnPaymentDocument)) && (
                  <Col sm="12" md="8">
                    <div className="mb-3">
                      <Dropdown
                          label={"Σειρά Παραστατικού Επιστροφής"}
                          name="returnPaymentDocumentPreNumber"
                          key={Math.random()}
                          defaultValue={getDefaultOptionByValue(preNumberGreek, SETTINGS.settings.editAdvancedType.returnPaymentDocumentPreNumber, 'value', 'label', 'value')}
                          options={preNumberGreek}
                          onChange={(e) => handleOnChange(e, "dd", "returnPaymentDocumentPreNumber")}
                      />
                    </div>
                  </Col>
              )}
              {SETTINGS.settings.editAdvancedType.section !== 'Accounting Records' && SETTINGS.settings.editAdvancedType.section !== 'Buys' &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNewEdit.conversionDocument')}
                        options={conversionDocumentOptions}
                        value={getDefaultOptionByValue(conversionDocumentOptions, SETTINGS.settings.editAdvancedType.conversionDocument, 'value', 'label', 'value')}
                        searchEnable={true}
                        enabledValue={true}
                        name="conversionDocument"
                        onChange={(e) => handleOnChange(e, 'dd', 'conversionDocument')}
                    />
                  </div>
                </Col>
              }
              {SETTINGS.settings.editAdvancedType.conversionDocument !== null && SETTINGS.settings.editAdvancedType.conversionDocument !== "" && SETTINGS.settings.editAdvancedType.conversionDocument !== undefined &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNewEdit.conversionDocumentPreNumber')}
                        name="conversionDocumentPreNumber"
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(preNumberGreek, SETTINGS.settings.editAdvancedType.conversionDocumentPreNumber, 'value', 'label', 'value')}
                        options={preNumberGreek}
                        onChange={(e) => handleOnChange(e, "dd", "conversionDocumentPreNumber")}
                    />
                  </div>
                </Col>
              }
              {SETTINGS.settings.editAdvancedType.section === 'Sales' &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNewEdit.proposedSalePurpose')}
                        options={proposedSalePurposeOptions}
                        value={getDefaultOptionByValue(proposedSalePurposeOptions, SETTINGS.settings.editAdvancedType.proposedSalePurpose, 'value', 'label', 'value')}
                        searchEnable={true}
                        enabledValue={true}
                        name="proposedSalePurpose"
                        onChange={(e) => handleOnChange(e, 'dd', 'proposedSalePurpose')}
                    />
                  </div>
                </Col>
              }
              {SETTINGS.settings.editAdvancedType.section === 'Sales' &&
                <Col sm="12" md="8">
                  <div className="mb-3">
                    <Dropdown
                        label={t('DocumentTypeAdvancedNewEdit.thirdPartySales')}
                        options={thirdPartySalesOptions}
                        value={getDefaultOptionByValue(thirdPartySalesOptions, SETTINGS.settings.editAdvancedType.thirdPartySales, 'value', 'label', 'value')}
                        searchEnable={true}
                        enabledValue={true}
                        name="thirdPartySales"
                        onChange={(e) => handleOnChange(e, 'dd', 'thirdPartySales')}
                    />
                  </div>
                </Col>
              }
            </Row>
          }
          <Row key={Math.random()}>
            {SETTINGS.settings.editAdvancedType.section === 'Client Payments' &&
              <Col sm="12" md="8">
                <div className="mb-3">
                  <Dropdown
                      label={t('DocumentTypeAdvancedNewEdit.proposedPaymentType.proposedPaymentType')}
                      options={SETTINGS.settings.editAdvancedType?.myDataType === "8.4" ? proposedPaymentTypeOptions.filter((el) => el.type === "pos") : proposedPaymentTypeOptions}
                      defaultValue={getDefaultOptionByValue(proposedPaymentTypeOptions, SETTINGS.settings.editAdvancedType.proposedPaymentType, 'value', 'label', 'value')}
                      key={Math.random()}
                      searchEnable={true}
                      name="proposedPaymentType"
                      classes="w-100"
                      onChange={(e) => handleOnChange(e, 'dd', 'proposedPaymentType')}
                  />
                </div>
              </Col>
            }
            {SETTINGS.settings.editAdvancedType.section === 'Supplier Payments' &&
              <Col sm="12" md="8">
                <div className="mb-3">
                  <Dropdown
                      label="Proposed Payment Type"
                      options={proposedPaymentTypeOptionsBuys}
                      defaultValue={getDefaultOptionByValue(proposedPaymentTypeOptionsBuys, SETTINGS.settings.editAdvancedType.proposedPaymentType, 'value', 'label', 'value')}
                      key={Math.random()}
                      name="proposedPaymentType"
                      required={true}
                      classes="w-100"
                      onChange={(e) => handleOnChange(e, 'dd', 'proposedPaymentType')}
                  />
                  {ACTIVITY.saveAdvancedType && (SETTINGS.settings.editAdvancedType.proposedPaymentType === '' || !SETTINGS.settings.editAdvancedType.proposedPaymentType) &&
                      <div className="text-danger">Proposed Payment Type is required.</div>
                  }
                </div>
              </Col>
            }
            {SETTINGS.settings.editAdvancedType.proposedPaymentType && SETTINGS.settings.editAdvancedType.proposedPaymentTypeType !== "cash" && SETTINGS.settings.editAdvancedType.proposedPaymentTypeType !== "cheque" &&
              <Col sm="12" md="8">
                <div className="mb-3">
                  <Dropdown
                      label={t('DocumentTypeAdvancedNewEdit.proposedPOSBankAccount.proposedPOSBankAccount')}
                      options={proposedPosBankAcountOptions}
                      defaultValue={getDefaultOptionByValue(proposedPosBankAcountOptions, SETTINGS.settings.editAdvancedType.proposedPosBankAccount, 'value', 'label', 'value')}
                      key={Math.random()}
                      searchEnable={true}
                      required={(SETTINGS.settings.editAdvancedType.proposedPaymentTypeType !== "cash" && SETTINGS.settings.editAdvancedType.proposedPaymentTypeType !== "cheque")}
                      name="proposedPosBankAccount"
                      classes="w-100"
                      onChange={(e) => handleOnChange(e, 'dd', 'proposedPosBankAccount')}
                  />
                  {ACTIVITY.saveEditAdvancedType && (SETTINGS.settings.editAdvancedType.proposedPosBankAccount === '' || !SETTINGS.settings.editAdvancedType.proposedPosBankAccount) &&
                      <div
                          className="text-danger">{t('DocumentTypeAdvancedNewEdit.proposedPOSBankAccount.required')}</div>
                  }
                </div>
              </Col>
            }
          </Row>
          {(SETTINGS.settings.editAdvancedType.section !== 'Accounting Records' && SETTINGS.settings.editAdvancedType.section !== 'Buys') &&
            <Row>
              <Col>
                <label>{t('DocumentTypeAdvancedNewEdit.documentNumbering')}<span
                    className="text-danger"> *</span></label>
                {etDocumentNumbering && etDocumentNumbering?.length === 0 &&
                    <div className="text-danger mb-1">{t('DocumentTypeAdvancedNewEdit.message2')}</div>}
                <EditableTable
                    tableName="Edit Advanced Types Document Numbering"
                    key={Math.random()}
                    allowInsertRow="true"
                    columns={advancedDocumentColumns}
                    data={etDocumentNumbering}
                    allowActions={true}
                    enableNewRowCheckbox={true}
                    onUpdate={documentNumberingActions}
                />
              </Col>
            </Row>
          }
        </Container>
      </Container>
  );
}

export const EditAdvancedTypeLogTable = () => {
  const { t } = useTranslation('common');
  const SETTINGS = useSelector((state) => state.SETTINGS.settings);

  return (
      <React.Fragment>
        <div className="text-muted mb-2"><i>{t('Logs.settingAdvancedLog')}</i></div>
        <LogTable itemId={SETTINGS.editAdvancedType._id}/>
      </React.Fragment>
  )
}
