import moment from "moment/moment";

export const fixedPublicHolidays = [
    { date: "01/01", notes: "Πρωτοχρονιά", deletable: false },
    { date: "06/01", notes: "Θεοφάνεια", deletable: false },
    { date: "25/03", notes: "Ευαγγελισμός της Θεοτόκου", deletable: false },
    { date: "01/05", notes: "Εργατική Πρωτομαγιά", deletable: false },
    { date: "15/08", notes: "Κοίμηση της Θεοτόκου", deletable: false },
    { date: "28/10", notes: "Ημέρα του Όχι", deletable: false },
    { date: "25/12", notes: "Χριστούγεννα", deletable: false },
    { date: "26/12", notes: "Σύναξη της Θεοτόκου", deletable: false },
]

export const getOrthodoxEasterMonday = (year) => {
    // year as a number
    let a = year % 19;
    let b = year % 7;
    let c = year % 4;
    let d = (19 * a + 15) % 30;
    let e = (2 * c + 4 * b - d + 34) % 7;
    let month = Math.floor((d + e + 114) / 31);
    let day = ((d + e + 114) % 31) + 1;
    let julianEasterSunday = new Date(Date.UTC(year, month - 1, day));
    let gregorianEasterSunday = new Date(julianEasterSunday);
    gregorianEasterSunday.setUTCDate(julianEasterSunday.getUTCDate() + 13);
    let orthodoxEasterMonday = new Date(gregorianEasterSunday);
    orthodoxEasterMonday.setUTCDate(gregorianEasterSunday.getUTCDate() + 1);

    return moment(orthodoxEasterMonday).format("DD/MM/YYYY");
}

export const isOrthodoxEasterMonday = (date) => {
    // date in DD/MM/YYYY format
    const year = Number(moment(date, "DD/MM/YYYY").format("YYYY"));

    return getOrthodoxEasterMonday(year) === date;
}

export const getFixedPublicHolidays = (year) => {
    let arr = [...fixedPublicHolidays];
    arr.push({
        date: getOrthodoxEasterMonday(Number(year)),
        notes: "Δευτέρα του Πάσχα",
        deletable: false,
    })

    return arr;
}
