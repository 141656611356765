import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import "../../../../primer/POPUP-COMPONENTS/modalWidths.css";
import Input from "../../../../common/Input";
import moment from "moment";
import {isEmpty} from "../../../../../_helpers/commonFunctions";
import {permitTypes} from "./constants";

const EmployeeInfoModal = ({employeeId, show, setShow}) => {
    const defaultData = {
        employeeData: {},
        permitData: {
            analysis: {},
            dateAnalysis: [],
        },
    }
    const [employeeData, setEmployeeData] = useState(defaultData);
    const [loading, setLoading] = useState(true);
    const company = useSelector((state) => state.COMPANY_DATA.company);

    useEffect(() => {
        if (!show) {
            setEmployeeData(defaultData);
        } else {
            fetchEmployeeData();
        }
    }, [show])

    const fetchEmployeeData = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/get-employee-data`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                year: company.year,
                employeeId: employeeId,
            }
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                setEmployeeData(res.data.data);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return (
        <React.Fragment>
            <Modal show={show} onHide={() => setShow(false)} dialogClassName={"modal42PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Πληροφορίες Εργαζομένου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <React.Fragment>
                            Παρακαλώ περιμένετε....
                            <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />
                        </React.Fragment>
                    ) : (
                        <>
                            <Row className={"mb-3"}>
                                <div className={"text-muted"}><i>Γενικά στοιχεία</i></div>
                                <Col md={6}>
                                    <Input
                                        name={"firstName"}
                                        label={"Όνομα"}
                                        value={employeeData?.employeeData?.firstName}
                                        disabled={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input
                                        name={"lastName"}
                                        label={"Επώνυμο"}
                                        value={employeeData?.employeeData?.lastName}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mb-3"}>
                                <Col md={6}>
                                    <Input
                                        name={"vatNumber"}
                                        label={"Α.Φ.Μ."}
                                        value={employeeData?.employeeData?.vatNumber}
                                        disabled={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input
                                        name={"birthDate"}
                                        label={"Ημερομηνία Γέννησης"}
                                        value={!isEmpty(employeeData?.employeeData?.birthDate) ? moment(employeeData?.employeeData?.birthDate).format("DD/MM/YYYY") : ""}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mb-3"}>
                                <Col md={6}>
                                    <Input
                                        name={"employmentDate"}
                                        label={"Ημερομηνία Πρόσληψης"}
                                        value={moment(employeeData?.employeeData?.employmentDate).isValid() ? moment(employeeData?.employeeData?.employmentDate).format("DD/MM/YYYY") : "-"}
                                        disabled={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input
                                        name={"resignationDate"}
                                        label={"Ημερομηνία Αποχώρησης"}
                                        value={moment(employeeData?.employeeData?.resignationDate).isValid() ? moment(employeeData?.employeeData?.resignationDate).format("DD/MM/YYYY") : "-"}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mb-3"}>
                                <Col md={12}>
                                    <Input
                                        name={"specialty"}
                                        label={"Ειδικότητα"}
                                        value={employeeData?.employeeData?.specialty}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mb-3"}>
                                <Col md={4}>
                                    <Input
                                        name={"takenNormalPermits"}
                                        label={"Ληφθείσες Κανονικές Άδειες"}
                                        value={employeeData?.permitData?.takenNormalPermits}
                                        disabled={true}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input
                                        name={"totalNormalPermits"}
                                        label={"Συνολικές Κανονικές Άδειες"}
                                        value={employeeData?.permitData?.totalNormalPermits}
                                        disabled={true}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input
                                        name={"otherPermits"}
                                        label={"Λοιπές Ληφθείσες Άδειες"}
                                        value={employeeData?.permitData?.otherPermits}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                            {Object.keys(employeeData?.permitData?.analysis).length > 0 && (
                                <Row>
                                    <Col md={12}>
                                        <Alert variant={"secondary"}>
                                            Οι μετρημένες άδειες που φαίνονται παρακάτω αφορούν το παρόν έτος ({company.year}) που είστε συνδεδεμένοι στο ERP.
                                        </Alert>
                                    </Col>
                                    <Col md={6}>
                                        <div className={"text-muted mb-1"} style={{textAlign: "center"}}><i>Ανάλυση Αδειών ανά τύπο</i></div>
                                        <table style={{tableLayout: "fixed", textAlign: "center"}} className={"simpleClassicTable"}>
                                            <colgroup>
                                                <col span={1} style={{width: "80%"}}></col>
                                                <col span={1} style={{width: "20%"}}></col>
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th>Είδος Αδείας</th>
                                                <th>Ποσό</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {Object.keys(employeeData?.permitData?.analysis).map((permType, idx) => (
                                                <tr key={`eim-row-${idx}`}>
                                                    <td>{permitTypes.find((el) => el.value === permType)?.label}</td>
                                                    <td>{employeeData?.permitData?.analysis[permType]}</td>
                                                </tr>
                                            ))}
                                            {Object.keys(employeeData?.permitData?.analysis).length > 0 && (
                                                <tr key={"eim-final-row"}>
                                                    <td style={{textAlign: "right", paddingRight: "10px"}}><strong>Σύνολο</strong></td>
                                                    <td><strong>{Object.values(employeeData?.permitData?.analysis).reduce((acc, val) => acc + val, 0)}</strong></td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col md={6}>
                                        <div className={"text-muted mb-1"} style={{textAlign: "center"}}><i>Ανάλυση Αδειών ανά ημέρα</i></div>
                                        <table style={{tableLayout: "fixed", textAlign: "center"}} className={"simpleClassicTable"}>
                                            <colgroup>
                                                <col span={1} style={{width: "30%"}}></col>
                                                <col span={1} style={{width: "70%"}}></col>
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th>Ημερομηνία Αδείας</th>
                                                <th>Τύπος Αδείας</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {employeeData?.permitData?.dateAnalysis?.map((row, idx) => (
                                                <tr key={`eimd-row-${idx}`}>
                                                    <td>{row.date}</td>
                                                    <td>{permitTypes.find((el) => el.value === row.permitType)?.label}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShow(false)}>
                        Κλείσιμο
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default EmployeeInfoModal
