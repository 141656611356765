import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo} from "react";
import {
    getDefaultOptionByValue,
    getOptionsByData,
    getRecordTypeOptions,
    isEmpty
} from "../../../../_helpers/commonFunctions";
import {accountingTypes, getBridgeTypes, signs, wildcardsList} from "./accountingBridgeConstants";
import {
    fetchAccountingAdvancedTypes,
    fetchAccountingRecordCategories,
    fetchAccountingRecordSubcategories
} from "../../../../_apis/api";
import axios from "axios";
import {setAccountingRecordSubcategories} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import {toast} from "react-toastify";
import {setSaveEditAdvancedType} from "../../../../_reducers/activitySlice";
import {setSettings} from "../../../../_reducers/settingsSlice";
import {Badge, Card, Col, Row} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import EditableTable from "../../../primer/Editable-Table";
import {AccountingBridgeInstructions} from "./AccountingBridgeInstructions";

export const EditAdvancedTypesAccounting = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const ACCOUNTING_DATA = useSelector((state) => state.ACCOUNTING_DATA);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const editAdvancedType = useSelector((state) => state.SETTINGS.settings.editAdvancedType);

    const categories = useMemo(() => [{ label: "Κενό", value: ""}, ...getOptionsByData(ACCOUNTING_DATA.accountingRecordCategories, "_id", "name")], [ACCOUNTING_DATA.accountingRecordCategories]);
    const subCategories = useMemo(() => [{ label: "Κενό", value: ""}, ...getOptionsByData(ACCOUNTING_DATA.accountingRecordSubcategories, "_id", "name")], [ACCOUNTING_DATA.accountingRecordSubcategories]);
    const documentTypesOptions = useMemo(() => getOptionsByData(ACCOUNTING_DATA.accountingAdvancedTypes, "_id", "name"), [ACCOUNTING_DATA.accountingAdvancedTypes]);

    const tableColumns = [
        {
            name: "Τύπος",
            field: "type",
            editable: true,
            width: "25%",
            inputType: {
                type: "select",
                options: getBridgeTypes(editAdvancedType.section),
            }
        },
        {
            name: "Λογιστικός Λογαριασμός",
            field: "account",
            editable: true,
            width: "40%",
            inputType: {
                type: "input",
            }
        },
        {
            name: "Πρόσημο",
            field: "sign",
            editable: true,
            width: "10%",
            inputType: {
                type: "select",
                options: signs,
            }
        },
        {
            name: "Λογιστικός Τύπος",
            field: "accountingType",
            editable: true,
            width: "25%",
            inputType: {
                type: "select",
                options: accountingTypes,
            }
        },
    ]

    useEffect(() => {
        dispatch(fetchAccountingRecordCategories(company.id));
        dispatch(fetchAccountingRecordSubcategories(company.id));
        dispatch(fetchAccountingAdvancedTypes(company.id));
        if (String(editAdvancedType?.accounting?.category).length === 24) {
            loadSubCategories(editAdvancedType?.accounting?.category);
        }
    }, [])

    const loadSubCategories = (categoryID) => {
        axios.post(process.env.REACT_APP_API_URL2 + `/accounting/accounting-record-category-list`, {id: categoryID}, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                dispatch(setAccountingRecordSubcategories(res.data.data?.subCategories));
            } else {
                toast.error(res.data.message);
            }
        }).catch((error) => {
            toast.error("Κάτι πήγε λάθος κατά την ανάκτηση της συγκεκριμένης κατηγορίας.");
            console.log(error);
        })
    }

    const handleOnChange = (e, type, name) => {
        if (!ACTIVITY.saveEditAdvancedType) dispatch(setSaveEditAdvancedType(true));

        let cloneAdvType = {...SETTINGS.settings.editAdvancedType};
        if (isEmpty(cloneAdvType.accounting)) {
            cloneAdvType.accounting = structuredClone(SETTINGS.newAdvancedTypeInitialValues.accounting);
        }
        let accountingObject = {...cloneAdvType.accounting};
        if (type === "dropdown") {
            if (name === "category") {
                accountingObject = {...accountingObject, category: e.value, categoryName: e.label, subCategory: "", subCategoryName: ""}
                if (!isEmpty(e.value)) loadSubCategories(e.value);
            } else if (name === "subCategory") {
                accountingObject = {...accountingObject, subCategory: e.value, subCategoryName: e.label}
            } else if (name === "documentType") {
                accountingObject = {...accountingObject, documentType: e.value, documentTypeName: e.label}
            } else {
                accountingObject = {...accountingObject, [name] : e.value};
            }
        } else if (type === "wildcard") {
            accountingObject = {...accountingObject, reason: String(accountingObject.reason + " " + name).trim()}
        } else if (type === "input") {
            accountingObject = {...accountingObject, reason: e.target.value}
        }
        cloneAdvType.accounting = accountingObject;
        dispatch(setSettings({...SETTINGS.settings, editAdvancedType: cloneAdvType}));
    }

    const bridgeTableFunctions = {
        insert: (e, data) => {
            if (!isEmpty(data.value)) {
                if (!ACTIVITY.saveEditAdvancedType) dispatch(setSaveEditAdvancedType(true));
                let cloneAdvType = structuredClone(editAdvancedType);
                if (isEmpty(cloneAdvType.accounting)) {
                    cloneAdvType.accounting = structuredClone(SETTINGS.newAdvancedTypeInitialValues.accounting);
                }
                let initRow = {
                    type: "",
                    account: "",
                    sign: "+",
                    accountingType: "",
                }
                initRow = {...initRow, [data.field] : data.value};
                cloneAdvType.accounting.bridge.push(initRow);
                dispatch(setSettings({...SETTINGS.settings, editAdvancedType: cloneAdvType}));
            }
        },
        update: (data, id, rowIndex) => {
            if (!ACTIVITY.saveEditAdvancedType) dispatch(setSaveEditAdvancedType(true));
            let cloneAdvType = structuredClone(editAdvancedType);
            cloneAdvType.accounting.bridge[rowIndex] = {...cloneAdvType.accounting.bridge[rowIndex], ...data};
            dispatch(setSettings({...SETTINGS.settings, editAdvancedType: cloneAdvType}));
        },
        selected: () => {},
        delete: (id, rowIndex) => {
            if (!ACTIVITY.saveEditAdvancedType) dispatch(setSaveEditAdvancedType(true));
            let cloneAdvType = structuredClone(editAdvancedType);
            cloneAdvType.accounting.bridge.splice(rowIndex, 1);
            dispatch(setSettings({...SETTINGS.settings, editAdvancedType: cloneAdvType}));
        }
    }

    return (
        <React.Fragment>
            {isEmpty(editAdvancedType.section) ?
                <div className={"mb-2"}>Παρακαλώ επιλέξτε κατηγορία πρώτα.</div>
                :
                <React.Fragment>
                    <Row>
                        <div className="text-muted mb-1"><i>Ρυθμίσεις Λογιστικής Εγγραφής</i></div>
                        <Col md={3}>
                            <Dropdown
                                name={"category"}
                                label={"Κατηγορία"}
                                options={categories}
                                key={Math.random()}
                                defaultValue={categories.find((el) => el.value === editAdvancedType?.accounting?.category)}
                                onChange={(e) => handleOnChange(e, "dropdown", "category")}
                            />
                        </Col>
                        <Col md={3}>
                            <Dropdown
                                name={"subCategory"}
                                label={"Υποκατηγορία"}
                                options={subCategories}
                                key={Math.random()}
                                defaultValue={subCategories.find((el) => el.value === editAdvancedType?.accounting?.subCategory)}
                                onChange={(e) => handleOnChange(e, "dropdown", "subCategory")}
                            />
                        </Col>
                        <Col md={3}>
                            <Dropdown
                                name={"documentType"}
                                label={"Τύπος Παραστατικού"}
                                options={documentTypesOptions}
                                key={Math.random()}
                                required={true}
                                defaultValue={documentTypesOptions.find((el) => el.value === editAdvancedType?.accounting?.documentType)}
                                onChange={(e) => handleOnChange(e, "dropdown", "documentType")}
                            />
                        </Col>
                        <Col md={3}>
                            <Dropdown
                                name={"recordType"}
                                label={"Τύπος Εγγραφής"}
                                key={Math.random()}
                                defaultValue={getDefaultOptionByValue(getRecordTypeOptions(),  editAdvancedType?.accounting?.recordType, 'value', 'label', 'value')}
                                options={getRecordTypeOptions()}
                                onChange={(e) => handleOnChange(e, "dropdown", 'recordType')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Input
                                name={"reason"}
                                label={"Αιτία λογιστικού άρθρου"}
                                value={editAdvancedType?.accounting?.reason}
                                autoComplete={"off"}
                                onChange={(e) => handleOnChange(e, "input", "reason")}
                            />
                        </Col>
                        <Col md={6}>
                            <Card className="wildcards-list">
                                <div style={{borderRadius: "8px", border: "1px solid lightgray", padding: "10px"}}>
                                    Προσθήκη Μεταβλητών<br/>
                                    {wildcardsList.map((item, idx) => (
                                        <Badge key={`wildcard${idx}`} bg={"primary"} style={{cursor: "pointer"}} onClick={(e) => handleOnChange(e, "wildcard", item.value)} className={"mr-1 mt-1"}>{item.label}</Badge>
                                    ))}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <div className="text-muted mb-1"><i>Παραμετροποίηση Γέφυρας</i></div>
                        <Col md={12}>
                            <EditableTable
                                tableName={"Accounting Bridge"}
                                key={Math.random()}
                                columns={tableColumns}
                                onUpdate={bridgeTableFunctions}
                                data={editAdvancedType?.accounting?.bridge ? editAdvancedType.accounting.bridge : []}
                                allowInsertRow={true}
                                allowActions={true}
                            />
                        </Col>
                    </Row>
                    <AccountingBridgeInstructions/>
                </React.Fragment>
            }
        </React.Fragment>
    )
}