import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Chart from "react-apexcharts";

export const SmallClientChart = () => {
    const dashboardData = useSelector(state => state.COMPANY_DATA.dashboardData);
    const [chartOptions, setChartOptions] = useState({});
    const [series, setSeries] = useState([]);

    useEffect(() => {
        if (dashboardData?.monthlyData) {
            let co = {
                chart: {
                    type: "area",
                    height: '100%',
                    parentHeightOffset: 0,
                    sparkline: {
                        enabled: true,
                    },
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false
                    }
                },
                grid: {
                    show: false,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    }
                },
                legend: {
                    show: false,
                },
                stroke: {
                    curve: "smooth",
                    width: 2,
                },
                xaxis: {
                    categories: [],
                    lines: {
                        show: false,
                    },
                    labels: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    }
                },
                yaxis: {
                    show: false,
                    lines: {
                        show: false,
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 0,
                },
                colors: ["#635BFF"],
                tooltip: {
                    enabled: true,
                    x: {
                        show: false,
                    },
                }
            }
            let s = [
                {
                    name: "Πελάτες",
                    data: []
                },
            ]
            for (let d of dashboardData.monthlyData) {
                co.xaxis.categories.push(d.month);
                s[0].data.push(d.totalClients);
            }
            setChartOptions(co);
            setSeries(s);
        }
    }, [dashboardData])

    return (
        <Chart
            options={chartOptions}
            series={series}
            type="area"
            height={"100px"}
            width={"100%"}
        />
    )
}