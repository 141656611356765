import React from "react";
import {isEmpty} from "../../../../../_helpers/commonFunctions";
import {getFilePreviewerType} from "./constants";
import Button from "react-bootstrap/Button";
import {ButtonGroup, Dropdown, DropdownButton, Spinner} from "react-bootstrap";
import "./styles.css";

const FilePreviewer = ({path, previewData, fileName, loading, onFileDownload, onPreviewNewTab, onResetPreview, onEmronSort, onEmronRestore, onEmronRegister, specialCode, isEmron}) => {
    const previewStyle = {
        borderTopRightRadius: "18px",
        borderBottomRightRadius: "18px",
        border: "2px solid lightgray",
        overflowY: "auto",
        width: "100%",
        padding: "10px",
    }

    const iframeStyle = {
        display: "block",
        width: "100%",
        height: "60vh",
        maxWidth: "100%",
        margin: 0,
        padding: 0,
        border: "0 none",
        boxSizing: "border-box",
    }

    const isRegister = !String(path).includes("/Καταχωρημένα/");
    const isSort = !String(path).includes("/Ταξινομημένα/");

    const registerOrSortToOptions = ["Αγορές", "Πωλήσεις", "Τράπεζες", "Λοιπά Αρχεία", "Πάγια", "Παρακρατούμενοι & Λοιποί Φόροι", "Ιδιωτικά Συμφωνητικά & Συμβάσεις"];

    return (
        <div style={previewStyle}>
            {isEmpty(previewData) ? (
                <div style={{textAlign: "center"}}>
                    Πατήστε κάποιο αρχείο για προεπισκόπηση
                </div>
            ) : (
                <React.Fragment>
                    {loading ? (
                        <div style={{textAlign: "center"}}>
                            <div>
                                <Spinner animation={"border"} variant={"dark"} className={"mt-2"} />
                            </div>
                            <div>
                                Η προεπισκόπηση φορτώνει παρακαλώ περιμένετε...
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div style={{textAlign: "center"}} className={"mb-1"}>
                                Προεπισκόπηση αρχείου <strong>{fileName}</strong>
                            </div>
                            <div style={{display: "flex", width: "100%", height: "40px"}}>
                                <Button size={"sm"} className={"mb-1"} style={{flex: "1"}} onClick={() => onPreviewNewTab(path)}>Άνοιγμα σε νέα καρτέλα</Button>
                                <Button size={"sm"} className={"ml-1 mb-1"} style={{flex: "1"}} onClick={() => onFileDownload(previewData, fileName)}>Λήψη αρχείου</Button>
                                <Button size={"sm"} className={"ml-1 mb-1"} style={{flex: "0.1"}} onClick={() => onResetPreview()}><i className="fa-solid fa-xmark"></i></Button>
                            </div>
                            {isEmron && (
                                <React.Fragment>
                                    {specialCode === "1" && !String(path).includes("/Διαγράφηκε/") && (
                                        <div style={{display: "flex", width: "100%", height: "40px"}} className={"dropdownP0"}>
                                            {isRegister ? (
                                                <DropdownButton
                                                    as={ButtonGroup}
                                                    variant={"primary"}
                                                    title={"Καταχώρηση σε"}
                                                    style={{flex: "1"}}
                                                    className={"mb-1"}
                                                >
                                                    {registerOrSortToOptions.map((opt, idx) => (
                                                        <Dropdown.Item key={`registerTo-${idx}`} onClick={() => onEmronRegister(path, opt)}>{opt}</Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            ) : (
                                                <Button size={"sm"} className={"mb-1"} style={{flex: "1"}} onClick={() => onEmronRegister(path)}>Αναίρεση Καταχώρησης</Button>
                                            )}
                                            {isSort ? (
                                                <DropdownButton
                                                    as={ButtonGroup}
                                                    variant={"primary"}
                                                    title={"Ταξινόμηση σε"}
                                                    style={{flex: "1"}}
                                                    className={"ml-1 mb-1"}
                                                >
                                                    {registerOrSortToOptions.map((opt, idx) => (
                                                        <Dropdown.Item key={`registerTo-${idx}`} onClick={() => onEmronSort(path, opt)}>{opt}</Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            ) : (
                                                <Button size={"sm"} className={"ml-1 mb-1"} style={{flex: "1"}} onClick={() => onEmronSort(path)}>Αναίρεση ταξινόμησης</Button>
                                            )}
                                        </div>
                                    )}
                                    {specialCode === "1" && String(path).includes("/Διαγράφηκε/") && (
                                        <div style={{display: "flex", width: "100%"}}>
                                            <Button size={"sm"} className={"mb-1"} style={{flex: "1 1"}} onClick={() => onEmronRestore(path)}>Επαναφορά</Button>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                            <div>
                                {getFilePreviewerType(fileName) === "blobURL" && (
                                    <React.Fragment>
                                        {!isEmpty(previewData) && (
                                            <iframe className={"mt-1"} src={previewData} style={iframeStyle} title={"File Previewer"}></iframe>
                                        )}
                                    </React.Fragment>
                                )}
                                {getFilePreviewerType(fileName) === "videoPlayer" && (
                                    <React.Fragment>
                                        <video controls src={previewData}
                                               style={{maxWidth: "100%", maxHeight: "100%"}} draggable={false}></video>
                                    </React.Fragment>
                                )}
                                {getFilePreviewerType(fileName) === "imageViewer" && (
                                    <React.Fragment>
                                        <img src={previewData} alt={"Preview"} style={{maxWidth: "100%"}}
                                             draggable={false}/>
                                    </React.Fragment>
                                )}
                                {getFilePreviewerType(fileName) === "audioPlayer" && (
                                    <React.Fragment>
                                        <audio controls src={previewData} draggable={false}/>
                                    </React.Fragment>
                                )}
                                {getFilePreviewerType(fileName) === "none" && (
                                    <React.Fragment>
                                        <div style={{textAlign: "center"}}>
                                            Δεν είναι δυνατή η προεπισκόπηση του αρχείου <strong>{fileName}</strong>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}

export default FilePreviewer
