import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    newSubscription: {},
    subscriptions: []
}

export const SubscriptionsSlice = createSlice({
    name: "subscriptions-slice",
    initialState,
    reducers: {
        setNewSubscription: (state, {payload}) => {
            state.newSubscription = payload;
        },
        resetNewSubscription: (state) => {
            state.newSubscription = {};
        },
        setSubscriptions: (state, {payload}) => {
            state.subscriptions = payload;
        }
    }
})

export const {
    setNewSubscription,
    resetNewSubscription,
    setSubscriptions
} = SubscriptionsSlice.actions