import React, {useState, useEffect, useRef} from "react";
import {Form, Row, Col, Button, Modal, FormLabel, Badge} from "react-bootstrap";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import {
    fetchClientPaymentData,
    fetchAdvancedTypes,
    fetchSalesGeneralSettings,
    fetchClients,
    fetchPOS,
    fetchBankAccounts,
    fetchInitialClients,
} from "../../../../_apis/api";
import { useSelector, useDispatch } from "react-redux";
import {
    getClientPaymentPrintData,
    getCurrentUser,
    getDefaultOptionByValue, getModeByInstallation, getNumberByInstallation,
    getOptionsByData, getPreNumberByInstallation,
    getSelectedInstallation, isEmpty,
    removeDuplicateObjects,
} from "../../../../_helpers/commonFunctions";
import "react-datepicker/dist/react-datepicker.css";
import {setSaveEditClientPayments} from "../../../../_reducers/activitySlice";
import {
    getLabelByValue,
    getObjectFromArrayOfObjects,
} from "../../../../_helpers/helperFunctions";
import {setEditClientPayment} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import TextArea from "antd/lib/input/TextArea";
import EditableTable from "../../../primer/Editable-Table";
import { toast } from "react-toastify";
import AccountingBox from "../../../primer/AccountingBox";
import DateBox from "../../../primer/DateBox";
import {useTranslation} from "react-i18next";
import SendEmail from "../../../primer/POPUP-COMPONENTS/Send-Email";
import ClientPaymentPrintTemplate1Greek from "../../PrintLayout/client-payment-print-template1-greek";
import ClientpaymentPrintTemplate1English from "../../PrintLayout/client-payment-print-template1-english";
import axios from "axios";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";
import moment from "moment/moment";
import {preNumberGreek} from "../../../../_data/settings/advanced-types-search";
import {setEditSales} from "../../../../_reducers/DataPanel/Sales/salesSlice";
import Alert from "react-bootstrap/Alert";
import {AccountingView} from "../../../primer/AccountingView/AccountingView";
import LogTable from "../../../primer/LogTable";

function EditClientPayments() {
    const [t] = useTranslation('common');
    const [showPaymentTypeConfirmation, setShowPaymentTypeConfirmation] = useState(false);
    const [paymentTypeTempData, setPaymentTypeTempData] = useState({});
    const [showErrors, setShowErrors] = useState(false);
    const dispatch = useDispatch();
    const COMPANY_DATA = useSelector((state) => state.COMPANY_DATA);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);
    const SALES = useSelector((state) => state.SALES); // Will only be used for printing/email
    let selectedClintData = []
    let clientsListData = CLIENTS_DATA.clients.slice();
    const componentRef = useRef();
    const componentRefEn = useRef();
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);

    const requestData = {
        company: company.id,
        year: company.year.toString(),
    };
    let requestDataF = {
        company: company.id,
        year: company.year.toString(),
        name: ''
    };

    const currentUser = getCurrentUser();

    const requestData2 = {
        company: company.id,
        year: company.year.toString(),
        isActive: "active",
        userId: currentUser.id,
    };

    // Options for Document Type Dropdown
    const allDocumentTypes = SETTINGS.settings.advancedTypes;
    const clientPaymentsDT = getObjectFromArrayOfObjects(
        allDocumentTypes,
        "Client Payments",
        "section"
    );
    const documentTypesOptions = getOptionsByData(
        clientPaymentsDT,
        "_id",
        "name"
    );
    let selectedDocumentTypeData = [];
    let dtInstallationsOptions = [];

    useEffect(() => {
        dispatch(fetchAdvancedTypes(requestData));
        dispatch(fetchClientPaymentData(requestData));
        dispatch(fetchSalesGeneralSettings(requestData2));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchPOS(requestData2));
        dispatch(fetchBankAccounts(requestData2));
        dispatch(fetchInitialClients(requestData));
    }, []);
    const paymentTypeOptions = getOptionsByData(
        SALES_SETTINGS.salesGeneralSettings.paymentTypes?.filter((el) => el.type !== "credit"),
        "_id",
        "name"
    );
    if (
        CLIENTS_DATA.editClientPayment.documentType === undefined ||
        CLIENTS_DATA.editClientPayment.documentType === ""
    ) {
    } else {
        let dtData = getObjectFromArrayOfObjects(
            clientPaymentsDT,
            CLIENTS_DATA.editClientPayment.documentType,
            "_id"
        );
        if (dtData.length > 0) {
            selectedDocumentTypeData = dtData["0"];
            dtInstallationsOptions = getOptionsByData(selectedDocumentTypeData['documentNumbering'], "installationMasterId", "installationType");
            dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
        }
    }
    const handlePaymentTypeConfirmation = () => {
        // Populate Selected Category Data
        let newClientPaymentData = {};
        let type = paymentTypeTempData.type;

        newClientPaymentData.paymentType = paymentTypeTempData.paymentType;
        newClientPaymentData.paymentTypeName = paymentTypeTempData.name;
        newClientPaymentData.paymentTypeType = type;

        if (type === "cash" || type === "iris") {
            newClientPaymentData.posDetails = CLIENTS_DATA.newClientPaymentInitialValue.posDetails;
            newClientPaymentData.bankDetails = CLIENTS_DATA.newClientPaymentInitialValue.bankDetails;
        }

        if (type === "pos") {
            newClientPaymentData.amount = "";
            newClientPaymentData.bankDetails = CLIENTS_DATA.newClientPaymentInitialValue.bankDetails;
        }

        if (type === "bank-account" || type === "web-banking" || type === "bank-account-outside") {
            newClientPaymentData.amount = "";
            newClientPaymentData.posDetails = CLIENTS_DATA.newClientPaymentInitialValue.posDetails;
        }

        dispatch(setEditClientPayment({...CLIENTS_DATA.editClientPayment, ...newClientPaymentData,}));
        setShowPaymentTypeConfirmation(false);
    };

    const handleOnChange = (e, type = "default", ddname = "") => {
        setShowErrors(true);

        // Set if editing the form
        if (!ACTIVITY.saveEditClientPayments) {
            dispatch(setSaveEditClientPayments(true));
        }

        let name;
        let value;

        if (type === "dd") {
            value = e.value;
            name = ddname;
        } else if (type === 'ac' ) {
            value = e.target.value;
            name = ddname;
        } else if (type === "dp") {
            value = e;
            name = ddname;
        } else if (type === "acdd") {
            selectedClintData = []
            console.log('e.row',e.row);
            e = e.row;
            selectedClintData.push(e)
            value = e._id;
            name = ddname;
        } else if (type === "acdd2") { // forSale
            value = e.row.value;
            name = "forSale";
        } else if (type === "tp") {
            value = e;
            name = ddname;
        } else {
            value = e.target.value;
            name = e.target.name;
        }

        let salesData = Object.assign({}, CLIENTS_DATA.editClientPayment);
        let newClientPaymentData = {};
        if (name !== "paymentType") {
            newClientPaymentData[name] = value;
        }

        if (name === "documentType") {
            newClientPaymentData[name] = value;
            newClientPaymentData.documentTypeName = getLabelByValue(documentTypesOptions, value, "value");
            let selectedDocumentTypeData = getObjectFromArrayOfObjects(clientPaymentsDT, value, "_id");
            if (selectedDocumentTypeData.length > 0) {
                let selectedDTData = selectedDocumentTypeData["0"];
                newClientPaymentData.myDataType = selectedDTData.myDataType;
                if(selectedDTData.myDataType === "8.4" || selectedDTData.myDataType === "8.5") {
                    newClientPaymentData.forSale = "";
                    newClientPaymentData.forSaleInputLabel = "";
                }
                let selectedInstallation = getSelectedInstallation(selectedDTData["documentNumbering"]);
                if (Object.keys(selectedInstallation).length > 0) {
                    newClientPaymentData.installation = selectedInstallation.installationMasterId;
                    newClientPaymentData.preNumber = selectedInstallation.preNumber;
                    newClientPaymentData.number = selectedInstallation.number + 1;
                    newClientPaymentData.installationMode = selectedInstallation.numberingMode;
                    newClientPaymentData.numberingMode = selectedInstallation.numberingMode;
                }
                const paymentObjArr = SALES_SETTINGS.salesGeneralSettings.paymentTypes.filter((el) => el._id === selectedDTData.proposedPaymentType);
                if(paymentObjArr.length > 0) {
                    const paymentObj = paymentObjArr[0];
                    newClientPaymentData.paymentType = paymentObj._id;
                    newClientPaymentData.paymentTypeName = paymentObj.name;
                    newClientPaymentData.paymentTypeType = paymentObj.type;
                }
                if(selectedDTData.proposedPosBankAccount !== undefined && selectedDTData.proposedPosBankAccount !== ""){
                    if(CASH_DATA.posList.length > 0 || CASH_DATA.bankAccounts.length > 0){
                        if(selectedDTData.proposedPaymentTypeType === "pos"){
                            const selectedPos = CASH_DATA.posList.filter(pos => pos._id === selectedDTData.proposedPosBankAccount)[0];
                            console.log(selectedPos)
                            let selectedPosDetails = {
                                id: "1",
                                no: "1",
                                name: selectedPos.name,
                                registrationNumber: selectedPos.serialNumber,
                                amount: 0,
                                notes: ""
                            }
                            newClientPaymentData.posDetails = [];
                            newClientPaymentData.posDetails.push(selectedPosDetails);
                        } else if(selectedDTData.proposedPaymentTypeType === "bank-account" || selectedDTData.proposedPaymentTypeType === "web-banking" || selectedDTData.proposedPaymentTypeType === "bank-account-outside"){
                            const selectedBank = CASH_DATA.bankAccounts.filter(bank => bank._id === selectedDTData.proposedPosBankAccount)[0];
                            console.log(selectedBank)
                            let selectedBankDetails = {
                                id: "1",
                                no: "1",
                                name: selectedBank.name,
                                accountNumber: selectedBank.iban,
                                amount: 0,
                                notes: ""
                            }
                            newClientPaymentData.bankDetails = [];
                            newClientPaymentData.bankDetails.push(selectedBankDetails);
                        }
                    }
                }
            }
        }
        if(name === "date") {
            newClientPaymentData.date = value;
        }
        if (name === 'installation') {
            newClientPaymentData.installation = value;
            newClientPaymentData.preNumber = getPreNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
            newClientPaymentData.installationMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
            newClientPaymentData.numberingMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
            newClientPaymentData.number = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId') + 1;
            newClientPaymentData.forSale = "";
            newClientPaymentData.forSaleInputLabel = "";
        }

        if (name === "client") {
            // populating client values on the sales form
            let selectedClient = getObjectFromArrayOfObjects(
                clientsListData,
                value,
                "_id"
            );
            if(selectedClient.length === 0) {
                selectedClient = [...selectedClintData]
            }
            if (selectedClient.length > 0) {
                let sClient = selectedClient["0"];
                newClientPaymentData.clientName = sClient.name;
                newClientPaymentData.clientCode = sClient.systemCode; // It is the client code
                newClientPaymentData.address = sClient.address;
                newClientPaymentData.vatNumber = sClient.vatNumber;
                newClientPaymentData.doy = sClient.DOY;
                newClientPaymentData.city = sClient.city;
                newClientPaymentData.pc = sClient.postalCode;
                newClientPaymentData.country = sClient.country;
                newClientPaymentData.phone = sClient.phone;
                newClientPaymentData.email = sClient.email.replaceAll("mailto:", "");
                newClientPaymentData.forSale = "";
                newClientPaymentData.forSaleInputLabel = "";
            }
        }

        if (name === "paymentType") {
            let paymentTypesData = getObjectFromArrayOfObjects(SALES_SETTINGS.salesGeneralSettings.paymentTypes, value, "_id");
            if (paymentTypesData && paymentTypesData.length > 0) {
                // Show warning before change
                if (salesData.paymentType !== "" && salesData.paymentType !== undefined && salesData.paymentTypeType !== paymentTypesData["0"].type) {
                    setShowPaymentTypeConfirmation(true);
                    let paymentTypeName = getLabelByValue(paymentTypeOptions, value, "value");
                    setPaymentTypeTempData({name: paymentTypeName, paymentType: value, type: paymentTypesData["0"].type,});
                } else {
                    newClientPaymentData[name] = value;
                    newClientPaymentData.paymentTypeName = getLabelByValue(paymentTypeOptions, value, "value");
                    newClientPaymentData.paymentTypeType = paymentTypesData["0"].type;
                }
            }
        }

        if(name === "forSale") {
            newClientPaymentData.forSaleInputLabel = e.row.documentTypeName + " " + e.row.number;
        }

        dispatch(setEditClientPayment({ ...salesData, ...newClientPaymentData }));
    };

    const clientDropdownColumns = [
        {
            label: t('SalesClientPaymentsNewEdit.dropTable.code'),
            name: "systemCode",
        },
        {
            label: t('SalesClientPaymentsNewEdit.dropTable.name'),
            name: "name",
        },
        {
            label: t('SalesClientPaymentsNewEdit.dropTable.phone'),
            name: "phone",
        },
        {
            label: t('SalesClientPaymentsNewEdit.dropTable.email'),
            name: "email",
        },
    ];

    const posFieldColumns = [
        {
            label: t('SalesClientPaymentsNewEdit.smallTable.name'),
            name: "name",
        },
        {
            label: t('SalesClientPaymentsNewEdit.smallTable.number'),
            name: "serialNumber",
        },
    ];

    const bankFieldColumns = [
        {
            label: t('SalesClientPaymentsNewEdit.bankTable.accountName'),
            name: "name",
        },
        {
            label: t('SalesClientPaymentsNewEdit.bankTable.bankName'),
            name: "bankName",
        },
        {
            label: t('SalesClientPaymentsNewEdit.bankTable.bankAccountName'),
            name: "iban",
        },
    ];

    const posColumns = [
        {
            name: t('SalesClientPaymentsNewEdit.posTable.posName'),
            field: "name",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: posFieldColumns,
                    data: CASH_DATA.posList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: "Select POS",
                },
                type: "advanced-combobox",
            },
        },
        {
            name: t('SalesClientPaymentsNewEdit.posTable.posRegistrationNumber'),
            field: "registrationNumber",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesClientPaymentsNewEdit.posTable.amount'),
            field: "amount",
            editable: true,
            inputType: {
                type: "accounting",
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 10,
                },
            },
        },
        {
            name: t('SalesClientPaymentsNewEdit.posTable.notes'),
            field: "notes",
            editable: true,
            inputType: {
                type: "text",
            },
        },
    ];

    const bankAccountColumns = [
        {
            name: t('SalesClientPaymentsNewEdit.bank2Table.bankAccount'),
            field: "name",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: bankFieldColumns,
                    data: CASH_DATA.bankAccounts,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: "Select Bank",
                },
                type: "advanced-combobox",
            },
        },
        {
            name: t('SalesClientPaymentsNewEdit.bank2Table.accountNumber'),
            field: "accountNumber",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesClientPaymentsNewEdit.bank2Table.amount'),
            field: "amount",
            editable: true,
            inputType: {
                type: "accounting",
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 10,
                },
            },
        },
        {
            name: t('SalesClientPaymentsNewEdit.bank2Table.notes'),
            field: "notes",
            editable: true,
            inputType: {
                type: "text",
            },
        },
    ];

    const CheckColumns = [
        {
            name: t('SalesClientPaymentsNewEdit.checkTable.startDate'),
            field: "startDate",
            editable: true,
            inputType: {
                type: "datebox",
            },
        },
        {
            name: t('SalesClientPaymentsNewEdit.checkTable.endDate'),
            field: "endDate",
            editable: true,
            inputType: {
                type: "datebox",
            },
        },
        {
            name: t('SalesClientPaymentsNewEdit.checkTable.amount'),
            field: "amount",
            editable: true,
            inputType: {
                type: "accounting",
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 10,
                },
            },
        },
        {
            name: t('SalesClientPaymentsNewEdit.checkTable.notes'),
            field: "notes",
            editable: true,
            inputType: {
                type: "text",
            },
        },
    ];

    const posDetailsActions = {
        insert: () => {},
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditClientPayments) {
                dispatch(setSaveEditClientPayments(true));
            }

            if (data.name && data.name === "") {
                toast.error("Name is mandatory field.");
            } else {
                let cpPosDetails = CLIENTS_DATA.editClientPayment.posDetails.slice();
                let foundPos = cpPosDetails.find((item) => item.id === id);
                if (foundPos !== undefined) {
                    let rowPosDetail = [];

                    // special case update name by name
                    if (data.name && data.name !== "") {
                        rowPosDetail = getObjectFromArrayOfObjects(CASH_DATA.posList, data.name, "name");
                    }

                    // Field auto population on change product
                    if (rowPosDetail.length > 0) {
                        let rowPosDetailData = rowPosDetail["0"];
                        data._id = rowPosDetailData._id;
                        data.name = rowPosDetailData.name;
                        data.registrationNumber = rowPosDetailData.serialNumber;
                    }
                    // Updated selected product with updated field data.
                    foundPos = { ...foundPos, ...data };
                    const updateCpPos = { ...cpPosDetails[id - 1], ...foundPos };
                    cpPosDetails[id - 1] = updateCpPos;
                    console.log('updateCpPos',updateCpPos);
                    let innerData = {...CLIENTS_DATA.editClientPayment};
                    innerData.amount = updateCpPos.amount
                    dispatch(setEditClientPayment({...innerData, posDetails: cpPosDetails})
                    );
                }
            }
        },
        selected: () => {},
        delete: () => {},
    };

    const bankDetailsActions = {
        insert: () => {},
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditClientPayments) {
                dispatch(setSaveEditClientPayments(true));
            }

            if (data.name && data.name === "") {
                toast.error("Name is mandatory field.");
            } else {
                let cpBankDetails = CLIENTS_DATA.editClientPayment.bankDetails.slice();
                let foundBank = cpBankDetails.find((item) => item.id === id);
                if (foundBank !== undefined) {
                    let rowBankDetail = [];

                    // special case update name by name
                    if (data.name && data.name !== "") {
                        rowBankDetail = getObjectFromArrayOfObjects(
                            CASH_DATA.bankAccounts,
                            data.name,
                            "name"
                        );
                    }
                    // Field auto population on change product
                    if (rowBankDetail.length > 0) {
                        let rowBankDetailData = rowBankDetail["0"];
                        data._id = rowBankDetailData._id;
                        data.name = rowBankDetailData.name;
                        data.accountNumber = rowBankDetailData.iban;
                    }
                    // Updated selected product with updated field data.
                    foundBank = { ...foundBank, ...data };
                    const updateCpBank = { ...cpBankDetails[id - 1], ...foundBank };
                    cpBankDetails[id - 1] = updateCpBank;
                    let innerData = {...CLIENTS_DATA.editClientPayment};
                    innerData.amount = updateCpBank.amount
                    dispatch(setEditClientPayment({...innerData, bankDetails: cpBankDetails}));
                }
            }
        },
        selected: () => {},
        delete: () => {},
    };
    const chequeDetailsActions = {
        insert: () => {},
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditClientPayments) {
                dispatch(setSaveEditClientPayments(true));
            }
            let chequeDetails = CLIENTS_DATA.editClientPayment.chequeDetails.slice();
            let foundCheck = chequeDetails.find((item) => item.id === id);
            if (foundCheck !== undefined) {
                foundCheck = { ...foundCheck, ...data };
                const updateCheque = { ...chequeDetails[id - 1], ...foundCheck };
                chequeDetails[id - 1] = updateCheque;
                let innerData = {...CLIENTS_DATA.editClientPayment};
                innerData.amount = updateCheque.amount
                dispatch(setEditClientPayment({...innerData, chequeDetails: chequeDetails}));
                console.log(chequeDetails)
            }
        },
        selected: () => {},
        delete: () => {},
    }
    const onChangeSearch = (keyword,setData) => {
        requestDataF.name = keyword

        if(keyword.length >= 2) {
            (dispatch(fetchClients(requestDataF,setData)))
        }
    }
    const printTemplateData = getClientPaymentPrintData(COMPANY_DATA, CLIENTS_DATA.editClientPayment);
    const selectedDocumentType = getDefaultOptionByValue(documentTypesOptions, CLIENTS_DATA.editClientPayment.documentType, 'value', 'label', 'value');



    const [linkedSales, setLinkedSales] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [showLinkedSales, setShowLinkedSales] = useState(false);
    const findLinkedSales = () => {
        axios.get(process.env.REACT_APP_API_URL2 + `/client/get-linked-sales`, {
            headers: {"Content-Type": "application/json"},
            params: {
                cpId: CLIENTS_DATA.editClientPayment._id,
                company: company.id,
            }
        }).then((res) => {
            if(res.data.status === "200") {
                setLinkedSales(res.data.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const isInvulnerable  = CLIENTS_DATA.editClientPayment?.myDataStatus === "sent"
        || String(CLIENTS_DATA.editClientPayment?.cancellatedBy).length === 24
        || String(CLIENTS_DATA.editClientPayment?.returnPayment).length === 24
        || CLIENTS_DATA.editClientPayment?.isSentToProvider === "true";

    useEffect(() => {
        if(firstLoad) {
            setFirstLoad(false);
            findLinkedSales();
            if (ACTIVITY.saveEditClientPayments && isInvulnerable) dispatch(setSaveEditClientPayments(false));
        }
        //console.log(CLIENTS_DATA.editClientPayment)
    })
    const [defaultForSaleData, setDefaultForSaleData] = useState([]);
    useEffect(() => {
        if(CLIENTS_DATA.editClientPayment.client && CLIENTS_DATA.editClientPayment.installation) {
            loadDefaultUnpaidSales();
        }
    }, [CLIENTS_DATA.editClientPayment.client])
    const loadUnpaidSales = (keyword, setData) => {
        axios.get(process.env.REACT_APP_API_URL2 + `/client/get-unpaid-list`, {
            params: {
                company: company.id,
                clientId: CLIENTS_DATA.editClientPayment.client,
                installation: CLIENTS_DATA.editClientPayment.installation,
                keyword: keyword
            }})
            .then(res => {
                if (res.data && res.data.data && res.data.status === '200') {
                    let tArr = [];
                    res.data.data.forEach((sale) => {
                        tArr.push({
                            number: sale.number.replace("noprefix", ""),
                            totalAmount: sale.totalAmount,
                            paymentRemainder: sale.paymentRemainder,
                            documentTypeName: sale.documentTypeName,
                            date: moment(sale.date).format("DD-MM-YYYY HH:mm"),
                            value: String(sale._id)
                        })
                    })
                    setData(tArr);
                }
            }).catch((err) => {
                console.log(err);
            })
    }
    const loadDefaultUnpaidSales = () => {
        axios.get(process.env.REACT_APP_API_URL2 + `/client/get-unpaid-list`, {
            params: {
                company: company.id,
                clientId: CLIENTS_DATA.editClientPayment.client,
                installation: CLIENTS_DATA.editClientPayment.installation,
            }})
            .then(res => {
                if (res.data && res.data.data && res.data.status === '200') {
                    let tArr = [];
                    res.data.data.forEach((sale) => {
                        tArr.push({
                            number: sale.number.replace("noprefix", ""),
                            totalAmount: sale.totalAmount,
                            paymentRemainder: sale.paymentRemainder,
                            documentTypeName: sale.documentTypeName,
                            date: moment(sale.date).format("DD-MM-YYYY HH:mm"),
                            value: String(sale._id)
                        })
                    })
                    setDefaultForSaleData(tArr);
                }
            }).catch((err) => {
                console.log(err);
            })
    }
    const onChangeSearchUnpaidSales = (keyword, setData) => {
        loadUnpaidSales(keyword, setData);
    }
    const clearForSale = () => {
        if (!ACTIVITY.saveEditClientPayments) {
            dispatch(setSaveEditClientPayments(true));
        }
        dispatch(setEditClientPayment({...CLIENTS_DATA.editClientPayment, forSale: "", forSaleInputLabel: ""}));
    }

    const handleOpenSale = (saleId) => {
        axios.get(process.env.REACT_APP_API_URL2 + `/sale/get-sale-data/${saleId}`, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if(res.data.status === "200") {
                dispatch(setEditSales(res.data.data));
                const idx = TABS_DATA.findIndex((item) => "view-sales" === item);
                if(idx === -1) {
                    dispatch(addTab("view-sales"));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(idx));
                }
            } else {
                toast.error("Πρόβλημα κατά το άνοιγμα της είσπραξης.")
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <div className="new-sales">
            <AccountingView document={CLIENTS_DATA.editClientPayment} />
            {isInvulnerable && (
                <Alert variant={"secondary"}>
                    <React.Fragment>
                        Η είσπραξη δεν είναι επεξεργάσιμη επειδή:
                        {(String(CLIENTS_DATA.editClientPayment?.cancellatedBy).length === 24) && (
                            <span><br/>- Έχει πραγματοποιηθεί επιστροφή</span>
                        )}
                        {(String(CLIENTS_DATA.editClientPayment?.returnPayment).length === 24) && (
                            <span><br/>- Αποτελεί είσπραξη επιστροφής.</span>
                        )}
                        {(CLIENTS_DATA.editClientPayment?.isSentToProvider === "true") && (
                            <span><br/>- Αποτελεί τρόπο πληρωμής σε πώληση.</span>
                        )}
                        {CLIENTS_DATA.editClientPayment.myDataStatus === "sent" && (
                            <React.Fragment>
                                <br/> - Έχει σταλεί στο My Data.
                                {CLIENTS_DATA.editClientPayment.mark && (
                                    <React.Fragment>
                                        <br/>
                                        <span style={{fontWeight: "600"}}>MARK: </span><span>{CLIENTS_DATA.editClientPayment.mark}</span>
                                        <br/>
                                        <span style={{fontWeight: "600"}}>UID: </span><span>{CLIENTS_DATA.editClientPayment.uid}</span>
                                        {CLIENTS_DATA.editClientPayment.authCode && (
                                            <React.Fragment>
                                                <br/>
                                                <span style={{fontWeight: "600"}}>AUTH CODE: </span><span>{CLIENTS_DATA.editClientPayment.authCode}</span>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Alert>
            )}
            <Form className="client-form product-form position-relative" autoComplete="off" style={{pointerEvents: CLIENTS_DATA.editClientPayment.myDataStatus === "sent" ? "none" : ""}}>
                <Row className="border-bottom mb-3">
                    <Col sm={12} md={3} className="showWithOverlay">
                        <Dropdown
                            label={t('SalesClientPaymentsNewEdit.documentTypes')}
                            key={selectedDocumentType.value}
                            defaultValue={selectedDocumentType}
                            required={true}
                            name="documentType"
                            options={documentTypesOptions}
                            disabled={true}
                            onChange={(e) => handleOnChange(e, "dd", "documentType")}
                        />
                    </Col>
                    {(CLIENTS_DATA.editClientPayment?.myDataType === "8.5") ? (
                        <React.Fragment>
                            <Col sm={12} md={6}>
                                <AdvancedCombobox
                                    className="w-100 ac-select-dropdown"
                                    searchableDropdownEnable={false}
                                    label={"Συσχετιζόμενη Είσπραξη"}
                                    placeholder={"Εισάγετε συσχετιζόμενη είσπραξη"}
                                    defaultValue={CLIENTS_DATA.editClientPayment.returnPaymentLabel}
                                    data={[]}
                                    onChange={(e) => handleOnChange(e, "acdd", "returnPayment")}
                                    excludeColumns={[]}
                                    columns={[
                                        {label: "Ημερομηνία", name: "date"},
                                        {label: "Τύπος Παραστατικού", name: "documentTypeName"},
                                        {label: "Αρίθμηση", name: "number"},
                                        {label: "Ποσό", name: "amount"},
                                    ]}
                                    editEnable={false}
                                    deleteEnable={false}
                                    searchTableEnable={false}
                                    showTableOptionOnSelect={true}
                                    key={Math.random()}
                                    disabled={true}
                                />
                            </Col>
                        </React.Fragment>
                    ) : (
                        <Col md={6}></Col>
                    )}
                    <Col sm={12} md={3}>
                        {linkedSales.length > 0 && (
                            <div style={{textAlign: "right"}}>
                                <Badge bg="primary" className="mr-3 mb-1" style={{userSelect: "none", cursor: "pointer"}} onClick={() => setShowLinkedSales(!showLinkedSales)}>Αντιστοιχία πωλήσεων <strong>{showLinkedSales === true ? "-" : "+"}</strong></Badge>
                                <React.Fragment>
                                    {showLinkedSales === true && linkedSales.map((sale) => (
                                        <>
                                            <br/>
                                            <Badge bg="primary" style={{userSelect: "none", cursor: "pointer"}} className="mb-1" onClick={() => handleOpenSale(sale.saleId)}>
                                                Εξοφλεί {String(Number(sale.amount).toFixed(2)).replace(".", ",")} € στην πώληση {sale.displayString}</Badge>
                                        </>
                                    ))}
                                </React.Fragment>
                            </div>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="3" className={"mb-3"}>
                        <DateBox
                            classes="w-100"
                            label={t('SalesClientPaymentsNewEdit.date')}
                            name="date"
                            selected={moment(CLIENTS_DATA.editClientPayment.date).isValid() ? new Date(CLIENTS_DATA.editClientPayment.date) : null}
                            onChange={(e) => handleOnChange(e, 'dp', 'date')}
                            disabled={isInvulnerable}
                        />
                        {isEmpty(CLIENTS_DATA.editClientPayment.date) && <div className={"text-danger"}>Απαιτείται Ημερομηνία.</div>}
                    </Col>
                    <Col sm="12" md="9" className="text-right d-flex align-items-center justify-content-end" style={{pointerEvents: "auto"}}>
                        {(SALES.printLayout === 'clientpayments-print-template1-greek' || !SALES.printLayout) &&
                            <SendEmail clientId={CLIENTS_DATA.editClientPayment.client} modalContent="Do you want to send invoice mail to the client?" mailTo={CLIENTS_DATA.editClientPayment.email} mailSubject="Your Invoice" mailContent="Here is your invoice" documentType="clientPayment" documentId={CLIENTS_DATA.editClientPayment._id} pdf={componentRef.current} />
                        }
                        {(SALES.printLayout === 'clientpayments-print-template1-english') &&
                            <SendEmail clientId={CLIENTS_DATA.editClientPayment.client} modalContent="Do you want to send invoice mail to the client?" mailTo={CLIENTS_DATA.editClientPayment.email} mailSubject="Your Invoice" mailContent="Here is your invoice" documentType="clientPayment" documentId={CLIENTS_DATA.editClientPayment._id} pdf={componentRefEn.current} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3} className="mb-2">
                        <div className="mb-3">
                            <Dropdown
                                label={t('SalesClientPaymentsNewEdit.installation.installation')}
                                options={dtInstallationsOptions}
                                value={getDefaultOptionByValue(dtInstallationsOptions, CLIENTS_DATA.editClientPayment.installation , 'value', 'label', 'value')}
                                enabledValue={true}
                                required={true}
                                name="installation"
                                classes="w-100"
                                onChange={(e) => handleOnChange(e, "dd", "installation")}
                                disabled={isInvulnerable}
                            />
                        </div>
                    </Col>
                    <Col sm="12" md="3">
                        <div className="mb-3">
                            <Dropdown
                                className="mb-3 w-100"
                                label={t('SalesClientPaymentsNewEdit.preNumber')}
                                name="preNumber"
                                options={preNumberGreek}
                                defaultValue={preNumberGreek?.find((el) => el.value === CLIENTS_DATA.editClientPayment.preNumber)}
                                key={Math.random()}
                                disabled={true}
                            />
                        </div>
                    </Col>
                    <Col sm="12" md="3">
                        <Input
                            className="mb-3 w-100"
                            label={t('SalesClientPaymentsNewEdit.number.number')}
                            name="number"
                            value={CLIENTS_DATA.editClientPayment.number}
                            placeholder={t('SalesClientPaymentsNewEdit.number.placeholder')}
                            onChange={(e) => handleOnChange(e)}
                            disabled={ CLIENTS_DATA.editClientPayment.numberingMode !== "manual" || isInvulnerable }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3} className="mb-2">
                        <Dropdown
                            label={t('SalesClientPaymentsNew.paymentType')}
                            options={paymentTypeOptions}
                            value={getDefaultOptionByValue(
                                paymentTypeOptions,
                                CLIENTS_DATA.editClientPayment.paymentType,
                                "value",
                                "label",
                                "value"
                            )}
                            enabledValue={true}
                            name="paymentType"
                            disabled={CLIENTS_DATA.editClientPayment?.myDataType === "8.4" || CLIENTS_DATA.editClientPayment?.myDataType === "8.5" || isInvulnerable}
                            searchEnable={true}
                            onChange={(e) => handleOnChange(e, "dd", "paymentType")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3} className="mb-2">
                        <AdvancedCombobox
                            ACName="Client Payment Client" // do not change name
                            className="w-100 ac-select-dropdown"
                            searchableDropdownEnable={false}
                            defaultValue={CLIENTS_DATA.editClientPayment.clientName}
                            key={Math.random()}
                            label={t('SalesClientPaymentsNewEdit.client.client')}
                            data={clientsListData}
                            onChange={(e) => {
                                handleOnChange(e, "acdd", "client");
                            }}
                            onChangeSearch = {onChangeSearch}
                            excludeColumns={[]}
                            columns={clientDropdownColumns}
                            editEnable={false}
                            deleteEnable={false}
                            searchTableEnable={false}
                            showTableOptionOnSelect={true}
                            placeholder={t('SalesClientPaymentsNew.client.placeholder')}
                            required={true}
                            showInsertAddButton={true}
                            hideSaveButton={true}
                            hideInsertNewRow={true}
                            disabled={isInvulnerable}
                        />
                        {showErrors && CLIENTS_DATA.editClientPayment.client === "" && (
                            <div className="text-danger">{t('SalesClientPaymentsNew.client.required')}</div>
                        )}
                    </Col>
                    <Col sm="12" md="3">
                        <div className="mb-3">
                            <Input
                                className="w-100"
                                label={t('SalesClientPaymentsNewEdit.vatNumber')}
                                name="vat"
                                value={CLIENTS_DATA.editClientPayment.vatNumber}
                                onChange={(e) => handleOnChange(e)}
                                disabled={true}
                                readonly
                            />
                        </div>
                    </Col>
                    <Col sm="12" md="3">
                        <div className="d-flex">
                            <Input
                                label={t('SalesClientPaymentsNewEdit.address')}
                                className="w-100"
                                name="address"
                                value={CLIENTS_DATA.editClientPayment.address}
                                onChange={(e) => handleOnChange(e)}
                                disabled={true}
                                readonly
                            />
                        </div>
                    </Col>
                    <Col sm="12" md="3">
                        <div className="d-flex">
                            <Input
                                label={t('SalesClientPaymentsNewEdit.country')}
                                className="w-100"
                                name="country"
                                value={CLIENTS_DATA.editClientPayment.country}
                                onChange={(e) => handleOnChange(e)}
                                disabled={true}
                                readonly
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="3">
                        <div className="d-flex">
                            <Input
                                label={t('SalesClientPaymentsNewEdit.phone')}
                                className="w-100"
                                name="phone"
                                value={CLIENTS_DATA.editClientPayment.phone}
                                onChange={(e) => handleOnChange(e)}
                                disabled={true}
                                readonly
                            />
                        </div>
                    </Col>
                    <Col sm="12" md="3">
                        <div className="d-flex">
                            <Input
                                label={t('SalesClientPaymentsNewEdit.email')}
                                className="w-100"
                                name="email"
                                value={CLIENTS_DATA.editClientPayment.email}
                                onChange={(e) => handleOnChange(e)}
                                disabled={true}
                                readonly
                            />
                        </div>
                    </Col>
                    {(CLIENTS_DATA.newClientPayment?.myDataType !== "8.4" && CLIENTS_DATA.newClientPayment.myDataType !== "8.5") && (
                        <React.Fragment>
                            <Col sm="12" md="3">
                                <AdvancedCombobox
                                    className="w-100 ac-select-dropdown"
                                    searchableDropdownEnable={false}
                                    label={"Αφορά την πώληση"}
                                    placeholder={"Προσθέστε πώληση"}
                                    defaultValue={CLIENTS_DATA.editClientPayment.forSaleInputLabel}
                                    data={defaultForSaleData}
                                    onChange={(e) => handleOnChange(e, "acdd2")}
                                    excludeColumns={[]}
                                    columns={[
                                        {label: "Ημερομηνία", name: "date"},
                                        {label: "Τύπος Παραστατικού", name: "documentTypeName"},
                                        {label: "Αρίθμηση", name: "number"},
                                        {label: "Πληρωτέο Ποσό", name: "totalAmount"},
                                        {label: "Υπολ. Ποσό", name: "paymentRemainder"},
                                    ]}
                                    editEnable={false}
                                    deleteEnable={false}
                                    searchTableEnable={false}
                                    showTableOptionOnSelect={true}
                                    key={Math.random()}
                                    disabled={!CLIENTS_DATA.editClientPayment.installation || !CLIENTS_DATA.editClientPayment.client || String(CLIENTS_DATA.editClientPayment.forSale).length === 24}
                                    onChangeSearch={onChangeSearchUnpaidSales}
                                />
                            </Col>
                            <Col sm="12" md="3" hidden={isInvulnerable}>
                                {String(CLIENTS_DATA.editClientPayment.forSale).length === 24 &&
                                    <button style={{position: "absolute", bottom: "13px"}} className="btn btn-sm" title="Καθαρισμός επιλεγμένης πώλησης" onClick={(e) => clearForSale()}><i className="fas fa-xmark"></i></button>
                                }
                            </Col>
                        </React.Fragment>
                    )}
                </Row>
                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            label={t('SalesClientPaymentsNewEdit.reason')}
                            className="w-100 mb-3"
                            name="reason"
                            value={CLIENTS_DATA.editClientPayment.reason}
                            onChange={(e) => handleOnChange(e)}
                            disabled={isInvulnerable}
                        />
                    </Col>
                </Row>

                {(CLIENTS_DATA.editClientPayment.paymentTypeType === "cash" || CLIENTS_DATA.editClientPayment.paymentTypeType === "iris") && (
                    <Row>
                        <Col sm="12" md="4">
                            <div className="mb-3">
                                <FormLabel className="" htmlFor="prefered-price">{t('SalesClientPaymentsNewEdit.paymentAmount.paymentAmount')}</FormLabel>
                                <AccountingBox
                                    name="amount"
                                    label={t('SalesClientPaymentsNewEdit.amount.amount')}
                                    placeholder={t('SalesClientPaymentsNewEdit.amount.placeholder')}
                                    defaultValue={ CLIENTS_DATA.editClientPayment.amount }
                                    onChange={(e) => handleOnChange(e, 'ac', 'amount')}
                                    disabled={isInvulnerable}
                                />
                            </div>
                        </Col>
                    </Row>
                )}

                {(CLIENTS_DATA.editClientPayment.paymentTypeType === "bank-account" || CLIENTS_DATA.editClientPayment.paymentTypeType === "web-banking" || CLIENTS_DATA.editClientPayment.paymentTypeType === "bank-account-outside") && (
                    <div className="bank-data" style={{pointerEvents: isInvulnerable ? "none" : ""}}>
                        <div className="text-muted mb-2">
                            <i>{t('SalesClientPaymentsNewEdit.bankAccountData')}</i>
                        </div>
                        <EditableTable
                            reqName="bank"
                            key={Math.random()}
                            tableName="Client Payment Bank Account Data Table" // do not change name
                            allowToggleColumns={true}
                            allowActions={true}
                            allowInsertRow={false}
                            allowPagination={false}
                            allowBulkDelete={false}
                            allowSorting={false}
                            allowSelectionCheckbox={false}
                            columns={bankAccountColumns}
                            allowPrintResults={false}
                            enableNewRowCheckbox={true}
                            onUpdate={bankDetailsActions}
                            data={CLIENTS_DATA.editClientPayment.bankDetails}
                            disabled={isInvulnerable}
                        />
                    </div>
                )}

                {CLIENTS_DATA.editClientPayment.paymentTypeType === "pos" && (
                    <div className="pos-data" style={{pointerEvents: isInvulnerable ? "none" : ""}}>
                        <div className="text-muted mb-2">
                            <i>{t('SalesClientPaymentsNewEdit.posData')}</i>
                        </div>
                        <EditableTable
                            reqName="pos"
                            key={Math.random()}
                            tableName="Client Payment POS Data Table" // do not change name
                            allowToggleColumns={true}
                            allowActions={true}
                            allowInsertRow={false}
                            allowPagination={false}
                            allowBulkDelete={false}
                            allowSorting={false}
                            allowSelectionCheckbox={false}
                            columns={posColumns}
                            enableNewRowCheckbox={true}
                            allowPrintResults={false}
                            onUpdate={posDetailsActions}
                            data={CLIENTS_DATA.editClientPayment.posDetails}
                            disabled={isInvulnerable}
                        />
                    </div>
                )}

                {CLIENTS_DATA.editClientPayment.paymentTypeType === "cheque" && (
                    <div className="cheque-data" style={{pointerEvents: isInvulnerable ? "none" : ""}}>
                        <div className="text-muted mb-2">
                            <i>{t('SalesClientPaymentsNewEdit.chequeData')}</i>
                        </div>
                        <EditableTable
                            key={Math.random()}
                            tableName="Client Payment Cheque Data Table" // do not change name
                            allowToggleColumns={true}
                            allowActions={true}
                            allowInsertRow={false}
                            allowPagination={false}
                            allowBulkDelete={false}
                            allowSorting={false}
                            allowSelectionCheckbox={false}
                            columns={CheckColumns}
                            enableNewRowCheckbox={true}
                            allowPrintResults={false}
                            onUpdate={chequeDetailsActions}
                            data={CLIENTS_DATA.editClientPayment.chequeDetails}
                            disabled={isInvulnerable}
                        />
                    </div>
                )}

                <Row>
                    <Col sm="12">
                        <div className="mb-3 w-100">
                            <div className="text-muted mb-2">
                                <i>{t('SalesClientPaymentsNewEdit.paymentNotes')}</i>
                            </div>
                            <TextArea
                                rows="4"
                                className="form-control"
                                placeholder={t('SalesClientPaymentsNewEdit.addNotes')}
                                limit="1000"
                                name="paymentNotes"
                                value={CLIENTS_DATA.editClientPayment.paymentNotes}
                                onChange={(e) => handleOnChange(e)}
                                disabled={isInvulnerable}
                            />
                        </div>
                    </Col>
                </Row>
            </Form>

            {/* Confirm Category Change */}
            <Modal className="themeModal" show={showPaymentTypeConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('SalesClientPaymentsNewEdit.warning')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('SalesClientPaymentsNewEdit.message2')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-primary"
                        onClick={() => setShowPaymentTypeConfirmation(false)}
                    >
                        {t('SalesClientPaymentsNewEdit.no')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => handlePaymentTypeConfirmation()}
                    >
                        {t('SalesClientPaymentsNewEdit.yes')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ClientPaymentPrintTemplate1Greek ref={componentRef} data={printTemplateData}/>
            <ClientpaymentPrintTemplate1English ref={componentRefEn} data={printTemplateData}/>
        </div>
    );
}

export const ClientPaymentsEditLog = () => {
    const { t } = useTranslation('common');
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.clientPaymentLog')}</i></div>
            <LogTable itemId={CLIENTS_DATA.editClientPayment._id}/>
        </React.Fragment>
    )
};

export default EditClientPayments;
