import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import {addTab, removeTab, reOrderTabs, shiftTabs, updateActiveMainTab} from "../../../_reducers/TabsSlice";
import "./index.scss";
import {Button, Modal} from "react-bootstrap";
import {checkViewFunc, getURLParams} from "../../../_helpers/helperFunctions";
import {setNewBankAccount, setNewCashRegisters, setNewPOS} from "../../../_reducers/DataPanel/Cash/cashSlice";
import {
    resetNewClientPayment, setClientPaymentsSearch,
    setNewClient,
    setNewClientMovements,
    setSearchClientMovements,
    setSearchClients
} from "../../../_reducers/DataPanel/Clients/clientSlice";
import {
    clearNewAdvancedType,
    clearNewBasicType,
    setSearchAdvancedTypes,
    setSearchBasicTypes
} from "../../../_reducers/settingsSlice";
import {
    resetNewPriceList,
    resetNewProductMovements,
    resetProductMovementsNewProduct,
    resetSelectedProduct,
    setSearchProductMovements,
    setSearchProductPriceList,
    setSearchProducts
} from "../../../_reducers/DataPanel/Products/productsSlice";
import {
    setIsEmronConnectFileERP,
    setRememberInnerTabsForce,
    setSaveAdvancedType,
    setSaveBasicType,
    setSaveBuys,
    setSaveClientPayments,
    setSaveNewAccountingAccount, setSaveNewAccountingRecord,
    setSaveNewBankAccounts,
    setSaveNewCashRegister,
    setSaveNewClient,
    setSaveNewClientMovements, setSaveNewEmployee,
    setSaveNewPOS,
    setSaveNewPriceList,
    setSaveNewProduct,
    setSaveNewProductMovements,
    setSaveSales,
    setSaveSupplierPayments
} from "../../../_reducers/activitySlice";
import {resetNewSales, setSearchSales} from "../../../_reducers/DataPanel/Sales/salesSlice";
import {resetNewBuys, setSearchBuys} from "../../../_reducers/DataPanel/Buys/buysSlice";
import {useTranslation} from "react-i18next";
import {
    resetNewAccountingAccount,
    resetNewAccountingRecord,
    setNewEmployee, setSearchAccountingAccounts, setSearchAccountingRecords
} from "../../../_reducers/DataPanel/Accounting/accountingSlice";
import {setFilters} from "../../../_reducers/SearchTableSlice";
import {
    setSearchSupplierMovements,
    setSearchSuppliers,
    setSupplierPaymentsSearch
} from "../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {setWorkingProgramOnEmployee} from "../../../_reducers/DataPanel/Payroll/payrollSlice";
import {toast} from "react-toastify";
import {isEmpty} from "../../../_helpers/commonFunctions";

const TabsLayout = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const ACTIVE_MAIN_TAB = useSelector((state) => state.ACTIVE_MAIN_TAB);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);
    const ACCOUNTING_DATA = useSelector((state) => state.ACCOUNTING_DATA);
    const SEARCH_TABLE = useSelector((state) => state.SEARCH_TABLE);

    const permissionsData = JSON.parse(localStorage.getItem("user"))?.permissions;
    const isEpay = permissionsData?.find((el) => el.permissionName === "custom-epay" || el.permissionName === "custom-peiraios" || el.permissionName === "custom-daras");
    const isCardlink = permissionsData?.find((el) => el.permissionName === "custom-cardlink");
    const isEmployee = permissionsData?.find((el) => el.permissionName === "custom-employee");
    const onlyStartOpen = TABS_DATA.length === 1 && TABS_DATA?.includes("start");

    const isEmronConnectFileERP = useSelector((state) => state.ACTIVITY.isEmronConnectFileERP);

    let tabClassName = "react-tabs__tab";

    // It will check if the device width is greater then 1900
    if (window.innerWidth > 1900 && TABS_DATA.length > 5) {
        tabClassName = "react-tabs__tab maxWidthLimit";
    } else if (window.innerWidth > 1900 && TABS_DATA.length > 4) {
        tabClassName = "react-tabs__tab maxWidthLimit";
    }

    useEffect(() => {
        // For emron connect
        const params = getURLParams();
        const ssKey = params.get("ssKey");
        const forceNewTab = params.get("forceNewTab");
        if (!isEmpty(ssKey) && !isEmpty(forceNewTab)) {
            dispatch(removeTab("start"));
            dispatch(addTab(forceNewTab));
            dispatch(updateActiveMainTab(TABS_DATA.length));
            window.history.pushState({}, "", window.location.origin);
            dispatch(setIsEmronConnectFileERP(ssKey));
        }
    }, [])

    useEffect(() => {
        // Check for the tabs length, if it is more than 10, delete the oldest tab opened, but before deleting, check if
        // the data is present there or not, if present, add a modal to confirm if you want to save the data , if clicked on yes, save the data and delete,
        // if clicked no, delete the oldest tab
        // ? A popup should come here if data not saved
        if (TABS_DATA.length > 10) {
            dispatch(shiftTabs());
        }
        if (ACTIVE_MAIN_TAB >= TABS_DATA.length) {
            dispatch(updateActiveMainTab(ACTIVE_MAIN_TAB - 1));
        }
        if (TABS_DATA.length === 0 && !isEpay && !isEmployee && !isCardlink) {
            dispatch(addTab("start"));
            dispatch(updateActiveMainTab(0));
        }
    }, [ACTIVE_MAIN_TAB, TABS_DATA.length]);

    useEffect(() => {
        // Forget inner tab selection when closing tab
        let deleteKeys = [];
        for (let key in ACTIVITY.rememberInnerTab) {
            if (!TABS_DATA.includes(key)) deleteKeys.push(key);
        }
        if (deleteKeys.length > 0) {
            let cloneRememberInnerTabs = structuredClone(ACTIVITY.rememberInnerTab);
            for (let key of deleteKeys) {
                delete cloneRememberInnerTabs[key];
            }
            dispatch(setRememberInnerTabsForce(cloneRememberInnerTabs));
        }
    }, [TABS_DATA])

    useEffect(() => {
        if (isEpay || isCardlink) {
            const idx = TABS_DATA.findIndex((item) => "reports-primer-payments" === item);
            if (idx === -1) {
                dispatch(addTab("reports-primer-payments"));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            }
            const startIndex = TABS_DATA.findIndex((item) => "start" === item);
            if (startIndex !== -1) {
                dispatch(removeTab("start"))
            }
        }
        if (isEmployee) {
            const idx = TABS_DATA.findIndex((item) => "employee-view" === item);
            if (idx === -1) {
                dispatch(addTab("employee-view"));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            }
            const startIndex = TABS_DATA.findIndex((item) => "start" === item);
            if (startIndex !== -1) {
                dispatch(removeTab("start"))
            }
        }
    }, [TABS_DATA])

    // States to define how the tabs will work out
    // const [tabIndex, setTabIndex] = useState(0);
    const [toggle, setToggle] = useState(false);
    const [tabCloseWarning, showTabCloseWarning] = useState(false);
    const [removeTabId, setRemoveTabId] = useState('');
    const [tabIndexR, setTabIndexR] = useState(0);
    const [draggedTabIndex, setDraggedTabIndex] = useState(null);

    const handleTabRemove = (e, tab, i) => {
        // Check before closing tab if changes saved or not
        if (
            (tab === 'sales-new' && ACTIVITY.saveSales) ||
            (tab === 'buys-new' && ACTIVITY.saveBuys) ||
            (tab === 'new-client-payments' && ACTIVITY.saveClientPayments) ||
            (tab === 'new-supplier-payments' && ACTIVITY.saveSupplierPayments) ||
            (tab === 'new-automatic-sales' && ACTIVITY.saveAutomaticSales) ||
            (tab === 'new-automatic-buys' && ACTIVITY.saveAutomaticBuys) ||
            (tab === 'products-new' && ACTIVITY.saveNewProduct) ||
            (tab === 'edit-product' && ACTIVITY.saveEditProduct) ||
            (tab === 'new-client' && ACTIVITY.saveNewClient) ||
            (tab === 'new-employee' && ACTIVITY.saveNewEmployee) ||
            (tab === 'new-accounting-account' && ACTIVITY.saveNewAccountingAccount) ||
            (tab === 'new-supplier' && ACTIVITY.saveNewClient) ||
            (tab === 'product-movements-new' && ACTIVITY.saveNewProductMovements) ||
            (tab === 'edit-product-movements' && ACTIVITY.saveEditProductMovements) ||
            (tab === 'product-pricelist-new' && ACTIVITY.saveNewPriceList) ||
            (tab === 'edit-price-list' && ACTIVITY.saveEditPriceList) ||
            (tab === 'new-client-movements' && ACTIVITY.saveNewClientMovements) ||
            (tab === 'new-supplier-movements' && ACTIVITY.saveNewClientMovements) ||
            (tab === 'cash-registers-new' && ACTIVITY.saveNewCashRegister) ||
            (tab === 'new-bank-account' && ACTIVITY.saveNewBankAccounts) ||
            (tab === 'basic-types-new' && ACTIVITY.saveBasicType) ||
            (tab === 'advanced-types-new' && ACTIVITY.saveAdvancedType) ||
            (tab === 'sales-general-settings' && ACTIVITY.saveSalesSettings) ||
            (tab === 'buys-general-settings' && ACTIVITY.saveBuysSettings) ||
            (tab === 'quick-retail-sales-settings' && ACTIVITY.saveQuickRetailSettings) ||
            (tab === 'new-automatic-sales' && ACTIVITY.saveAutomaticSales) ||
            (tab === 'new-automatic-buys' && ACTIVITY.saveAutomaticBuys) ||
            (tab === 'new-quick-retail-sales' && ACTIVITY.saveQuickRetailSales) ||
            (tab === 'new-quick-retail-buys' && ACTIVITY.saveQuickRetailBuys) ||
            (tab === 'new-pos' && ACTIVITY.saveNewPOS) ||
            (tab === 'accounting-record-new' && ACTIVITY.saveNewAccountingRecord) ||
            (tab === 'accounting-record-edit' && ACTIVITY.saveNewAccountingRecord)
        ) {
            showTabCloseWarning(true) // show tab close warning
            setRemoveTabId(tab); // set remove tab id
            setTabIndexR(i); // set remove tab id
        } else {
            dispatch(removeTab(tab))
            let newIndex = 0;
            if (ACTIVE_MAIN_TAB === i) {
                newIndex = i > 0 ? i - 1 : 0;
                dispatch(updateActiveMainTab(newIndex))
            } else if (ACTIVE_MAIN_TAB < i) {
                dispatch(updateActiveMainTab(ACTIVE_MAIN_TAB))
            } else {
                newIndex = ACTIVE_MAIN_TAB > 0 ? ACTIVE_MAIN_TAB - 1 : 0;
                dispatch(updateActiveMainTab(newIndex))
            }

            if (tab === "products-search") {
                dispatch(setSearchProducts([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Search Products": {}}));
            } else if (tab === "product-pricelist-new") {
                dispatch(setSearchProductPriceList([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Search Product Price List": {}}));
            } else if (tab === "product-movements-search") {
                dispatch(setSearchProductMovements([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Search Product Movements": {}}));
            } else if (tab === "sales-search") {
                dispatch(setSearchSales([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Sales Search": {}}));
            } else if (tab === "search-client-payments") {
                dispatch(setClientPaymentsSearch([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Client Payments Search": {}}));
            } else if (tab === "buys-search") {
                dispatch(setSearchBuys([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Buys Search": {}}));
            } else if (tab === "search-supplier-payments") {
                dispatch(setSupplierPaymentsSearch([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Supplier Payments Search": {}}));
            } else if (tab === "search-client") {
                dispatch(setSearchClients([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Client Search": {}}));
            } else if (tab === "search-client-movements") {
                dispatch(setSearchClientMovements([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Client Movements Search": {}}));
            } else if (tab === "search-supplier") {
                dispatch(setSearchSuppliers([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Supplier Search": {}}));
            } else if (tab === "search-supplier-movements") {
                dispatch(setSearchSupplierMovements([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Supplier Movements Search": {}}));
            } else if (tab === "basic-types-search") {
                dispatch(setSearchBasicTypes([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Basic Types Search": {}}));
            } else if (tab === "advanced-types-search") {
                dispatch(setSearchAdvancedTypes([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Advanced Types Search": {}}));
            } else if (tab === "accounting-account-search") {
                dispatch(setSearchAccountingAccounts([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Accounting Account Search": {}}));
            } else if (tab === "accounting-record-search") {
                dispatch(setSearchAccountingRecords([]));
                dispatch(setFilters({...SEARCH_TABLE.filters, "Accounting Record Search": {}}));
            } else if (tab === "working-program-employee") {
                dispatch(setWorkingProgramOnEmployee(null));
            }
        }
    }

    const tabCloseConfirm = () => {
        // It will use to reset the forms on modal close
        if (removeTabId === 'products-new') {
            if (ACTIVITY.saveNewProduct) {
                dispatch(resetSelectedProduct()) // Reset form
                dispatch(setSaveNewProduct(false));
            }
        } else if (removeTabId === 'product-movements-new') {
            if (ACTIVITY.saveNewProductMovements) {
                dispatch(resetNewProductMovements()) // Reset form
                dispatch(resetProductMovementsNewProduct()); // Reset new row
                dispatch(setSaveNewProductMovements(false));
            }
        } else if (removeTabId === 'product-pricelist-new') {
            if (ACTIVITY.saveNewPriceList) {
                dispatch(resetNewPriceList()) // Reset form
                dispatch(setSaveNewPriceList(false));
            }
        } else if (removeTabId === 'new-client') {
            if (ACTIVITY.saveNewClient) {
                dispatch(setNewClient(CLIENTS_DATA.newClientInitialValue)) // Reset form
                dispatch(setSaveNewClient(false));
            }
        } else if (removeTabId === 'new-employee') {
            if (ACTIVITY.saveNewEmployee) {
                dispatch(setNewEmployee(ACCOUNTING_DATA.newEmployeeInitialValue)) // Reset form
                dispatch(setSaveNewEmployee(false));
            }
        } else if (removeTabId === 'accounting-account-new') {
            if (ACTIVITY.saveNewAccountingAccount) {
                dispatch(resetNewAccountingAccount()) // Reset form
                dispatch(setSaveNewAccountingAccount(false));
            }
        } else if (removeTabId === 'accounting-record-new') {
            if (ACTIVITY.saveNewAccountingRecord) {
                dispatch(resetNewAccountingRecord()); // Reset form
                dispatch(setSaveNewAccountingRecord(false));
            }
        } else if (removeTabId === 'new-client-movements') {
            if (ACTIVITY.saveNewClientMovements) {
                dispatch(setNewClientMovements(CLIENTS_DATA.clientMovementsInitialValues)) // Reset form
                dispatch(setSaveNewClientMovements(false));
            }
        } else if (removeTabId === 'cash-registers-new') {
            if (ACTIVITY.saveNewCashRegister) {
                dispatch(setNewCashRegisters(CASH_DATA.newCashRegistersInitialValue)) // Reset form
                dispatch(setSaveNewCashRegister(false));
            }
        } else if (removeTabId === 'new-bank-account') {
            if (ACTIVITY.saveNewBankAccounts) {
                dispatch(setNewBankAccount(CASH_DATA.newBankAccountInitialValue)) // Reset form
                dispatch(setSaveNewBankAccounts(false));
            }
        } else if (removeTabId === 'new-pos') {
            if (ACTIVITY.saveNewPOS) {
                dispatch(setNewPOS(CASH_DATA.newPOSInitialValue)) // Reset form
                dispatch(setSaveNewPOS(false));
            }
        } else if (removeTabId === 'basic-types-new') {
            if (ACTIVITY.saveBasicType) {
                dispatch(clearNewBasicType()) // Reset form
                dispatch(setSaveBasicType(false));
            }
        } else if (removeTabId === 'advanced-types-new') {
            if (ACTIVITY.saveAdvancedType) {
                dispatch(clearNewAdvancedType()) // Reset form
                dispatch(setSaveAdvancedType(false));
            }
        } else if (removeTabId === 'sales-new') {
            if (ACTIVITY.saveSales) {
                dispatch(resetNewSales()) // Reset form
                dispatch(setSaveSales(false));
            }
        } else if (removeTabId === 'buys-new') {
            if (ACTIVITY.saveBuys) {
                dispatch(resetNewBuys()) // Reset form
                dispatch(setSaveBuys(false));
            }
        } else if (removeTabId === 'new-client-payments') {
            if (ACTIVITY.saveSales) {
                dispatch(resetNewClientPayment()) // Reset form
                dispatch(setSaveClientPayments(false));
            }
        } else if (removeTabId === 'new-supplier-payments') {
            if (ACTIVITY.saveBuys) {
                // dispatch(resetNewSupplierPayment()) // Reset form
                dispatch(setSaveSupplierPayments(false));
            }
        }
        dispatch(removeTab(removeTabId)) // remove tab on confirm
        showTabCloseWarning(false) // Close popup
        let newIndex = 0;
        let i = tabIndexR;
        if (ACTIVE_MAIN_TAB === i) {
            newIndex = i > 0 ? i - 1 : 0;
            dispatch(updateActiveMainTab(newIndex))
        } else if (ACTIVE_MAIN_TAB < i) {
            dispatch(updateActiveMainTab(ACTIVE_MAIN_TAB))
        } else {
            newIndex = ACTIVE_MAIN_TAB > 0 ? ACTIVE_MAIN_TAB - 1 : 0;
            dispatch(updateActiveMainTab(newIndex))
        }
    }

    const handleDragStart = (e, index) => {
        setDraggedTabIndex(index);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const handleDragEnter = (e, index) => {
        e.preventDefault();
        if (draggedTabIndex < index) e.target.closest("li").classList.add("tab-dragover-right");
        else if (draggedTabIndex > index) e.target.closest("li").classList.add("tab-dragover-left");
    }

    const handleDragLeave = (e, index) => {
        e.preventDefault();
        if (draggedTabIndex !== index) {
            e.target.closest("li").classList.remove("tab-dragover-right");
            e.target.closest("li").classList.remove("tab-dragover-left");
        }
    }

    const handleDrop = (e, dropIndex) => {
        e.preventDefault();
        e.target.closest("li").classList.remove("tab-dragover-right");
        e.target.closest("li").classList.remove("tab-dragover-left");

        if (draggedTabIndex === null || draggedTabIndex === dropIndex) return;

        if (ACTIVE_MAIN_TAB > draggedTabIndex && ACTIVE_MAIN_TAB <= dropIndex) dispatch(updateActiveMainTab(ACTIVE_MAIN_TAB - 1));
        else if (ACTIVE_MAIN_TAB < draggedTabIndex && ACTIVE_MAIN_TAB >= dropIndex) dispatch(updateActiveMainTab(ACTIVE_MAIN_TAB + 1));
        else if (ACTIVE_MAIN_TAB === draggedTabIndex) dispatch(updateActiveMainTab(dropIndex));
        dispatch(reOrderTabs([draggedTabIndex, dropIndex]));
        setDraggedTabIndex(null);
    }

    const onSelect = (index) => {
        if (TABS_DATA[index] === TABS_DATA[ACTIVE_MAIN_TAB]) return; // Prevent same tab re-rendering on re-pressing
        if (TABS_DATA?.includes("working-program-employee")) {
            if (TABS_DATA[index] !== "working-program-employee") toast.info("Παρακαλώ τελειώστε πρώτα με την επεξεργασία προγράμματος του εργαζομένου.");
            return; // Prevent user from changing tabs when in working-program-employee
        }
        setToggle(!toggle);
        dispatch(updateActiveMainTab(index));
    }

    return (
        <React.Fragment>
            {TABS_DATA.length > 0 && (
                <Tabs
                    selectedIndex={ACTIVE_MAIN_TAB}
                    onSelect={(index) => onSelect(index)}
                >
                    {/* Tab list  */}
                    <div className="d-flex main-tabs no-print">
                        <TabList className="react-tabs__tab-list tab-style" style={{pointerEvents: isEmronConnectFileERP ? "none" : ""}}>
                            <React.Fragment>
                                {TABS_DATA.map((tab, i) => (
                                    <Tab
                                        draggable
                                        onDragStart={(e) => handleDragStart(e, i)}
                                        onDragOver={(e) => handleDragOver(e)}
                                        onDragEnter={(e) => handleDragEnter(e, i)}
                                        onDragLeave={(e) => handleDragLeave(e, i)}
                                        onDrop={(e) => handleDrop(e, i)}
                                        title={t('TabsText.' + tab)}
                                        className={tabClassName}
                                        key={i}>
                                        <button
                                            onClick={(e) => handleTabRemove(e, tab, i)}
                                            type="button"
                                            className="tab-close-btn"
                                            hidden={onlyStartOpen}
                                        >
                                            <i className="mdi mdi-close"></i>
                                        </button>
                                        <span className="tabText">{t('TabsText.' + tab)}</span>
                                    </Tab>
                                ))}
                            </React.Fragment>
                        </TabList>
                    </div>

                    {/* Tab Panel containing data for the specific tab to render in the view */}
                    {/* Rendering the table tabs data  */}
                    {TABS_DATA.map((tab, idx) => (
                        // the following function creates the view according to the tab opened in the vie wrapper of the application
                        <TabPanel key={idx}>
                            {checkViewFunc(tab)}
                        </TabPanel>
                    ))}

                </Tabs>
            )}

            {/* Confirm Close Tabs */}
            <Modal className="themeModal" show={tabCloseWarning}>
                <Modal.Header>
                    <Modal.Title>{t('ClosingTabWarning.warning')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('ClosingTabWarning.message')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary"
                            onClick={() => showTabCloseWarning(false)}>{t('ClosingTabWarning.no')}</Button>
                    <Button variant="primary" onClick={() => tabCloseConfirm()}>{t('ClosingTabWarning.yes')}</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default TabsLayout;
