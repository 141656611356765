import React, {useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import el from "date-fns/locale/el";
import en from "date-fns/locale/en-IN";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import moment from "moment";
import {isEmpty} from "../../../_helpers/commonFunctions";

registerLocale("el", el);
registerLocale("en", en);

const DateTimeBox = (props) => {
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [date, setDate] = useState(new Date());
    let selectedValue = null;

    if (props.selected && props.selected === 'today') {
        selectedValue = date;
    } else if (props.selected && props.selected !== 'today') {
        selectedValue = props.selected;
    }

    const handleOnChange = (e) => {
        if (e === null || e === undefined || e === "") {
            setDate(e)
            props.onChange && props.onChange(e)
        } else {
            setDate(e)
            props.onChange && props.onChange(e)
        }
    }

    const handleOnBlur = () => {
        if (isEmpty(date) || !moment(date, "DD/MM/YYYY HH:mm").isValid()) { // Invalid date
            props.onChange && props.onChange("");
        } else if (String(date.getFullYear()) !== String(company.year) && !props?.allowOtherYears) { // Do not allow other years
            toast.error("Please select a date between the current year you are logged in.");
            props.onChange && props.onChange("");
        }
    }

    return (
        <div className={`input-container ${props.classes ? props.classes : ""}`}>
            {props.label && (
                <label htmlFor={props.label} className="input-label mb-2">
                    {props.label}{" "}
                    {props.required && <span className="text-danger">*</span>}
                </label>
            )}
            <div className="input-wrapper">
                <DatePicker
                    name={props.name ? props.name : ""}
                    timeFormat="HH:mm"
                    dateFormat="dd/MM/yyyy HH:mm"
                    locale={props.language === "en" ? "en" : "el"}
                    maxDate={props.maxDate ? props.maxDate : new Date("31-12-2030")}
                    minDate={props.minDate ? props.minDate : new Date("01-01-2010")}
                    selected={selectedValue}
                    placeholder={props.placeholder}
                    readOnly={props.allowOlderDate !== true ? props.readOnly : ""}
                    autoComplete="off"
                    showMonthYearPicker={props.showMonthYearPicker}
                    onChange={(e) => handleOnChange(e)}
                    onBlur={(e) => handleOnBlur(e)}
                    className={"form-control " + props.classes}
                    disabled={props?.disabled}
                    wrapperClassName={"datepicker-full-width"}
                    timeIntervals={10}
                    showTimeSelect={true}
                />
            </div>
        </div>
    )
}

export default DateTimeBox;
