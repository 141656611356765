import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Modal, Row} from "react-bootstrap";
import EmployeeProgram from "./employee-program";
import {useDispatch, useSelector} from "react-redux";
import {
    setWorkingProgram,
    setWorkingProgramNeedsSave,
    setWorkingProgramOnEmployee
} from "../../../../../_reducers/DataPanel/Payroll/payrollSlice";
import {toast} from "react-toastify";
import {addTab, removeTab, updateActiveMainTab} from "../../../../../_reducers/TabsSlice";
import {applyMetaballomenaApologistika, applyRounding} from "./constants";
import BetterCheckBox from "../../../../common/BetterCheckBox";
import moment from "moment";
import {isEmpty} from "../../../../../_helpers/commonFunctions";
import axios from "axios";

const EmployeeWorkingProgram = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const workingProgram = useSelector((state) => state.PAYROLL.workingProgram);
    const workingProgramExtraData = useSelector((state) => state.PAYROLL.workingProgramExtraData);
    const workingProgramOnEmployee = useSelector((state) => state.PAYROLL.workingProgramOnEmployee);
    const workingProgramOnlyView = useSelector((state) => state.PAYROLL.workingProgramOnlyView);
    const legacyWorkingProgram = useSelector((state) => state.PAYROLL.legacyWorkingProgram);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);

    const displayHourFrom = 0;
    const displayHourTo = 24;
    const dragCoefficient = 0.025;

    const [legacyEvents, setLegacyEvents] = useState([]); // The events before any editing
    const [events, setEvents] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);

    const [persistentModal, setPersistentModal] = useState(false);
    const [copyLastWeekModal, setCopyLastWeekModal] = useState(false);

    useEffect(() => {
        if (workingProgramOnEmployee && firstLoad) {
            setFirstLoad(false)
            const cloneEvents = structuredClone(workingProgramOnEmployee.dailyCalendar);
            let cnt = 0;
            for (let ev of cloneEvents) {
                ev.id = cnt;
                cnt++;
            }
            setEvents(cloneEvents);

            if (legacyWorkingProgram) {
                const findMatch = legacyWorkingProgram.employees.find((el) => el.employeeId === workingProgramOnEmployee.employeeId);
                if (findMatch) {
                    const cEvents = structuredClone(findMatch.dailyCalendar);
                    setLegacyEvents(cEvents);
                }
            }
        }
    }, [workingProgramOnEmployee])

    // resizeType = bottom or top
    const onEventResize = (eventId, diff, resizeType) => {
        let cancelEvent = false;
        const cloneEvents = structuredClone(events);
        const findEvent = cloneEvents.find((el) => el.id === eventId);

        const inMinutes = diff * dragCoefficient;
        if (findEvent) {
            if (resizeType === "bottom") {
                findEvent.hourTo = Math.min(findEvent.hourTo + inMinutes, displayHourTo);
                const hook = hookOverworkOvertime(findEvent);
                for (let key in hook) findEvent[key] = hook[key];

                const findOverlap = cloneEvents.find((el) => el.day === findEvent.day && el.id !== findEvent.id && el.hourFrom <= findEvent.hourTo && findEvent.hourTo <= el.hourTo);
                if (findOverlap) cancelEvent = true;
            } else if (resizeType === "top") {
                findEvent.hourFrom = Math.max(findEvent.hourFrom + inMinutes, displayHourFrom);
                const hook = hookOverworkOvertime(findEvent);
                for (let key in hook) findEvent[key] = hook[key];

                const findOverlap = cloneEvents.find((el) => el.day === findEvent.day && el.id !== findEvent.id && el.hourTo <= findEvent.hourFrom && findEvent.hourFrom <= el.hourFrom);
                if (findOverlap) cancelEvent = true;
            }
        }
        if (!cancelEvent) setEvents(cloneEvents);
    }

    const onEventDrag = (eventId, diff) => {
        const cloneEvents = structuredClone(events);
        let findEvent = cloneEvents.find((el) => el.id === eventId);

        const inMinutes = diff * dragCoefficient;
        if (findEvent) {
            if (findEvent.hourTo + inMinutes <= displayHourTo && findEvent.hourFrom + inMinutes >= displayHourFrom) {
                findEvent.hourFrom = Math.max(findEvent.hourFrom + inMinutes, displayHourFrom);
                findEvent.hourTo = Math.min(findEvent.hourTo + inMinutes, displayHourTo);
            } else if (findEvent.hourTo + inMinutes >= displayHourTo) {
                findEvent.hourTo = displayHourTo;
            } else if (findEvent.hourFrom + inMinutes <= displayHourFrom) {
                findEvent.hourFrom = displayHourFrom;
            }
        }
        setEvents(cloneEvents);
    }

    const hookOverworkOvertime = (newEvent) => {
        if (newEvent.workType === "normal") {
            let hoursAlreadyWorked = 0; // For overnight hook
            if (newEvent.hourFrom === 0) {
                const findPrevMatch = events.find((el) => el.day + 1 === newEvent.day && el.hourTo === 24);
                if (findPrevMatch) hoursAlreadyWorked = +Number(findPrevMatch.hourTo - findPrevMatch.hourFrom).toFixed(2);
            }
            newEvent.overwork = 0;
            newEvent.overtime = 0;
            // Scan for overwork and overtime
            let workingDaysPerWeek = workingProgramExtraData.workingDaysPerWeek[workingProgramOnEmployee.employeeId];
            if (workingDaysPerWeek === "6") {
                let diff = +Number(newEvent.hourTo - newEvent.hourFrom + hoursAlreadyWorked).toFixed(2);
                if (diff > 6.67) {
                    diff = +Number(diff - 6.67).toFixed(2);
                    const overwork = Math.min(diff, 1.33); // Max 1.6 hour overwork
                    newEvent.overwork = overwork;
                    if (diff > 1.33) { // Overtime
                        newEvent.overtime = +Number(diff - overwork).toFixed(2);
                    }
                }
            } else {
                let diff = +Number(newEvent.hourTo - newEvent.hourFrom + hoursAlreadyWorked).toFixed(2);
                if (diff > 8) {
                    diff = +Number(diff - 8).toFixed(2);
                    const overwork = Math.min(diff, 1); // Max 1 hour overwork
                    newEvent.overwork = overwork;
                    if (diff > 1) { // Overtime
                        newEvent.overtime = +Number(diff - overwork).toFixed(2);
                    }
                }
            }
        }
        return newEvent;
    }

    const canHappen = (ev, type) => {
        if (type === "onEventAdd") {
            for (let event of events.filter((el) => el.day === ev.day)) {
                if (!((event.hourFrom >= ev.hourFrom && event.hourFrom >= ev.hourTo) || (event.hourTo <= ev.hourFrom && event.hourTo <= ev.hourTo))) {
                    toast.error("Το ωράριο δεν είναι αποθηκεύτηκε διότι συμπίπτει με ήδη υπάρχον.", {autoClose: 6000});
                    return false;
                }
            }
        } else if (type === "onEventsAdd" && ev?.length > 0) {
            for (let event of events.filter((el) => el.day === ev[0].day)) {
                for (let e of ev) {
                    if (!((event.hourFrom >= e.hourFrom && event.hourFrom >= e.hourTo) || (event.hourTo <= e.hourFrom && event.hourTo <= e.hourTo))) {
                        toast.error("Το ωράριο δεν είναι αποθηκεύτηκε διότι συμπίπτει με ήδη υπάρχον.", {autoClose: 6000});
                        return false;
                    }
                }
            }
        } else if (type === "onEventEdit") {
            for (let event of events.filter((el) => el.day === ev.day && el.id !== ev.id)) {
                if (!((event.hourFrom >= ev.hourFrom && event.hourFrom >= ev.hourTo) || (event.hourTo <= ev.hourFrom && event.hourTo <= ev.hourTo))) {
                    toast.error("Το ωράριο δεν είναι αποθηκεύτηκε διότι συμπίπτει με ήδη υπάρχον.", {autoClose: 6000});
                    return false;
                }
            }
        }
        return true;
    }

    const onEventAdd = (newEvent) => {
        const cloneEvents = structuredClone(events);
        newEvent = hookOverworkOvertime(newEvent);
        if (canHappen(newEvent, "onEventAdd")) {
            cloneEvents.push(newEvent);
            setEvents(cloneEvents);
        }
    }

    const onEventsAdd = (newEvents) => {
        let cloneEvents = structuredClone(events);
        if (canHappen(newEvents, "onEventsAdd")) {
            cloneEvents = [...cloneEvents, ...newEvents];
            setEvents(cloneEvents);
        }
    }

    const onEventDelete = (id) => {
        let cloneEvents = structuredClone(events);
        const findMatching = cloneEvents.find((el) => el.id === id);
        if (findMatching) {
            cloneEvents = cloneEvents.filter((el) => el.id !== id);
            setEvents(cloneEvents);
        }
    }

    const onEventEdit = (ev) => {
        let cloneEvents = structuredClone(events);
        ev = hookOverworkOvertime(ev);
        if (canHappen(ev, "onEventEdit")) {
            let idx = cloneEvents.findIndex((el) => el.id === ev.id);
            cloneEvents[idx] = ev;
            setEvents(cloneEvents);
        }
    }

    const handleCloseEmployeeProgram = () => {
        dispatch(removeTab("working-program-employee"));
        const findWorkingProgram = TABS_DATA.findIndex((item) => "working-program" === item);
        if (findWorkingProgram === -1) {
            dispatch(addTab("working-program"));
            dispatch(updateActiveMainTab(TABS_DATA.length));
        } else {
            dispatch(updateActiveMainTab(findWorkingProgram));
        }
    }

    const onSave = () => {
        const cloneWorkingProgram = structuredClone(workingProgram);
        const findEmployee = cloneWorkingProgram?.employees?.find((el) => el.employeeId === workingProgramOnEmployee.employeeId);
        if (findEmployee) {
            let cloneEvents = structuredClone(events);
            const moreThan12Hours = cloneEvents.filter((el) => el.workType === "normal").some((ev) => {
                const sameDay = cloneEvents.filter((el) => el.day === ev.day);
                let totalHours = 0;
                for (let sm of sameDay) totalHours += +Number(sm.hourTo).toFixed(2) - +Number(sm.hourFrom).toFixed(2);
                totalHours = +Number(totalHours).toFixed(2);
                return totalHours > 12;
            })
            if (moreThan12Hours) {
                return toast.error(`Υπάρχει μέρα που ο εργαζόμενος εργάζεται περισσότερο από 12 ώρες. Παρακαλώ διορθώστε.`, {autoClose: 5000});
            }
            cloneEvents = applyMetaballomenaApologistika(workingProgram.dateFrom, workingProgram.dateTo, legacyEvents, cloneEvents);
            findEmployee.dailyCalendar = cloneEvents.sort((a, b) => a.day < b.day ? -1 : 1);
            findEmployee.persistent = workingProgramOnEmployee.persistent;
            findEmployee.ignorePersistent = workingProgramOnEmployee.ignorePersistent;
            if (!isEmpty(findEmployee.onlyDisplay)) delete findEmployee.onlyDisplay;
            if (isEmpty(findEmployee?.ignorePersistent)) delete findEmployee.ignorePersistent;
            dispatch(setWorkingProgram(cloneWorkingProgram));
            dispatch(setWorkingProgramOnEmployee(null));
            dispatch(setWorkingProgramNeedsSave(true));
            handleCloseEmployeeProgram();
        } else {
            toast.error("Σφάλμα κατά την αποθήκευση.");
        }
    }

    const onNotSave = () => {
        dispatch(setWorkingProgramOnEmployee(null));
        handleCloseEmployeeProgram();
    }

    const onApplyRounding = (eventId) => {
        let cloneEvents = structuredClone(events);
        let findMatching = cloneEvents.find((el) => el.id === eventId);
        if (findMatching) {
            findMatching.hourTo = applyRounding(findMatching.hourTo, 10);
            findMatching.hourFrom = applyRounding(findMatching.hourFrom, 10);
            const hook = hookOverworkOvertime(findMatching);
            for (let key in hook) findMatching[key] = hook[key];

            setEvents(cloneEvents);
        }
    }

    const handleSavePersistentProgram = () => {
        setPersistentModal(false);
        dispatch(setWorkingProgramOnEmployee({...workingProgramOnEmployee, persistent: "true"}));
    }

    const correctMaxYpergergasia = (newEvents) => {
        let cloneEvents = structuredClone(newEvents);
        let totalOverwork = 0;
        let totalOvertime = 0;
        for (let ev of cloneEvents) {
            if (ev.overwork) {
                totalOverwork += ev.overwork;
                totalOvertime += ev.overtime;
            }
        }
        const workingDaysPerWeek = workingProgramExtraData.workingDaysPerWeek[workingProgramOnEmployee.employeeId];
        const maxOverwork = workingDaysPerWeek === "6" ? 8 : 5;
        if (totalOverwork < maxOverwork) { // Need to transfer overtime to overwork

        } else { // Need to transfer overwork to overtime
            let additionalOvertime = totalOverwork - maxOverwork;
            if (additionalOvertime > 0) {
                cloneEvents.sort((a, b) => a.day < b.day ? 1 : -1);
                for (let ev of cloneEvents) {
                    if (ev.overwork > 0) {
                        ev.overtime += Math.min(ev.overwork, additionalOvertime);
                        additionalOvertime -= Math.min(ev.overwork, additionalOvertime);
                        ev.overwork = 0;
                        if (additionalOvertime <= 0) break;
                    }
                }
            }
            cloneEvents.sort((a, b) => a.day < b.day ? -1 : 1);
            return cloneEvents;
        }
    }

    const handleCopyPreviousWeek = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/fetch-working-program`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                dateFrom: moment(workingProgram.dateFrom, "DD/MM/YYYY").subtract(3, "days").startOf("week").format("DD/MM/YYYY"),
                dateTo: moment(workingProgram.dateFrom, "DD/MM/YYYY").subtract(3, "days").endOf("week").format("DD/MM/YYYY"),
            }
        }).then((res) => {
            setCopyLastWeekModal(false);
            if (res.data.status === "200") {
                const workingProgram = res.data.data;
                const findMatching = workingProgram.employees.find((el) => el.employeeId === workingProgramOnEmployee.employeeId);
                if (findMatching) {
                    const dailyCalendar = findMatching.dailyCalendar;
                    if (dailyCalendar.length === 0 || !dailyCalendar) {
                        toast.info("Δεν βρέθηκε προηγούμενο εβδομαδιαίο πρόγραμμα.");
                    } else {
                        let evId = 0;
                        let arr = [];
                        for (let dc of dailyCalendar) {
                            dc.id = evId;
                            if (dc.workType === "permit") dc.workType = "normal";
                            delete dc.permitType;
                            delete dc.metaballomeno;
                            delete dc.apologistiko;
                            delete dc.sentToErgani;

                            arr.push(dc);
                            evId++;
                        }
                        setEvents(arr);
                        toast.success("Επιτυχής αντιγραφή προηγούμενης εβδομάδας.");
                    }
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setCopyLastWeekModal(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const canShowPersistentCheckbox = moment(workingProgram.dateTo, "DD/MM/YYYY").endOf("day") > moment().endOf("week");

    return (
        <div style={{height: "calc(100vh - 175px)", overflowX: "hidden", paddingRight: "10px"}}>
            <Row className={"mb-2 pb-2"} style={{position: "sticky", top: "0", backgroundColor: "white", zIndex: "201"}}>
                <Col md={4}>
                    <Button size={"sm"} onClick={() => onNotSave()}>{"<<"} Πίσω στο πρόγραμμα (χωρίς αποθήκευση)</Button><br/>
                    <Button size={"sm"} className={"mt-1"} onClick={() => setCopyLastWeekModal(true)}>
                        <i className="fa-regular fa-copy mr-2"></i>
                        Αντιγραφή προηγούμενης εβδομάδας
                    </Button>
                </Col>
                <Col md={3} style={{textAlign: "center", fontSize: "16px", fontWeight: "600"}}>
                    Εργαζόμενος: {workingProgramOnEmployee.employeeIdentifier.split("|")[0]} {workingProgramOnEmployee.employeeIdentifier.split("|")[1]} <br/>
                    ΑΦΜ: {workingProgramOnEmployee.employeeIdentifier.split("|")[2]}
                </Col>
                <Col md={5} className={"d-flex justify-content-end"}>
                    {(canShowPersistentCheckbox && workingProgramOnEmployee?.persistent === "true" && (workingProgram?.sentToErgani === true || workingProgramOnEmployee?.ignorePersistent === "true")) && (
                        <BetterCheckBox
                            className={"mt-1 mb-0 mr-3"}
                            name={"ignorePersistent"}
                            text={"Τροποποίηση μόνο για αυτήν την εβδομάδα"}
                            disabled={workingProgramOnlyView}
                            checked={workingProgramOnEmployee?.ignorePersistent === "true"}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    dispatch(setWorkingProgramOnEmployee({...workingProgramOnEmployee, ignorePersistent: "true"}));
                                } else {
                                    const clone = structuredClone(workingProgramOnEmployee);
                                    delete clone.ignorePersistent;
                                    dispatch(setWorkingProgramOnEmployee(clone));
                                }
                            }}
                        />
                    )}
                    {canShowPersistentCheckbox && (
                        <BetterCheckBox
                            className={"mt-1 mb-0 mr-3"}
                            name={"persistent"}
                            text={"Σταθερό πρόγραμμα"}
                            disabled={workingProgramOnEmployee?.persistent === "true" || workingProgramOnlyView}
                            checked={workingProgramOnEmployee?.persistent === "true"}
                            onChange={(e) => {
                                if (e.target.checked === true) setPersistentModal(true);
                            }}
                        />
                    )}
                    <Button size={"sm"} onClick={() => onSave()} disabled={workingProgramOnlyView}>Αποθήκευση & πίσω στο πρόγραμμα</Button>
                </Col>
            </Row>
            {workingProgramOnEmployee && (
                <Row style={{position: "relative"}}>
                    <Col md={12}>
                        <EmployeeProgram
                            dateFrom={workingProgram.dateFrom}
                            dateTo={workingProgram.dateTo}
                            events={events}
                            displayHoursFrom={displayHourFrom}
                            displayHoursTo={displayHourTo}

                            onEventResize={onEventResize}
                            onEventDrag={onEventDrag}
                            onEventAdd={onEventAdd}
                            onEventsAdd={onEventsAdd}
                            onEventEdit={onEventEdit}
                            onEventDelete={onEventDelete}
                            onApplyRounding={onApplyRounding}

                            disabled={workingProgramOnlyView}

                            employeeInstallation={workingProgramExtraData.employeesInstallation[workingProgramOnEmployee.employeeId]}
                        />
                    </Col>
                </Row>
            )}
            <Modal show={persistentModal} onHide={() => setPersistentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Σταθερό Εβδομαδιαίο</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να θέσετε το παρόν πρόγραμμα ως
                    σταθερό εβδομαδιαίο για τον εργαζόμενο {workingProgramOnEmployee.employeeIdentifier.split("|")[0]} {workingProgramOnEmployee.employeeIdentifier.split("|")[1]};
                    Μπορείτε να μην αποθηκεύσετε τελικά την αλλαγή αυτήν πατώντας το κουμπί "Πίσω στο πρόγραμμα (χωρίς αποθήκευση)".<br/>
                    <Alert variant={"danger"} className={"mt-3"}>
                        <strong>ΠΡΟΣΟΧΗ:</strong> Δεν θα επηρεαστούν σταθερά προγράμματα που έχουν ισχύ σε μεταγενέστερη εβδομάδα. Για παράδειγμα αν ένα νέο
                        σταθερό πρόγραμμα ισχύει από την παρά-επόμενη εβδομάδα και μετά, η ισχύς της παρούσας τροποποίησης θα ισχύει έως και την λήξη της προηγούμενης εβδομάδας του νέου προγράμματος.
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setPersistentModal(false)}>
                        Όχι, κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => handleSavePersistentProgram()}>
                        Ναι, εφαρμογή
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={copyLastWeekModal} onHide={() => setCopyLastWeekModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Αντιγραφή προηγούμενης εβδομάδας</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να αντιγράψετε το πρόγραμμα της προηγούμενης εβδομάδας; Το παρόν πρόγραμμα θα διαγραφεί.
                    Μπορείτε να μην αποθηκεύσετε τελικά την αλλαγή αυτήν πατώντας το κουμπί "Πίσω στο πρόγραμμα (χωρίς αποθήκευση)".
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setCopyLastWeekModal(false)}>
                        Όχι, κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => handleCopyPreviousWeek()}>
                        Ναι, αντιγραφή
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EmployeeWorkingProgram
