import { createSlice } from "@reduxjs/toolkit";

const newSendingGoodsInitialValue = {
  id: 1,
  no: 1,
  name: '',
  isActive: 'active'
}

const newCarNumbersInitialValue = {
  id: 1,
  no: 1,
  number: '',
  isActive: 'active'
}

const newPaymentTypesInitialValue = {
  id: 1,
  no: 1,
  name: '',
  type: '',
  typeName: '',
  isActive: 'active',
  myDataPaymentId: "",
  options: {
    preSelectedPosBankAccount: "",
    preSelectedPosBankAccountName: "",
    autopayment: 'inactive',
    documentType: "",
    preNumber: "",
    number:"",
    installation:"",
    documentTypeName: "",
    paymentReason: "",
  }
}

const buysGeneralSettingsInitailValue = {
  sendingGoods: [],
  carNumbers: [],
  forbitChange: 'inactive',
  paymentTypes: [],
  paymentReason: "",
  emailSettings: {},
  isActive: "active",
  autofillProductClassifications: "active",
}

const buysEmailSettingsInitialValue = {
  subject: "",
  emailBody: "",
  attachments: [],
}

const initialState = {
  buysGeneralSettingsInitailValue: buysGeneralSettingsInitailValue,
  buysGeneralSettings: buysGeneralSettingsInitailValue,
  newSendingGoodsInitialValue: newSendingGoodsInitialValue,
  newSendingGoods: newSendingGoodsInitialValue,
  newCarNumbersInitialValue: newCarNumbersInitialValue,
  newCarNumbers: newCarNumbersInitialValue,
  newPaymentTypesInitialValue: newPaymentTypesInitialValue,
  newPaymentTypes: newPaymentTypesInitialValue,
  buysEmailSettingsInitialValue: buysEmailSettingsInitialValue,
  buysEmailSettings: buysEmailSettingsInitialValue,
}

export const BuysSettingsSlice = createSlice({
  name: "Buys Settings",
  initialState,
  reducers: {
    setBuysGeneralSettings: (state, { payload }) => {
      state.buysGeneralSettings = payload;
      state.buysEmailSettings = payload?.emailSettings;
    },
    setBuysPaymentTypes: (state, { payload }) => {
      state.buysGeneralSettings.paymentTypes = payload;
    },
    setNewSendingGoods: (state, { payload }) => {
      state.newSendingGoods = payload;
    },
    setNewCarNumbers: (state, { payload }) => {
      state.newCarNumbers = payload;
    },
    setNewPaymentTypes: (state, { payload }) => {
      state.newPaymentTypes = payload;
    },
    setBuysEmailSettings: (state, { payload }) => {
      state.buysEmailSettings = payload;
      state.buysGeneralSettings.emailSettings = payload;
    },
  },
});

export const {
  setBuysPaymentTypes,
  setBuysGeneralSettings,
  setNewSendingGoods,
  setNewCarNumbers,
  setNewPaymentTypes,
  setBuysEmailSettings,
} = BuysSettingsSlice.actions;
