// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primerLoader {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top-color: #76aeb7;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: primerLoaderLoad 0.8s ease-in-out infinite;
}

@keyframes primerLoaderLoad {
    to {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/_components/primer/POPUP-COMPONENTS/Loader/loaderStyle.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,qDAAqD;AACzD;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".primerLoader {\r\n    border: 5px solid rgba(0, 0, 0, 0.1);\r\n    border-top-color: #76aeb7;\r\n    border-radius: 50%;\r\n    width: 50px;\r\n    height: 50px;\r\n    animation: primerLoaderLoad 0.8s ease-in-out infinite;\r\n}\r\n\r\n@keyframes primerLoaderLoad {\r\n    to {\r\n        transform: rotate(360deg);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
