import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Col, Row, Spinner} from "react-bootstrap";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import EditableTable from "../../../primer/Editable-Table";
import TextArea from "../../../common/TextArea";
import Alert from "react-bootstrap/Alert";
import {permitTypes} from "./Components/constants";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import {toast} from "react-toastify";
import {
    resetTempNewEditWorkPermit,
    setEditWorkPermit,
    setTempNewEditWorkPermit
} from "../../../../_reducers/DataPanel/Payroll/payrollSlice";
import {addToForceRefresh} from "../../../../_reducers/SearchTableSlice";
import {setSaveEditWorkPermit} from "../../../../_reducers/activitySlice";
import moment from "moment/moment";
import {isEmpty} from "../../../../_helpers/commonFunctions";

const EditWorkPermit = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const workPermit = useSelector((state) => state.PAYROLL.editWorkPermit);
    const tempNewWorkPermit = useSelector((state) => state.PAYROLL.tempNewEditWorkPermit);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);

    const [installationOptions, setInstallationOptions] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [incrKey, setIncrKey] = useState(0);

    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [workPermit.employees, employeeList])

    useEffect(() => {
        if (companyInstallations.length > 0) {
            let options = [];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
    }, [])

    useEffect(() => {
        loadEmployees(null, "empty", setEmployeeList);
    }, [])

    const loadEmployees = (cellName, keyword, setData) => {
        let reqData = {
            company: company.id,
            subAccountType: "employee",
            keyword: keyword,
        }
        axios.get(process.env.REACT_APP_API_URL2 + `/accounting/get-subAccount-by-word`, {
            params: reqData
        }).then(res => {
            if (res.data && res.data.status === '200') {
                if (res.data.data) {
                    if (setData) setData(res.data.data);
                } else {
                    if (setData) setData([]);
                }
            } else {
                if (setData) setData([]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const employeeColumns = [
        {
            label: "Όνομα",
            name: "firstName",
        },
        {
            label: "Επώνυμο",
            name: "lastName",
        },
        {
            label: "Α.Φ.Μ.",
            name: "vatNumber",
        },
    ]


    const tableColumns = [
        {
            name: "Εργαζόμενος",
            field: "employeeIdentifier",
            editable: true,
            width: "40%",
            required: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: employeeColumns,
                    data: employeeList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: "Επιλέξτε εργαζόμενο",
                    onChangeSearchCell: loadEmployees,
                    isPayrollCombobox: true
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: "Τύπος Άδειας",
            field: "permitType",
            editable: true,
            width: "30%",
            inputType: {
                type: "select",
                options: permitTypes,
            }
        },
        {
            name: "Ημερομηνία από",
            field: "dateFrom",
            editable: true,
            width: "15%",
            inputType: {
                type: "dayFormatDatebox"
            }
        },
        {
            name: "Ημερομηνία μέχρι",
            field: "dateTo",
            editable: true,
            width: "15%",
            inputType: {
                type: "dayFormatDatebox"
            }
        },
    ]

    const handleOnChange = (e, type, name) => {
        if (!ACTIVITY.saveEditWorkPermit) dispatch(setSaveEditWorkPermit(true));

        if (type === "input") {
            dispatch(setEditWorkPermit({...workPermit, [name] : e.target.value}));
        } else if (type === "dropdown") {
            dispatch(setEditWorkPermit({...workPermit, [name] : e.value}));
        }
    }

    const tableActions = {
        insert: (e, data) => {
            if (!ACTIVITY.saveEditWorkPermit) dispatch(setSaveEditWorkPermit(true));

            const cloneNewWorkPermit = structuredClone(tempNewWorkPermit);
            if (data.field === "employeeIdentifier") {
                cloneNewWorkPermit.employeeId = e.row._id;
                cloneNewWorkPermit.employeeIdentifier = `${e.row.firstName}|${e.row.lastName}|${e.row.vatNumber}`;
            } else if (data.field === "dateFrom") {
                if (moment(data.value).isValid()) {
                    cloneNewWorkPermit[data.field] = moment(data.value).format("DD/MM/YYYY");
                } else {
                    cloneNewWorkPermit[data.field] = "";
                }
            } else if (data.field === "dateTo") {
                if (moment(data.value).isValid()) {
                    cloneNewWorkPermit[data.field] = moment(data.value).format("DD/MM/YYYY");
                } else {
                    cloneNewWorkPermit[data.field] = "";
                }
            } else {
                cloneNewWorkPermit[data.field] = data.value;
            }
            if (!isEmpty(cloneNewWorkPermit.employeeId) && !isEmpty(cloneNewWorkPermit.employeeIdentifier) && !isEmpty(cloneNewWorkPermit.permitType) && !isEmpty(cloneNewWorkPermit.dateFrom) && !isEmpty(cloneNewWorkPermit.dateTo)) { // Insert
                let cloneEmployees = structuredClone(workPermit.employees);
                cloneEmployees.push(cloneNewWorkPermit);
                dispatch(setEditWorkPermit({...workPermit, employees: cloneEmployees}));
                dispatch(resetTempNewEditWorkPermit());
            } else { // Pre-insert row update
                dispatch(setTempNewEditWorkPermit(cloneNewWorkPermit));
            }
        },
        update: (data, id, rowIndex) => {
            if (!ACTIVITY.saveEditWorkPermit) dispatch(setSaveEditWorkPermit(true));

            let cloneEmployees = structuredClone(workPermit.employees);
            if (data?.employeeIdentifier?.row) {
                let tempCopy = structuredClone(data.employeeIdentifier.row);
                data.employeeId = tempCopy._id;
                data.employeeIdentifier = `${tempCopy.firstName}|${tempCopy.lastName}|${tempCopy.vatNumber}`;
            } else if (data.dateFrom) {
                data.dateFrom = moment(data.dateFrom).format("DD/MM/YYYY");
            } else if (data.dateTo) {
                data.dateTo = moment(data.dateTo).format("DD/MM/YYYY");
            }
            cloneEmployees[rowIndex] = {...cloneEmployees[rowIndex], ...data};
            dispatch(setEditWorkPermit({...workPermit, employees: cloneEmployees}));
        },
        selected: () => {},
        delete: (id, rowIndex) => {
            if (!ACTIVITY.saveEditWorkPermit) dispatch(setSaveEditWorkPermit(true));

            let cloneEmployees = structuredClone(workPermit.employees);
            cloneEmployees.splice(rowIndex, 1);
            dispatch(setEditWorkPermit({...workPermit, employees: cloneEmployees}));
        }
    }

    const handleCancelDocument = () => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/cancel-ergani-document`, {
            type: "permit",
            documentId: workPermit._id,
            company: company.id,
        }, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                setShowCancelModal(false);
                dispatch(addToForceRefresh("Work Permits Search"));
                dispatch(setEditWorkPermit({...res.data.data}));
                toast.success("Επιτυχής ακύρωση υποβολής.");
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleResendToErgani = () => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/resend-work-permit`, {
            ...workPermit,
            company: company.id,
        }, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                dispatch(addToForceRefresh("Work Permits Search"));
                dispatch(setEditWorkPermit({...res.data.data}));
                toast.success("Επιτυχής υποβολή στην Εργάνη.");
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const disableFields = workPermit.sentToErgani === true;

    return (
        <React.Fragment>
            {workPermit.sentToErgani === true ? (
                <Alert variant={"secondary"}>
                    Οι άδειες δεν είναι επεξεργάσιμες επειδή έχουν σταλεί στην Εργάνη.
                    <Button size={"sm"} style={{position: "absolute", right: "5px", top: "5px"}} onClick={() => setShowCancelModal(true)}>
                        Ακύρωση υποβολής
                    </Button>
                </Alert>
            ) : (
                <Alert variant={"secondary"}>
                    <Row>
                        <Col md={9}>
                            Οι άδειες είναι επεξεργάσιμες επειδή δεν έχουν σταλεί στην Εργάνη. Μπορείτε να τις αποστείλετε πατώντας το κουμπί "Αποστολή στην Εργάνη".
                            Αφού κάνετε αποστολή δεν θα είναι δυνατή η επεξεργασία τους.
                        </Col>
                        <Col md={3}>
                            <Button size={"sm"} style={{position: "absolute", right: "5px", top: "5px"}} onClick={() => handleResendToErgani()} disabled={loading}>
                                Αποστολή στην Εργάνη
                                {loading && (
                                    <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Alert>
            )}
            <Row className={"mb-2"}>
                <Col md={7}>
                    <Input
                        name={"description"}
                        label={"Περιγραφή"}
                        value={workPermit.description}
                        required={true}
                        onChange={(e) => handleOnChange(e, "input", "description")}
                        disabled={disableFields}
                        autoComplete={"off"}
                    />
                </Col>
                <Col md={5}>
                    <Dropdown
                        name={"installationMasterId"}
                        label={t("General.installation")}
                        options={installationOptions}
                        defaultValue={installationOptions.find((el) => el.value === workPermit.installationMasterId)}
                        key={Math.random()}
                        disabled={disableFields}
                        onChange={(e) => handleOnChange(e, "dropdown", "installationMasterId")}
                    />
                </Col>
            </Row>
            <Row className={"mb-2"}>
                <Col md={12} style={{pointerEvents: disableFields ? "none" : ""}}>
                    <div className={"text-muted mb-2"}><i>Άδειες</i></div>
                    <EditableTable
                        tableName={"Edit Work Permit Employees"}
                        key={incrKey}
                        data={workPermit.employees}
                        columns={tableColumns}
                        onUpdate={tableActions}
                        allowInsertRow={true}
                        allowActions={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <TextArea
                        name={"notes"}
                        label={"Σημειώσεις"}
                        rows={4}
                        value={workPermit.notes}
                        onChange={(e) => handleOnChange(e, "input", "notes")}
                        autoComplete={"off"}
                    />
                </Col>
            </Row>
            <Modal show={showCancelModal} backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>Ακύρωση υποβολής αδειών</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να ακυρώσετε το παρόν έγγραφο αδειών από την Εργάνη;
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowCancelModal(false)} disabled={loading}>
                        Όχι, κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => handleCancelDocument()} disabled={loading}>
                        Ναι, ακύρωση
                        {loading && (
                            <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default EditWorkPermit
