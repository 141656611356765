import React, {useEffect, useState} from "react";
import TextArea from "../../../common/TextArea";
import {Col, FormControl, Row} from "react-bootstrap";
import EditableTable from "../../../primer/Editable-Table";
import {useDispatch, useSelector} from "react-redux";
import {setEditPriceList, setPriceListEditProduct} from "../../../../_reducers/DataPanel/Products/productsSlice";
import {setSaveEditPriceList} from "../../../../_reducers/activitySlice";
import {
  getOptionsByData,
  vatIncludedOptions
} from "../../../../_helpers/commonFunctions";
import {toast} from "react-toastify";
import {deleteObjectFromArrayOfObjects, getObjectFromArrayOfObjectsByFields, getObjectFromArrayOfObjectsByFields2} from "../../../../_helpers/helperFunctions";
import {fetchInitialClients, fetchInitialProducts} from "../../../../_apis/api";
import Checkbox from "../../../common/CheckBox";
import LogTable from "../../../primer/LogTable";
import {useTranslation} from "react-i18next";
import axios from "axios";
import "./index.css";

export const EditPriceListGeneral = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const ACTIVITY = useSelector((state) => state.ACTIVITY);
  const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
  const company = useSelector(state => state.COMPANY_DATA.company);
  const vatOptions = vatIncludedOptions();
  const [tableClientDropdownOptions, setTableClientDropdownOptions] = useState([{value: 'all', label: 'All Clients'}]);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if(firstLoad) {
      setFirstLoad(false);
      axios.post(process.env.REACT_APP_API_URL2 + "/client/list-client-short", {company: company.id}, {headers: { "Content-Type": "application/json" }})
          .then((res) => {
            if(res.data.status === "200") {
              setTableClientDropdownOptions(getOptionsByData([{_id: 'all', name: 'All Clients'}, ...res.data.data], '_id', 'name'));
            } else {
              console.log(res.data);
            }
          })
          .catch((err) => console.log(err));
    }
  })
  const productCodeColumns = [
    {
      label: t("ProductsListNewEdit.smallTable.code"),
      name: "code",
    },
    {
      label: t("ProductsListNewEdit.smallTable.productName"),
      name: "name",
    },
    {
      label: t("ProductsListNewEdit.smallTable.alternativeName"),
      name: "alternateName",
    },
  ];
  const productPriceListColumns = [
    {
      name: "No",
      field: "no",
      editable: false,
      width: "25px"
    },
    {
      name: t("ProductsListNewEdit.table.productCode"),
      field: "code",
      editable: true,
      width: "100px",
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: PRODUCTS_DATA.products,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ProductsListNew.table.placeholderCode')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t("ProductsListNewEdit.table.productName"),
      field: "name",
      editable:true,
      width: "200px",
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: PRODUCTS_DATA.products,
          editEnable: false ,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('ProductsListNew.table.placeholderName')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t("ProductsListNewEdit.table.client"),
      field: "client",
      width: "300px",
      editable: true,
      inputType: {
        options: tableClientDropdownOptions,
        type: 'multiselectdropdown',
      }
    },
    {
      name: t("ProductsListNewEdit.table.price"),
      field: "price",
      width: "100px",
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          // maxLength: 5
          maxLength: 10 // Given in the wireframe
        }
      }
    },
    {
      name: t("ProductsListNewEdit.table.vat"),
      field: "vat",
      width: "200px",
      editable:true,
      inputType: {
        type: 'dropdown',
        options: vatOptions
      }
    },
    {
      name: "%",
      field: "isPerDiscount",
      editable: true,
      width: "40px",
      inputType: {
        type: 'radio',
        name: 'discountType',
        radioLogic: 'oneperrow',
        options: [{
          value: true,
          label: 'True'
        }, {
          value: false,
          label: 'False'
        }]
      }
    },
    {
      name: "€",
      field: "isDiscountAmount",
      editable: true,
      width: "40px",
      inputType: {
        type: 'radio',
        radioLogic: 'oneperrow',
        name: 'discountType',
        options: [{
          value: true,
          label: 'True'
        }, {
          value: false,
          label: 'False'
        }]
      }
    },
    {
      name: t("ProductsListNewEdit.table.discount"),
      field: "discount",
      editable: true,
      width: "100px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 5
        }
      }
    },
  ];
  const requestData = {
    company:company.id,
    year:company.year.toString(),
  }
  useEffect(() => {
    dispatch(fetchInitialClients(requestData));
    dispatch(fetchInitialProducts(requestData));
  }, [dispatch])

  const handleOnChange = (e) => {
    // Set if editing the form
    if (!ACTIVITY.saveEditPriceList) {
      dispatch(setSaveEditPriceList(true));
    }
    const name = e.target.name;
    let value = e.target.value;
    let editPriceData = {};
    editPriceData[name] = value;
    dispatch(setEditPriceList({...PRODUCTS_DATA.editPriceList, ...editPriceData}));
  }
  const priceListActions = {
    insert: async (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditPriceList) {
        dispatch(setSaveEditPriceList(true));
      }
      let tempEditProduct = Object.assign({}, PRODUCTS_DATA.priceListEditProduct);
      if (data.field === "client") {
        data.value = e;
      }
      if (data.field === 'name' || data.field === 'code' || data.field === 'client') {
        let filterFields = {
          code: data.field === 'code' ? data.value : tempEditProduct.code,
          name: data.field === 'name' ? data.value : tempEditProduct.name,
          client: data.field === 'client' ? data.value : tempEditProduct.client,
        }
        let filterFields2 = Object.assign({}, filterFields);
        filterFields2.client = 'all';
        let filterFields3 = {
          code: data.field === 'code' ? data.value : tempEditProduct.code,
          name: data.field === 'name' ? data.value : tempEditProduct.name,
        }
        // Cases to check duplicate entry in product table
        let productListData = getObjectFromArrayOfObjectsByFields(PRODUCTS_DATA.editPriceList.productList, filterFields);
        let productListData2 = getObjectFromArrayOfObjectsByFields(PRODUCTS_DATA.editPriceList.productList, filterFields2);
        let productListData3 = getObjectFromArrayOfObjectsByFields(PRODUCTS_DATA.editPriceList.productList, filterFields3);
        if (productListData || productListData2 || (productListData3 && data.value === "all")) {
          toast.error('Same product with same client not allowed.');
        } else {
          if (e.key !== "Enter") {
            tempEditProduct[data.field] = data.value;
            if (data.field === 'isPerDiscount' && (data.value === "on" || data.value === "true")) {
              tempEditProduct.isPerDiscount = "true";
              tempEditProduct.isDiscountAmount = "false";
            }
            if (data.field === 'isDiscountAmount' && (data.value === "on" || data.value === "true")) {
              tempEditProduct.isPerDiscount = "false";
              tempEditProduct.isDiscountAmount = "true";
            }
            if (data && data?.field === 'code') {
              await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(data.value)}`, {params: company})
                  .then(res => {
                    if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                      tempEditProduct['name'] = res.data.data['0'].name;
                      tempEditProduct['_id'] = res.data.data['0']._id;
                    }
                  }).catch((err) => {
                    console.log(err);
                  })
            }
            // Get Product By Name
            if (data && data?.field === 'name') {
              await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-name/${encodeURIComponent(data.value)}`, {params: company})
                  .then(res => {
                    if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                      tempEditProduct['code'] = res.data.data['0'].code;
                      tempEditProduct['_id'] = res.data.data['0']._id;
                    }
                  }).catch((err) => {
                    console.log(err);
                  })
            }
            dispatch(setPriceListEditProduct(tempEditProduct)); // Update redux
            // If validated fields done add new row
            if (tempEditProduct.name !== '' && tempEditProduct.code !== '' && tempEditProduct.client !== '' && tempEditProduct.client.toString() !== "0") {
              let oldProductsList = PRODUCTS_DATA.editPriceList.productList;
              let updatePriceListNewProduct = Object.assign({}, PRODUCTS_DATA.priceListNewProduct);
              updatePriceListNewProduct.name = tempEditProduct.name;
              updatePriceListNewProduct.code = tempEditProduct.code;
              updatePriceListNewProduct.client = tempEditProduct.client;
              updatePriceListNewProduct._id = tempEditProduct._id;
              updatePriceListNewProduct.no = oldProductsList.length + 1;
              updatePriceListNewProduct.id = oldProductsList.length + 1;
              dispatch(setEditPriceList({
                ...PRODUCTS_DATA.editPriceList,
                productList: [...oldProductsList, updatePriceListNewProduct]
              }));
              dispatch(setPriceListEditProduct(PRODUCTS_DATA.priceListNewProductInitialValue)); // Reset new category mydata
            }

          } else {
            let updatePriceListNewProduct = Object.assign({}, PRODUCTS_DATA.priceListNewProduct);
            let addCheck = true;

            let oldProductsList = PRODUCTS_DATA.editPriceList.productList;
            if (data.field === 'add' && addCheck) {
              updatePriceListNewProduct.no = oldProductsList.length + 1;
              updatePriceListNewProduct.id = oldProductsList.length + 1;
              dispatch(setEditPriceList({
                ...PRODUCTS_DATA.editPriceList,
                productList: [...oldProductsList, updatePriceListNewProduct]
              }));
              dispatch(setPriceListEditProduct(PRODUCTS_DATA.priceListNewProductInitialValue)); // Reset new category mydata
              dispatch(setSaveEditPriceList(false)); // set save false
            }
          }
        }
      }

    },
    update: async (data, id, e) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditPriceList) {
        dispatch(setSaveEditPriceList(true));
      }
      if (data.field === "client") {
        data.value = e;
      }
      if (data.isPerDiscount && (data.isPerDiscount === "on" || data.isPerDiscount === "true")) {
        data.isPerDiscount = "true";
        data.isDiscountAmount = "false";
      }
      if (data.isDiscountAmount && (data.isDiscountAmount === "on" || data.isDiscountAmount === "true")) {
        data.isPerDiscount = "false";
        data.isDiscountAmount = "true";
      }
      if (data.name && data.name === '') {
        toast.error("Name is mandatory field.");
      } else if (data.code && data.code === '') {
        toast.error("Code is mandatory field.");
      } else {
        let plProducts = PRODUCTS_DATA.editPriceList.productList.slice();
        if (plProducts.find(item => item.id === id) !== undefined) {
          if (data && data?.code !== '') {
            await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(data.code)}`, {params: company})
                .then(res => {
                  if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                    data['name'] = res.data.data['0'].name;
                    data['code'] = res.data.data['0'].code;
                    data['_id'] = res.data.data['0']._id;
                  }
                }).catch((err) => {
                  console.log(err);
                })
          }
          // Get Product By Name
          if (data && data?.name !== '') {
            await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-name/${encodeURIComponent(data.name)}`, {params: company})
                .then(res => {
                  if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                    data['code'] = res.data.data['0'].code;
                    data['name'] = res.data.data['0'].name;
                    data['_id'] = res.data.data['0']._id;
                  }
                }).catch((err) => {
                  console.log(err);
                })
          }
          if (data.name || data.code || data.client) {
            let plProductsRowData = plProducts[id - 1];
            let filterFields = {
              code: data.code ? data.code : plProductsRowData.code,
              name: data.name ? data.name : plProductsRowData.name,
              client: data.client ? data.client : plProductsRowData.client,
            }
            let filterFields2 = {
              code: data.code ? data.code : plProductsRowData.code,
              name: data.name ? data.name : plProductsRowData.name,
            }
            let filterFields3 = {
              code: data.code ? data.code : plProductsRowData.code,
              name: data.name ? data.name : plProductsRowData.name,
              client: 'all',
            }
            // Cases to check duplicate entry in product table
            let productListData = getObjectFromArrayOfObjectsByFields(PRODUCTS_DATA.editPriceList.productList, filterFields);
            let productListData2 = getObjectFromArrayOfObjectsByFields2(PRODUCTS_DATA.editPriceList.productList, filterFields2);
            let productListData3 = getObjectFromArrayOfObjectsByFields(PRODUCTS_DATA.editPriceList.productList, filterFields3);
            let productListData2Check = false;
            // If we have more than 1
            if (productListData2 && productListData2.length > 1 && data.client === 'all') {
              productListData2Check = true;
            }
            // If we have more than 1
            if (productListData3 && productListData2 && productListData2.length >= 1 && data.client !== 'all') {
              productListData2Check = productListData2['0'].code !== plProductsRowData.code;
            }
            // Check
            if (productListData || productListData2Check) {
              toast.error('Same product with same client not allowed.');
              dispatch(setEditPriceList({...PRODUCTS_DATA.editPriceList, productList: plProducts})); // update data
            } else {
              plProducts[id - 1] = {...plProducts[id - 1], ...data};
              dispatch(setEditPriceList({...PRODUCTS_DATA.editPriceList, productList: plProducts})); // update data
            }

          } else {
            plProducts[id - 1] = {...plProducts[id - 1], ...data};
            dispatch(setEditPriceList({...PRODUCTS_DATA.editPriceList, productList: plProducts})); // update data
          }
        }
      }
    },
    selected: () => {
      console.log('selected mydata row clicked');
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditPriceList) {
        dispatch(setSaveEditPriceList(true));
      }
      let oldProductsList = PRODUCTS_DATA.editPriceList.productList.slice();
      if (id !== '' && Number.isInteger(id)) {
        let deletedPriceList = deleteObjectFromArrayOfObjects(oldProductsList, id,'id');
        dispatch(setEditPriceList({...PRODUCTS_DATA.editPriceList, productList: deletedPriceList }))
      }

      if (id !== '' && !Number.isInteger(id)) {
        let deletedPriceList = deleteObjectFromArrayOfObjects(oldProductsList, id,'_id');
        dispatch(setEditPriceList({...PRODUCTS_DATA.editPriceList, productList: deletedPriceList }))
      }
    }
  }

  return (
      <React.Fragment>
        <Row className="position-relative border-bottom mb-3">
          <Col xs="12" md="12" className="d-flex align-items-center justify-content-end" >
            <div className="inline-field d-flex align-items-center acremd mb-3" >
              <label htmlFor="product-active" className="mb-0 mr-2 " >{t('ProductsListNewEdit.pricelistActive')}</label>
              <Checkbox
                  className="mb-2"
                  name="isActive"
                  key = {Math.random()}
                  defaultValue={PRODUCTS_DATA.editPriceList.isActive}
                  value={ PRODUCTS_DATA.editPriceList.isActive === 'active' ? "inactive" : "active" }
                  onChange = {(e) => handleOnChange(e) }
              />
            </div>
          </Col>
        </Row>
        {/* First Row */}
        <Row>
          <Col sm-="12" md="6">
            <div className="mb-3">
              <label>{t('ProductsListNewEdit.name')}<span className="text-danger">*</span></label>
              <FormControl
                  className={PRODUCTS_DATA.editPriceList.name === '' ? 'invalid' : ''}
                  name = "name"
                  placeholder={t('ProductsListNewEdit.namePlaceholder')}
                  value = {PRODUCTS_DATA.editPriceList.name}
                  onChange = {(e) => handleOnChange(e) }
              />
              {PRODUCTS_DATA.editPriceList.name === '' && ACTIVITY.editing && <span className="text-danger">{t('ProductsListNewEdit.required')}</span>}
            </div>
          </Col>
        </Row>
        <div className="price-list-table">
          {
              PRODUCTS_DATA.editPriceList?.productList && PRODUCTS_DATA.editPriceList?.productList?.length === 0 &&
              ACTIVITY.saveEditPriceList &&
              <span className="text-danger d-inline-block">{t('ProductsListNewEdit.warning')}</span>
          }
          <EditableTable
              key = { Math.random() }
              tableName="Edit Price List General" // do not change name
              allowToggleColumns = { true }
              allowActions={ true }
              allowInsertRow={ true }
              allowPagination={ false }
              allowBulkDelete={ false }
              allowSorting={ false }
              allowSelectionCheckbox={ false }
              columns={ productPriceListColumns }
              onUpdate={priceListActions}
              enableNewRowCheckbox = {true}
              data={ PRODUCTS_DATA.editPriceList.productList }
          />
        </div>
        <Row>
          <div style={{ width: "100%" }}>
            <div>
              <TextArea
                  rows="4"
                  label={t('ProductsListNewEdit.priceListNotes')}
                  placeholder={t('ProductsListNewEdit.addPriceListNotes')}
                  name="notes"
                  value = {PRODUCTS_DATA.editPriceList.notes}
                  onChange = {(e) => handleOnChange(e) }
              />
            </div>
          </div>
        </Row>
        { process.env.REACT_APP_DEBUG_MODE === 'true' && <pre>{JSON.stringify(PRODUCTS_DATA.editPriceList, null, 2)}</pre> }
      </React.Fragment>
  );
};

export const PriceListEditLog = () => {
  const { t } = useTranslation('common');
  const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);

  return (
      <React.Fragment>
        <div className="text-muted mb-2"><i>{t('Logs.priceListLog')}</i></div>
        <LogTable itemId={PRODUCTS_DATA.editPriceList._id}/>
      </React.Fragment>
  )
}
