import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    getDefaultOptionByValue, getEmployeeBreakOptions, getEmployeeCategoryOptions,
    getEmployeeTypeOptions, getKathestosOptions,
    getOptionsByData, getResignationCauseOptions,
    getSexOptions, getWorkingDaysPerWeekOptions, isEmpty
} from "../../../../_helpers/commonFunctions";
import {setSaveEditEmployee} from "../../../../_reducers/activitySlice";
import {setEditEmployee} from "../../../../_reducers/DataPanel/Accounting/accountingSlice";
import {Col, Row} from "react-bootstrap";
import Checkbox from "../../../common/CheckBox";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import DateBox from "../../../primer/DateBox";
import AccountingBox from "../../../primer/AccountingBox";
import TextArea from "../../../common/TextArea";
import BetterCheckBox from "../../../common/BetterCheckBox";
import ShowQRModal from "./show-qr-modal";

const EditEmployee = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const ACCOUNTING_DATA = useSelector((state) => state.ACCOUNTING_DATA);
    const PAYROLL = useSelector((state) => state.PAYROLL);
    const currentDate = new Date();
    const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const companyInstallations = getOptionsByData(installations, "installationMasterId", "address");

    const handleOnChange = (e, type = "default", ddname = '') => {
        if (!ACTIVITY.saveEditEmployee) dispatch(setSaveEditEmployee(true));

        let name;
        let value;
        if (type === 'dp') {
            value = e;
            name = ddname;
        } else if (type === 'ac') {
            value = e.target.value;
            name = ddname;
        } else if (type === 'dd') {
            name = ddname;
            value = e.value;
        } else if (type === "bcb") {
            value = e.target.checked;
            name = ddname;
        } else {
            value = e.target.value;
            name = e.target.name;
        }
        let employeeData = Object.assign({}, ACCOUNTING_DATA.editEmployee);

        employeeData[name] = value;
        if (name === "resignationDate") {
            if (value) {
                employeeData.isActive = "inactive";
            } else {
                employeeData.isActive = "active";
            }
        }
        dispatch(setEditEmployee(employeeData));
    }
    
    const disabledFields = ACCOUNTING_DATA.editEmployee.isFromHyper === "true" || ACCOUNTING_DATA.editEmployee.fromErgani === true;

    return (
        <React.Fragment>
            <Row className="position-relative border-bottom mb-3">
                <Col md={12} className="d-flex align-items-center justify-content-end">
                    <div className="inline-field d-flex align-items-center acremd  mb-3">
                        <label htmlFor="product-active" className="mb-0 mr-2">{t('PosNew.active')}</label>
                        <Checkbox
                            className="mb-2"
                            name="isActive"
                            key={Math.random()}
                            id="isActive"
                            disabled={disabledFields}
                            defaultValue={ACCOUNTING_DATA.editEmployee.isActive}
                            value={ACCOUNTING_DATA.editEmployee.isActive === 'active' ? "inactive" : "active"}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                </Col>
            </Row>
            <p className="text-muted mb-1">
                <i>{t('EmployeeNew.personalLabel')}</i>
            </p>
            <Row className={"mb-2"}>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.firstName.name')}
                        name="firstName"
                        disabled={disabledFields}
                        placeholder={t('EmployeeNew.firstName.placeholder')}
                        value={ACCOUNTING_DATA.editEmployee.firstName}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.firstName === '' &&
                        <div className="text-danger">{t('EmployeeNew.firstName.required')}</div>}
                </Col>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.lastName.name')}
                        name="lastName"
                        disabled={disabledFields}
                        placeholder={t('EmployeeNew.lastName.placeholder')}
                        value={ACCOUNTING_DATA.editEmployee.lastName}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.lastName === '' &&
                        <div className="text-danger">{t('EmployeeNew.lastName.required')}</div>}
                </Col>
                <Col md={2}>
                    <Dropdown
                        label={t('EmployeeNew.sex.name')}
                        name="sex"
                        disabled={disabledFields}
                        placeholder="Επιλέξτε Φύλο"
                        options={getSexOptions()}
                        enabledValue={true}
                        required={true}
                        onChange={(e) => handleOnChange(e, "dd", "sex")}
                        value={getDefaultOptionByValue(getSexOptions(), ACCOUNTING_DATA.editEmployee.sex, 'value', 'label', 'value')}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.sex === '' &&
                        <div className="text-danger">{t('EmployeeNew.sex.required')}</div>}
                </Col>
                <Col md={2}>
                    <DateBox
                        label={t('EmployeeNew.birthDate.name')}
                        name="birthDate"
                        allowOtherYears={true}
                        required={true}
                        disabled={disabledFields}
                        minDate={new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate())}
                        maxDate={new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate())}
                        selected={ACCOUNTING_DATA.editEmployee?.birthDate ? new Date(ACCOUNTING_DATA.editEmployee.birthDate) : ""}
                        onChange={(e) => handleOnChange(e, 'dp', 'birthDate')}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.birthDate === '' &&
                        <div className="text-danger">{t('EmployeeNew.birthDate.required')}</div>}
                </Col>
                <Col md={2}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.vatNumber.name')}
                        name="vatNumber"
                        disabled={disabledFields}
                        placeholder={t('EmployeeNew.vatNumber.placeholder')}
                        value={ACCOUNTING_DATA.editEmployee.vatNumber}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.vatNumber === '' &&
                        <div className="text-danger">{t('EmployeeNew.vatNumber.required')}</div>}
                </Col>
            </Row>
            <Row className={"mb-2"}>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.fatherFirstName.name')}
                        name="fatherFirstName"
                        disabled={disabledFields}
                        placeholder={t('EmployeeNew.fatherFirstName.placeholder')}
                        value={ACCOUNTING_DATA.editEmployee.fatherFirstName}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.fatherFirstName === '' &&
                        <div className="text-danger">{t('EmployeeNew.fatherFirstName.required')}</div>}
                </Col>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.fatherLastName.name')}
                        name="fatherLastName"
                        disabled={disabledFields}
                        placeholder={t('EmployeeNew.fatherLastName.placeholder')}
                        value={ACCOUNTING_DATA.editEmployee.fatherLastName}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.motherFirstName.name')}
                        name="motherFirstName"
                        disabled={disabledFields}
                        placeholder={t('EmployeeNew.motherFirstName.placeholder')}
                        value={ACCOUNTING_DATA.editEmployee.motherFirstName}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.motherFirstName === '' &&
                        <div className="text-danger">{t('EmployeeNew.motherFirstName.required')}</div>}
                </Col>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.motherLastName.name')}
                        name="motherLastName"
                        disabled={disabledFields}
                        placeholder={t('EmployeeNew.motherLastName.placeholder')}
                        value={ACCOUNTING_DATA.editEmployee.motherLastName}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label="Τηλέφωνο"
                        name="phone"
                        placeholder="Εισάγετε Τηλέφωνο"
                        value={ACCOUNTING_DATA.editEmployee.phone}
                        required={false}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
                <Col md={3}>
                    <Input
                        className="w-100"
                        label="E-Mail"
                        name="email"
                        placeholder="Εισάγετε E-Mail"
                        value={ACCOUNTING_DATA.editEmployee.email}
                        required={false}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
            </Row>
            <hr/>
            <p className="text-muted">
                <i>{t('EmployeeNew.securityLabel')}</i>
            </p>
            <Row className={"mb-3"}>
                <Col md={4}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.amka.name')}
                        name="amka"
                        disabled={disabledFields}
                        placeholder={t('EmployeeNew.amka.placeholder')}
                        value={ACCOUNTING_DATA.editEmployee.amka}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.amka === '' &&
                        <div className="text-danger">{t('EmployeeNew.amka.required')}</div>}
                </Col>
                <Col md={4}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.amika.name')}
                        name="amika"
                        placeholder={t('EmployeeNew.amika.placeholder')}
                        value={ACCOUNTING_DATA.editEmployee.amika}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
                <Col md={4}>
                    <Input
                        className="w-100"
                        label={"ΑΜΑ"}
                        name="ama"
                        placeholder={"Εισάγετε ΑΜΑ"}
                        value={ACCOUNTING_DATA.editEmployee.ama}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
            </Row>
            <hr/>
            <p className="text-muted">
                <i>{t('EmployeeNew.workingLabel')}</i>
            </p>
            <Row className={"mb-3"}>
                <Col md={3}>
                    <Dropdown
                        label={t('EmployeeNew.employeeCategory.name')}
                        name="employeeCategory"
                        placeholder={t('EmployeeNew.employeeCategory.placeholder')}
                        options={getEmployeeCategoryOptions()}
                        enabledValue={true}
                        required={true}
                        onChange={(e) => handleOnChange(e, "dd", "employeeCategory")}
                        value={getDefaultOptionByValue(getEmployeeCategoryOptions(), ACCOUNTING_DATA.editEmployee.employeeCategory, 'value', 'label', 'value')}
                    />
                    {ACTIVITY.saveEditEmployee && !disabledFields && !ACCOUNTING_DATA.editEmployee.employeeCategory &&
                        <div className="text-danger">{t('EmployeeNew.employeeCategory.required')}</div>}
                </Col>
                <Col md={3}>
                    <Dropdown
                        label={t('EmployeeNew.employeeType.name')}
                        name="employeeType"
                        placeholder={t('EmployeeNew.employeeType.placeholder')}
                        options={getEmployeeTypeOptions()}
                        enabledValue={true}
                        required={true}
                        onChange={(e) => handleOnChange(e, "dd", "employeeType")}
                        value={getDefaultOptionByValue(getEmployeeTypeOptions(), ACCOUNTING_DATA.editEmployee.employeeType, 'value', 'label', 'value')}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.employeeType === '' &&
                        <div className="text-danger">{t('EmployeeNew.employeeType.required')}</div>}
                </Col>
                <Col md={6}>
                    <Input
                        className="w-100"
                        label={t('EmployeeNew.specialty.name')}
                        name="specialty"
                        disabled={disabledFields}
                        placeholder={t('EmployeeNew.specialty.placeholder')}
                        value={ACCOUNTING_DATA.editEmployee.specialty}
                        required={true}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.specialty === '' &&
                        <div className="text-danger">{t('EmployeeNew.specialty.required')}</div>}
                </Col>
                <Col md={4}>
                    <Dropdown
                        label={t('EmployeeNew.installation.name')}
                        options={companyInstallations}
                        value={getDefaultOptionByValue(companyInstallations, ACCOUNTING_DATA.editEmployee.installation, 'value', 'label', 'value')}
                        enabledValue={true}
                        searchEnable={true}
                        required={true}
                        name="installation"
                        onChange={(e) => handleOnChange(e, 'dd', 'installation')}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.installation === '' &&
                        <div className="text-danger">{t('EmployeeNew.installation.required')}</div>}
                </Col>
                <Col md={2}>
                    <Dropdown
                        label={"Εβδομαδιαίες ημέρες εργασίας"}
                        name={"workingDaysPerWeek"}
                        key={Math.random()}
                        disabled={disabledFields}
                        options={getWorkingDaysPerWeekOptions()}
                        defaultValue={getDefaultOptionByValue(getWorkingDaysPerWeekOptions(), ACCOUNTING_DATA.editEmployee.workingDaysPerWeek, 'value', 'label', 'value')}
                        onChange={(e) => handleOnChange(e, 'dd', 'workingDaysPerWeek')}
                    />
                </Col>
                <Col md={2}>
                    <Input
                        name={"workingHoursPerWeek"}
                        label={"Εβδομαδιαίες ώρες εργασίας"}
                        type={"number"}
                        disabled={disabledFields}
                        value={ACCOUNTING_DATA.editEmployee.workingHoursPerWeek}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
                <Col md={2}>
                    <Input
                        name={"symbatikesWorkingHoursPerWeek"}
                        label={"Συμβ. εβδ. ώρες εργασίας"}
                        type={"number"}
                        value={ACCOUNTING_DATA.editEmployee.symbatikesWorkingHoursPerWeek}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
                <Col md={2}>
                    <Input
                        name={"workingExperienceYears"}
                        label={"Χρόνια προϋπηρεσίας"}
                        type={"number"}
                        disabled={disabledFields}
                        value={ACCOUNTING_DATA.editEmployee.workingExperienceYears}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <Input
                        name={"firstYearPermits"}
                        label={"Άδειες πρώτου έτους"}
                        type={"number"}
                        disabled={disabledFields}
                        value={ACCOUNTING_DATA.editEmployee.firstYearPermits}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
                <Col md={2}>
                    <Input
                        name={"breakMinutes"}
                        label={"Χρόνος διαλείμματος (σε λεπτά)"}
                        type={"number"}
                        disabled={disabledFields}
                        value={ACCOUNTING_DATA.editEmployee.breakMinutes}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        label={"Διάλειμμα εντός/εκτός ωραρίου"}
                        name={"breakMinutesWhen"}
                        key={Math.random()}
                        disabled={disabledFields}
                        options={getEmployeeBreakOptions()}
                        defaultValue={getDefaultOptionByValue(getEmployeeBreakOptions(), ACCOUNTING_DATA.editEmployee.breakMinutesWhen, 'value', 'label', 'value')}
                        onChange={(e) => handleOnChange(e, 'dd', 'breakMinutesWhen')}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        label={"Καθεστώς Απασχόλησης"}
                        name={"kathestos"}
                        key={Math.random()}
                        disabled={disabledFields}
                        options={getKathestosOptions()}
                        defaultValue={getDefaultOptionByValue(getKathestosOptions(), ACCOUNTING_DATA.editEmployee.kathestos, 'value', 'label', 'value')}
                        onChange={(e) => handleOnChange(e, 'dd', 'kathestos')}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        classes="w-100 mb-3"
                        label={t('EmployeeNew.employmentDate.name')}
                        name="employmentDate"
                        allowOtherYears={true}
                        disabled={disabledFields}
                        required={true}
                        minDate={new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate())}
                        selected={ACCOUNTING_DATA.editEmployee?.employmentDate ? new Date(ACCOUNTING_DATA.editEmployee.employmentDate) : ""}
                        onChange={(e) => handleOnChange(e, 'dp', 'employmentDate')}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.employmentDate === '' &&
                        <div className="text-danger">{t('EmployeeNew.employmentDate.required')}</div>}
                </Col>
                <Col md={2}>
                    <label className="" htmlFor="prefered-price">{t('EmployeeNew.salary.name')}</label>
                    <AccountingBox
                        name="salary"
                        className="mb-3 form-control"
                        placeholder={t('ProductsNewGeneralEdit.preferredPrice.placeholder')}
                        defaultValue={ACCOUNTING_DATA.editEmployee.salary}
                        onChange={(e) => handleOnChange(e, 'ac', 'salary')}
                    />
                    {ACTIVITY.saveEditEmployee && ACCOUNTING_DATA.editEmployee.isFromHyper !== "true" && ACCOUNTING_DATA.editEmployee.salary === 0 &&
                        <div className="text-danger">{t('EmployeeNew.salary.required')}</div>}
                </Col>
            </Row>
            {PAYROLL.companyHasErgani && (
                <Row>
                    <Col md={2}>
                        <BetterCheckBox
                            name={"erganiCard"}
                            text={"Κάρτα εργασίας"}
                            className={"mt-4"}
                            checked={ACCOUNTING_DATA.editEmployee.erganiCard}
                            onChange={(e) => handleOnChange(e, "bcb", "erganiCard")}
                        />
                    </Col>
                    {ACCOUNTING_DATA.editEmployee.erganiCard === true && (
                        <React.Fragment>
                            <Col md={3}>
                                <Input
                                    name={"flexibleAttendanceMinutes"}
                                    label={"Ευέλικτος Χρόνος Προσέλευσης (σε λεπτά)"}
                                    type={"number"}
                                    required={true}
                                    value={ACCOUNTING_DATA.editEmployee.flexibleAttendanceMinutes}
                                    onChange={(e) => handleOnChange(e)}
                                />
                                {ACTIVITY.saveEditEmployee && isEmpty(ACCOUNTING_DATA.editEmployee.flexibleAttendanceMinutes) && <div className="text-danger">Απαιτείται ο χρόνος προσέλευσης</div>}
                            </Col>
                            <Col md={3}>
                                <BetterCheckBox
                                    name={"autoSendErganiCard"}
                                    text={"Αυτόματη αποστολή κάρτας εργασίας"}
                                    className={"mt-4"}
                                    checked={ACCOUNTING_DATA.editEmployee.autoSendErganiCard}
                                    onChange={(e) => handleOnChange(e, "bcb", "autoSendErganiCard")}
                                />
                            </Col>
                            <Col md={3}>
                                <ShowQRModal employeeData={ACCOUNTING_DATA.editEmployee} />
                            </Col>
                        </React.Fragment>
                    )}
                </Row>
            )}
            <Row className={"mt-3"}>
                <Col md={2}>
                    <DateBox
                        classes="w-100 mb-3"
                        label={t('EmployeeNew.resignationDate')}
                        name="resignationDate"
                        allowOtherYears={true}
                        minDate={ACCOUNTING_DATA.editEmployee.employmentDate ? ACCOUNTING_DATA.editEmployee.employmentDate : new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate())}
                        maxDate={new Date()}
                        disabled={disabledFields}
                        selected={ACCOUNTING_DATA.editEmployee?.resignationDate ? new Date(ACCOUNTING_DATA.editEmployee.resignationDate) : ""}
                        onChange={(e) => handleOnChange(e, 'dp', 'resignationDate')}
                    />
                </Col>
                <Col md={2}>
                    <Dropdown
                        label={t('EmployeeNew.resignationCause')}
                        options={getResignationCauseOptions()}
                        value={getDefaultOptionByValue(getResignationCauseOptions(), ACCOUNTING_DATA.editEmployee.resignationCause, 'value', 'label', 'value')}
                        enabledValue={true}
                        disabled={disabledFields}
                        searchEnable={true}
                        name="resignationCause"
                        onChange={(e) => handleOnChange(e, 'dd', 'resignationCause')}
                    />
                </Col>
            </Row>
            <hr className="mb-4"/>
            <Row>
                <Col md={12}>
                    <div className="text-muted mb-2"><i>{t('PosNew.notes')}</i></div>
                    <TextArea
                        rows="4"
                        placeholder={t('PosNew.addNotes')}
                        name="notes"
                        limit="1000"
                        value={ACCOUNTING_DATA.editEmployee.notes}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default EditEmployee;
