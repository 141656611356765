import React from "react";
import {Col, Row} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

export const AccountingBridgeInstructions = () => {
    return (
        <React.Fragment>
            <Row>
                <div className="text-muted mb-1"><i>Οδηγίες παραμετροποίησης</i></div>
                <Col md={6}>
                    <Alert variant={"secondary"}>
                        Παρακάτω βρίσκονται οι μεταβλητές που τοποθετούνται εσωτερικά στους κωδικούς λογιστικών
                        λογαριασμών:<br/>
                        - &#123;Client&#125; : Πρόθεμα Πελάτη<br/>
                        - &#123;Supplier&#125; : Πρόθεμα Προμηθευτή<br/>
                        - &#123;Employee&#125; : Πρόθεμα Εργαζομένου <br/>
                        - &#123;Product&#125; : Πρόθεμα Είδους <br/>
                        <br/>
                        - &#123;Cash&#125; : Πρόθεμα μετρητών από τις ρυθμίσεις εταιρείας<br/>
                        - &#123;Installation&#125; : Πρόθεμα Εγκατάστασης από τις ρυθμίσεις εταιρείας<br/>
                        - &#123;BankAccount&#125; : Πρόθεμα Τραπεζικού Λογαριασμού<br/>
                        - &#123;POS&#125; : Πρόθεμα POS<br/>
                        - &#123;CashRegister&#125; : Πρόθεμα Ταμειακής Μηχανής<br/>
                        <br/>
                        - &#123;VAT&#125; : Ποσοστό Φ.Π.Α.<br/>
                        - &#123;Fee&#125; : Πρόθεμα τέλους από τις ρυθμίσεις φόρων<br/>
                        - &#123;Withhold&#125; : Πρόθεμα παρακρατούμενου φόρου από τις ρυθμίσεις φόρων<br/>
                        - &#123;Stamp&#125; : Πρόθεμα χαρτόσημου από τις ρυθμίσεις φόρων<br/>
                        - &#123;OtherTax&#125; : Πρόθεμα λοιπού φόρου από τις ρυθμίσεις φόρων<br/>
                    </Alert>
                </Col>
                <Col md={6}>
                    <Alert variant={"secondary"}>
                        <table style={{width: "100%", border: "1px solid gray"}}>
                            <thead style={{textAlign: "center", borderBottom: "1px solid gray"}}>
                            <tr>
                                <th>Κατηγορία Γενικού Παραστατικού</th>
                                <th>Τύπος : Υποστηριζόμενα Wildcards</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style={{borderBottom: "1px solid gray"}}>
                                <td style={{textAlign: "center"}}>Αγορές</td>
                                <td>
                                    Προμηθευτής : &#123;Supplier&#125;<br/>
                                    Καθαρή Αξία : &#123;Product&#125; &#123;VAT&#125;<br/>
                                    Φ.Π.Α. : &#123;Product&#125; &#123;VAT&#125;<br/>
                                    Φ.Π.Α. Εκπιπτόμενου: &#123;Product&#125; &#123;VAT&#125;<br/>
                                    Φ.Π.Α. Μη Εκπιπτόμενου: &#123;Product&#125; &#123;VAT&#125;<br/>
                                    Τέλη : &#123;Fee&#125;<br/>
                                    Παρακρατούμενοι Φόροι : &#123;Withhold&#125;<br/>
                                    Χαρτόσημο : &#123;Stamp&#125;<br/>
                                    Λοιποί Φόροι : &#123;OtherTax&#125;<br/>
                                    <br/>
                                    (*) Στην περίπτωση την οποία συμπληρώνονται οι γραμμές (Φ.Π.Α. Εκπιπτόμενου) ή (Φ.Π.Α. Μη Εκπιπτόμενου) αγνοείται η σκέτη γραμμή Φ.Π.Α.
                                </td>
                            </tr>
                            <tr style={{borderBottom: "1px solid gray"}}>
                                <td style={{textAlign: "center"}}>Πωλήσεις</td>
                                <td>
                                    Πελάτης : &#123;Client&#125;<br/>
                                    Καθαρή Αξία : &#123;Product&#125; &#123;VAT&#125;<br/>
                                    Φ.Π.Α. : &#123;Product&#125; &#123;VAT&#125;<br/>
                                    Τέλη : &#123;Fee&#125;<br/>
                                    Παρακρατούμενοι Φόροι : &#123;Withhold&#125;<br/>
                                    Χαρτόσημο : &#123;Stamp&#125;<br/>
                                    Λοιποί Φόροι : &#123;OtherTax&#125;<br/>
                                    Κρατήσεις : &#123;Deductions&#125;<br/>
                                </td>
                            </tr>
                            <tr style={{borderBottom: "1px solid gray"}}>
                                <td style={{textAlign: "center"}}>Εισπράξεις Πελατών</td>
                                <td>
                                    Πελάτης : &#123;Client&#125;<br/>
                                    Τραπεζικός Λογαριασμός : &#123;BankAccount&#125;<br/>
                                    POS : &#123;POS&#125;<br/>
                                    Μετρητά : &#123;Cash&#125;<br/>
                                    IRIS : -<br/>
                                </td>
                            </tr>
                            <tr style={{borderBottom: "1px solid gray"}}>
                                <td style={{textAlign: "center"}}>Πληρωμές Προμηθευτών</td>
                                <td>
                                    Προμηθευτής : &#123;Supplier&#125;<br/>
                                    Τραπεζικός Λογαριασμός : &#123;BankAccount&#125;<br/>
                                    POS : &#123;POS&#125;<br/>
                                    Μετρητά : &#123;Cash&#125;<br/>
                                    IRIS : -<br/>
                                </td>
                            </tr>
                            <tr style={{borderBottom: "1px solid gray"}}>
                                <td style={{textAlign: "center"}}>Κινήσεις Πελατών</td>
                                <td>
                                    Πελάτης : &#123;Client&#125;<br/>
                                    Καθαρή Αξία : -<br/>
                                </td>
                            </tr>
                            <tr style={{borderBottom: "1px solid gray"}}>
                                <td style={{textAlign: "center"}}>Κινήσεις Προμηθευτών</td>
                                <td>
                                    Προμηθευτής : &#123;Supplier&#125;<br/>
                                    Καθαρή Αξία : -<br/>
                                </td>
                            </tr>
                            <tr style={{borderBottom: "1px solid gray"}}>
                                <td style={{textAlign: "center"}}>Κινήσεις Ειδών</td>
                                <td>
                                    Εγκατάσταση : &#123;Installation&#125;<br/>
                                    Καθαρή Αξία : &#123;Product&#125; &#123;VAT&#125;<br/>
                                    Φ.Π.Α. : &#123;Product&#125; &#123;VAT&#125;<br/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Alert>
                </Col>
            </Row>
        </React.Fragment>
    )
}