import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import "./loaderStyle.css";

const Loader = ({ cancel, doNotShowSmallText }) => {
    const [t] = useTranslation('common');
    const [showSmallText, setShowSmallText] = useState(false);
    const [showSmallText15, setShowSmallText15] = useState(false);

    useEffect(() => {
        if(doNotShowSmallText !== true) {
            const timeoutId = setTimeout(() => {
                setShowSmallText(true);
            }, 5000);

            return () => clearTimeout(timeoutId);
        }
    }, []);
    useEffect(() => {
        const timeoutId15 = setTimeout(() => {
            setShowSmallText(false);
            setShowSmallText15(true);
        }, 300000);

        return () => clearTimeout(timeoutId15);
    }, []);
    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%"}}>
            <div className={"primerLoader"}></div>
            <div style={{fontSize: "24px", fontWeight: "bold", marginTop: "20px"}}>{t("LoaderText.loading")}</div>
            {showSmallText && (
                <div style={{
                    fontSize: "12px",
                    fontStyle: "italic",
                    marginTop: "10px",
                    opacity: "1",
                    transition: "opacity 0.5s ease-in-out"
                }}>
                    {t("LoaderText.loaderDesc5")}
                </div>
            )}
            {showSmallText15 && (
                <div style={{
                    fontSize: "12px",
                    fontStyle: "italic",
                    marginTop: "10px",
                    opacity: "1",
                    transition: "opacity 0.5s ease-in-out"
                }}>
                    {t("LoaderText.loaderDesc15")}
                </div>
            )}
            {cancel && (
                <Button size={"sm"} className={"mt-2"} onClick={cancel}>{t("LoaderText.cancel")}</Button>
            )}
        </div>
    )
}

export default Loader;
