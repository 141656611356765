import { createSlice } from "@reduxjs/toolkit";

const initialState = "login";

export const RouteSlice = createSlice({
  name: "route paths state",
  initialState,
  reducers: {
    login: (state) => (state = "login"),
  },
});

export const { login,  dashboard } =
  RouteSlice.actions;
